import { Stack, Select, MenuItem, Chip } from '@mui/material';
import { SearchBar } from '../../stories/SearchBar';
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import './index.scss';

const getOptionsMap = (arr, props) => {
  const obj = {};
  arr.forEach((item) => {
    // 如果是对象
    let id = item;
    if (typeof item === 'object') {
      id = item[props.id];
    }
    obj[id] = item;
  });
  return obj;
};
// 检索
function SearchForm({ searchPlaceholder, searchText, handleCancel, handleSearch }) {
  return (
    <Stack className="select-form">
      <SearchBar
        searchText={searchText}
        placeholder={searchPlaceholder}
        handleCancel={(e) => {
          e.stopPropagation();
          handleCancel(e);
        }}
        handleChange={(e) => {
          e.stopPropagation();
          handleSearch(e.target.value);
        }}
      />
    </Stack>
  );
}

function BuildOptions({ options, multiple = false, selectedId, props, onHandleChange }) {
  return (
    <Stack className="common-select-options">
      {options.length > 0 ? (
        options.map((item) => {
          // 如果是对象
          let id = item;
          let name = item;
          if (typeof item === 'object') {
            id = item[props.id];
            name = item[props.label];
          }
          const isActive = multiple ? selectedId.includes(id) : id === selectedId;
          return (
            <MenuItem
              className={clsx('common-select-item', {
                'Mui-selected': isActive,
              })}
              key={id}
              value={id}
              onClick={(event) => {
                // 支持多选情况下
                if (multiple) {
                  // 如果已选，则移除
                  if (isActive) {
                    onHandleChange(
                      event,
                      selectedId.filter((item) => item !== id)
                    );
                  } else {
                    // 未选，则添加
                    onHandleChange(event, [...selectedId, id]);
                  }
                } else {
                  onHandleChange(event, id);
                }
              }}
            >
              {name}
            </MenuItem>
          );
        })
      ) : (
        <Stack
          className="common-select-empty"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          No Data
        </Stack>
      )}
    </Stack>
  );
}

export default function CommonSelect({
  className,
  placeholder,
  displayEmpty = true,
  value,
  options = [],
  MenuProps = {},
  props = {
    id: 'id',
    label: 'name',
  },
  onChange,
  disabled = false,
  isFilter = false,
  searchPlaceholder = 'Search',
  multiple = false,
  onFilter,
}) {
  // 获取当前options的map
  const optionsMap = getOptionsMap(options, props);
  const [filterOptions, setFilterOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const wrapperRef = useRef(null);

  const toggleOpen = (event) => {
    if (disabled) {
      return;
    }
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      isOpen ? setIsOpen(false) : setIsOpen(true);
    } else {
      setIsOpen(true);
    }
  };

  // 选中某一个option
  const onHandleChange = (event, val) => {
    event.stopPropagation();
    if (onChange) {
      // 传递当前select的value 和 option item
      onChange(val);
      setIsOpen(false);
    }
  };

  // 通过关键字过滤
  useEffect(() => {
    const text = searchText.trim();
    const filterArr =
      text.length > 0
        ? options.filter((item) => {
            let name = item;
            if (typeof item === 'object') {
              name = item[props.label];
            }
            if (name.toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
              return true;
            } else {
              return false;
            }
          })
        : options;
    setFilterOptions(filterArr);
  }, [searchText]);

  // 通过关键字过滤过滤
  const handleCancel = () => {
    setSearchText('');
  };
  const handleSearch = (keywords) => {
    console.log('keywords', keywords);
    setSearchText(keywords);
    if (onFilter) {
      onFilter(keywords);
    }
  };
  const handleDelete = (val) => {
    // event.stopPropagation();
    if (onChange) {
      // 传递当前select的value
      onChange(value.filter((item) => item !== val));
    }
  };
  return (
    <Select
      ref={wrapperRef}
      className={`common-select ${className}`}
      MenuProps={{
        ...MenuProps,
        className: clsx(
          'common-select-menu',
          {
            'common-select-filter': isFilter,
          },
          MenuProps.className
        ),
        MenuListProps: {
          variant: 'menu', //默认为selectedMenu，不设置这个，输入关键字，会出bug
        },
      }}
      multiple={multiple}
      displayEmpty={displayEmpty}
      disabled={disabled}
      value={value}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span className="placeholder">{placeholder}</span>;
        } else {
          // 拓展，增加多选
          if (multiple) {
            return selected.map((value) => {
              const item = optionsMap[value];
              let itemLabel = '';
              let itemId = '';
              // 如果数组是对象格式，则通过id，获取展示的文案
              if (typeof item === 'object') {
                itemLabel = item[props.label];
                itemId = item[props.id];
              } else {
                itemLabel = item;
                itemId = item;
              }
              return (
                <Chip
                  key={itemId}
                  label={itemLabel}
                  onDelete={() => handleDelete(itemId)}
                />
              );
            });
          } else {
            const item = optionsMap[selected];
            // 如果数组是对象格式，则通过id，获取展示的文案
            if (typeof item === 'object') {
              return item[props.label];
            }
            return item;
          }
        }
      }}
      open={isOpen}
      onClick={(event) => {
        event.stopPropagation();
        toggleOpen(event);
      }}
    >
      <Stack ref={wrapperRef}>
        {isFilter && (
          <SearchForm
            searchPlaceholder={searchPlaceholder}
            searchText={searchText}
            handleCancel={handleCancel}
            handleSearch={handleSearch}
          ></SearchForm>
        )}
        <BuildOptions
          props={props}
          options={filterOptions}
          selectedId={value}
          multiple={multiple}
          onHandleChange={onHandleChange}
        ></BuildOptions>
      </Stack>
    </Select>
  );
}
