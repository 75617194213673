import React, { useState, useEffect } from 'react';
import SaveYourJob from '../../../../images/saveYourJob.svg';
import { ModalcloseIcon } from '../../../../components/icons';
import { Button } from '../../../../stories/Button';
import Modal from '@mui/material/Modal';
import { OB_STEP, OB_STATUS } from '../../../../constants/onboarding';
import ReactPlayer from 'react-player';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import '../../../../styles/typography.scss';
import { getProfiles } from '../../../../services/ProfileServices';
import { Mixpanel } from '../../../../utilities/mixpanel';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: grey[100],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgressDark: {
    color: grey[600],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ApplyForANewJob = (props) => {
  const [open, setOpen] = useState(false);
  const [resumeSizeStatus, setResumeSizeStatus] = useState(true);
  const [profileExists, setProfileExists] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(true);
  const [loading, setResumeLoading] = useState(false);

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
    Mixpanel.track('Onboarding: Watch Auto-fill Video');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onUploadResumeButtonClick = () => {
    // track when upload button clicked
    Mixpanel.track('onboarding: Upload Resume');
  };

  const onFileChange = (event) => {
    setResumeLoading(true);

    if (event.target.files[0].size > 5000000) {
      setUploadStatus(false);
      setResumeSizeStatus(false);
      setResumeLoading(false);
    } else {
      props
        .uploadResume(event.target.files[0])
        .then((result) => {
          if (result && result.code === 'ECONNABORTED') {
            setUploadStatus(false);
          } else {
            setUploadStatus(true);
          }
          setResumeSizeStatus(true);
          setResumeLoading(false);
          Mixpanel.track('onboarding: Upload Resume Successful');
        })
        .catch((error) => {
          //Short term solution: Error type varied, here need to allow user can re-upload resume despite of the error type
          setUploadStatus(false);
          setResumeLoading(false);
          setResumeSizeStatus(true);
          Mixpanel.track('onboarding: Upload Resume Failed');
          throw error;
        });
    }
    handleClose();
  };

  useEffect(() => {
    getProfiles().then((res) => {
      if (res && res.data) {
        let profileExists = res.data.titles[0].resume_name;
        setProfileExists(profileExists !== null);
      }
    });
  }, []);

  const videoSection = (
    <div className="p-3 video-section">
      <img
        className="video-pic"
        src={SaveYourJob}
        alt="applyYourJobVideo"
        onClick={handleOpen}
      />
      <div>
        <div className="video-header body-text-12 ml-2">Walk through tutorials</div>{' '}
        <div className="body-text-14 ml-2">
          Learn how to use auto-fill job applications
        </div>
      </div>
    </div>
  );

  const downloadExtension = !props.downloadExtensionComplete && (
    <div className="download-extension-reminder body-text-18">
      Need to{' '}
      <span
        className="download-extension-reminder-link header-18"
        onClick={() => {
          Mixpanel.track('onboarding: Download Plugin');
          window.open(
            'https://chrome.google.com/webstore/detail/offerland/cgneenbonfipejfincfoeljokamigfae',
            '_self'
          );
        }}
      >
        Download Extension
      </span>
    </div>
  );

  const tryButton = (
    <Button
      height="39px"
      width="284px"
      isDisabled={!props.downloadExtensionComplete}
      onClick={() => {
        window.open(
          `${window.location.origin}/example-application?tlm-ref=ApplyForANewJob`,
          '_self'
        );
        handleClose();
        Mixpanel.track('Onboarding: Click Next to Open Example Application');
      }}
    >
      {profileExists
        ? props.saveAndTrackJobsComplete
          ? 'Try Auto-fill with Example Job'
          : 'Skip step 2 &  Try Auto-fill'
        : 'Try Auto-fill with Example Job'}
    </Button>
  );

  const backButton = (
    <Button
      height="39px"
      width="278px"
      isDisabled={!props.downloadExtensionComplete}
      onClick={() =>
        localStorage.getItem(OB_STATUS) === 'SaveAndTrackJobs'
          ? props.setStep(OB_STEP.DOWNLOAD_EXTENSION_COMPLETE)
          : props.setStep(OB_STEP.SAVE_AND_TRACK_JOBS)
      }
    >
      Back to step 2
    </Button>
  );

  const resumeSubmitButton = (
    <div className="v-center mt-1">
      <input
        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="d-none"
        id="contained-button-file-onboard"
        multiple
        type="file"
        disabled={!props.downloadExtensionComplete}
        onChange={onFileChange}
      />
      <label htmlFor="contained-button-file-onboard">
        <Button
          height="39px"
          width="268px"
          isDisabled={!props.downloadExtensionComplete}
          variant="contained"
          component="span"
          onClick={onUploadResumeButtonClick}
        >
          {props.downloadExtensionComplete && !props.saveAndTrackJobsComplete
            ? 'Skip step 2 & Upload Resume'
            : uploadStatus
            ? 'Upload Resume'
            : 'Upload Again'}
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </label>
    </div>
  );

  const stepView1 = (
    <div className="tlm-onboard-card pl-3 pr-3 pb-3">
      {videoSection}
      <div className="tlm-onboard-card-step body-text-14 m-2">Step 1/2</div>
      <div className="body-text-16 m-2 grey-reminder">
        Let's build your profile first. We will use that to auto-fill applications later.{' '}
      </div>
      {downloadExtension}
      {resumeSizeStatus ? (
        uploadStatus ? null : (
          <div className="tlm-onboard-card-title-sub-warning">
            Sorry, the file could not be upload.
            <p>Please try again.</p>
          </div>
        )
      ) : (
        <div className="tlm-onboard-card-title-sub-warning">
          Sorry, this file is too large.
          <p>Please upload a file less than 5MB.</p>
        </div>
      )}
      {resumeSubmitButton}

      {props.downloadExtensionComplete && !props.saveAndTrackJobsComplete && (
        <div className="v-center m-2">{backButton}</div>
      )}
      {props.saveAndTrackJobsComplete && props.downloadExtensionComplete && (
        <div className="v-center tlm-onboard-card-title-sub">
          DOC, DOCX, PDF(Less than 5 MB)
        </div>
      )}
    </div>
  );

  const stepView2 = props.allComplete ? (
    <div className="tlm-onboard-card pl-3 pr-3 pb-3">
      {videoSection}
      <div className="pt-2">
        We support autofilling applications on many job websites like Greenhouse,
        Jobslever, SmartRecruiters and etc.
      </div>
    </div>
  ) : (
    <div className="tlm-onboard-card pl-3 pr-3 pb-3">
      {videoSection}
      {!profileExists && (
        <div className="tlm-onboard-card-step body-text-14 m-2">Step 2/2</div>
      )}
      <div className="body-text-16 m-2 grey-reminder">
        Try to auto-fill an example job application with your profile.
      </div>
      {downloadExtension}
      <div className="v-center m-2">{tryButton}</div>
      {profileExists && !props.saveAndTrackJobsComplete && (
        <div className="v-center m-2">{backButton}</div>
      )}
    </div>
  );

  const helperModal = (
    <Modal open={open}>
      <div className="onboarding-modal">
        <div className="onboarding-modal-closeButton" onClick={handleClose}>
          {ModalcloseIcon}
        </div>
        <p className="page-title-24 text-center">Auto-fill your first job👇</p>
        <div className="v-center m-3">
          <ReactPlayer playing={true} url="https://youtu.be/aUdgR32uvfE" />
        </div>
        {!props.allComplete && (
          <div className="v-center m-3">
            {props.applyNewJobStep === '2' || profileExists
              ? tryButton
              : resumeSubmitButton}
          </div>
        )}
      </div>
    </Modal>
  );

  function getStepView() {
    switch (props.applyNewJobStep) {
      case '1-1':
        return profileExists ? stepView2 : stepView1;
      case '2':
        return stepView2;
      default:
        return profileExists ? stepView2 : stepView1;
    }
  }

  return (
    <>
      {getStepView()}
      {helperModal}
    </>
  );
};

export default ApplyForANewJob;
