import React, { useState, useEffect } from 'react';
import './Dropdown.scss';
import clsx from 'clsx';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CheckboxWrapper from '../components/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';

/*Dropdown arrow*/
const Arrow = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 0.590027L6 5.17003L10.59 0.590027L12 2.00003L6 8.00003L0 2.00003L1.41 0.590027Z"
      fill="#323232"
    />
  </svg>
);

/*
 label: optional, used to put inside dropdown button
 isDisabled: optional,true will set dropdown disabled
 isSelected:optional,true will set dropdown as selected style
 itemsAry: array of string , used for render options field inside dropdown
 classNames: give dropdown customized classname
 noCheckbox: default will allow checkbox options
 defaultValue: set certain option is checked
 value: keep track which option is choosed.
 */

export interface DropdownProps {
  label?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  itemsAry: Array<string>;
  classNames?: string;
  /*Default we allow checkbox */
  noCheckbox?: boolean;
  onChange: (e: string) => void;
  defaultValue?: string;
  value: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  isDisabled = false,
  isSelected = false,
  noCheckbox = false,
  itemsAry,
  classNames = '',
  onChange,
  label = '',
  value,
  defaultValue,
  ...props
}) => {
  const [choosedValue, setchoosedValue] = useState(value);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const {
      target: { name },
    } = event;
    setchoosedValue(name as string);
    onChange(name as string);
    setOpen(false);
  };
  useEffect(() => {
    // reset value to empty once we become disabled
    setchoosedValue('');
  }, [isDisabled]);

  useEffect(() => {
    setchoosedValue(value);
  }, [value]);

  let dropDownClassName = 'tlm-dropdown-select';
  let menuClassName = 'tlm-dropdown-menu-item';
  let dropdownFormControlName = 'tlm-dropdown-form-control';
  const labelFlag = label !== '';

  dropDownClassName = clsx(dropDownClassName, classNames);
  if (isDisabled) {
    dropDownClassName = clsx(dropDownClassName, 'tlm-dropdown-select-disabled');
    dropdownFormControlName = clsx(
      dropdownFormControlName,
      'tlm-dropdown-form-control-disabled'
    );
  }

  if (noCheckbox) {
    dropDownClassName = clsx(dropDownClassName, 'tlm-dropdown-select-checkbox');
    menuClassName = clsx(menuClassName, 'tlm-dropdown-menu-item-checkbox');
  }

  return (
    <FormControl className={dropdownFormControlName}>
      {labelFlag && (
        <InputLabel id="drop-down-label" className="tlm-dropdown-label">
          {label}
        </InputLabel>
      )}
      <Select
        className={dropDownClassName}
        sx={{ borderRadius: '20px' }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={choosedValue}
        defaultValue={choosedValue}
        renderValue={(selected) => <>{selected}</>}
        disabled={isDisabled}
        IconComponent={() => <div className="tlm-dropdown-icon"> {Arrow}</div>}
        labelId="drop-down-label"
        id="drop-down-label"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          style: {
            transform: ' translateY(10px)',
          },
          // getContentAnchorEl: null,
          PaperProps: {
            style: {
              border: '1px solid #05756C',
              borderRadius: '8px',
            },
          },
        }}
      >
        <div className={menuClassName}>
          {itemsAry.map((ele, key) => (
            <FormControlLabel
              key={key}
              control={
                <CheckboxWrapper
                  checked={choosedValue === ele}
                  onChange={(
                    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
                  ) => handleChange(e)}
                />
              }
              label={ele}
              name={ele}
            />
          ))}
        </div>
      </Select>
    </FormControl>
  );
};
