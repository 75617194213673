import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Snackbar } from '@mui/material';
import './index.scss';
import { getJob } from '../../services/JobServices';
import { createJob } from '../../services/JobServices';
import { emptyJob } from '../../services/JobServices';
import ModalHead from './components/ModalHead';
import JobInfoTab from './components/JobInfoTab';
import SavePrompt from './components/CancelConfirmationModal';
import errorMessages from '../dashboard-view/errorMessages';
import { DASHBOARDCONSTANTS } from '../dashboard-view/DashboardConstants';
import { Mixpanel } from '../../utilities/mixpanel';

function JobModal({
  tabId,
  jobId,
  closeJobModal,
  dashboardId,
  setRows,
  setPlanUpgradeModal,
}) {
  const [job, setJob] = useState({ timestamps: [], todos: [] });
  const [tabIndex, setTabIndex] = useState(tabId);
  const [snack, setSnack] = useState({
    open: false,
    status: job.id
      ? DASHBOARDCONSTANTS.snackStatus.SUCCESS
      : DASHBOARDCONSTANTS.snackStatus.ERROR,
    type: job.id
      ? DASHBOARDCONSTANTS.snackType.SAVEJOB
      : DASHBOARDCONSTANTS.snackType.CREATEJOB,
  });
  const [onSaveClicked, setOnSaveClicked] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (jobId != 'new job') {
      getJob(jobId).then((res) => {
        if (res.data.company) {
          if (res.data.company.name) {
            setJob({ ...res.data, company_name: res.data.company.name });
          }
        } else {
          setJob(res.data);
        }
      });
    }
  }, [jobId]);

  const handleCloseBtnClicked = () => {
    if (!hasChanged) handleClose();
    else setShowPrompt(true);
  };

  const handleClose = () => {
    Mixpanel.track('Cancel Add New Job');
    setShowPrompt(false);
    closeJobModal();
    setJob(emptyJob);
  };

  const handleSave = () => {
    setOnSaveClicked(!onSaveClicked);
    setShowPrompt(false);
    if (!job.title || !job.company_name) {
      setSnack({
        open: true,
        status: DASHBOARDCONSTANTS.snackStatus.ERROR,
        type: DASHBOARDCONSTANTS.snackType.MISSINGFIELD,
      });
      return;
    }

    createJob(dashboardId, job)
      .then((response) => {
        setJob({
          ...job,
          id: response.data.id,
        });
        setSnack({
          open: true,
          status: DASHBOARDCONSTANTS.snackStatus.SUCCESS,
          type: DASHBOARDCONSTANTS.snackType.CREATEJOB,
        });
        setHasChanged(false);
        // 新增成功，则需要重新刷新列表
        closeJobModal(true);
      })
      .catch((err) => {
        // 状态码400 则表示需要付费
        if (JSON.stringify(err).includes(400)) {
          setPlanUpgradeModal(true);
          Mixpanel.track('UpgradeToPremiumPopup_Dashboard');
          closeJobModal(true);
        } else {
          setSnack({
            open: true,
            status: DASHBOARDCONSTANTS.snackStatus.ERROR,
            type: DASHBOARDCONSTANTS.snackType.CREATEJOB,
          });
        }
      });
  };

  const onselectTab = (index) => {
    if (index === tabIndex) return;
    if (!job.title || !job.company_name) {
      setSnack({
        open: true,
        status: DASHBOARDCONSTANTS.snackStatus.ERROR,
        type: DASHBOARDCONSTANTS.snackType.MISSINGFIELD,
      });
      return;
    }
    setTabIndex(index);
  };

  return (
    <div className="tlm-jobModal">
      <div className="tlm-jobModal-leftdiv">
        <ModalHead
          dashboardId={dashboardId}
          job={{ ...job }}
          setJob={setJob}
          closeJobModal={closeJobModal}
          handleSave={handleSave}
          setSnack={setSnack}
        />
        <Tabs selectedIndex={tabIndex} onSelect={(index) => onselectTab(index)}>
          <TabList>
            <Tab>Job Info</Tab>
          </TabList>
          <TabPanel>
            <JobInfoTab
              onSave={onSaveClicked}
              job={job}
              setJob={setJob}
              dashboardId={dashboardId}
              setHasChanged={setHasChanged}
              handleSave={handleSave}
              handleCloseBtnClicked={handleCloseBtnClicked}
            />
          </TabPanel>
        </Tabs>
      </div>
      <CloseIcon className="tlm-jobModal-closebtn" onClick={handleCloseBtnClicked} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack.open}
        autoHideDuration={6000}
        message={
          snack.status === DASHBOARDCONSTANTS.snackStatus.SUCCESS
            ? `The job is ${
                snack.type === DASHBOARDCONSTANTS.snackType.SAVEJOB ? 'saved' : 'created'
              } successfully`
            : errorMessages[snack.type]
        }
        onClose={() => {
          setSnack({
            open: false,
            status: DASHBOARDCONSTANTS.snackStatus.SUCCESS,
            type: DASHBOARDCONSTANTS.snackType.SAVEJOB,
          });
        }}
      ></Snackbar>
      <SavePrompt
        show={showPrompt}
        onCancelFunc={handleClose}
        setShowPrompt={setShowPrompt}
      />
    </div>
  );
}

JobModal.propTypes = {
  tabId: PropTypes.string,
  jobId: PropTypes.string,
  closeJobModal: PropTypes.func,
  dashboardId: PropTypes.string,
};

export default JobModal;
