import React from 'react';
import clsx from 'clsx';

import { editIcon, addBoxIcon } from '@components/icons';

import { Stack } from '@mui/material';

const BoxHeader = ({
  title = '',
  onlyTitle = false,
  isEditIcon = false,
  disabled = false,
  handleClick,
}) => {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Stack className="block-box-title">{title}</Stack>
      {!onlyTitle && (
        <Stack
          className={clsx('svg-icon-button', { disabled: disabled })}
          onClick={() => {
            if (disabled) {
              return;
            } else {
              handleClick();
            }
          }}
        >
          {isEditIcon ? editIcon : addBoxIcon}
        </Stack>
      )}
    </Stack>
  );
};

export default BoxHeader;
