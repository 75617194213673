import React, { useState, useEffect } from 'react';
import GuidanceHeader from './components/GuidanceHeader';
import GuidanceStepCards from './components/GuidanceStepCards';
import NUX from './components/NUX';
import { getUserGuide } from '../../services/GuidanceServices';
import './index.scss';

export default function Guidance(props) {
  const [guidance, setGuidance] = useState('');

  useEffect(() => {
    if (props.guidance) {
      getUserGuide(props.guidance.id).then((res) => {
        setGuidance(res.data);
      });
    } else {
      setGuidance('');
    }
  }, [props.guidance]);

  return (
    <div className="guidance">
      <GuidanceHeader guidance={guidance} />
      {props.guidance !== 'guideNUX' ? (
        <div className="guidance-cards">
          <GuidanceStepCards guidance={guidance} />
        </div>
      ) : (
        <NUX
          guidance={props.guidance}
          directToGuidance={props.directToGuidance}
          userGuides={props.userGuides}
          refreshMain={props.refreshMain}
        />
      )}
    </div>
  );
}
