import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import '../../../styles/typography.scss';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  form: {
    padding: '25px',
  },
  label: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'black',
    paddingRight: '15px',
  },
}));

const DummyApplication = (props) => {
  const classes = useStyles();
  return (
    <div className="dummay-page">
      <div className="dummy-company">
        <div className="round"></div>
        <div className="page-title-32">Try Auto-Fill with example job application</div>
        <div className="body-text-20">at Example Company</div>
      </div>
      <form className="dummy-form" noValidate autoComplete="off">
        <div className="dummy-yellow-title body-text-20">
          This step will NOT actually submit an application.
        </div>
        <div className="dummy-header page-title-24">
          Example job application
          <span className="body-text-16" style={{ color: 'red', marginLeft: '320px' }}>
            *{' '}
          </span>
          <span className="body-text-16">Required</span>
        </div>
        <Grid className={classes.form} container spacing={3}>
          <Grid item xs={12} className={classes.item}>
            <InputLabel className={classes.label}>
              First Name <span style={{ color: 'red' }}>*</span>{' '}
            </InputLabel>
            <input name="firstName" />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <InputLabel className={classes.label}>
              Last Name <span style={{ color: 'red' }}>*</span>{' '}
            </InputLabel>
            <input name="lastName" />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <InputLabel className={classes.label}>
              Email <span style={{ color: 'red' }}>*</span>{' '}
            </InputLabel>
            <input name="email" />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <InputLabel className={classes.label}>
              Confirm Your Email <span style={{ color: 'red' }}>*</span>{' '}
            </InputLabel>
            <input name="confirmEmail" />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <InputLabel className={classes.label}>Location (City) </InputLabel>
            <input name="city" />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <InputLabel className={classes.label}>Phone Number</InputLabel>
            <input name="phone" />
          </Grid>
        </Grid>
      </form>
      <div>
        <div className="dummy-links body-text-16">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.offerland.ai/', '_self');
            }}
          >
            {'<'} Back to Offerland.ai
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.offerland.ai/?tlm-ref=COMPLETE', '_self');
            }}
          >
            Skip {'>'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DummyApplication;
