import React, { useEffect, useState } from 'react';
import { AppBar, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Mixpanel } from '@utilities/mixpanel';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    paddingTop: '8vh',
    boxShadow: 'none',
  },
  root: {
    color: '#9B9B9B',
    fontWeight: 'bold',
    fontSize: 20,
    paddingLeft: 0,
  },
  selectedRoot: {
    outline: 'none',
    fontSize: 24,
    whiteSpace: 'nowrap',
    paddingLeft: 0,
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
    },
  },
  indicator: {
    backgroundColor: 'white',
  },
  offerlandRoot: {
    padding: '15px 0px 0px',
    borderBottom: '1px solid #E0E0E0',
  },
  offerlandTab: {
    justifyContent: 'end',
    minWidth: 'auto',
    fontSize: 17,
    '& > span': {
      display: 'block',
      textAlign: 'left',
      width: 'auto',
    },
  },
  selectedOfferlandTab: {
    justifyContent: 'end',
    minWidth: 'auto',
    fontSize: 17,
    color: '#05756C',
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
      color: '#05756C',
    },
  },
  selected: {
    color: '#05756C',
  },
  offerlandIndicator: {
    backgroundColor: '#05756C',
  },
}));

export default function TemplateNavBars(props) {
  const classes = useStyles();
  const [navbar, setNavbar] = useState();
  const [myTemplate, setMyTemplate] = useState(); //index value of firstline navbar
  const [offerlandTemplate, setOfferlandTemplate] = useState(); //index value of secondline navbar

  useEffect(() => {
    setMyTemplate(props.categoryIndex < 0 ? 0 : 1);
    setOfferlandTemplate(props.categoryIndex < 0 ? 0 : props.categoryIndex);
  }, [props.categoryIndex]);

  //change the firstline navbar index
  const handleChange = (event, newTemplate) => {
    if (newTemplate === 0) {
      props.setTemplate('My Template');
    } else if (newTemplate !== myTemplate) {
      props.setTemplate(props.categories[offerlandTemplate].name);
    }
    props.setEditCard();
    setMyTemplate(newTemplate);
  };

  //change the secondline navbar index
  const changeOfferlandTemplate = (event, newOfferlandTemplate) => {
    setOfferlandTemplate(newOfferlandTemplate);
    const title = props.categories[newOfferlandTemplate].name;
    props.setTemplate(title);
    if (title !== 'Blank Template') {
      props.setEditCard();
    } else {
      props.setEditCard({
        title: 'Blank Template',
        content: '',
        template_category: props.categories.find((c) => c.name === 'Blank Template').id,
      });
    }
    const trackInfo = `Click ${title.includes('Template') ? title : title + ' Template'}`;
    Mixpanel.track(trackInfo);
  };

  const getSelectedTemplate = (t) => {
    if (myTemplate === t) {
      return { root: classes.selectedRoot };
    } else {
      return { root: classes.root };
    }
  };

  const getSelectedOfferlandTemplate = (t) => {
    if (offerlandTemplate === t) {
      return { root: classes.selectedOfferlandTab };
    } else {
      return { root: classes.offerlandTab };
    }
  };

  const firstTimeNavSlogan1 = (
    <div className="template-navbar-slogan">
      What kind of template are you looking for?
    </div>
  );
  const firstTimeNavSlogan2 = (
    <div className="template-navbar-slogan">Choose a template to start</div>
  );

  //secondline of navbar
  const offerlandTemplateNavBars = (
    <div>
      <Tabs
        classes={{ root: classes.offerlandRoot, indicator: classes.offerlandIndicator }}
        value={offerlandTemplate}
        onChange={changeOfferlandTemplate}
        indicatorColor="primary"
        textColor="inherit"
      >
        {props.categories.map((category) => {
          return (
            <Tab
              classes={getSelectedOfferlandTemplate(props.categories.indexOf(category))}
              disableRipple
              label={category.name}
              {...a11yProps(props.categories.indexOf(category))}
            />
          );
        })}
      </Tabs>
    </div>
  );

  //firstline of navbar
  const templateNavBars = (
    <div>
      <AppBar className={classes.appBarRoot} position="static" color="inherit">
        <Tabs
          classes={{ indicator: classes.indicator }}
          TabIndicatorProps={{ outline: 'none' }}
          value={myTemplate}
          onChange={handleChange}
        >
          <Tab
            classes={getSelectedTemplate(0)}
            disableRipple
            label="My Template"
            {...a11yProps(0)}
          />
          <Tab
            classes={getSelectedTemplate(1)}
            disableRipple
            label="Offerland Template"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
    </div>
  );

  return (
    <div>
      {props.template === 'My Template' && props.firstTime && (
        <div>{firstTimeNavSlogan1}</div>
      )}
      {props.template === 'My Template' && !props.firstTime && (
        <div>{templateNavBars} </div>
      )}
      {props.template !== 'My Template' && props.firstTime && (
        <div>
          {firstTimeNavSlogan2}
          {offerlandTemplateNavBars}
        </div>
      )}
      {props.template !== 'My Template' && !props.firstTime && (
        <div>
          {templateNavBars}
          {offerlandTemplateNavBars}
        </div>
      )}
    </div>
  );
}
