import React, { useEffect, useState } from 'react';
import './JobBoardNewGradTable.scss';
import { Table, TableBody, TableCell, TableHead, TableRow, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Tag } from '../../../stories/Tag';
import {
  FireIcon,
  LinkIcon,
  PlusIcon,
  DefaultSortIcon,
  SortIconDown,
  SortIconUp,
  companyIcon,
} from '../../../components/icons';
import { SaveNgData, getUserName } from '../../../services/JobBoardServices';

import { Button } from '../../../stories/Button.tsx';
import JobboardConstants from '../JobboardConstants';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Mixpanel } from '../../../utilities/mixpanel';
import LoadingProgress from '../../../components/LoadingProgress';
import UpgradePlanModal from '../../../components/CommonDialog/UpgradePlanModal';
import PreloaderModal from '../../../components/CommonDialog/PreloaderModal';
export default function JobBoardNewGradTable({
  sortInfo,
  setSortInfo,
  totalData = [],
  defaultDashboard,
  directToDashboard,
  setCurrentTotalData,
  // setOriginalData,
  loadMore,
  hasMore,
  noDataFlag,
  arrowHandler,
  setSnackBarOpen,
  setSnackBarRow,
  loading,
  refetch,
}) {
  const [isPlanUpgradeModal, setPlanUpgradeModal] = useState(false);
  const [saveJobCount, setSaveJobCount] = useState(false);
  const [preloaderActive, setPreloaderActive] = useState(false);
  const [savingJobIds, setSavingJobIds] = useState({}); // 暂存发起job save的id，已经发起的save 按钮，变成loading
  const isOnFireJob = (temp) => {
    if (temp === null || temp === undefined) {
      return false;
    }
    const prev = new Date(temp);
    const today = Date.parse(new Date());
    const nextWeek = Date.parse(
      new Date(prev.getFullYear(), prev.getMonth(), prev.getDate() + 8)
    );
    return nextWeek > today;
  };

  const createSponsorCell = (id) => {
    if (!id) {
      id = 0;
    }
    const label = JobboardConstants.sponsorshipType[id];
    switch (id) {
      case 0:
        return (
          <Tag
            label={label}
            themeColor="c-grey-50"
            className="tlm-sponsor-cell-unknown"
          />
        );
      case 1:
        return (
          <Tag label={label} themeColor="c-grey-50" className="tlm-sponsor-cell-yes" />
        );
      case 2:
        return (
          <Tag label={label} themeColor="c-grey-50" className="tlm-sponsor-cell-no" />
        );
      default:
        return (
          <Tag
            label={label}
            themeColor="c-grey-50"
            className="tlm-sponsor-cell-potential"
          />
        );
    }
  };
  const handleSortClick = (type) => {
    if (sortInfo.field === type) {
      setSortInfo((prev) => {
        // convert from downward to upward
        if (prev.direction === 'down') {
          return { ...prev, direction: 'up' };
        }
        // convert from upward to none
        if (prev.direction === 'up') {
          return { field: '', direction: 'none' };
        }
      });
    } else {
      setSortInfo({ field: type, direction: 'down' });
    }
  };

  useEffect(() => {
    setSaveJobCount(
      defaultDashboard?.jobs?.pin?.length + defaultDashboard?.jobs?.unpin?.length
    );
  }, [defaultDashboard]);

  useEffect(() => {
    toGetDashboardPopup();
  }, [saveJobCount]);

  function handleCloseOnboardingModal() {
    setPlanUpgradeModal(false);
  }

  function toGetDashboardPopup() {
    getUserName().then((result) => {
      let allSaveJobCount =
        result?.data[0]?.jobs?.pin?.length + result?.data[0]?.jobs?.unpin?.length;
      setSaveJobCount(allSaveJobCount);
    });
  }

  const dataSaveBtn = (row) => {
    // 如果当前job 已经在saving中，则直接返回，否则就变成true;只有在catch时，才允许再次点击
    if (savingJobIds[row.id]) return;
    setSavingJobIds({
      ...savingJobIds,
      [row.id]: true,
    });
    SaveNgData(row.id)
      .then((res) => {
        refetch();
        setSnackBarOpen(true);
        setSnackBarRow(row);
        Mixpanel.track('Save Job to Dashboard from List');
      })
      .catch((err) => {
        if (JSON.stringify(err).includes(400)) {
          setPlanUpgradeModal(true);
          Mixpanel.track('UpgradeToPremiumPopup_Jobboard');
          setSavingJobIds({}); // 如果已经超过上限，则清空所有暂存的saving job 标识
        } else {
          setSavingJobIds({
            ...savingJobIds,
            [row.id]: false,
          });
          console.log('err', err);
        }
      });
  };

  const buttonSaveData = (row) => {
    return (
      <Button
        className="tlm-ng-jobBoard-table_save_btn"
        isSecondary
        icon={savingJobIds[row.id] ? <CircularProgress size={12} /> : PlusIcon}
        onClick={() => dataSaveBtn(row)}
      >
        &nbsp;Save
      </Button>
    );
  };

  const handleButtonSubscreption = (row) => {
    // 1 都是save按钮，都能点击
    // 2 点击后，正常调后端接口，接口正常 则 执行 dataSaveBtn，接口不正常，则 handlePlanUpgradePopup
    return buttonSaveData(row);
  };

  return (
    <div className="tlm-ng-jobBoard-table">
      <UpgradePlanModal
        open={isPlanUpgradeModal}
        handleClose={handleCloseOnboardingModal}
        trigger="jobboard"
      />
      <PreloaderModal open={preloaderActive} />
      <InfiniteScroll
        dataLength={totalData.length} //This is important field to render the next data
        hasMore={false}
        loader={
          <div
            style={{
              marginTop: '33px',
              marginBottom: '33px',
            }}
          >
            <LoadingProgress />
          </div>
        }
        endMessage={
          loading ? (
            <div
              style={{
                marginTop: '33px',
                marginBottom: '33px',
              }}
            >
              <LoadingProgress />
            </div>
          ) : null
        }
      >
        <Table aria-label="New Grad">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '19%' }}>
                Company & Role{' '}
                <span
                  className="tlm-ng-jobBoard-sort-icon"
                  onClick={() => {
                    handleSortClick('role');
                    Mixpanel.track('Sort by Company and Role');
                  }}
                >
                  {sortInfo.field !== 'role'
                    ? DefaultSortIcon()
                    : sortInfo.direction === 'up'
                    ? SortIconDown
                    : SortIconUp}
                </span>
              </TableCell>
              <TableCell style={{ width: '14%' }}>
                Year & Season{' '}
                <span
                  className="tlm-ng-jobBoard-sort-icon"
                  onClick={() => {
                    handleSortClick('year');
                    Mixpanel.track('Sort by Year and Season');
                  }}
                >
                  {sortInfo.field !== 'year'
                    ? DefaultSortIcon()
                    : sortInfo.direction === 'up'
                    ? SortIconDown
                    : SortIconUp}
                </span>
              </TableCell>
              <TableCell style={{ width: '15%' }}>
                Location{' '}
                <span
                  className="tlm-ng-jobBoard-sort-icon"
                  onClick={() => {
                    handleSortClick('location');
                    Mixpanel.track('Sort by Location');
                  }}
                >
                  {sortInfo.field !== 'location'
                    ? DefaultSortIcon()
                    : sortInfo.direction === 'up'
                    ? SortIconDown
                    : SortIconUp}
                </span>
              </TableCell>
              <TableCell style={{ width: '13.5%' }}>
                Sponsorship{' '}
                <span
                  className="tlm-ng-jobBoard-sort-icon"
                  onClick={() => {
                    handleSortClick('sponsorship');
                    Mixpanel.track('Sort by Sponsorship');
                  }}
                >
                  {sortInfo.field !== 'sponsorship'
                    ? DefaultSortIcon()
                    : sortInfo.direction === 'up'
                    ? SortIconDown
                    : SortIconUp}
                </span>
              </TableCell>
              <TableCell style={{ width: '14.5%' }}>
                Date Opened{' '}
                <span
                  className="tlm-ng-jobBoard-sort-icon"
                  onClick={() => {
                    handleSortClick('date');
                    Mixpanel.track('Sort by Date Opened');
                  }}
                >
                  {' '}
                  {sortInfo.field !== 'date'
                    ? DefaultSortIcon()
                    : sortInfo.direction === 'up'
                    ? SortIconDown
                    : SortIconUp}
                </span>
              </TableCell>
              <TableCell style={{ width: '9%' }}></TableCell>
              <TableCell style={{ width: '15%' }}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {totalData.length > 0 &&
              totalData.map((row, key) => (
                <TableRow key={row.id + key}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="tlm-ng-jobBoard-table_role_cell"
                  >
                    {row.logo ? (
                      <img
                        className={
                          row.open_status === 2
                            ? 'tlm-ng-jobBoard-table_role_cell_icon_disabled'
                            : 'tlm-ng-jobBoard-table_role_cell_icon'
                        }
                        src={row.logo}
                        alt="company logo"
                        onError={(e) => {
                          e.target.src =
                            'https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png';
                        }}
                      />
                    ) : (
                      <span className="tlm-ng-jobBoard-table_role_cell_icon">
                        {companyIcon}
                      </span>
                    )}

                    <div className="tlm-ng-jobBoard-table_role_cell_info">
                      <span>{row.company_name}</span>
                      {row.title}
                    </div>
                  </TableCell>
                  <TableCell>{`${row.year}, ${
                    JobboardConstants.seasonType[row.season]
                  }`}</TableCell>
                  {row.open_status === 2 ? (
                    <>
                      <TableCell>{row.location}</TableCell>
                      <TableCell
                        // colSpan={4}
                        className="tlm-ng-jobBoard-table_previous_cell"
                      >
                        <div className="tlm-ng-jobBoard-table_previous_cell_container">
                          <Tag label="Currently Closed" themeColor="c-grey-100" />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>Previously Opened:</div>
                        <div>{row.open_date}</div>
                      </TableCell>
                      <TableCell>
                        <div className="tlm-ng-jobBoard-table_link_cell">
                          {LinkIcon}
                          <a
                            href={row.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => Mixpanel.track('Apply Job from List')}
                          >
                            Link
                          </a>
                        </div>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell className="tlm-ng-jobBoard-table_location_cell">
                        {row.location}
                      </TableCell>
                      <TableCell className="tlm-ng-jobBoard-table_sponsorship_cell">
                        {createSponsorCell(row.sponsorship)}
                      </TableCell>
                      <TableCell>
                        <div className="tlm-ng-jobBoard-table_date_cell">
                          {isOnFireJob(row.open_date) && FireIcon}
                          <span> {row.open_date}</span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="tlm-ng-jobBoard-table_link_cell">
                          {LinkIcon}
                          <a
                            href={row.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => Mixpanel.track('Apply Job from List')}
                          >
                            Apply
                          </a>
                        </div>
                      </TableCell>
                    </>
                  )}

                  <TableCell>
                    {!row.is_subscripted &&
                      !row.is_saved &&
                      row.open_status === 1 &&
                      handleButtonSubscreption(row)}
                    {row.open_status === 1 && row.is_saved && (
                      <div className="tlm-ng-jobBoard-table_save_btn">
                        Saved in <br />
                        <Link
                          to={`/dashboard/${defaultDashboard.id}`}
                          onClick={() => {
                            directToDashboard(defaultDashboard.id);
                            arrowHandler('dashboard');
                          }}
                        >
                          {defaultDashboard.name}
                        </Link>
                      </div>
                    )}
                    {row.open_status === 2 && row.is_subscripted && (
                      <div className="tlm-ng-jobBoard-table_save_btn">
                        Subscribed in <br />
                        <Link
                          to={`/dashboard/${defaultDashboard.id}`}
                          onClick={() => directToDashboard(defaultDashboard.id)}
                        >
                          {defaultDashboard.name}
                        </Link>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}

            {!loading && totalData.length === 0 && (
              <Stack
                sx={{
                  display: 'table-row',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  color: '#757575',
                  position: 'relative',
                  height: '72px',
                }}
              >
                <Stack
                  sx={{
                    p: 3,
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                  }}
                >
                  No rows
                </Stack>
              </Stack>
            )}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </div>
  );
}
