import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Stack, CircularProgress } from '@mui/material';
import { primaryUpload } from '@components/icons';
import './index.scss';

const UploadFile = ({ uploadFile, isLoading, error }) => {
  const [fileName, setFileName] = useState('');
  const [resumeSizeStatus, setResumeSizeStatus] = useState(true);
  const onFileChange = async (files) => {
    // event.persist();
    if (files[0].size > 5000000) {
      setResumeSizeStatus(false);
    } else {
      setFileName(files[0].name);
      if (uploadFile) {
        uploadFile(files[0]);
      }
      setResumeSizeStatus(true);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    onFileChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
        '.docx',
      ],
      'application/pdf': ['.pdf'],
    },
  });

  return (
    <Stack
      className="file-upload"
      sx={{ flex: 1 }}
      direction={'column'}
      justifyContent="center"
      alignItems="center"
    >
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Stack
          className="profile-upload-box"
          direction={'column'}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            disableElevation
            direction={'column'}
            justifyContent="center"
            alignItems="center"
          >
            <Stack className="upload-icon">
              {!isLoading ? primaryUpload : <CircularProgress size={24} />}
            </Stack>
            <Stack className="upload-info">
              {!isLoading ? (
                <>
                  <strong>Click to Upload</strong>, or Drag and Drop
                </>
              ) : (
                <strong>Uploading ...</strong>
              )}
            </Stack>
            <Stack className="upload-info">
              ({fileName ? fileName : 'DOC, DOCX, PDF less than 5 MB'})
            </Stack>
          </Stack>
        </Stack>
      </div>
      {(error || !resumeSizeStatus) && (
        <Stack className="warning-text">
          {error && 'The file could not be upload. Please try to upload resume again.'}
          {!resumeSizeStatus && 'Sorry, this file is too large.'}
        </Stack>
      )}
    </Stack>
  );
};

export default UploadFile;
