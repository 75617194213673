/**
 * 基础表单校验规则，只做非空校验
 * form    表单的数据项
 * rules   表单的校验规则
 *
 * return 表单的校验结果
 * */
export const baseFormValidator = (form = {}, rules = {}) => {
  const validKeys = Object.keys(rules);
  let errorFields = {};
  validKeys.forEach((key) => {
    if (isEmpty(form[key]) && rules[key].required) {
      errorFields[key] = rules[key].msg;
    }
  });
  return errorFields;
};

function isEmpty(value) {
  return value === null || value === undefined || value === '';
}
