import React, { useEffect, useState } from 'react';
import { Toggle } from '../../stories/Toggle';
import './index.scss';

import { getFeatureList } from '../../services/FeatureFlagServices';
import { postFeatureFlag } from '../../services/FeatureFlagServices';

function FeatureFlagView() {
  const [featureList, setFeatureList] = useState([]);

  useEffect(() => {
    getFeatureList().then((res) => {
      setFeatureList(res.data);
    });
  }, []);

  return (
    <div className="container">
      <p>These toggles will enable/disable features</p>
      <a href="/">Go Back to dashboard</a>
      {featureList.map((feature) => {
        return (
          <Toggle
            key={feature.name}
            label={feature.name}
            defaultChecked={feature.is_on}
            onChange={() => {
              postFeatureFlag(`${feature.name}`);
            }}
          />
        );
      })}
    </div>
  );
}

export default FeatureFlagView;
