import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import AutoFocusTextField from './AutoFocusTextField';
import PropTypes from 'prop-types';

import { addNewDashboardToDatabase } from '../../../services/InfoPanelServices';
import { popupError } from '../../../utilities/error-handler';
import { errorMessages } from '../constants';
import { MainPageContext } from '../index';
import { BUTTON, DASHBOARD } from '../../../constants/behaviors';
import { Mixpanel } from '../../../utilities/mixpanel';

const AddNewDashboardModal = ({ onAddNewDashboard, addDashboard, setAddDashboard }) => {
  const [newDashboardName, setNewDashboardName] = useState('');
  const [invalidNameError, setInvalidNameError] = useState('');

  const dashboards = useContext(MainPageContext).dashboards.map((item) => item.name);

  const handleAddNewDashboard = () => {
    const name = newDashboardName.trim();
    if (!name) {
      setInvalidNameError(errorMessages.nameNotExist);
    } else if (name.length > 30) {
      setInvalidNameError(errorMessages.nameTooLong);
    } else if (dashboards && dashboards.includes(name)) {
      setInvalidNameError(errorMessages.nameDuplicate);
    } else {
      setInvalidNameError('');
      addNewDashboardToDatabase(name)
        .then((response) => {
          if (response.data) {
            setNewDashboardName('');
            onAddNewDashboard(response.data.dashboard_detail.id);
            Mixpanel.track('Add New Dashboard', {
              DashboardName: name,
            });
          }
        })
        .catch(() => popupError(errorMessages.addDashboard));
    }
  };

  return (
    <Modal
      className="tlm-main-modal"
      show={addDashboard}
      onHide={() => {
        setAddDashboard(false);
        setInvalidNameError('');
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="tlm-main-modal-title">Create a new dashboard</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tlm-main-modal-input-panel">
          <form>
            <AutoFocusTextField
              placeholder="dashboard name"
              variant="outlined"
              value={newDashboardName}
              onChange={(e) => {
                setInvalidNameError('');
                setNewDashboardName(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddNewDashboard();
                }
              }}
            />
          </form>
        </div>
        <div className="tlm-main-modal-button-panel">
          <button
            className="tlm-main-modal-button-cancel"
            onClick={() => {
              setAddDashboard(false);
            }}
          >
            Cancel
          </button>
          <button className="tlm-main-modal-button-ok" onClick={handleAddNewDashboard}>
            Ok
          </button>
        </div>
        {invalidNameError ? (
          <div className="tlm-main-modal-invalid-dashboard-name">{invalidNameError}</div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

AddNewDashboardModal.propTypes = {
  onAddNewDashboard: PropTypes.func,
  addDashboard: PropTypes.bool,
  setAddDashboard: PropTypes.func,
};
export default AddNewDashboardModal;
