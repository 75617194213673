import { getFeatureFlag } from '../services/FeatureFlagServices';
import { postFeatureFlag } from '../services/FeatureFlagServices';

//for future features
export const CreateOrToggleFeatureFlag = async (featureName) => {
  let featureValue = false;
  await getFeatureFlag(featureName)
    .then((res) => {
      featureValue = res.data.is_on;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        postFeatureFlag(featureName);
      } else {
        console.log('Failed to get feature flag value', error);
      }
    });
  return featureValue;
};
