import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography } from '@mui/material';
import blankTemplate from '../../../images/blank-template.svg';
import followUp from '../../../images/follow-up.svg';
import linkedInMessages from '../../../images/linkedin.svg';
import thank from '../../../images/thank-you.svg';
import interview from '../../../images/interview-invite.svg';
import other from '../../../images/other-template.svg';
//Todo: we need server-side data for category in the future
import { getTemplateCategories } from '../../../services/EmailTemplateServices';
import { Mixpanel } from '@utilities/mixpanel';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 270,
    width: 315,
    borderRadius: 8,
    boxShadow: '0px 4px 20px rgba(0, 116, 132, 0.13)',
    margin: '40px 20px 0px 0px',
    cursor: 'pointer',
  },
  content: {
    position: 'relative',
    height: 270,
    width: 315,
  },
  img: {
    maxWidth: '100%',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    margin: 'auto auto',
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    top: 175,
    fontSize: 19,
    fontWeight: 800,
  },
  blank: {
    border: '2px solid #DADADA',
    boxShadow: 'none',
    boxSizing: 'border-box',
  },
}));

const firstTimeCardsInfo = [
  {
    img: linkedInMessages,
    name: 'Linkedin Message',
  },
  {
    img: followUp,
    name: 'Follow Up',
  },
  {
    img: thank,
    name: 'Thank You Email',
  },
  {
    img: interview,
    name: 'Interview',
  },
  {
    img: other,
    name: 'Offer',
  },
  {
    img: blankTemplate,
    name: 'Blank Template',
  },
];

export default function FirstTimeTemplateCards(props) {
  const classes = useStyles();

  const handleClick = (title) => {
    const trackInfo = `Click ${title.includes('Template') ? title : title + ' Template'}`;
    Mixpanel.track(trackInfo);
    props.setTemplate(title);
    if (title !== 'Blank Template') {
      props.setEditCard();
    } else {
      props.setEditCard({
        title: 'Blank Template',
        content: '',
        template_category: props.categories.find((c) => c.name === 'Blank Template').id,
      });
    }
  };

  return (
    <div className="template-cards">
      {/* uncomment following when backend store svg */}
      {/* <Card
        className={`${classes.root} ${classes.blank}`}
        onClick={() => handleClick("Blank Template")}
      >
        <CardContent className={classes.content}>
          <img className={classes.img} src={'/static/media/blank-template.038f5ae8.svg'} />
          <Typography className={classes.title}>Blank Template</Typography>
        </CardContent>
      </Card> */}
      {firstTimeCardsInfo.map((category) => {
        return (
          <Card
            className={
              category.title !== 'Blank Template'
                ? classes.root
                : `${classes.root} ${classes.blank}`
            }
            onClick={() => handleClick(category.name)}
          >
            <CardContent className={classes.content}>
              <img className={classes.img} src={category.img} />
              <Typography className={classes.title}>{category.name}</Typography>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}
