import React, { useState, useEffect } from 'react';
import { Stack, Button, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';

export default function AlertDialog({
  open = false,
  title = '',
  sureText = 'Save',
  cancelText = 'Cancel',
  onSure,
  onClose,
  children,
  width = '600px',
  loading = false,
}) {
  return (
    // onClose={onClose} 增该属性，点击遮罩或者esc，会关闭弹窗
    <Dialog open={open}>
      <Stack
        className="alter-dialog"
        sx={{ width: width }}
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack className="alter-dialog-title">{title}</Stack>
          <CloseIcon className="alter-dialog-close" onClick={onClose}></CloseIcon>
        </Stack>
        <Stack className="alter-dialog-content">{children}</Stack>
        <Stack
          className="alter-dialog-footer"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button variant="outlined" onClick={onClose}>
            {cancelText}
          </Button>
          <Button variant="contained" disabled={loading} onClick={onSure}>
            {sureText}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
