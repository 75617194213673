const errorMessages = {
  updateDashboardName: 'Have trouble renaming dashboard',
  getDashboardData: 'Have trouble getting dashboard',
  changeStatus: 'Have trouble changing status',
  setPinStatus: 'Have trouble setting pin status',
  deleteJobs: 'Have trouble deleting jobs',
  missingField: 'Please fill in the required information',
  addAJob: 'Have trouble adding a job',
  deleteJob: 'Have trouble deleting a job',
  createJob: 'Error occurred when creating the job, please try again later.',
  saveJob: 'Error occurred when saving the job, please try again later.',
  addTodo: 'Have trouble adding a todo',
  todoWithoutJob: 'Please create a job before adding a todo.',
  setpinWithoutJob: 'Please create a job before changing pin status',
  updateJobLink: 'Have trouble update job link',
  updateJobName: 'Have trouble update job name',
  updateCompanyName: 'Have trouble update company name',
  updateJobLocation: 'Have trouble update job location',
  updateJobDescription: 'Have trouble update job description',
  addJobNote: 'Have trouble add job note',
  removeJobNote: 'Have trouble remove job note',
  updateJobNote: 'Have trouble update job note',
};

export default errorMessages;
