import React from 'react';
import FormItem from './form-item';
import { Grid } from '@mui/material';
import { useFormMode } from '@utilities/form-hooks';

import '../style/index.scss';

const LinksForm = ({ info = {}, updateForm, changeFormValid }) => {
  const { formData, updateInfo } = useFormMode(info, updateForm, changeFormValid);
  return (
    <form className="" noValidate autoComplete="off">
      <Grid className="form-container" container spacing={2}>
        <Grid item xs={12}>
          <FormItem
            label="Linkedin Link"
            handleUpdate={(info, valid) => updateInfo('linkedin_link', info, valid)}
            value={formData.linkedin_link}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Portfolio Link"
            handleUpdate={(info, valid) => updateInfo('portfolio_link', info, valid)}
            value={formData.portfolio_link}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Github Link"
            handleUpdate={(info, valid) => updateInfo('github_link', info, valid)}
            value={formData.github_link}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Other Link"
            handleUpdate={(info, valid) => updateInfo('other_link', info, valid)}
            value={formData.other_link}
          ></FormItem>
        </Grid>
      </Grid>
    </form>
  );
};

export default LinksForm;
