import React, { useEffect, useState } from 'react';
import { Tab } from '../../stories/Tab';
import { TabItem } from '../../stories/Tab-item';
import accountIcon from '../../images/accountIcon.svg';
import SecurityItem from './components/SecurityItem';
import premiumIcon from '../../images/premiumIcon.svg';
import startIcon from '../../images/starterIcon.svg';
import checkMark from '../../images/checkMark.svg';
import { Button } from '../../stories/Button';
import {
  tabMemberActive,
  tabMemberNotActive,
  tabSecurityActive,
  tabSecurityNotActive,
} from '../../components/icons';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { STARTER_PLAN_COUNT } from '../../constants/planCount';
import { toGetStripeBillingPortal } from '../../services/PaymentServices';
import { makeStyles } from '@mui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import { checkNextDatePayment } from '../../utilities/tool';
import { useGetUserInfo } from '../../services/AccountServices';
import { useGetUserPaymentDetail } from '../../services/PaymentServices';
import { premiumFeature } from './components/PremiumFeature';
import { formatDate } from '../../utilities/tool';

const useStyles = makeStyles((theme) => ({
  title_usage: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000',
  },
  p_tag: {
    marginBottom: '5px',
  },
}));

const AccountView = (props) => {
  //Todo: need an api to track the number of attributes
  let defaultDashboard = props?.getAllRecords?.dashboards[0];
  let saveJobCt =
    defaultDashboard?.jobs?.pin?.length + defaultDashboard?.jobs?.unpin?.length;
  let profileCt = props?.getAllRecords?.profiles?.titles?.length;
  const classes = useStyles();
  const history = useHistory();
  const monthlyPrice = 14.99;
  const [saveJobCount, setSaveJobCount] = useState('0');
  const [profileCount, setProfileCount] = useState('0');
  const [enableToast, setEnableToast] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const {
    data: userInfo = {},
    isLoading: isUserInfoLoading,
    error: isUserInfoLoadingError,
  } = useGetUserInfo();
  const {
    email,
    login_method,
    auth0_user_id,
    user_id,
    first_name,
    is_subscripted,
    last_name,
    subscription_next_or_end_date,
    subscription_status,
  } = userInfo;
  const {
    data: userPaymentDetails,
    isLoading: isUserPaymentDetailLoading,
    error: isUserPaymentDetailError,
  } = useGetUserPaymentDetail(
    user_id && {
      user_id: user_id,
    }
  );

  const preprocessPaymentDetail = (data) => {
    let paymetType = data?.payment_amount <= monthlyPrice ? 'month' : 'quarter';
    let payment_details = {
      payment_amount: data?.payment_amount,
      next_payment_date: checkNextDatePayment(data?.payment_date, paymetType),
      payment_method: data?.payment_method,
      payment_type: paymetType,
    };
    return payment_details;
  };
  const newPaymentDetails = preprocessPaymentDetail(userPaymentDetails);

  const handleToManagePlanBilling = () => {
    let billingPortalObj = {
      user_id: user_id,
    };
    setIsActive(true);
    toGetStripeBillingPortal(billingPortalObj)
      .then((res) => {
        setIsActive(false);
        window.location.href = res.data.redirectURL;
      })
      .catch((error) => {
        setIsActive(false);
      });
  };

  const premiumDetail = [
    {
      key: 'Cost',
      value:
        '$' + newPaymentDetails?.payment_amount + '/' + newPaymentDetails?.payment_type,
    },
    // Only SUBSCRIBED and SUBSCRIBED_END_OF_PERIOD have premium view
    subscription_status === 1 && {
      key: 'Next subscription renewal',
      value: formatDate(subscription_next_or_end_date),
    },
    subscription_status === 2 && {
      key: 'Plan cancellation date',
      //Todo: need to update above status once next date is available
      value: formatDate(subscription_next_or_end_date),
    },
    {
      key: 'Payment method',
      value: newPaymentDetails?.payment_method,
    },
  ].filter(Boolean); // Filter out any false values resulting from the conditionals

  const handleUpgradePlan = () => {
    history.push('/plan-selection');
  };

  const premiumPlan = () => {
    return (
      <div className="tlm-plan-bill">
        <div className="tlm-plan-bill-current">Your current plan</div>
        <div className="tlm-plan-bill-premium">
          <img
            className="tlm-plan-bill-premium-icon"
            src={premiumIcon}
            alt="tlm-plan-bill-premium-icon"
          ></img>
          <div className="tlm-plan-bill-premium-content">
            <div className="tlm-plan-bill-premium-content-title">Offerland Premium</div>
            <div className="tlm-plan-bill-premium-content-subtitle">
              You are on Offerland Premium plan
            </div>
            {premiumDetail.map((detail) => {
              return (
                <div className="tlm-plan-bill-premium-content-detail" key={detail.key}>
                  <div className="tlm-plan-bill-premium-content-detail-key">
                    {detail.key}
                  </div>
                  <div className="tlm-plan-bill-premium-content-detail-value">
                    {detail.value}
                  </div>
                </div>
              );
            })}
            <div className="mt-4">
              <div className="d-flex" style={{ gap: '11px' }}>
                <div className="tlm-plan-bill-premium-content-title">Usage</div>
                <div
                  style={{
                    background: '#D3EFEC',
                    color: '#000',
                    bordeRadius: '12px',
                    padding: '5px',
                    fontSize: '12px',
                    fontWeight: '500',
                    borderRadius: '12px',
                  }}
                >
                  Unlimited
                </div>
              </div>

              <div className="tlm-plan-bill-premium-content-detail">
                <div className="tlm-plan-bill-premium-content-detail-key">Saved jobs</div>
                <div className="tlm-plan-bill-premium-content-detail-value">
                  {saveJobCount}
                </div>
              </div>
              <div className="tlm-plan-bill-premium-content-detail">
                <div className="tlm-plan-bill-premium-content-detail-key">Profile</div>
                <div className="tlm-plan-bill-premium-content-detail-value">
                  {profileCount}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="tlm-plan-bill-button"
          isSecondary={true}
          onClick={() => handleToManagePlanBilling()}
          isDisabled={isActive}
        >
          Manage plan & billing{' '}
          {isActive && <CircularProgress style={{ color: '#05756C' }} size={16} />}
        </Button>
      </div>
    );
  };

  const basicPlan = () => {
    return (
      <div className="tlm-plan-bill">
        <div className="tlm-plan-bill-current">Your current plan</div>
        <div className="tlm-plan-bill-starter">
          <img
            className="tlm-plan-bill-starter-icon"
            src={startIcon}
            alt="tlm-plan-bill-starter-icon"
          ></img>
          <div className="tlm-plan-bill-starter-content">
            <div className="tlm-plan-bill-starter-content-title">Offerland Starter</div>
            <div className="tlm-plan-bill-starter-content-description">
              You are on Offerland Starter plan
            </div>
            <div className="mt-4">
              <div className="tlm-plan-bill-starter-content-title">Usage</div>
              <div className="tlm-plan-bill-starter-content-description">
                <p className={classes.p_tag}>
                  <span className={classes.title_usage}>Saved jobs {saveJobCount}</span>/
                  {STARTER_PLAN_COUNT.SAVE_JOB_COUNTS}
                </p>
                <p>
                  <span className={classes.title_usage}>Profile {profileCount}</span>/
                  {STARTER_PLAN_COUNT.PROFILE_COUNTS}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tlm-plan-bill-upgrade">Consider upgrading to</div>
        <div className="tlm-plan-bill-toPremium">
          <img
            className="tlm-plan-bill-toPremium-icon"
            src={premiumIcon}
            alt="tlm-plan-bill-toPremium-icon"
          ></img>
          <div className="tlm-plan-bill-toPremium-main">
            <div className="tlm-plan-bill-toPremium-main-header">
              <div className="tlm-plan-bill-toPremium-main-header-title">
                Offerland Premium
              </div>
              <div className="tlm-plan-bill-toPremium-main-header-price">
                $14.99/month
              </div>
            </div>
            <div className="tlm-plan-bill-toPremium-main-content">
              {premiumFeature.map((feature) => {
                return (
                  <div
                    className="tlm-plan-bill-toPremium-main-content-feature"
                    key={feature.title}
                  >
                    <img
                      className="tlm-plan-bill-toPremium-main-content-feature-check"
                      src={checkMark}
                      alt="tlm-plan-bill-toPremium-main-content-feature-check"
                    />
                    <div className="tlm-plan-bill-toPremium-main-content-feature-body">
                      <div className="tlm-plan-bill-toPremium-main-content-feature-body-title">
                        {`${feature.title}: `}
                      </div>
                      <div className="tlm-plan-bill-toPremium-main-content-feature-body-desp">
                        {feature.description}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Button className="tlm-plan-bill-button" onClick={() => handleUpgradePlan()}>
          Upgrade plan
        </Button>
      </div>
    );
  };

  useEffect(() => {
    setSaveJobCount(saveJobCt);
    setProfileCount(profileCt);
  }, [props, profileCt, saveJobCt]);

  useEffect(() => {
    if (enableToast?.status === 200) {
      toast.success(enableToast?.data?.message);
    } else {
      if (enableToast?.status === 402) {
        toast.error(enableToast?.data?.error);
      } else if (enableToast.status === 400) {
        toast.error('Something went wrong. Please try again later.');
      }
    }
  }, [enableToast]);

  if (isUserInfoLoading || isUserPaymentDetailLoading) {
    return <div>Loading...</div>;
  }

  if (isUserInfoLoadingError || isUserPaymentDetailError) {
    return <div>Loading error, please refresh the page or contact customer service</div>;
  }

  return (
    <div className="tlm-account-view">
      <ToastContainer />
      <div className="tlm-account-view-header">My Account</div>
      <div className="tlm-account-view-main">
        <div className="tlm-account-view-main-user">
          <img
            className="tlm-account-view-main-user-icon"
            src={accountIcon}
            alt="tlm-account-view-main-user-icon"
          />
          <div className="tlm-account-view-main-user-name">
            {first_name + ' ' + last_name}
          </div>
        </div>
        <div className="tlm-account-view-main-content">
          <Tab
            className="tlm-account-view-main-content-tab"
            defaultActiveKey="Security"
            width={744}
            tabWidthPercent={20}
          >
            <TabItem
              text={'Security'}
              dataKey={'Security'}
              icon={(select) => {
                return select ? tabSecurityActive : tabSecurityNotActive;
              }}
            >
              <div className="tlm-tab-content">
                <div className="tlm-tab-content-header">Security</div>
                <div className="tlm-tab-content-email">
                  <SecurityItem
                    // Need to update userEmail method once change email feature back
                    userUpdateEmail={() => {}}
                    enableToast={setEnableToast}
                    isUserID={auth0_user_id}
                    checkLoginMethod={login_method === 1 ? 'gmail' : 'auth0'}
                    type={'Email'}
                    content={email}
                  />
                  {!login_method === 1 && (
                    <SecurityItem
                      enableToast={setEnableToast}
                      isUserID={auth0_user_id}
                      checkLoginMethod={login_method === 1 ? 'gmail' : 'auth0'}
                      type={'Password'}
                    />
                  )}
                </div>
              </div>
            </TabItem>
            <TabItem
              text={'Plan & billing'}
              dataKey={'Plan'}
              icon={(select) => {
                return select ? tabMemberActive : tabMemberNotActive;
              }}
            >
              <div className="tlm-tab-content">
                <div className="tlm-tab-content-header">Plan & billing</div>
                {is_subscripted ? premiumPlan() : basicPlan()}
              </div>
            </TabItem>
          </Tab>
        </div>
      </div>
    </div>
  );
};

export default AccountView;
