import React, { useState } from 'react';
import offerlandLogo from '../../images/offerlandLogo.svg';
import arrowBack from '../../images/arrowBack.svg';
import PlanCard from './components/PlanCard';
import QuestionCard from './components/QuestionCard';
import twitterLogo from '../../images/twitterLogo.svg';
import linkedinLogo from '../../images/linkedinLogo.svg';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import twentyPercentOff from '../../images/twentyPercentOff.svg';
import './index.scss';

// Plan selection page for user to choose the plan
const PlanSelection = (props) => {
  // Type of plan in length
  const [lengthType, setLengthType] = useState('monthly');

  // Handle toggle button group click
  const handleToggleButtonChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setLengthType(newAlignment);
    }
  };

  // Direct to main page
  const directToMainPage = () => {
    props.history.goBack();
  };

  // Render different plan card
  const renderPlanCard = (lengthType) => {
    if (lengthType === 'monthly') {
      return (
        <div className="tlm-planSelection-main-card">
          <div className="tlm-planSelection-main-card-current">
            <PlanCard
              title={'Starter'}
              price={'$0'}
              subtitle={'Free Forever'}
              buttonContent={'Current Plan'}
              features={currentFeature}
            />
          </div>
          <div className="tlm-planSelection-main-card-premium">
            <PlanCard
              title={'Premium'}
              price={'$14.99'}
              subtitle={'Per Month'}
              buttonContent={'Upgrade to Premium'}
              premium={true}
              features={premiumFeature}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="tlm-planSelection-main-card">
          <div className="tlm-planSelection-main-card-current">
            <PlanCard
              title={'Starter'}
              price={'$0'}
              subtitle={'Free Forever'}
              buttonContent={'Current Plan'}
              features={currentFeature}
            />
          </div>
          <div className="tlm-planSelection-main-card-premium">
            <PlanCard
              title={'Premium'}
              price={'$35.97'}
              subtitle={'Billed Quarterly'}
              buttonContent={'Upgrade to Premium'}
              premium={true}
              lengthType={lengthType}
              features={premiumFeature}
            />
          </div>
        </div>
      );
    }
  };

  // Current feature json
  const currentFeature = [
    {
      icon: 'Check',
      name: 'Profile',
      description: 'Create your application profiles (Up to 1)',
    },
    {
      icon: 'Check',
      name: 'Dashboard',
      description: 'Set up dashboards to track jobs (Up to 80 jobs)',
    },
    {
      icon: 'Check',
      name: 'Auto-fill',
      description: 'Fill out applications with one click',
    },
    {
      icon: 'Check',
      name: 'Auto-track',
      description: 'Save jobs to your dashboards with ease',
    },
    {
      icon: 'Check',
      name: 'Job Board',
      description: 'Browse job opportunities in one place',
    },
    {
      icon: 'Check',
      name: 'Template',
      description: 'Send email with confidence',
    },
  ];

  // Premium feature json
  const premiumFeature = [
    {
      icon: 'Check',
      name: 'Profile',
      description: 'Create your application profiles',
      suffix: '(Unlimited)',
    },
    {
      icon: 'Check',
      name: 'Dashboard',
      description: 'Set up dashboards to track jobs',
      suffix: '(Unlimited)',
    },
    {
      icon: 'Check',
      name: 'Auto-fill',
      description: 'Fill out applications with one click',
    },
    {
      icon: 'Check',
      name: 'Auto-track',
      description: 'Save jobs to your dashboards with ease',
    },
    {
      icon: 'Check',
      name: 'Job Board',
      description: 'Browse job opportunities in one place',
    },
    {
      icon: 'Check',
      name: 'Template',
      description: 'Send email with confidence',
    },
    {
      icon: 'New',
      name: 'AI Resume Builder',
      description: 'Craft impressive resumes with AI',
      suffix: '(Up to 500 times per month)',
    },
    {
      icon: 'New',
      name: 'Referral',
      description: 'Access to potential referral connections',
    },
    {
      icon: 'New',
      name: 'Early Bird',
      description: 'Early access to all new features',
    },
  ];

  // Question list json
  const questionList = [
    {
      question: 'Are you storing my payment information?',
      answer:
        'No. All payment information are only stored with Stripe, which is a trusted payment processor. Offerland does not have access to your payment information.',
    },
    {
      question: 'What is your renewal policy?',
      answer:
        'Your subscription will renew automatically. You can cancel your plan at any time before your renewal date by visiting the account page.',
    },
  ];

  return (
    <div className="tlm-planSelection">
      <div className="tlm-planSelection-header">
        <img
          className="tlm-planSelection-header-logo"
          src={offerlandLogo}
          onClick={directToMainPage}
        />
        <div className="tlm-planSelection-header-back" onClick={directToMainPage}>
          <img className="tlm-planSelection-header-back-icon" src={arrowBack} />
          <span className="tlm-planSelection-header-back-content">Back</span>
        </div>
      </div>
      <div className="tlm-planSelection-main">
        <div className="tlm-planSelection-main-title">Choose Your Plan</div>
        <div className="tlm-planSelection-main-subtitle">
          Pick the plan with the features you need
        </div>
        <div className="tlm-planSelection-main-toggle">
          <ToggleButtonGroup
            value={lengthType}
            exclusive
            onChange={handleToggleButtonChange}
          >
            <ToggleButton disableRipple className="toggle-button monthly" value="monthly">
              Monthly
            </ToggleButton>
            <ToggleButton
              disableRipple
              className="toggle-button quarterly"
              value="quarterly"
            >
              Quarterly
            </ToggleButton>
          </ToggleButtonGroup>
          <img className="tlm-planSelection-main-toggle-tag" src={twentyPercentOff} />
        </div>
        {renderPlanCard(lengthType)}
      </div>
      <div className="tlm-planSelection-question">
        <div className="tlm-planSelection-question-title">Frequently Asked Questions</div>
        {questionList.map((questionItem) => {
          return (
            <QuestionCard
              question={questionItem.question}
              answer={questionItem.answer}
              key={questionItem.question}
            />
          );
        })}
      </div>
      <div className="tlm-planSelection-footer">
        <div className="tlm-planSelection-footer-content">
          <div className="tlm-planSelection-footer-content-word">
            &copy; 2023 Offerland.ai. All rights reserved.
          </div>
          <div className="tlm-planSelection-footer-content-logo">
            <a href={'https://twitter.com/OfferlandAi'}>
              <img
                className="tlm-planSelection-footer-content-logo-twitter"
                src={twitterLogo}
              />
            </a>
            <a href={'https://www.linkedin.com/company/offerland-ai'}>
              <img
                className="tlm-planSelection-footer-content-logo-linkedin"
                src={linkedinLogo}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanSelection;
