import React, { useState } from 'react';

// components
import { Button } from '../../../stories/Button.tsx';
import { remainderIconGreen as remainderIcon } from '../../../components/icons';
import Calendar from 'react-calendar';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Mixpanel } from '../../../utilities/mixpanel';

// styles
import './CalendarButton.scss';
import 'react-calendar/dist/Calendar.css';
import Popover from '@mui/material/Popover';
// ------------------------------------
const useStyles = makeStyles((theme) => ({
  paper: {
    transform: 'translateY(-10px)!important',
  },
}));
export default function CalendarButton({ btnText, setBtnText, jobTitle }) {
  const [value, onChange] = useState(new Date());

  const onChangeDate = (value) => {
    const btnNewText = moment(value).format('YYYY-MM-DD');
    setBtnText(btnNewText);
    Mixpanel.track('Set Reminder', {
      jobTitle: jobTitle,
      reminderDate: btnNewText,
    });
    handlePopoverClose();
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="tlm-datepicker-container">
      <Button
        className="tlm-jobBoardNotes-button"
        isSecondary
        icon={remainderIcon}
        onClick={handlePopoverOpen}
      ></Button>
      <Popover
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <Calendar
          locale="en-US"
          className="tlm-datepicker"
          onChange={onChangeDate}
          value={value}
        />
      </Popover>
    </div>
  );
}
