import React from 'react';
import filter from '../../../images/filter.svg';
import filterArrow from '../../../images/guide-filter-arrow.svg';

export default function GuidanceHeader(props) {
  const stepNum = props.guidance.tasks ? props.guidance.tasks.length : 0;
  let taskNum = 0;
  props.guidance.tasks &&
    props.guidance.tasks.forEach((task) => (taskNum += task.subtasks.length));

  //P2 feature
  const showFilter = () => {};

  return (
    <div>
      {props.guidance === '' ? (
        <div className="nux-header">Which industry are you looking at?</div>
      ) : (
        <div className="guide-header">
          <div className="guide-header-title">
            How to get a job of {props.guidance.title}
          </div>
          <div className="guide-header-count">
            {stepNum} steps {taskNum} tasks
          </div>
          <div className="guide-header-filter" onClick={showFilter}>
            <img src={filter} />
            <span className="guide-header-filter-text">Filter</span>
            <img src={filterArrow} />
          </div>
        </div>
      )}
    </div>
  );
}
