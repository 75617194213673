import React, { useState } from 'react';
import CalendarButton from './CalendarButton';
import { Button } from '../../../stories/Button.tsx';
import { Dropdown } from '../../../stories/Dropdown.tsx';
import { TextField } from '@mui/material';

import { deleteIcon as DeleteIcon } from '../../../components/icons';
import { Mixpanel } from '../../../utilities/mixpanel';
import { DASHBOARD_STATUS, DASHBOARDCONSTANTS } from '../DashboardConstants';

// ----------------------------------------------------------------------
export default function JobBoardEditedNoteCard({
  btnText,
  setBtnText,
  onCancelClick,
  onSaveClick,
  jobTitle,
  notesWrittingFlag,
  curNoteText,
  curStatus,
  selectedStatus,
}) {
  const [currentNote, setCurrentNote] = useState(curNoteText);
  const [currentStatusIndex, setCurrentStatusIndex] = useState(
    curStatus || selectedStatus
  );

  const onSave = () => {
    let newJobObj = {
      text: currentNote,
      status_code: currentStatusIndex,
    };
    //add date field
    if (btnText !== 'Set Reminder') {
      newJobObj['reminder'] = btnText;
    }
    onSaveClick(newJobObj);
  };

  const onCancel = () => {
    onCancelClick();
    Mixpanel.track('Cancel Add New Note', {
      jobTitle: jobTitle,
    });
  };

  return (
    <div
      className={
        notesWrittingFlag
          ? 'tlm-jobBoardNotes-container--notesWritting-container notesWrittingFlag'
          : 'tlm-jobBoardNotes-container--notesWritting-container'
      }
    >
      <TextField
        className="tlm-jobBoardNotes-container--notesWritting"
        size="small"
        fullWidth
        placeholder="e.g., call with recruiter at 6pm"
        variant="standard"
        value={currentNote}
        multiline
        rows={4}
        onChange={(event) => {
          setCurrentNote(event.target.value);
        }}
      />
      <div className="tlm-jobBoardNotes-container--actions-row">
        <div className="tlm-jobBoardNotes-container--actions">
          <div className="tlm-jobBoardNotes-container--actions__right">
            <Dropdown
              classNames="tlm-jobBoardNotes-container--actions-save"
              itemsAry={DASHBOARD_STATUS}
              value={DASHBOARDCONSTANTS.status[currentStatusIndex]?.statusName}
              onChange={(event) =>
                setCurrentStatusIndex(DASHBOARDCONSTANTS.statusIdxMap[event])
              }
            />
            <CalendarButton setBtnText={setBtnText} btnText={btnText} />

            <Button
              isSecondary={true}
              className="tlm-jobBoardNotes-container--actions-cancel"
              onClick={onCancel}
            >
              {DeleteIcon}
            </Button>
            <Button
              className="tlm-jobBoardNotes-container--actions-save"
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
