import React from 'react';
import InfoItem from './info-item';
import PersonIcon from '@mui/icons-material/PersonOutline';
import MailIcon from '@mui/icons-material/MailOutline';
import { phoneIcon, locationIcon, identityIcon } from '@components/icons';
import { Stack } from '@mui/material';
import { boolMap } from '../constant';

import '../style/index.scss';

const addressText = ['street_address', 'city', 'state', 'postal_code', 'country'];

const BaseInfo = ({ info = {}, address = {} }) => {
  const addressArr = [];
  addressText.forEach((item) => {
    if (address[item]) {
      addressArr.push(address[item]);
    }
  });
  const addressStr = addressArr.join(' , ');
  return (
    <Stack className="contact-information">
      <Stack className="contact-information-row" direction={'row'}>
        <InfoItem
          icon={<PersonIcon></PersonIcon>}
          label="First Name"
          value={info.first_name}
        ></InfoItem>
        <InfoItem label="Last Name" value={info.last_name}></InfoItem>
      </Stack>
      <Stack className="contact-information-row" direction={'row'} sx={{ mt: 2 }}>
        <InfoItem
          className="w-218"
          icon={phoneIcon}
          label="Phone Number"
          value={info.phone}
        ></InfoItem>
        <InfoItem
          className="w-218"
          icon={<MailIcon></MailIcon>}
          label="Email"
          value={info.email}
        ></InfoItem>
        <InfoItem icon={locationIcon} label="Address" value={addressStr}></InfoItem>
      </Stack>
      <Stack className="contact-information-row" direction={'row'} sx={{ mt: 2 }}>
        <InfoItem
          icon={identityIcon}
          label="Need Sponsorship"
          value={info.require_sponsorship ? boolMap.true : boolMap.false}
        ></InfoItem>
        <InfoItem
          label="Need Work Authorization"
          value={info.has_work_authorization ? boolMap.true : boolMap.false}
        ></InfoItem>
      </Stack>
    </Stack>
  );
};

export default BaseInfo;
