import axios from 'axios';
import { BACKEND_PATH } from '@services/ServiceConstants';

const baseEndpoint = 'api';

//get job
export const getJob = async (jobId) => {
  return await axios.get(`${BACKEND_PATH}${baseEndpoint}/jobs/${jobId}/`);
};

//save job
export const saveJob = async (jobId, payload) => {
  return await axios.post(`${BACKEND_PATH}${baseEndpoint}/jobs/${jobId}/`, {
    ...payload,
  });
};

//create job
export const createJob = async (dashboardId, payload) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/dashboards/${dashboardId}/jobs/`,
    {
      ...payload,
    }
  );
};

//delete job
export const deleteJob = async (jobId) => {
  return await axios.delete(`${BACKEND_PATH}${baseEndpoint}/jobs/${jobId}/`);
};

// save Todo
export const saveTodo = async (todoId, payload) => {
  return await axios.post(`${BACKEND_PATH}${baseEndpoint}/todos/${todoId}/`, {
    ...payload,
  });
};

//delete Todo
export const deleteTodo = async (todoId) => {
  return await axios.delete(`${BACKEND_PATH}${baseEndpoint}/todos/${todoId}/`);
};

//create Todo
export const createTodo = async (jobId, payload) => {
  return await axios.post(`${BACKEND_PATH}${baseEndpoint}/jobs/${jobId}/todos/`, {
    ...payload,
  });
};

//empty Job
const curtime = Date().valueOf();

export const emptyJob = {
  title: 'Title',
  company_name: 'Company',
  status: 1,
  is_pinned: false,
  todos: [],
  timestamps: [
    {
      status_code: 1,
    },
  ],
  creat_time: curtime,
};
