export const TOOLTIP_STATUS = {
  INIT: 'INIT',
  PROFILE: 'PROFILE',
  COMPLETE: 'COMPLETE',
};

export const errorMessages = {
  getData: 'Have trouble loading data',
  getStripeData: 'Have trouble getting stripe data',
  getSubscriptionInfoData: 'Have trouble getting Customer Portal',
  addProfile: 'Have trouble adding new profile',
  addDashboard: 'Have trouble adding new dashboard',
  deleteDashboard: 'Have trouble deleting dashboard',
  nameNotExist: 'Dashboard name cannot be empty.',
  nameTooLong: 'Dashboard name must be less than 30 characters.',
  nameDuplicate: 'Dashboard name has been taken.',
};

export const libraryInfo = ['Library Resources', 'My Collection'];
