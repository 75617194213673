import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  InputBase,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Mixpanel } from '@utilities/mixpanel';

const useStyles = makeStyles(() => ({
  card: {
    height: 'calc(100vh - 220px) !important',
    maxHeight: 660,
    overflow: 'auto',
    width: 550,
    marginTop: 28,
    borderLeft: '1px solid rgba(0, 116, 132, 0.18)',
    boxShadow: 'none',
    paddingLeft: 16,
  },
  title: {
    fontWeight: 400,
    fontSize: 24,
    textTransform: 'capitalize',
    paddingTop: 0,
  },
  content: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  button: {
    fontWeight: 800,
    fontSize: 18,
    color: 'white',
    backgroundColor: '#05756C',
    width: 137,
    height: 42,
    borderRadius: 4,
    padding: '9px 21px',
    border: 'none',
    position: 'absolute',
    bottom: 64,
    marginLeft: 4,
  },
}));

export default function PreviewModal(props) {
  const classes = useStyles();

  const closeModal = () => {
    props.setPreviewCard();
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.title}
        title={props.card.title}
        action={<CloseIcon onClick={() => props.setPreviewCard()} />}
      />
      <CardContent>
        <InputBase
          sx={{ width: '100%' }}
          readOnly={true}
          className={classes.content}
          multiline={true}
          value={props.card.content}
        />
      </CardContent>
      <CardActions>
        <button
          className={classes.button}
          onClick={() => {
            Mixpanel.track('Click Use Template');
            props.setEditCard(props.card);
            props.setIsOfferlandTemplate(true);
          }}
        >
          Use
        </button>
      </CardActions>
    </Card>
  );
}
