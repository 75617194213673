export const categorysList = [
  'role',
  'application',
  'find_jobs',
  'learning_resource',
  'community',
  'interview',
];

export const tagsList = {
  role: ['PM', 'DS', 'SDE', 'PD'],
  application: ['Resume', 'CL', 'Profile'],
  find_jobs: ['Job', 'Event'],
  learning_resource: ['Courses', 'Bootcamp', 'Project', 'Books', 'Articles'],
  community: ['Networking', 'Mentorship', 'Groups'],
  interview: ['Behavioral Qn', 'Technical Qn', 'Case Qn', ' Interview', 'Mock'],
};

export const filtersList = {
  role: ['Product Management', 'Data Science', 'Software Engineering', 'Product Design', 'Product Manager', 'Data Engineer'],
  application: ['Resume', 'Cover Letter', 'Profile'],
  find_jobs: ['Job Boards', 'Career Event'],
  learning_resource: ['Courses', 'Bootcamp', 'Project', 'Books', 'Articles'],
  community: ['Networking', 'Mentorship', 'Groups'],
  interview: [
    'Behavioral Question',
    'Technical Question',
    'Case Question',
    'Interview Tips',
    'Mock Platform',
  ],
};

export const tagColorsList = {
  role: 'c-tag-mint',
  application: 'c-tag-berry',
  find_jobs: 'c-tag-cornflower',
  learning_resource: 'c-tag-navy',
  community: 'c-tag-pink',
  interview: 'c-tag-orange',
};
