import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Routes from './routes';
import theme from './mui-theme';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import store from './store';
import { Auth0Provider } from '@auth0/auth0-react';
import { getAuth0Config } from './services/LoginServices';
import { pad, AES, enc, mode } from 'crypto-js';

// Load Montserrat typeface
require('typeface-montserrat');
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [encryptedAuth0Config, setEncryptedAuth0Config] = useState(null);

  function DecryptCrypto(mode, pad, message, key, iv) {
    return JSON.parse(
      AES.decrypt({ ciphertext: enc.Base64.parse(message) }, enc.Utf8.parse(key), {
        mode: mode.CBC,
        padding: pad.Pkcs7,
        iv: enc.Utf8.parse(iv),
      }).toString(enc.Utf8)
    );
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAuth0Config();
        setEncryptedAuth0Config(
          DecryptCrypto(
            mode,
            pad,
            response.data['ciphertext'],
            'psnzhrryqalkfgdu',
            'losheyatqpncgsal'
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  if (!encryptedAuth0Config) {
    return <div>Loading...</div>;
  }

  // const domain = 'dev-6b6in2-n.us.auth0.com';
  // const clientId = 'zphlNzvI8BmrXbI9khps29fZYxqxzqPP';
  // const redirect_uri = 'https://offerland.devtrust.biz/login';

  // const audience = encryptedAuth0Config.find(
  //   (config) => config.key === 'REACT_APP_AUTH0_AUDIENCE'
  // ).value;
  const domain = encryptedAuth0Config.find(
    (config) => config.key === 'REACT_APP_AUTH0_DOMAIN'
  ).value;
  const clientId = encryptedAuth0Config.find(
    (config) => config.key === 'REACT_APP_AUTH0_CLIENT_ID'
  ).value;
  const redirect_uri = encryptedAuth0Config.find(
    (config) => config.key === 'REACT_APP_AUTH0_REDIRECT_URI'
  ).value;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirect_uri,
      }}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <div className="tlm">
              <Routes />
              <div id="error-dialog-root-el" />
            </div>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </Auth0Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
