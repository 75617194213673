import React from 'react';
import InfoItem from './info-item';
// import LinkIcon from '@mui/icons-material/Link';
import { selfIdentificationIcon } from '@components/icons';
import { Stack, Link } from '@mui/material';
import {
  identificationSubheading,
  identificationUrls,
  genderListMap,
  veteransListMap,
  disabilityListMap,
} from '../constant';

import '../style/index.scss';

const IdentificationsInfo = ({ info = {} }) => {
  return (
    <Stack className="contact-information">
      <Stack className="information-subheading">
        {identificationSubheading.replace('Privacy Policy', '')}
        <Link href={identificationUrls.privacy} target="_blank" variant="button">
          Privacy Policy
        </Link>
      </Stack>
      <Stack className="contact-information-row" direction={'row'} sx={{ mt: 2 }}>
        <InfoItem
          className="w-400"
          icon={selfIdentificationIcon}
          label="Gender"
          value={genderListMap[info.gender]}
        ></InfoItem>
        <InfoItem
          className="w-400"
          icon={selfIdentificationIcon}
          label="Ethnicity"
          value={info.ethnicity && info.ethnicity.join(', ')}
        ></InfoItem>
      </Stack>
      <Stack className="contact-information-row" direction={'row'} sx={{ mt: 2 }}>
        <InfoItem
          className="w-400"
          icon={selfIdentificationIcon}
          label="Veterans Status"
          value={veteransListMap[info.veteran_status]}
        ></InfoItem>
        <InfoItem
          className="w-400"
          icon={selfIdentificationIcon}
          label="Disability"
          value={disabilityListMap[info.disability]}
        ></InfoItem>
      </Stack>
    </Stack>
  );
};

export default IdentificationsInfo;
