import axios from 'axios';
import { BACKEND_PATH } from '../ServiceConstants';
import { converPageInfo } from '../../utilities/dataHandler';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export const useDashboardData = (dashboardId, params) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data, ...rest } = useQuery({
    queryKey: ['dashboard', dashboardId, params],
    queryFn: async () => {
      const res = await axios.post(
        `${BACKEND_PATH}api/dashboards/${dashboardId}/?timezone=${timezone}`,
        params
      );
      return res.data;
    },
  });

  const dataRows = data ? data.data.jobs.others : [];
  return {
    jobBoardInfo: data?.data,
    data: dataRows.map((ele) => {
      return {
        ...ele,
        Company: ele.company.name,
        'Job Title': ele.title,
        Status: ele.status,
        isPin: ele.is_pinned,
      };
    }),
    page: converPageInfo(data?.pagination),
    ...rest,
  };
};

export const useJobsBulkMove = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.post(`${BACKEND_PATH}api/jobs/bulk_move/`, {
        ...params,
      });
      console.log('res', res);
      return res;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('dashboard');
      },
    }
  );
};
