import React, { useState } from 'react';
import { Stack, Button, InputBase } from '@mui/material';
import { AiSuggestion } from './AiSuggestion';
import { useAiSuggestions } from '../../../services/AIServices';
import clsx from 'clsx';
import '../style/index.scss';
import { editIcon } from '../../../components/icons';
import { formatAiSuggestion } from '@utilities/tool';
import { toGenerateCountResumeBuilder } from '../../../services/ProfileServices';
import AIBuilderResumeValidateModal from '../../../components/CommonDialog/AIBuilderResumeValidateModal';
import AIBuilderResumeValidatePremuimModal from '../../../components/CommonDialog/AIBuilderResumeValidatePremuimModal';
import { STARTER_PLAN_COUNT, PREMIUM_PLAN_COUNT } from '../../../constants/planCount';
import { Mixpanel } from '../../../utilities/mixpanel';

export const ProfileExperience = ({
  info,
  targetInfo,
  isGenerateNow,
  onGenerate,
  onUpdateExperience,
  isLast,
  updateAiSuggestion,
  genrateCount,
  isPremiumStatus,
  toCheckCurrentMonth,
  isEnabledPopup,
}) => {
  // 当前组件一旦开启查询模式，父级组件操作禁用
  const [isGenerate, setIsGenerate] = useState(false);
  const [queryNum, setQueryNum] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isProfilePlanUpgrade, setProfilePlanUpgrade] = useState(false);
  const [isCheckPremiumCount, setCheckPremiumCount] = useState(false);
  const [isMonthCheckRestCount, setMonthCheckRestCount] = useState(false);
  // temp 左侧pannel 暂时展示的文本
  const [description, setDescription] = useState(
    // 正则替换- 为 ·
    formatAiSuggestion(info.description_draft || info.description)
  );
  // aiSuggestionDesc
  const [aiSuggestionDesc, setAiSuggestionDesc] = useState('');
  const [isApplied, setIsApplied] = useState(false);

  const { mutateAsync: postAiBuilder, isLoading } = useAiSuggestions();
  // 重置描述信息
  const resetInfo = () => {
    setIsEdit(false);
    onGenerate(false);
    setDescription(formatAiSuggestion(info.description_draft || info.description));
    setIsGenerate(false);
    // todo cancel queryNum 是否需要重置为1
  };
  const onEdit = () => {
    if (isGenerateNow && !isGenerate) return;
    setIsEdit(true);
    setIsGenerate(true);
    onGenerate(true);
    //  如果有ai suggestion，则展示，则直接展示
    const { has_ai_suggestion = false, ai_suggestion } = info.ai_suggestion;
    if (has_ai_suggestion) {
      // 如果 aiSuggestionDesc 存在（表示当前点击过generate），则直接展示 ai；
      // case：当前页面，点了generate，点cancel；修改其他work experience，再回来编辑
      if (!aiSuggestionDesc) {
        setAiSuggestionDesc(formatAiSuggestion(ai_suggestion));
      }
      // todo P5 待确认，点edit 是否直接隐藏pop，还是显示ai suggestion时，才隐藏
      isEnabledPopup('none');
      setIsEdit(false);
    }
  };
  // chatGPT 获取最新描述
  const aiReBuilder = async () => {
    // It is used for validate ai count
    var d = new Date();
    let currentMonth = d.getMonth();
    if (isPremiumStatus) {
      genrateCount = checkCountGenerated(
        genrateCount,
        PREMIUM_PLAN_COUNT.AI_RESUME_BUILDER_FOR_PREMIUM_USER
      );
    } else {
      genrateCount = checkCountGenerated(
        genrateCount,
        STARTER_PLAN_COUNT.AI_RESUME_BUILDER_FOR_FREE_USER
      );
    }
    // End
    isEnabledPopup('none');
    setIsEdit(false);
    setIsGenerate(true);
    onGenerate(true);
    const trackInfo = `Click ${queryNum > 1 ? 'Re-Generate' : 'Generate'} AI Resume`;
    Mixpanel.track(trackInfo);
    setQueryNum(queryNum + 1);
    const params = {
      profile_id: targetInfo.profileId,
      work_experience_id: info.id,
      role: targetInfo.role,
      level: targetInfo.level,
      // todo , re-Generate 是拿最新的description查询，还是用最开始的，目前用的是新的description
      question: description,
      generate: queryNum,
    };

    const res = await postAiBuilder(params);
    if (res) {
      // It is used for validate ai count
      generateCountData(
        info.profile,
        info.user,
        info.id,
        isPremiumStatus,
        genrateCount,
        currentMonth
      );
      // 每次重新查询ai时，isApplied均要重置为false
      setIsApplied(false);
      setAiSuggestionDesc(formatAiSuggestion(res.suggestions));
      // 获取ai suggestion后， 更新ai suggestion , 如果不更新，只有重新请求profile data， 否则再次点eidt的时候，不显示ai suggestion
      if (updateAiSuggestion) {
        updateAiSuggestion({
          ...info,
          ai_suggestion: {
            has_ai_suggestion: true,
            ai_suggestion: formatAiSuggestion(res.suggestions),
          },
        });
      }
    }
  };

  // 使用AiSuggestion
  const onApplyAiSuggestion = () => {
    Mixpanel.track('Click Apply to Description');
    setDescription(aiSuggestionDesc);
    setIsApplied(true);
  };

  function checkCountGenerated(valCount, flagSet) {
    if (valCount === 0) {
      valCount = 1;
    } else {
      if (valCount >= flagSet) {
        valCount = checkCountInMonth(flagSet);
      } else {
        valCount = valCount + 1;
      }
    }
    return valCount;
  }

  const checkCountInMonth = (flagSet) => {
    var count;
    var d1 = new Date();
    let staticMonth = d1.getMonth(); // current month detail
    if (toCheckCurrentMonth === staticMonth) {
      count = flagSet;
    } else {
      count = 1;
    }
    return count;
  };

  const saveExperience = () => {
    Mixpanel.track('Click Save to Profile');
    isEnabledPopup('block');
    if (onUpdateExperience) {
      onUpdateExperience(info, description);
      onGenerate(false);
      setIsGenerate(false);
    }
  };

  const generateCountData = (
    profileId,
    userId,
    experienceId,
    userType,
    generatedCount,
    currentMonth
  ) => {
    const toGenrateCountObj = {
      profile_id: profileId,
      generated_count: isMonthCheckRestCount ? 1 : generatedCount,
      current_month: currentMonth,
      user_id: userId,
      experience_id: experienceId,
      user_type: userType,
    };
    toGenerateCountResumeBuilder(toGenrateCountObj)
      .then((res) => {
        console.log('res', res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  function handleCloseUpgradePlanModal() {
    setProfilePlanUpgrade(false);
  }

  function handleCloseResumeValidatePremuimModal() {
    setCheckPremiumCount(false);
  }

  const handleButtonPremiumCheck = () => {
    setProfilePlanUpgrade(true);
    Mixpanel.track('UpgradeToPremiumPopup_AIResumeBuilder');
  };

  const handleButtonPremiumCheckCount = () => {
    setCheckPremiumCount(true);
  };

  const handleButtonMonthCheckCountReset = () => {
    setMonthCheckRestCount(true);
    aiReBuilder();
  };

  const checkMonthOuterGenerateCount = () => {
    let buttonGenerate;
    var d1 = new Date();
    let staticMonth = d1.getMonth(); // current month detail
    // todo P5 这个判断是干啥 本月不能再次点击？
    if (toCheckCurrentMonth === staticMonth) {
      buttonGenerate = (
        <Button
          style={{ background: 'grey', color: '#fff' }}
          className="ai-button"
          size="small"
          variant="contained"
          onClick={handleButtonPremiumCheckCount}
          disabled={true}
        >
          Generate
        </Button>
      );
    } else {
      buttonGenerate = (
        <Button
          className="ai-cv-button ai-button"
          size="small"
          variant="contained"
          onClick={handleButtonMonthCheckCountReset}
        >
          Generate
        </Button>
      );
    }
    return buttonGenerate;
  };

  // This is used for outer genrate button
  const handlePremiumButtonGenerate = () => {
    let buttonGenerate;
    // For premium user
    if (isPremiumStatus) {
      if (genrateCount >= PREMIUM_PLAN_COUNT.AI_RESUME_BUILDER_FOR_PREMIUM_USER) {
        checkMonthOuterGenerateCount();
      } else {
        buttonGenerate = (
          <Button
            className="ai-cv-button ai-button"
            size="small"
            variant="contained"
            onClick={aiReBuilder}
            disabled={(isGenerateNow && !isGenerate) || !description}
          >
            Generate
          </Button>
        );
      }
    } else {
      // For Free user
      if (genrateCount >= STARTER_PLAN_COUNT.AI_RESUME_BUILDER_FOR_FREE_USER) {
        //fix P5  免费次数达到上限，显示引导付费pop 点了got it，免费的3次也用完了 Generate 不能用，也不显示付费
        isEnabledPopup('block');
        buttonGenerate = (
          <Button
            style={{ background: 'grey', color: '#fff' }}
            className="ai-button"
            size="small"
            variant="contained"
            disabled={true}
          >
            Generate
          </Button>
        );
      } else {
        buttonGenerate = (
          <Button
            className="ai-cv-button ai-button"
            size="small"
            variant="contained"
            onClick={aiReBuilder}
            disabled={(isGenerateNow && !isGenerate) || !description}
          >
            Generate
          </Button>
        );
      }
    }
    return buttonGenerate;
  };

  const checkMonthInnerGenerateCount = () => {
    let regenratePopupButton;
    var d1 = new Date();
    let staticMonth = d1.getMonth(); // current month detail
    if (toCheckCurrentMonth === staticMonth) {
      regenratePopupButton = (
        <Button
          className="ai-cv-button ai-button"
          size="small"
          variant="outlined"
          onClick={handleButtonPremiumCheckCount}
          disabled={false}
        >
          {queryNum > 1 || info.ai_suggestion.has_ai_suggestion
            ? 'Re-Generate'
            : 'Generate'}
        </Button>
      );
    } else {
      regenratePopupButton = (
        <Button
          className="ai-cv-button ai-button"
          size="small"
          variant="outlined"
          disabled={!description}
          onClick={handleButtonMonthCheckCountReset}
        >
          {queryNum > 1 || info.ai_suggestion.has_ai_suggestion
            ? 'Re-Generate'
            : 'Generate'}
        </Button>
      );
    }
    return regenratePopupButton;
  };

  // This is used for inner generate button
  const handleRegenratePupupButton = () => {
    let regenratePupupButton;
    if (isPremiumStatus) {
      if (genrateCount >= PREMIUM_PLAN_COUNT.AI_RESUME_BUILDER_FOR_PREMIUM_USER) {
        checkMonthInnerGenerateCount();
      } else {
        regenratePupupButton = (
          <Button
            className="ai-cv-button ai-button"
            size="small"
            variant="outlined"
            disabled={!description}
            onClick={aiReBuilder}
          >
            {queryNum > 1 || info.ai_suggestion.has_ai_suggestion
              ? 'Re-Generate'
              : 'Generate'}
          </Button>
        );
      }
    } else {
      if (genrateCount >= STARTER_PLAN_COUNT.AI_RESUME_BUILDER_FOR_FREE_USER) {
        regenratePupupButton = (
          <Button
            className="ai-cv-button ai-button"
            size="small"
            variant="outlined"
            onClick={handleButtonPremiumCheck}
            disabled={false}
          >
            {queryNum > 1 || info.ai_suggestion.has_ai_suggestion
              ? 'Re-Generate'
              : 'Generate'}
          </Button>
        );
      } else {
        regenratePupupButton = (
          <Button
            className="ai-cv-button ai-button"
            size="small"
            variant="outlined"
            disabled={!description}
            onClick={aiReBuilder}
          >
            {queryNum > 1 || info.ai_suggestion.has_ai_suggestion
              ? 'Re-Generate'
              : 'Generate'}
          </Button>
        );
      }
    }
    return regenratePupupButton;
  };

  return (
    <Stack className="experience-item" direction={'row'}>
      <AIBuilderResumeValidateModal
        open={isProfilePlanUpgrade}
        handleClose={handleCloseUpgradePlanModal}
      />
      <AIBuilderResumeValidatePremuimModal
        open={isCheckPremiumCount}
        handleClose={handleCloseResumeValidatePremuimModal}
      />
      <Stack
        style={{ maxWidth: '50%' }}
        className={clsx('experience-item-left', {
          'border-bottom': !isLast,
        })}
        sx={{ flex: 1 }}
      >
        <Stack className="experience-title bold">{info.title}</Stack>
        <Stack className="help-text">
          <Stack>{info.company_name}</Stack>
          <Stack>
            {info.start_date} - {info.current_work_status ? 'present' : info.end_date}
          </Stack>
        </Stack>
        <Stack
          className="experience-top-handler"
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Stack className="help-text bold">Description</Stack>
          {!isGenerate && (
            <Stack direction={'row'} spacing={2}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                onClick={onEdit}
                className={clsx('edit-icon-button', {
                  disabled: isGenerateNow && !isGenerate,
                })}
              >
                {editIcon}
              </Stack>
              {handlePremiumButtonGenerate()}
            </Stack>
          )}
        </Stack>
        <Stack className={clsx('experience-desc', { active: isGenerate })}>
          <Stack className="content-text large">
            <InputBase
              readOnly={!isGenerate}
              placeholder="Unlock the AI generation service by filling out your work experiences"
              multiline={true}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </Stack>
          {isGenerate && (
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: 2 }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="help-text pointer"
                onClick={resetInfo}
              >
                Cancel
              </Stack>
              <Stack direction={'row'} spacing={1}>
                {description &&
                  (info.description_draft
                    ? info.description_draft !== description
                    : info.description !== description) && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="warning-text"
                    >
                      Don't forget to save your changes before leaving
                    </Stack>
                  )}
                {handleRegenratePupupButton()}

                <Button
                  className="ai-cv-button ai-button"
                  size="small"
                  variant="contained"
                  onClick={saveExperience}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {isGenerate && !isEdit && (
        <Stack sx={{ flex: 1, position: 'relative' }}>
          <AiSuggestion
            isPremiumStatus={isPremiumStatus}
            genrateCount={genrateCount}
            isLoading={isLoading}
            description={aiSuggestionDesc}
            onApplyAi={onApplyAiSuggestion}
            isApplied={isApplied}
          ></AiSuggestion>
        </Stack>
      )}
    </Stack>
  );
};
