import { DASHBOARDCONSTANTS } from '../views/dashboard-view/DashboardConstants';

export const getCount = (data) => {
  let all = 0,
    wishlist = 0,
    applied = 0,
    interview = 0,
    offer = 0,
    rejected = 0;
  data.pin.forEach((job) => {
    if (job.status === 1) wishlist++;
    else if (job.status === 2) applied++;
    else if (job.status === 8) offer++;
    else if (job.status === 9) rejected++;
    else interview++;
    all++;
  });
  data.unpin.forEach((job) => {
    if (job.status === 1) wishlist++;
    else if (job.status === 2) applied++;
    else if (job.status === 8) offer++;
    else if (job.status === 9) rejected++;
    else interview++;
    all++;
  });
  return { all, wishlist, applied, interview, offer, rejected };
};

export const formatDashboardResponse = (responseData) => {
  const formatedData = {};
  formatedData.id = responseData.id;
  formatedData.name = responseData.name;
  formatedData.jobs = responseData.jobs;
  formatedData.count = getCount(formatedData.jobs);
  return formatedData;
};

export const formatInfoResponse = (responseArr) => {
  const formatedData = {};
  formatedData.dashboards = responseArr[0].data;
  formatedData.profiles = responseArr[1].data.content;
  return formatedData;
};

export const stableSort = (data, sortOrder, sortBy) => {
  let aToZ, zToA, oldestOnTop, newestOnTop;
  const compareFunction = (a, b) => {
    switch (sortBy) {
      case 'title':
        aToZ = b.title.toLowerCase() < a.title.toLowerCase() ? 1 : -1;
        zToA = a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
        return sortOrder === 'asc' ? aToZ : zToA;
      case 'company':
        aToZ = b.company.name.toLowerCase() < a.company.name.toLowerCase() ? 1 : -1;
        zToA = a.company.name.toLowerCase() < b.company.name.toLowerCase() ? 1 : -1;
        return sortOrder === 'asc' ? aToZ : zToA;
      case 'date':
        oldestOnTop =
          Date.parse(b.last_modified_time) < Date.parse(a.last_modified_time) ? 1 : -1;
        newestOnTop =
          Date.parse(a.last_modified_time) < Date.parse(b.last_modified_time) ? 1 : -1;
        return sortOrder === 'asc' ? newestOnTop : oldestOnTop;
      default:
        return 0;
    }
  };
  data.jobs.pin.sort(compareFunction);
  data.jobs.unpin.sort(compareFunction);
  return data;
};

const filterDataHelper = (
  jobs,
  filter_search,
  filter_tag,
  filter_status,
  filteredData,
  jobsFilteredByTag
) => {
  jobs.forEach((job) => {
    if (
      filter_search === '' ||
      job.title.toLowerCase().indexOf(filter_search.toLowerCase()) !== -1 ||
      job.company.name.indexOf(filter_search) !== -1
    ) {
      const todos = job.todos.map((item) => item.tag);
      if (filter_tag === DASHBOARDCONSTANTS.tagFilter[0] || todos.includes(filter_tag)) {
        if (
          filter_status === DASHBOARDCONSTANTS.statusFilter[0] ||
          DASHBOARDCONSTANTS.statusFilterMap[filter_status].includes(job.status)
        )
          filteredData.push(job);
        jobsFilteredByTag.push(job);
      }
    }
  });
};

export const filterData = ({ data, filter_tag, filter_status, filter_search }) => {
  if (
    filter_tag === DASHBOARDCONSTANTS.tagFilter[0] &&
    filter_status === DASHBOARDCONSTANTS.statusFilter[0] &&
    filter_search === ''
  ) {
    return data;
  }
  const filteredData = {
    id: data.id,
    name: data.name,
    data: {},
    jobs: { pin: [], unpin: [] },
  };
  const jobsFilteredByTag = { pin: [], unpin: [] };
  filterDataHelper(
    data.jobs.pin,
    filter_search,
    filter_tag,
    filter_status,
    filteredData.jobs.pin,
    jobsFilteredByTag.pin
  );
  filterDataHelper(
    data.jobs.unpin,
    filter_search,
    filter_tag,
    filter_status,
    filteredData.jobs.unpin,
    jobsFilteredByTag.unpin
  );
  filteredData.count = getCount(jobsFilteredByTag);
  return filteredData;
};

export const dateToMonth = (date) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!date || !date.match(regEx)) return '';
  return date.slice(0, 10);
};

export const shallowEqual = (object1, object2) => {
  if (
    (object1 === undefined && object2 === undefined) ||
    (object1 === null && object2 === null)
  ) {
    return true;
  }

  if (object1 || object2) {
    return false;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export function cleanNullToEmptyString(obj) {
  if (obj === null) {
    return '';
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(cleanNullToEmptyString);
  }

  return Object.keys(obj).reduce(
    (result, key) => ({
      ...result,
      [key]: cleanNullToEmptyString(obj[key]),
    }),
    {}
  );
}

const metaFields = new Set();
metaFields.add('created_time');
metaFields.add('id');
metaFields.add('last_modified_time');
metaFields.add('profile');
metaFields.add('user');

export function isEmptySection(obj) {
  if (obj === null) return true;
  if (typeof obj !== 'object') {
    return obj === '' || obj === undefined || obj === false;
  }
  if (Array.isArray(obj)) {
    return obj.reduce((res, cur) => res && isEmptySection(cur), true);
  }
  return Object.keys(obj).reduce(
    (res, key) => res && (metaFields.has(key) || isEmptySection(obj[key])),
    true
  );
}

export function hasDuplicateCaseInsensitive(str, strArr, actions = []) {
  let dup = false;
  let strL = str.toLowerCase();
  strArr.forEach((s) => {
    if (s.toLowerCase() === strL) {
      dup = true;
      actions.forEach((a) => a());
    }
  });
  return dup;
}

export function skillsToSkillList(skills) {
  return skills
    ?.split(/,|;/)
    .map((s) => s.trim())
    .filter((s) => s !== '');
}
