import React, { useState, useContext } from 'react';
import { Stack, Grid } from '@mui/material';
import { MainPageContext } from '../../views/main-view';
import ProfileHeader from './components/profile-header';
import ProfileUpload from './components/profile-upload';
import BlockBox from './components/block-box';
import BaseInfo from './components/base-info';
import BaseForm from './components/base-form';
import ExperienceBox from './components/experience-box';
import ExperienceForm from './components/experience-form';
import EducationBox from './components/education-box';
import EducationForm from './components/education-form';
import CertificateBox from './components/certificate-box';
import CertificateForm from './components/certificate-form';
import LinksInfo from './components/links-info';
import LinksForm from './components/links-form';
import SkillsInfo from './components/skills-info';
import IdentificationsInfo from './components/identifications-info';
import IdentificationsForm from './components/identifications-form';
import ProfileAttachment from './components/profile-attachment';
import AlertDialog from '@components/CommonDialog';
import {
  defaultExperienceInfo,
  defaultEducationalInfo,
  defaultCertificationInfo,
  baseInfoRules,
  linksRules,
  identificationsRules,
  rulesMap,
} from './constant';

import EmptyWorkExperiences from '@images/profile/empty/work-experience.svg';
import EmptyEducationExperiences from '@images/profile/empty/education-experience.svg';
import EmptyCertificates from '@images/profile/empty/certificates.svg';
import EmptyAttachment from '@images/profile/empty/attachment.svg';

import { Mixpanel } from '../../utilities/mixpanel';

import { AiCvNoticeInfo } from '@views/main-view/components/AiCvNoticeInfo';

import { baseFormValidator } from './utils/validator';
// server
import {
  useGetProfileDataById,
  useUpdateProfileById,
  useAddExperience,
  useUpdateProfileExperience,
  useDeleteProfileExperience,
  useAddEducational,
  useUpdateEducational,
  useDeleteEducational,
  useAddCertificate,
  useUpdateCertificate,
  useDeleteCertificate,
  useGetProfileAttachments,
  useDeleteAttachment,
} from '@services/Profile';

import './style/index.scss';
import { popupError } from '@utilities/error-handler';

export const ProfileContext = React.createContext();
// import { useStyles } from './Style';
//import { BUTTON, PROFILE } from '../../constants/behaviors';

export const Profile = (props) => {
  const profileId = props.profileId;

  const { data: profileData } = useGetProfileDataById(profileId);
  const { attachment, refetch: refetchProfileAttachment } =
    useGetProfileAttachments(profileId);
  const { mutateAsync: onUpdateProfile } = useUpdateProfileById();

  const handleUpdateProfile = async (params, trackInfo, cb, errorCb) => {
    // console.log(params, trackInfo, cb);
    params.profileId = profileId;
    const res = await onUpdateProfile(params);
    if (res) {
      // refetchProfileData();
      Mixpanel.track(trackInfo);
      if (cb) {
        cb(res);
      }
    } else {
      if (errorCb) {
        errorCb(res);
      }
    }
  };

  const profiles = useContext(MainPageContext).profiles.titles.map((item) => item.title);

  // 页面始终只有一个弹窗，共用form校验
  const [formValidator, setFormValidator] = useState({});
  // 弹出表单时，先将错误信息覆盖
  const resetFormValidator = () => {
    setFormValidator({});
  };
  // 修改基础信息
  const [editContactInfo, setEditContactInfo] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    ...profileData.contact_information,
    ...profileData.address,
    require_sponsorship: profileData.require_sponsorship,
    has_work_authorization: profileData.has_work_authorization,
  });
  // 修改links
  const [editLinks, setEditLinks] = useState(false);
  const [linksInfo, setLinksInfo] = useState({
    ...profileData.links,
  });
  // 修改identifications
  const [editIdentifications, setEditIdentifications] = useState(false);
  const [identificationsInfo, setIdentificationsInfo] = useState({
    ...profileData.identity,
  });
  /**
   * 修改弹窗表单
   * editFormData 弹窗表单数据
   * trackInfo 修改成功后Mixpanel.track的消息内容
   * */
  const handleSaveForm = (editFormData, rules, trackInfo) => {
    let validFormFlag = Object.keys(formValidator).length > 0; // 如果为true，表示本身存在错误
    // 如果 formValidator 为空，可能是新增，没有做任何操作，直接点保存，此时需要走表单校验
    if (!validFormFlag) {
      let validForm = {};
      validForm = baseFormValidator(editFormData, rules);
      validFormFlag = Object.keys(validForm).length > 0;
      setFormValidator(validForm);
    }
    if (!validFormFlag) {
      handleUpdateProfile({ ...editFormData }, trackInfo, () => {
        setEditContactInfo(false);
        setEditLinks(false);
        setEditIdentifications(false);
      });
    }
  };

  // 新增 修改experience
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editDialogInfo, setEditDialogInfo] = useState({});

  const { mutateAsync: onAddExperience } = useAddExperience();
  const { mutateAsync: onUpdateExperience } = useUpdateProfileExperience();
  const { mutateAsync: onAddEducational } = useAddEducational();
  const { mutateAsync: onUpdateEducational } = useUpdateEducational();
  const { mutateAsync: onAddCertificate } = useAddCertificate();
  const { mutateAsync: onUpdateCertificate } = useUpdateCertificate();
  const addFunMap = {
    work: onAddExperience,
    educational: onAddEducational,
    certificate: onAddCertificate,
  };
  const updateFunMap = {
    work: onUpdateExperience,
    educational: onUpdateEducational,
    certificate: onUpdateCertificate,
  };
  // 编辑 新增 点击事件
  const onShowEditDialog = (info) => {
    resetFormValidator();
    setEditDialogInfo(info);
    setShowEditDialog(true);
  };
  // 更新form表单数据
  const updateFormData = (info) => {
    setEditDialogInfo(info);
  };
  const getComponentForm = (type) => {
    if (type === 'work') {
      return (
        <ExperienceForm
          info={editDialogInfo}
          rules={rulesMap[type]}
          formValidator={formValidator}
          updateForm={updateFormData}
          changeFormValid={setFormValidator}
        ></ExperienceForm>
      );
    } else if (type === 'educational') {
      return (
        <EducationForm
          info={editDialogInfo}
          rules={rulesMap[type]}
          formValidator={formValidator}
          updateForm={updateFormData}
          changeFormValid={setFormValidator}
        ></EducationForm>
      );
    } else if (type === 'certificate') {
      return (
        <CertificateForm
          info={editDialogInfo}
          rules={rulesMap[type]}
          formValidator={formValidator}
          updateForm={updateFormData}
          changeFormValid={setFormValidator}
        ></CertificateForm>
      );
    } else {
      return null;
    }
  };

  const handleSaveAddAndEditForm = async () => {
    const { id, componentName, dialogTitle, ...other } = editDialogInfo;

    let validFormFlag = Object.keys(formValidator).length > 0; // 如果为true，表示本身存在错误
    // 如果 formValidator 为空，可能是新增，没有做任何操作，直接点保存，此时需要走表单校验
    if (!validFormFlag) {
      let validForm = {};
      const formRules = { ...rulesMap[componentName] };
      // 如果是工作经历，且current_work_status = true，则不校验end_date
      if (componentName === 'work' && other.current_work_status) {
        delete formRules.end_date;
      }
      validForm = baseFormValidator(editDialogInfo, formRules);
      validFormFlag = Object.keys(validForm).length > 0;
      setFormValidator(validForm);
    }

    if (!validFormFlag) {
      let funName = null;
      // 编辑
      if (id) {
        funName = updateFunMap[componentName];
      } else {
        funName = addFunMap[componentName];
      }
      // // 如果是工作经历，则需清除掉 description_draft，ai-cv显示最新的编辑经历即可
      // if (componentName === 'work') {
      //   other.description_draft = other.description;
      // }

      const res = await funName({ profileId: profileId, id: id, ...other });
      if (res) {
        Mixpanel.track(dialogTitle);
        setShowEditDialog(false);
      } else {
        popupError(dialogTitle + ' failed');
      }
    }
  };
  // 删除 experience
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({});
  const { mutateAsync: onDeleteExperience } = useDeleteProfileExperience();
  const { mutateAsync: onDeleteEducational } = useDeleteEducational();
  const { mutateAsync: onDeleteCertificate } = useDeleteCertificate();
  const { mutateAsync: onDeleteAttachment } = useDeleteAttachment();
  const deleteFunMap = {
    work: onDeleteExperience,
    educational: onDeleteEducational,
    certificate: onDeleteCertificate,
    attachment: onDeleteAttachment,
  };
  const handleDeleteItem = async () => {
    const { id, componentName, dialogTitle } = deleteInfo;
    const funName = deleteFunMap[componentName];
    const res = await funName({ id: id });
    if (res) {
      Mixpanel.track(dialogTitle);
      setShowDeleteDialog(false);
      setDeleteInfo({});
    }
  };

  // 关闭
  const closeIdentificationDialog = () => {};

  /**
   * attachment的upload 和replace 的标识
   *
   * 目前只考虑一个attachment，新增 修改，其实都是本质是用新上传的覆盖老的
   * 只是dialog的title不一样而已
   * */
  const [openAttachment, setOpenAttachment] = useState(false);
  return (
    <div className="tlm-profile-2">
      <div className="w-100">
        <ProfileContext.Provider value={profileId}>
          <AiCvNoticeInfo
            style={{ marginBottom: '30px' }}
            goAiCv={props.directToProfileAiBuilder}
          ></AiCvNoticeInfo>

          <ProfileHeader
            type="profile"
            name={profileData.profile_title || 'My Profile'}
            names={profiles}
            update={handleUpdateProfile}
            refreshMain={props.refreshMain}
          />
          <ProfileUpload profileId={profileId} resumeName={profileData.resume_name} />
          <BlockBox
            title="Basic Information"
            isEditIcon={true}
            handleClick={() => {
              resetFormValidator();
              setEditContactInfo(true);
            }}
          >
            <BaseInfo
              info={profileData.contact_information}
              address={profileData.address}
            ></BaseInfo>
            <AlertDialog
              open={editContactInfo}
              title="Edit Basic Information"
              onClose={() => setEditContactInfo(false)}
              onSure={() =>
                handleSaveForm(contactInfo, baseInfoRules, 'Edit Basic Information')
              }
            >
              <BaseForm
                info={profileData.contact_information}
                address={profileData.address}
                rules={baseInfoRules}
                formValidator={formValidator}
                updateForm={setContactInfo}
                changeFormValid={setFormValidator}
              ></BaseForm>
            </AlertDialog>
          </BlockBox>
          <BlockBox
            title="Work Experience"
            emptyImgSrc={EmptyWorkExperiences}
            isEmpty={!profileData.experiences || profileData.experiences.length === 0}
            handleClick={() => {
              onShowEditDialog({
                ...defaultExperienceInfo,
                componentName: 'work',
                dialogTitle: 'Add New Work Experience',
              });
            }}
          >
            {profileData.experiences &&
              profileData.experiences.map((item) => {
                return (
                  <ExperienceBox
                    key={item.id}
                    info={item}
                    onEdit={() => {
                      onShowEditDialog({
                        ...item,
                        componentName: 'work',
                        dialogTitle: 'Edit Work Experience',
                      });
                    }}
                    onDelete={() => {
                      setDeleteInfo({
                        ...item,
                        componentName: 'work',
                        dialogTitle: 'Delete Work Experience',
                        dialogContent: 'Are you sure to delete the work experience',
                        dialogStrongContent: [item.title, item.company_name].join(' , '),
                      });
                      setShowDeleteDialog(true);
                    }}
                  ></ExperienceBox>
                );
              })}
          </BlockBox>
          <BlockBox
            title="Educational Experiences"
            emptyImgSrc={EmptyEducationExperiences}
            isEmpty={!profileData.educations || profileData.educations.length === 0}
            handleClick={() => {
              onShowEditDialog({
                ...defaultEducationalInfo,
                componentName: 'educational',
                dialogTitle: 'Add New Educational Experience',
              });
            }}
          >
            {profileData.educations &&
              profileData.educations.map((item) => {
                return (
                  <EducationBox
                    key={item.id}
                    info={item}
                    onEdit={() => {
                      onShowEditDialog({
                        ...item,
                        componentName: 'educational',
                        dialogTitle: 'Edit Educational Experience',
                      });
                    }}
                    onDelete={() => {
                      setDeleteInfo({
                        ...item,
                        componentName: 'educational',
                        dialogTitle: 'Delete Educational Experience',
                        dialogContent: 'Are you sure to delete the education experience',
                        dialogStrongContent: [item.major, item.school].join(' , '),
                      });
                      setShowDeleteDialog(true);
                    }}
                  ></EducationBox>
                );
              })}
          </BlockBox>
          <BlockBox
            title="Licenses & Certifications"
            emptyImgSrc={EmptyCertificates}
            isEmpty={!profileData.licenses || profileData.licenses.length === 0}
            handleClick={() => {
              resetFormValidator();
              setShowEditDialog(true);
              setEditDialogInfo({
                ...defaultCertificationInfo,
                componentName: 'certificate',
                dialogTitle: 'Add Certification Information',
              });
            }}
          >
            <Grid container spacing={2}>
              {profileData.licenses &&
                profileData.licenses.map((item) => {
                  return (
                    <Grid key={item.id} item xs={6} md={6}>
                      <CertificateBox
                        info={item}
                        onEdit={() => {
                          onShowEditDialog({
                            ...item,
                            componentName: 'certificate',
                            dialogTitle: 'Edit Certification Information',
                          });
                        }}
                        onDelete={() => {
                          setDeleteInfo({
                            ...item,
                            componentName: 'certificate',
                            dialogTitle: 'Delete Certification Information',
                            dialogContent: 'Are you sure to delete the certification',
                            dialogStrongContent: item.name,
                          });
                          setShowDeleteDialog(true);
                        }}
                      ></CertificateBox>
                    </Grid>
                  );
                })}
            </Grid>
          </BlockBox>

          <BlockBox
            title="Links"
            isEditIcon={true}
            handleClick={() => {
              resetFormValidator();
              setEditLinks(true);
            }}
          >
            <LinksInfo info={profileData.links}></LinksInfo>
            <AlertDialog
              open={editLinks}
              title="Edit Links"
              onClose={() => setEditLinks(false)}
              onSure={() => handleSaveForm(linksInfo, linksRules, 'Edit Links')}
            >
              <LinksForm
                info={profileData.links}
                rules={linksRules}
                updateForm={setLinksInfo}
                formValidator={formValidator}
                changeFormValid={setFormValidator}
              ></LinksForm>
            </AlertDialog>
          </BlockBox>

          <BlockBox title="Skills" onlyTitle={true}>
            <SkillsInfo
              data={profileData.skills}
              handleUpdateProfile={handleUpdateProfile}
            ></SkillsInfo>
          </BlockBox>

          <BlockBox
            title="Self-Identification Questions"
            isEditIcon={true}
            handleClick={() => {
              resetFormValidator();
              setEditIdentifications(true);
            }}
          >
            <IdentificationsInfo info={profileData.identity}></IdentificationsInfo>
            <AlertDialog
              open={editIdentifications}
              title="Edit Self-Identification Questions"
              onClose={(needVerifyChange) => {
                console.log('needVerifyChange', needVerifyChange);
                if (needVerifyChange) {
                  // todo 二次弹窗
                  closeIdentificationDialog();
                  setEditIdentifications(false);
                } else {
                  setEditIdentifications(false);
                }
              }}
              onSure={() =>
                handleSaveForm(
                  identificationsInfo,
                  identificationsRules,
                  'Edit Self-Identification Questions'
                )
              }
            >
              <IdentificationsForm
                info={profileData.identity}
                rules={identificationsRules}
                updateForm={setIdentificationsInfo}
                formValidator={formValidator}
                changeFormValid={setFormValidator}
              ></IdentificationsForm>
            </AlertDialog>
          </BlockBox>

          <BlockBox
            title="Attachments"
            type="attachment"
            emptyInfoText="Save your attachment to upload in job applications"
            emptyHandleText="Upload attachments"
            emptyImgSrc={EmptyAttachment}
            isEmpty={!attachment?.file_key}
            disabled={attachment?.file_key}
            handleClick={() => {
              setOpenAttachment(true);
            }}
          >
            <ProfileAttachment
              profileId={profileId}
              attachmentData={attachment}
              refetch={refetchProfileAttachment}
              onDelete={() => {
                setDeleteInfo({
                  id: profileId, // 目前删除attachment 使用profileid，正儿八经应该调整成文件id
                  componentName: 'attachment',
                  dialogTitle: 'Delete Attachment',
                  dialogContent: 'Are you sure to delete your current resume',
                  dialogStrongContent: attachment?.file_name,
                });
                setShowDeleteDialog(true);
              }}
              showEditDialog={openAttachment}
              setShowEditDialog={setOpenAttachment}
            ></ProfileAttachment>
          </BlockBox>
          {/* add & edit dialog  experience Educational Licenses */}
          <AlertDialog
            key="Edit"
            open={showEditDialog}
            title={editDialogInfo.dialogTitle}
            onClose={() => setShowEditDialog(false)}
            onSure={handleSaveAddAndEditForm}
          >
            {getComponentForm(editDialogInfo.componentName)}
          </AlertDialog>
          {/* delete dialog  experience Educational Licenses attachment */}
          <AlertDialog
            key="Delete"
            width="480px"
            sureText="Delete"
            open={showDeleteDialog}
            title={deleteInfo.dialogTitle}
            onClose={() => setShowDeleteDialog(false)}
            onSure={handleDeleteItem}
          >
            <Stack
              sx={{
                display: 'inline-block',
                fontSize: '18px',
                lineHeight: '24px',
                wordBreak: 'break-all',
              }}
            >
              {deleteInfo.dialogContent + ' : '}
              <strong>{deleteInfo.dialogStrongContent}</strong>&nbsp;&nbsp;？
            </Stack>
          </AlertDialog>
        </ProfileContext.Provider>
      </div>
    </div>
  );
};

export default Profile;
