import React, { useState } from 'react';
import { Stack, Button, Menu, MenuItem, FormControlLabel } from '@mui/material';
import CheckboxWrapper from '../Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import './index.scss';

export default function CommonDropdown({
  isDisabled,
  label = 'Select',
  noCheckbox = false,
  items = [],
  value,
  itemProps = {
    id: 'id',
    label: 'name',
  },
  hasButton = false,
  buttonText = 'Apply',
  emptyText,
  onChange,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [tempValue, setTempValue] = useState(value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTempValue('');
  };

  // 点击按钮
  const handleApply = () => {
    onChange(tempValue);
    handleClose();
  };

  return (
    <Stack>
      <Button
        sx={{ height: '100%' }}
        disabled={isDisabled}
        id="basic-button"
        variant={open ? 'contained' : 'outlined'}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        className="common-dropdown-menu"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.length > 0 ? (
          <Stack className="common-dropdown-menu-items">
            {items.map((ele, key) => {
              // 如果是对象
              let id = ele;
              let name = ele;
              if (typeof ele === 'object') {
                id = ele[itemProps.id];
                name = ele[itemProps.label];
              }
              return noCheckbox ? (
                <MenuItem
                  key={id}
                  onClick={() => {
                    onChange(id);
                    handleClose();
                  }}
                >
                  {name}
                </MenuItem>
              ) : (
                <FormControlLabel
                  key={id}
                  control={
                    <CheckboxWrapper
                      isRadio={true}
                      checked={tempValue === id}
                      onChange={() => setTempValue(id)}
                    />
                  }
                  label={name}
                  name={name}
                  value={id}
                />
              );
            })}
          </Stack>
        ) : (
          <Stack className="common-dropdown-empty">{emptyText}</Stack>
        )}
        {hasButton ? (
          <Stack sx={{ p: 2 }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleApply}
              disabled={items.length === 0}
            >
              {buttonText}
            </Button>
          </Stack>
        ) : null}
      </Menu>
    </Stack>
  );
}
