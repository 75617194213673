import React from 'react';
import PropTypes from 'prop-types';

const RoundTag = (props) => {
  const style = {
    color: props.color,
    backgroundColor: props.backgroundColor,
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '12px',
    width: props.width || '122px',
    height: '25px',
    lineHeight: '25px',
    borderRadius: '20px',
    padding: '.1em 1em',
  };
  return <span style={style}>{props.text}</span>;
};

RoundTag.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
};

export default RoundTag;
