import axios from 'axios';
import { BACKEND_PATH } from '../ServiceConstants';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import mockData from './mockData';

export const useHomeData = (initTaskList) => {
  const { data, ...rest } = useQuery({
    queryKey: ['home'],
    queryFn: async () => {
      const res = await axios.get(`${BACKEND_PATH}api/homepage/`);
      // console.log(res.data);
      const { has_signup_5days = false, insight_data, tasks = {}, user_name } = res.data;
      // // 浏览器安装完插件，从localStorage中获取
      const isDownloadExtensionComplete = localStorage.getItem('OnboardingStatus');
      if (isDownloadExtensionComplete === 'DownloadExtensionComplete') {
        tasks.download_extension = true;
      }
      // const { has_signup_5days = false, insight_data, tasks = {}, user_name } = mockData;

      const taskList = has_signup_5days ? initTaskList : initTaskList.splice(0, 4);

      const initStatus = (arr = []) => {
        return arr.map((item) => tasks[item.taskId]);
      };
      return {
        userName: user_name,
        statisticsData: insight_data,
        tasksStatus: initStatus(taskList),
        tasks: tasks,
        taskList,
      };
    },
  });

  return {
    ...data,
    ...rest,
  };
};

export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.post(`${BACKEND_PATH}api/homepage/`, {
        ...params,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('home');
      },
    }
  );
};
