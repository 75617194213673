import React from 'react';
import { beakRight, NotificationCloseIcon } from '../../../components/icons';
import '../../../styles/typography';

const PanelNotificationTooltip = (props) => {
  const pathName = window.location.pathname;
  return (
    <div>
      <div className="tlm-notification left">
        <span className={'tooltip-beak'}>{beakRight}</span>
        <div
          className="notification-closeButton"
          onClick={() => {
            props.setPanelNotification(false);
            //profile onboarding flow only trigger when user don have resume, which is not a case when user finish onboarding
            let isDashboardPage = pathName === '/' || pathName.includes('dashboard');
            if (
              isDashboardPage &&
              localStorage.getItem('numOfDashboardNewUserGuideFlow') !== '1'
            ) {
              StonlyWidget('openGuidedTour', { guideId: 'dOWd8JdSlW' });
              localStorage.setItem('numOfDashboardNewUserGuideFlow', '1');
            }
          }}
        >
          {NotificationCloseIcon}
        </div>
        <div className="body-text-16">
          You can refer back to the onboarding process whenever you want at Quick Start
        </div>
      </div>
    </div>
  );
};

export default PanelNotificationTooltip;
