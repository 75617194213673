import React from 'react';
import './NotFoundPage.scss';
import notFoundPage from '@images/notFoundPage.jpg';

function NotFoundPage(props) {
  const goToHomePage = () => {
    props.history.push(`/`);
  };
  const goToContactUs = () => {
    window.location.href = 'https://home.offerland.ai/contactus/';
  };
  return (
    <div className="tlm-error-page">
      <div className="tlm-error-page-img">
        <img src={notFoundPage} />
      </div>
      <div className="tlm-error-page-message">
        Sorry. the content you’re looking for doesn’t exist. <br />
        Either it was removed, or you mistyped the link.
      </div>
      <div className="tlm-error-page-button-panel">
        <button className="tlm-error-page-button-homepage" onClick={goToHomePage}>
          Go to homepage
        </button>
        <button className="tlm-error-page-button-contact-us" onClick={goToContactUs}>
          Contact us
        </button>
      </div>
    </div>
  );
}

export default NotFoundPage;
