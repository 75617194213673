import React from 'react';
export const defaultThumbupIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 8H14.69L15.64 3.43L15.67 3.11C15.67 2.7 15.5 2.32 15.23 2.05L14.17 1L7.59 7.59C7.22 7.95 7 8.45 7 9V19C7 20.1 7.9 21 9 21H18C18.83 21 19.54 20.5 19.84 19.78L22.86 12.73C22.95 12.5 23 12.26 23 12V10C23 8.9 22.1 8 21 8ZM21 12L18 19H9V9L13.34 4.66L12.23 10H21V12ZM1 9H5V21H1V9Z"
      fill="#5CB6AE"
    />
  </svg>
);

export const thumbupIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 20H4C4.55 20 5 19.55 5 19V10C5 9.45 4.55 9 4 9H2V20ZM21.83 12.88C21.94 12.63 22 12.36 22 12.08V11C22 9.9 21.1 9 20 9H14.5L15.42 4.35C15.47 4.13 15.44 3.89 15.34 3.69C15.11 3.24 14.82 2.83 14.46 2.47L14 2L7.59 8.41C7.21 8.79 7 9.3 7 9.83V17.67C7 18.95 8.05 20 9.34 20H17.45C18.15 20 18.81 19.63 19.17 19.03L21.83 12.88Z"
      fill="#5CB6AE"
    />
  </svg>
);

export const unBookmarkedIcon = (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18L7 15L14 18V2C14 0.9 13.1 0 12 0ZM12 15L7 12.82L2 15V2H12V15Z"
      fill="#FBC35B"
    />
  </svg>
);

export const bookmarkedIcon = (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H2C0.9 0 0 0.9 0 2V18L7 15L14 18V2C14 0.9 13.1 0 12 0Z"
      fill="#FBC35B"
    />
  </svg>
);

export const notFoundIcon = (
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.015 21C11.61 21 11.235 20.85 10.95 20.565C10.65 20.265 10.515 19.92 10.515 19.5C10.5 18.675 11.16 18.015 11.985 18C12 18 12 18 12.015 18C12.435 18 12.78 18.15 13.08 18.435C13.35 18.72 13.5 19.08 13.5 19.485C13.5 19.89 13.35 20.25 13.065 20.55C12.78 20.85 12.42 21 12.015 21ZM10.695 15.51C10.695 14.835 10.845 14.25 11.13 13.77C11.415 13.275 11.925 12.72 12.63 12.09C13.05 11.715 13.35 11.385 13.545 11.1C13.74 10.815 13.83 10.5 13.83 10.14C13.83 9.705 13.665 9.345 13.35 9.03C13.035 8.73 12.6 8.58 12.075 8.58C11.52 8.58 10.965 8.73 10.635 9.03C10.32 9.33 10.035 9.705 10.035 10.5H7.5C7.5 8.985 8.19 7.905 8.955 7.185C9.795 6.42 10.875 6 12 6C12.885 6 13.665 6.18 14.355 6.525C15.045 6.87 15.54 7.35 15.93 7.965C16.32 8.58 16.5 9.255 16.5 9.99C16.5 10.725 16.35 11.34 16.035 11.865C15.72 12.39 15.315 12.93 14.7 13.5C14.22 13.95 13.905 14.34 13.725 14.655C13.545 14.97 13.455 15.39 13.455 15.87V16.5H10.68V15.51H10.695ZM21 12.3C21 6.855 17.025 3 12 3C6.975 3 3 6.855 3 12.3C3 15.81 5.925 20.46 12 26.01C18.075 20.46 21 15.81 21 12.3ZM12 0C18.3 0 24 4.83 24 12.3C24 17.28 19.995 23.175 12 30C4.005 23.175 0 17.28 0 12.3C0 4.83 5.7 0 12 0Z"
      fill="#05756C"
    />
  </svg>
);

export const noBookmarkIcon = (
  <svg
    width="22"
    height="28"
    viewBox="0 0 22 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 0.5H3.5C1.85 0.5 0.515 1.85 0.515 3.5L0.5 27.5L11 23L21.5 27.5V3.5C21.5 1.85 20.15 0.5 18.5 0.5ZM18.5 23L11 19.73L3.5 23V3.5H18.5V23Z"
      fill="#FBC35B"
    />
  </svg>
);
