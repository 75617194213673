import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';

export const getTemplateCategories = async () => {
  return await axios.get(`${BACKEND_PATH}api/templates/categories/`);
};

// should work properly once the backend api update the get request using params
export const getTemplatesForACategory = async (categoryId) => {
  return await axios.get(`${BACKEND_PATH}api/templates/`, {
    params: {
      template_category: categoryId,
    },
  });
};

export const createUserTemplate = async (title, content, id) => {
  return await axios.post(`${BACKEND_PATH}api/user-templates/`, {
    title: title,
    content: content,
    template_category: id,
  });
};

export const getUserTemplates = async () => {
  return await axios.get(`${BACKEND_PATH}api/user-templates/`);
};

export const saveUserTemplate = async (userTemplateId, title, content, id) => {
  return await axios.post(`${BACKEND_PATH}api/user-templates/${userTemplateId}/`, {
    title: title,
    content: content,
    template_category: id,
  });
};

export const deleteUserTemplate = async (userTemplateId) => {
  return await axios.delete(`${BACKEND_PATH}api/user-templates/${userTemplateId}/`);
};

export const firstTimeUser = async () => {
  return await axios.get(`${BACKEND_PATH}api/user-templates/first-time-user/`);
};

export const getUserEmail = async () => {
  return await axios.get(`${BACKEND_PATH}api/user-email/`);
};
