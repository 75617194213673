import axios from 'axios';
import { baseEndpoint, finishTooltipEndpoint } from './Endpoints';
import { BACKEND_PATH } from './ServiceConstants';

export const getInfoData = async () => {
  const data = {};
  await axios
    .get(`${BACKEND_PATH}api/dashboards/`)
    .then((response) => {
      if (response.data) data.dashboards = response.data;
      else data.dashboards = [];
    })
    .catch((err) => {
      data.dashboards = [];
    });
  await axios
    .get(`${BACKEND_PATH}api/profile-titles/`)
    .then((response) => {
      if (response.data) data.profiles = response.data;
      else data.profiles = { titles: [] };
    })
    .catch((err) => {
      data.profiles = { titles: [] };
    });
    await axios
    .get(`${BACKEND_PATH}api/guides/`)
    .then((response) => {
      if(response.data) data.guidances = response.data;
      else data.guidances = [];
    })
    .catch((err) => {
      data.guidances = [];
    });
  return data;
};

export const getDefaultDashboard = async () => {
  return await axios.get(`${BACKEND_PATH}api/default-dashboard/`).then((response) => {
    return response.data.id;
  });
};

export const addNewDashboardToDatabase = async (dashboardName) => {
  return await axios.post(`${BACKEND_PATH}api/dashboards/`, {
    name: dashboardName,
  });
};

export const finishTooltip = async () => {
  return await axios.post(`${baseEndpoint}/${finishTooltipEndpoint}`, {
    completed_tooltip: true,
  });
};
