import React, { useState } from 'react';
import { discordIcon, floatingMenuIcon, emailIcon } from '../../../components/icons';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './FloatingMenu.scss';

const FloatingMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHoverDiscord, setHoverDiscord] = useState(false);
  const [isHoverEmail, setHoverEmail] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="floating-menu">
      <div className="d-flex justify-content-end">
        {/* Toggle button */}
        {isExpanded ? (
          <div onClick={toggleMenu} className="close-btn">
            <CloseIcon sx={{ fontSize: 24 }} />
          </div>
        ) : (
          <div onClick={toggleMenu} className="toggle-btn">
            {floatingMenuIcon}
          </div>
        )}
      </div>

      {/* The expanded menu */}
      <div id="expanded-menu" className={isExpanded ? 'show' : 'hidden'}>
        <div
          className="menu-button discord"
          onClick={() => window.open('https://discord.gg/ujffAUn3BQ', '_blank')}
          onMouseEnter={() => setHoverDiscord(true)}
          onMouseLeave={() => setHoverDiscord(false)}
        >
          {isHoverDiscord ? (
            <div className="text">
              <span>Go to Discord</span>
              <ArrowForwardIcon sx={{ fontSize: 24 }} />
            </div>
          ) : (
            discordIcon
          )}
        </div>

        <div
          className="menu-button email"
          onClick={() => (window.location.href = 'mailto:contact@offerland.ai')}
          onMouseEnter={() => setHoverEmail(true)}
          onMouseLeave={() => setHoverEmail(false)}
        >
          {isHoverEmail ? (
            <div>
              <div className="text">
                <span>Email us</span>
                <ArrowForwardIcon sx={{ fontSize: 24 }} />
              </div>
              <div sx={{ fontSize: 12 }}>contact@offerland.ai</div>
            </div>
          ) : (
            emailIcon
          )}
        </div>
      </div>
    </div>
  );
};

export default FloatingMenu;
