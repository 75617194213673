import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import MissingPartialImage from '@images/dashboard/ai-guidance-missing-partial.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const MissingPartialHelpText = ({
  hasJobDescription,
  hasProfile,
  startNowHandler,
  switchToProfileHandler,
  switchToDescriptionHandler,
}) => {
  const helpText =
    !hasJobDescription && !hasProfile
      ? 'Complete your profile and job description now to receive more accurate results.'
      : !hasJobDescription
      ? 'Add job description to receive more accurate results.'
      : 'Complete your profile to receive more accurate results.';

  return (
    <Stack m={3} spacing={2} alignItems="flex-start">
      <img src={MissingPartialImage} alt="missing partial information" />
      <Typography variant="body1">
        {`Enhance your experience for tailored guidance! ${helpText}`}
      </Typography>
      {!hasProfile && (
        <Button
          variant="text"
          style={{ fontWeight: 'bold', padding: 0 }}
          onClick={switchToProfileHandler}
        >
          {'Complete profile'}
          <KeyboardArrowRightIcon fontSize="small" />
        </Button>
      )}
      {!hasJobDescription && (
        <Button
          variant="text"
          style={{ fontWeight: 'bold', padding: 0 }}
          onClick={switchToDescriptionHandler}
        >
          {'Add job description'}
          <KeyboardArrowRightIcon fontSize="small" />
        </Button>
      )}
      <Button variant="outlined" style={{ fontWeight: 'bold' }} onClick={startNowHandler}>
        Start Anyway
      </Button>
    </Stack>
  );
};

export default MissingPartialHelpText;
