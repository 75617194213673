import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import './DeleteConfirmationModal.scss';

const DeleteConfirmationModal = (props) => {
  return (
    <Modal className="tlm-modal" show={props.show} onHide={props.onHideFunc}>
      <Modal.Header closeButton>
        <Modal.Title className="tlm-modal-title">Delete {props.deleteField}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tlm-modal-message-panel">
          <span>
            {props.fieldName
              ? `Are you sure you want to delete "${
                  props.fieldName
                }" ${props.deleteField.toLowerCase()}?`
              : 'Are you sure you to delete the selected job(s)?'}
          </span>
        </div>
        <div className="tlm-modal-button-panel">
          <button className="tlm-modal-button-no" onClick={props.onCancelFunc}>
            No
          </button>
          <button className="tlm-modal-button-yes" onClick={props.onConfirmFunc}>
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  deleteField: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onHideFunc: PropTypes.func.isRequired,
  onCancelFunc: PropTypes.func.isRequired,
  onConfirmFunc: PropTypes.func.isRequired,
};

export default DeleteConfirmationModal;
