import React from 'react';
import './MyTemplateDropdown.css';

const MyTemplateDropdown = (props) => {
  return (
    <div className="my-temp-prev-card-dropdown-menu">
      <div
        className="my-temp-prev-card-dropdown-menu-save"
        onClick={props.handleSaveAsNew}
      >
        Save as new
      </div>
      {!props.isOfferlandTemplate && (
        <div
          className="my-temp-prev-card-dropdown-menu-delete"
          onClick={props.handleDelete}
        >
          Delete
        </div>
      )}
    </div>
  );
};

export default MyTemplateDropdown;
