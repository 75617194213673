import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  expandMore,
  expandMoreDisabled,
  stepSuccess,
  expandLess,
  expandLessDisabled,
  DownloadExtensionIcon,
  SaveandTrackJobsIcon,
  AutofillIcon,
  DisabledDownloadExtensionIcon,
  DisabledSaveandTrackJobsIcon,
  DisabledAutofillIcon,
} from '../icons';
import './index.scss';

const CollapseCard = (props) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props]);

  function getExpandIcon() {
    if (props.completed) {
      return collapsed ? expandMore : expandLess;
    } else {
      return collapsed ? expandMoreDisabled : expandLess;
    }
  }

  function toggleExpand() {
    setCollapsed(!collapsed);
  }

  const HeaderIcon = props.completed
    ? stepSuccess
    : props.tag === 'download'
    ? collapsed
      ? DisabledDownloadExtensionIcon
      : DownloadExtensionIcon
    : props.tag === 'autotrack'
    ? collapsed
      ? DisabledSaveandTrackJobsIcon
      : SaveandTrackJobsIcon
    : collapsed
    ? DisabledAutofillIcon
    : AutofillIcon;

  return (
    <section className={collapsed ? 'tlm-collapse-card-disabled' : 'tlm-collapse-card'}>
      {!props.noHeader && (
        <div className="tlm-collapse-card-header-section" onClick={toggleExpand}>
          <div className="d-flex align-items-center pl-3">
            {HeaderIcon}
            <span
              className={
                props.completed
                  ? 'tlm-collapse-card-header pl-1'
                  : collapsed
                  ? 'tlm-collapse-card-header-disabled pl-1'
                  : 'tlm-collapse-card-header pl-1'
              }
            >
              {props.header}
            </span>
          </div>
          <div className={'tlm-collapse-card-icon d-inline'}>{getExpandIcon()}</div>
        </div>
      )}
      <div className={`d-${collapsed ? 'none' : 'block'}`}>{props.children}</div>
    </section>
  );
};

CollapseCard.propTypes = {
  header: PropTypes.string.isRequired,
  collapsed: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  noHeader: PropTypes.bool.isRequired,
};

export default CollapseCard;
