import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { Mixpanel } from '@utilities/mixpanel';

import '../style/index.scss';

import errorMessages from '@constants/errorMessages';
import { popupError } from '@utilities/error-handler';

const TitleWithRename = ({ type, name, names, update, refreshMain }) => {
  const [editing, setEditing] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');
  const [tempName, setTempName] = useState(name);

  // const classes = useStyles();
  const notExistTxt =
    type === 'dashboard'
      ? errorMessages.dashboardNameNotExist
      : errorMessages.profileNameNotExist;
  const duplicateTxt =
    type === 'dashboard'
      ? errorMessages.dashboardNameDuplicate
      : errorMessages.profileNameDuplicate;

  useEffect(() => {
    setEditing(false);
    setTempName(name);
  }, [type, name]);

  const handleSaveName = () => {
    if (!editing) {
      setEditing(true);
      return;
    }
    if (tempName === name) {
      setEditing(false);
      return;
    }
    if (!tempName) {
      setInvalidMessage(notExistTxt);
    } else if (tempName !== name && names && names.includes(tempName)) {
      setInvalidMessage(duplicateTxt);
    } else {
      setInvalidMessage('');
      update(
        { title: tempName },
        null,
        () => {
          refreshMain();
          setEditing(false);
          Mixpanel.track('Rename Profile Name', {
            ProfileName: name,
          });
        },
        () => {
          popupError(errorMessages.updateProfileName);
        }
      );
    }
  };

  return (
    <div className="profile-header">
      {!editing ? (
        <Tooltip title="Rename">
          <span className="profile-header-name" onClick={handleSaveName}>
            {name}
          </span>
        </Tooltip>
      ) : (
        <TextField
          className="profile-header-input"
          variant="outlined"
          value={tempName}
          onChange={(e) => {
            setTempName(e.target.value);
          }}
          onBlur={handleSaveName}
        />
      )}
      {invalidMessage ? <div className="warning-text">{invalidMessage}</div> : null}
    </div>
  );
};

export default TitleWithRename;
