import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import LoginPage from './views/LoginPage';
import MainView from './views/main-view';
import MobileView from './views/mobile-view';
import FeatureFlagView from './views/featureflag-view';
import NotFoundPage from './views/NotFoundPage';
import { createBrowserHistory } from 'history';
import { setupInterceptors } from './services/Endpoints';
import DummyApplication from './views/onboarding-view/component/DummyApplication';
import PlanSelection from './views/plan-selection-view';
import { SuccessPage } from './views/success-view/index';

function Routes() {
  const history = createBrowserHistory();
  setupInterceptors(history);
  return (
    <Router>
      <Route
        history={history}
        render={(props) => (
          <Switch>
            <Route path="/" exact component={isMobile ? MobileView : MainView} />
            <Route path="/homepage" component={MainView} />
            <Route path="/dashboard" component={isMobile ? MobileView : MainView} />
            <Route path="/login" component={LoginPage} />
            <Route path="/subscription-success" component={MainView} />
            <Route path="/subscription-failed" component={MainView} />
            <Route path="/profile/:id" component={MainView} />
            <Route path="/ai-cv-builder" component={MainView} />
            <Route path="/template" component={MainView} />
            <Route path="/job-board" component={MainView} />
            <Route path="/planning-guidance" component={MainView} />
            <Route path="/library" component={MainView} />
            <Route path="/interview" component={MainView} />
            <Route path="/featureflag57D56J5K" component={FeatureFlagView} />
            <Route path="/example-application" component={DummyApplication} />
            <Route path="/plan-selection" component={PlanSelection} />
            <Route path="/account" component={MainView} />
            <Route path="/success" component={SuccessPage} />
            <Route component={NotFoundPage} />
          </Switch>
        )}
      />
    </Router>
  );
}

export default Routes;
