import React, { useState, useEffect } from 'react';
import { createNewNote } from '../../../services/DashBoardServices';

import errorMessages from '../errorMessages';
// components
import { popupError } from '../../../utilities/error-handler';
import { addIcon } from '../../../components/icons';
import JobBoardNoteCard from './JobBoardNoteCard';
import JobBoardEditedNoteCard from './JobBoardEditedNoteCard';
import { Button } from '../../../stories/Button.tsx';
import { Mixpanel } from '../../../utilities/mixpanel';

// styles
import './JobBoardNotesTab.scss';

// ----------------------------------------------------------------------
export default function JobBoardNotesTab({
  curJobId,
  notesList,
  setNotesList,
  // setRefreshPageFlag,
  notesOpenFlag,
  jobTitle,
  curStatus,
  selectedStatus,
  refetch,
}) {
  const [notesWrittingFlag, setNotesWrittingFlag] = useState(notesOpenFlag);
  const [loaded, setLoaded] = useState(false);
  const [btnText, setBtnText] = React.useState('Set Reminder');
  const onCancelClick = () => {
    setBtnText('Set Reminder');
    setNotesWrittingFlag(false);
  };

  useEffect(() => {
    if (!loaded) {
      Mixpanel.track('Click Note Tab', {
        jobTitle: jobTitle,
      });
      setLoaded(true);
    }
  });
  useEffect(() => {
    setLoaded(true);
  });
  const onSaveClick = (jobNoteObj) => {
    setNotesWrittingFlag(false);
    jobNoteObj['job'] = curJobId;

    createNewNote(jobNoteObj)
      .then((res) => {
        setNotesList((prev) => [res.data, ...prev]);
        // setRefreshPageFlag((prev) => !prev);
        refetch();
        Mixpanel.track('Save Note', {
          jobTitle: jobTitle,
        });
      })
      .catch(() => {
        popupError(errorMessages.addJobNote);
      });
    setBtnText('Set Reminder');
  };

  return (
    <section className="tlm-jobBoardNotes-container">
      <Button
        isSecondary={true}
        className="tlm-jobBoardNotes-container--actions-add-notes"
        onClick={() => {
          setNotesWrittingFlag(true);
          Mixpanel.track('Add New Note', {
            jobTitle: jobTitle,
          });
        }}
      >
        <span className="tlm-jobBoardNotes-container--clickBtn-icon">{addIcon}</span>
        Add a Note
      </Button>
      <span style={{ marginBottom: '10px', display: 'block' }}> </span>
      <JobBoardEditedNoteCard
        setBtnText={setBtnText}
        btnText={btnText}
        jobTitle={jobTitle}
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
        curStatus={curStatus}
        notesWrittingFlag={notesWrittingFlag}
        selectedStatus={selectedStatus}
        notesOpenFlag={notesOpenFlag}
      />

      <div className="tlm-jobBoardNotes-allnotes-container">
        {notesList.length !== 0 && (
          <>
            <h6 className="tlm-jobBoardNotes-allnotes-title"> </h6>
            {notesList.map((ele) => (
              <JobBoardNoteCard
                jobNoteInfo={ele}
                curJobId={curJobId}
                key={ele.id}
                curStatus={ele.status_code}
                selectedStatus={selectedStatus}
                notesOpenFlag={notesOpenFlag}
                setNotesList={setNotesList}
                notesList={notesList}
                // setRefreshPageFlag={setRefreshPageFlag}
                refetch={refetch}
              />
            ))}
          </>
        )}
      </div>
    </section>
  );
}
