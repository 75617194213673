import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';

export const getDataOnDashboard = async (dashboardId) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return await axios.get(
    `${BACKEND_PATH}api/dashboards/${dashboardId}/?timezone=${timezone}`
  );
};

export const getDefaultDashboardData = async () => {
  return axios.get(`${BACKEND_PATH}api/dashboards/`, {});
};

export const updateDashboard = async (dashboardId, name) => {
  return await axios.post(`${BACKEND_PATH}api/dashboards/${dashboardId}/`, { name });
};

export const deleteDashboard = async (dashboardId) => {
  return await axios.delete(`${BACKEND_PATH}api/dashboards/${dashboardId}/`);
};

export const setPinStatus = async (jobId, is_pinned) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, { is_pinned: !is_pinned });
};

export const deleteJobs = async (selectedJobs) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/bulk_delete/`, {
    jobs_id: selectedJobs,
  });
};

export const changeStatus = async (jobId, status) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, { status });
};

export const changeMultipleStatus = async (selectedJobs, status) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/bulk_edit/`, {
    jobs_id: selectedJobs,
    status,
  });
};

export const updateJobLocation = async (jobId, location) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, { location });
};
export const updateJobLink = async (jobId, link) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, { link });
};

export const updateCompanyName = async (jobId, Company) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, {
    company_name: Company,
  });
};
export const updateJobName = async (jobId, jobName) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, { title: jobName });
};

export const updateJobDescription = async (jobId, description) => {
  return await axios.post(`${BACKEND_PATH}api/jobs/${jobId}/`, { description });
};
export const createNewNote = async (jobNote) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return await axios.post(`${BACKEND_PATH}api/notes/`, {
    ...jobNote,
    timezone: timezone,
  });
};

export const getAllNotes = async (jobId) => {
  return await axios.post(`${BACKEND_PATH}api/notes/`, { jobId });
};

export const getSingleNote = async (noteId) => {
  return await axios.get(`${BACKEND_PATH}api/notes/${noteId}`);
};

export const updateSingleNote = async (noteId, note, jobId) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return await axios.post(`${BACKEND_PATH}api/notes/${noteId}`, {
    ...note,
    timezone: timezone,
    job_id: jobId,
  });
};

export const deleteSingleNote = async (noteId) => {
  return await axios.delete(`${BACKEND_PATH}api/notes/${noteId}`);
};

export const getReferralInfo = async (jobId) => {
  return await axios.post(`${BACKEND_PATH}api/referrals/`, { job_id: jobId });
};

export const requestReferral = async (jobId) => {
  return await axios.post(`${BACKEND_PATH}api/referrals/`, { request_referral: jobId });
};

export const isSpecialUser = async () => {
  return await axios.get(`${BACKEND_PATH}api/user-strat/`, {
    Accept: 'application/json',
  });
};

export const delightfulMomentSurvey = async (rating, comment) => {
  return await axios.post(`${BACKEND_PATH}api/delightful-moment-survey/`, {
    score: rating,
    comment: comment,
  });
};
