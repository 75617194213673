import * as React from 'react';
import { Stack, Snackbar } from '@mui/material';

const successIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="20" height="20" rx="10" fill="#08CF58" />
    <path d="M9.5 14L13.5455 18L19 12" stroke="white" stroke-linecap="round" />
  </svg>
);

const errorIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2679 3C13.0377 1.66667 14.9623 1.66667 15.7321 3L24.3923 18C25.1621 19.3333 24.1999 21 22.6603 21H5.33975C3.80015 21 2.8379 19.3333 3.6077 18L12.2679 3Z"
      fill="#FF4646"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0217 13.7609C13.0217 14.3011 13.4597 14.7391 14 14.7391C14.5403 14.7391 14.9783 14.3011 14.9783 13.7609V9.63042C14.9783 9.09014 14.5403 8.65216 14 8.65216C13.4597 8.65216 13.0217 9.09014 13.0217 9.63042V13.7609ZM14 17.3478C14.5403 17.3478 14.9783 16.9098 14.9783 16.3696C14.9783 15.8293 14.5403 15.3913 14 15.3913C13.4597 15.3913 13.0217 15.8293 13.0217 16.3696C13.0217 16.9098 13.4597 17.3478 14 17.3478Z"
      fill="white"
    />
  </svg>
);

const warningIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2679 3C13.0377 1.66667 14.9623 1.66667 15.7321 3L24.3923 18C25.1621 19.3333 24.1999 21 22.6603 21H5.33975C3.80015 21 2.8379 19.3333 3.6077 18L12.2679 3Z"
      fill="#FFD335"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0217 14.7609C13.0217 15.3012 13.4597 15.7391 14 15.7391C14.5403 15.7391 14.9783 15.3012 14.9783 14.7609V10.6304C14.9783 10.0902 14.5403 9.65218 14 9.65218C13.4597 9.65218 13.0217 10.0902 13.0217 10.6304V14.7609ZM14 18.3478C14.5403 18.3478 14.9783 17.9098 14.9783 17.3696C14.9783 16.8293 14.5403 16.3913 14 16.3913C13.4597 16.3913 13.0217 16.8293 13.0217 17.3696C13.0217 17.9098 13.4597 18.3478 14 18.3478Z"
      fill="white"
    />
  </svg>
);

const iconMaps = {
  success: successIcon,
  error: errorIcon,
  warning: warningIcon,
};

export default function CommonSnackbar({
  snackBarOpen,
  setSnackBarOpen,
  type = '',
  children,
  duration = 2000,
  action,
}) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  return (
    <Snackbar
      className="tlm-jobboard-snackbar"
      open={snackBarOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={duration}
      onClose={handleClose}
      action={action}
      message={
        <Stack direction="row" sx={{ lineHeight: '24px' }}>
          {type ? <Stack sx={{ pr: 1 }}>{iconMaps[type.toLowerCase()]}</Stack> : null}
          {children}
        </Stack>
      }
    />
  );
}
