import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Card, CardContent } from '../../../stories/Card';
import { Tag } from '../../../stories/Tag';
import { Button } from '../../../stories/Button';
import { Checkbox } from '../../../stories/Checkbox';
import { changeSubtaskStatus } from '../../../services/GuidanceServices';

const styles = {
  root: {
    width: 942,
  },
  title: {
    fontSize: 16,
    lineHeight: '22px',
  },
  content: {
    paddingLeft: 34,
    fontSize: 16,
    lineHeight: '22px',
  },
  button: {
    marginTop: 7,
  },
  tag: {
    marginBottom: 8,
  },
  card: {
    marginBottom: 12,
  },
};

export default function GuidanceTaskCard(props) {
  const [checked, setChecked] = React.useState(props.task.is_checked);

  const handCheckboxChange = () => {
    changeSubtaskStatus(props.task.id, !checked);
    props.onCheckChange(!checked);
    setChecked(!checked);
  };

  return (
    <div style={styles.card}>
      <Card>
        <CardContent width="748px">
          <div style={styles.title}>
            <Checkbox
              value={checked}
              label={props.task.title}
              onChange={handCheckboxChange}
            />
          </div>
          <div id="markdown" style={styles.content}>
            <ReactMarkdown children={props.task.content}></ReactMarkdown>
            {props.task.routing_page && (
              <div style={styles.button}>
                <Button
                  onClick={() => {
                    window.open(props.task.routing_page.split(',')[0], '_blank');
                  }}
                >
                  {props.task.routing_page.split(',')[1]}
                </Button>
              </div>
            )}
          </div>
        </CardContent>
        <CardContent width="194px">
          <div style={styles.tag}>
            <Tag
              label={'Finish time: ~' + props.task.finish_time + 'mins'}
              themeColor="c-tag-default"
            />
          </div>
          {props.task.is_required ? (
            <Tag label="Required task" themeColor="c-tag-orange" />
          ) : (
            <Tag label="Optional task" themeColor="c-tag-sapphire" />
          )}
        </CardContent>
      </Card>
    </div>
  );
}
