import React from 'react';
import { Stack } from '@mui/material';

const BoxEmpty = ({
  title = '',
  emptyImgSrc = '',
  emptyInfoText = '',
  emptyHandleText = '',
  handleClick,
}) => {
  const helpText = title.toLowerCase();
  return (
    <Stack
      className="block-empty"
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Stack>
        <img alt={helpText} src={emptyImgSrc} />
      </Stack>
      <Stack className="help-text">
        {emptyInfoText ? emptyInfoText : `You don’t have any ${helpText} added yet.`}
      </Stack>
      <Stack className="handle-text" onClick={handleClick}>
        + {emptyHandleText ? emptyHandleText : `Add ${helpText}`}
      </Stack>
    </Stack>
  );
};

export default BoxEmpty;
