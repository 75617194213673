import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { deleteSingleNote, updateSingleNote } from '../../../services/DashBoardServices';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// components
import JobBoardEditedNoteCard from './JobBoardEditedNoteCard';
import {
  pencilIcon,
  remainderIcon,
  deleteIcon as DeleteIcon,
} from '../../../components/icons';
import { popupError } from '../../../utilities/error-handler';
import errorMessages from '../errorMessages';
import { DASHBOARDCONSTANTS } from '../DashboardConstants';
// styles
import './JobBoardNoteCard.scss';

// ----------------------------------------------------------------------
export default function JobBoardNoteCard({
  jobNoteInfo,
  curJobId,
  setNotesList,
  notesList,
  // setRefreshPageFlag,
  curStatus,
  selectedStatus,
  notesOpenFlag,
  refetch,
}) {
  const [curNoteInfo, setCurNoteInfo] = useState(jobNoteInfo);
  const [btnText, setBtnText] = useState(() => {
    if (curNoteInfo.reminder !== null) {
      const textTemp = moment(curNoteInfo.reminder.date).format('YYYY-MM-DD');
      return textTemp;
    }
    return 'Set Reminder';
  });
  const [notesWrittingFlag, setNotesWrittingFlag] = useState(false);

  const onEditedClick = () => {
    setNotesWrittingFlag(true);
  };

  const onRemovedClick = () => {
    deleteSingleNote(curNoteInfo.id)
      .then((res) => {
        //remove local data array
        const noteTempAry = notesList.filter((ele) => {
          return ele.id !== curNoteInfo.id;
        });
        setNotesList(noteTempAry);
        // setRefreshPageFlag((prev) => !prev);
        refetch();
      })
      .catch(() => {
        popupError(errorMessages.removeJobNote);
      });
  };

  const onEditedNoteCardCancel = () => {
    setNotesWrittingFlag(false);
    setBtnText(() => {
      if (curNoteInfo.reminder !== null) {
        const textTemp = moment(curNoteInfo.reminder.date).format('YYYY-MM-DD');
        return textTemp;
      }
      return 'Set Reminder';
    });
  };

  const onEditedNoteCardSave = (noteObj) => {
    setNotesWrittingFlag(false);
    updateSingleNote(curNoteInfo.id, noteObj, curJobId)
      .then((res) => {
        // update job notes array
        const newNoteAry = [...notesList];
        for (let idx in newNoteAry) {
          if (curNoteInfo.id === newNoteAry[idx].id) {
            newNoteAry[idx] = res.data;
          }
        }

        setNotesList(newNoteAry);
        setCurNoteInfo(res.data);

        // setRefreshPageFlag((prev) => !prev);
        refetch();
      })
      .catch((error) => {
        popupError(errorMessages.updateJobNote);
      });
  };

  return (
    <>
      <JobBoardEditedNoteCard
        setBtnText={setBtnText}
        btnText={btnText}
        onCancelClick={onEditedNoteCardCancel}
        onSaveClick={onEditedNoteCardSave}
        notesWrittingFlag={notesWrittingFlag}
        curNoteText={curNoteInfo.text}
        curStatus={curNoteInfo.status_code}
        selectedStatus={selectedStatus}
      />
      {!notesWrittingFlag && (
        <div className="tlm-jobBoardNotes-noteCard">
          {/* <Card variant="outlined" style={{ boxShadow: '1px 2px #bdbdbd' }}> */}
          <Card>
            <CardContent>
              <div className="tlm-jobBoardNotes-noteContent">{curNoteInfo.text}</div>

              <div
                className={
                  btnText !== 'Set Reminder'
                    ? 'tlm-jobBoardNotes-noteCard--actions'
                    : 'tlm-jobBoardNotes-noteCard--actions tlm-flex-end'
                }
              >
                <div className="flex items-center justify-between w-100">
                  {btnText !== 'Set Reminder' && (
                    <div className="tlm-jobBoardNotes-noteCard--reminder">
                      {remainderIcon}
                      <span>{btnText}</span>
                    </div>
                  )}
                  <div className="tlm-jobBoardNotes-noteCard--status">
                    <span>{DASHBOARDCONSTANTS.status[curStatus]['statusName']}</span>
                  </div>
                </div>
                <div className="tlm-jobBoardNotes-noteCard--actionsBtn">
                  <span
                    className="tlm-jobBoardNotes-noteCard--action-delete"
                    onClick={onRemovedClick}
                  >
                    {/* {xRemoveIcon} */}
                    {DeleteIcon}
                  </span>
                  <span
                    className="tlm-jobBoardNotes-noteCard--action-edit"
                    onClick={onEditedClick}
                  >
                    {pencilIcon}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
}
