import React, { useState, useEffect } from 'react';
import { Stack, Grid, Button, InputLabel } from '@mui/material';
import { roleList, levelList } from '../constant';
import { Mixpanel } from '../../../utilities/mixpanel';
import CommonSelect from '@components/CommonSelect';
import ProfileValidateModal from '../../../components/CommonDialog/ProfileValidateModal';

export const ProfileForm = ({
  profiles,
  targetInfo = {
    profileId: '',
    role: '',
    level: '',
  },
  createNewProfile,
  preAiCvProfile,
  getAllRecords,
}) => {
  // profile列表
  const profileList = profiles.titles || [];
  // const defaultProfileId = props.profiles.default_profile_id;
  const [isDisable, setIsDisable] = useState(true);
  const [isPremiumUser, setPremiumUser] = useState(false);
  const [isProfilePlanUpgrade, setProfilePlanUpgrade] = useState(false);
  const [profileId, setProfileId] = useState(targetInfo.profileId);
  const handleProfileChange = (val, item) => {
    if (val === '0') {
      // todo
      if (isPremiumUser) {
        if (createNewProfile) {
          createNewProfile();
          return;
        }
      } else {
        setProfilePlanUpgrade(true);
      }
    } else {
      setProfileId(val);
    }
  };

  const [role, setRole] = useState(targetInfo.role);
  const handleRoleChange = (val) => {
    setRole(val);
  };

  const [level, setlevel] = useState(targetInfo.level);
  const handlelevelChange = (val) => {
    setlevel(val);
  };

  useEffect(() => {
    setPremiumUser(getAllRecords?.dashboards[0]?.user?.is_subscripted);
    if (profileId && role && level) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [profileId, role, level]);

  const goNext = () => {
    if (preAiCvProfile) {
      Mixpanel.track('Click Next to AI Resume Builder');
      preAiCvProfile({
        profileId,
        role,
        level,
      });
    }
  };

  function handleCloseUpgradePlanModal() {
    setProfilePlanUpgrade(false);
  }

  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{ width: 600 }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div className="title">AI Resume Builder</div>
        <div className="sub-title">
          Start using the Resume Builder by answering the following questions:
        </div>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 310, mt: '60px' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} className="form-items">
              <InputLabel className="form-label">
                Select a profile for work experience optimization*
              </InputLabel>
              <CommonSelect
                className="w-100"
                placeholder="Select profile"
                props={{ id: 'id', label: 'title' }}
                options={[...profileList, { id: '0', title: 'Create a new profile' }]}
                value={profileId}
                onChange={handleProfileChange}
              ></CommonSelect>
            </Grid>
            <Grid item xs={12} className="form-items">
              <InputLabel className="form-label">
                What kind of job are you looking for *
              </InputLabel>
              <CommonSelect
                className="w-100"
                placeholder="Select job title"
                options={roleList}
                value={role}
                onChange={handleRoleChange}
                isFilter={true}
                searchPlaceholder="Job title"
              ></CommonSelect>
            </Grid>
            <Grid item xs={12} className="form-items">
              <InputLabel className="form-label">
                What level of this position are you looking for *
              </InputLabel>
              <CommonSelect
                className="w-100"
                placeholder="Select job level"
                options={levelList}
                value={level}
                onChange={handlelevelChange}
              ></CommonSelect>
            </Grid>
            <Grid item xs={12} className="form-items" textAlign={'center'}>
              <Button
                sx={{ width: 175, mt: '36px' }}
                className="ai-cv-button ai-cv-button-black"
                variant="contained"
                disabled={isDisable}
                onClick={goNext}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <ProfileValidateModal
        open={isProfilePlanUpgrade}
        handleClose={handleCloseUpgradePlanModal}
      />
    </Stack>
  );
};
