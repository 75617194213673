import React from 'react';
import InfoItem from './info-item';
import LinkIcon from '@mui/icons-material/Link';
import { Stack } from '@mui/material';

import '../style/index.scss';

const LinksInfo = ({ info = {} }) => {
  return (
    <Stack className="links-information">
      <Stack className="links-information-row" direction={'row'}>
        <InfoItem
          className="w-240"
          icon={<LinkIcon></LinkIcon>}
          label="Linkedin"
          value={info.linkedin_link}
        ></InfoItem>
        <InfoItem
          className="w-240"
          icon={<LinkIcon></LinkIcon>}
          label="Github"
          value={info.github_link}
        ></InfoItem>
      </Stack>
      <Stack className="links-information-row" direction={'row'} sx={{ mt: 2 }}>
        <InfoItem
          className="w-240"
          icon={<LinkIcon></LinkIcon>}
          label="Portfolio"
          value={info.portfolio_link}
        ></InfoItem>
        <InfoItem
          className="w-240"
          icon={<LinkIcon></LinkIcon>}
          label="Other Links"
          value={info.other_link}
        ></InfoItem>
      </Stack>
    </Stack>
  );
};

export default LinksInfo;
