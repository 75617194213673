import { BACKEND_PATH } from './ServiceConstants';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const getStripeSession = async () => {
  return await axios.get(`${BACKEND_PATH}api/checkout-session/`);
};

export const getSubscriptionInfo = async () => {
  return await axios.get(`${BACKEND_PATH}api/subscription/`);
};

export const toGetCheckoutPortal = async (payloadObj) => {
  return axios.post(`${BACKEND_PATH}api/checkout/`, payloadObj);
};

export const useGetUserPaymentDetail = (payloadObj) => {
  // Create a query key that uniquely identifies this query and its variables
  const queryKey = ['getUserPaymentDetails', payloadObj];
  const isEnabled = Boolean(payloadObj?.user_id);
  return useQuery(
    queryKey,
    async () => {
      const response = await axios.post(
        `${BACKEND_PATH}api/user-payment-detail/`,
        payloadObj
      );
      return response.data;
    },
    {
      // Optionally, disable this query from automatically running if the payloadObj is empty
      enabled: isEnabled,
    }
  );
};

export const toGetStripeBillingPortal = async (payloadObj) => {
  return axios.post(`${BACKEND_PATH}api/create-customer-portal-session/`, payloadObj);
};
