import React from 'react';
import FormItem from './form-item';
import { Grid } from '@mui/material';
import { useFormMode, useValidTwoDate } from '@utilities/form-hooks';

import '../style/index.scss';

const CertificateForm = ({
  info = {},
  rules,
  formValidator,
  updateForm,
  changeFormValid,
}) => {
  const { formData, updateInfo } = useFormMode(
    info,
    updateForm,
    formValidator,
    changeFormValid
  );
  const { validDate, validTwoDate } = useValidTwoDate();
  return (
    <form className="" noValidate autoComplete="off">
      <Grid className="form-container" container spacing={2}>
        <Grid item xs={12}>
          <FormItem
            label="Name"
            handleUpdate={(info, valid) => updateInfo('name', info, valid)}
            value={formData.name}
            error={!!formValidator.name}
            errorMsg={formValidator.name}
            required={rules.name.required}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Organization"
            handleUpdate={(info, valid) => updateInfo('issue_organization', info, valid)}
            value={formData.issue_organization}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Issue Date"
            type="date"
            error={!!formValidator.issue_date}
            errorMsg={formValidator.issue_date}
            handleUpdate={(info, valid) => {
              const flag = validTwoDate(info, formData.expiration_date);
              updateInfo('issue_date', info, flag, rules.issue_date.msg);
              if (flag && formValidator.expiration_date) {
                //如果校验通过，且expiration_date 存在错误，需要清除 expiration_date 的错误
                updateInfo('expiration_date', formData.expiration_date, flag);
              }
            }}
            value={formData.issue_date}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Expiration Date"
            type="date"
            value={formData.expiration_date}
            error={!!formValidator.expiration_date}
            errorMsg={formValidator.expiration_date}
            handleUpdate={(info, valid) => {
              const flag = validTwoDate(formData.issue_date, info);
              updateInfo('expiration_date', info, flag, rules.expiration_date.msg);
              if (flag && formValidator.issue_date) {
                //如果校验通过，且expiration_date 存在错误，需要清除 expiration_date 的错误
                updateInfo('issue_date', formData.issue_date, flag);
              }
            }}
          ></FormItem>
        </Grid>
      </Grid>
    </form>
  );
};

export default CertificateForm;
