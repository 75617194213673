import React from 'react';

export interface TabItemProps {
  className?: string;
  dataKey: string;
  text: string;
  icon?: any;
}

export const TabItem: React.FC<TabItemProps> = ({
  className,
  dataKey,
  text,
  icon,
  ...props
}) => {
  let newClassName = `tlm-tab-item ${className}`;
  return <div className={newClassName}>{props.children}</div>;
};
