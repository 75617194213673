import React, { useEffect, useState } from 'react';
import TemplateNavBars from './components/TemplateNavBars';
import FirstTimeTemplateCards from './components/FirstTimeTemplateCards';
import OfferlandTemplateCards from './components/OfferlandTemplateCards';
import MyTemplateEditor from './components/MyTemplateEditor';
import MyTemplatePreviewCards from './components/MyTemplatePreviewCards';
import { Snackbar } from '@mui/material';
import {
  firstTimeUser,
  getTemplateCategories,
  getUserTemplates,
} from '../../services/EmailTemplateServices';
import './index.scss';

export const Template = (props) => {
  const [template, setTemplate] = useState('My Template');
  const [firstTime, setFirstTime] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isOfferlandTemplate, setIsOfferlandTemplate] = useState(false);
  const [editCard, setEditCard] = useState();
  const [userTemplates, setUserTemplates] = useState([]);
  const [isSavedFlag, setIsSavedFlag] = useState(false);

  const refreshUserTemplates = () => {
    getUserTemplates().then((res) => setUserTemplates(res.data));
  };

  const getFirstTime = () => {
    firstTimeUser().then((res) => {
      setFirstTime(res.data.is_first_time);
    });
  };

  useEffect(() => {
    getFirstTime();
    getTemplateCategories().then((res) => {
      setCategories(res.data);
    });
    refreshUserTemplates();
  }, []);
  useEffect(() => {}, [editCard]);

  return (
    <div className="template">
      {
        <TemplateNavBars
          template={template}
          setTemplate={setTemplate}
          setEditCard={setEditCard}
          firstTime={firstTime}
          categories={categories}
          categoryIndex={categories.indexOf(categories.find((c) => c.name === template))}
        />
      }
      <div className="template-component">
        {template === 'My Template' && firstTime && (
          <FirstTimeTemplateCards
            setTemplate={setTemplate}
            setEditCard={setEditCard}
            categories={categories}
          />
        )}
        {template === 'My Template' && !firstTime && !editCard && (
          <MyTemplatePreviewCards
            setEditCard={setEditCard}
            userTemplates={userTemplates}
            getFirstTime={getFirstTime}
            refreshUserTemplates={refreshUserTemplates}
            setIsOfferlandTemplate={setIsOfferlandTemplate}
          />
        )}
        {template !== 'My Template' && !editCard && (
          <OfferlandTemplateCards
            category={template}
            categoryId={categories.find((c) => c.name === template).id}
            setEditCard={setEditCard}
            setIsOfferlandTemplate={setIsOfferlandTemplate}
          />
        )}
        {/* 只要是编辑，则显示edit */}
        {editCard && (
          <MyTemplateEditor
            card={editCard}
            hideBack={true}
            setEditCard={() => {
              setEditCard();
              setTemplate('My Template');
            }}
            userTemplates={userTemplates}
            firstTime={firstTime}
            getFirstTime={getFirstTime}
            refreshUserTemplates={refreshUserTemplates}
            isOfferlandTemplate={isOfferlandTemplate}
            setIsSavedFlag={setIsSavedFlag}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSavedFlag}
          autoHideDuration={1000}
          onClose={() => setIsSavedFlag(false)}
          message="Template has been saved successfully!"
          key={'top center'}
        />
      </div>
    </div>
  );
};

export default Template;
