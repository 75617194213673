import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import PreviewModal from './PreviewModal';
import { getTemplatesForACategory } from '../../../services/EmailTemplateServices';
import './OfferlandTemplateCards.scss';
import clsx from 'clsx';
import { Mixpanel } from '@utilities/mixpanel';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 432,
    height: 222,
    margin: '28px 28px 0px 0px',
    padding: '32px 32px 28px',
    boxShadow: '0px 4px 20px rgba(0, 116, 132, 0.13)',

    borderRadius: 8,
    '&:hover': {
      border: '1px solid rgba(0, 116, 132, 0.4)',
      boxShadow: '0px 4px 20px rgba(0, 116, 132, 0.24)',
    },
    '&:hover > div': {
      color: '#007484',
    },
    '&:hover > div > div:last-of-type': {
      border: '1px solid #007484',
      borderRadius: 8,
    },
    '&:hover > div > div:last-of-type > div': {
      color: '#007484',
    },
  },
  button: {
    fontWeight: 600,
    fontSize: 16,
    color: '#A8A8A8',
    padding: '0px 8px',
  },
  type: {
    fontSize: 16,
    textTransform: 'uppercase',
    color: '#AFAFAF',
    fontWeight: 400,
  },
  title: {
    fontWeight: 800,
    fontSize: 19,
    lineHeight: '22px',
    textTransform: 'capitalize',
    marginBottom: 12,
  },
  content: {
    fontWeight: 400,
    fontSize: 16,
    color: '#848484',
    lineHeight: '22px',
    height: 66,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  root: {
    padding: '0px 0px 16px',
  },
  cardContent: {
    padding: 0,
  },
}));

export default function OfferlandTemplateCards(props) {
  const classes = useStyles();
  const [previewCard, setPreviewCard] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [previewComponent, setPreviewComponent] = useState();
  const [cards, setCards] = useState([]);

  const openPreviewModal = (card) => {
    Mixpanel.track('Click Use Template');
    setShowPreview(true);
    setPreviewCard(card);
  };

  useEffect(() => {
    getTemplatesForACategory(props.categoryId).then((res) => setCards(res.data));
  }, [props.categoryId]);

  useEffect(() => {
    if (previewCard) {
      setPreviewComponent(
        <PreviewModal
          card={previewCard}
          setPreviewCard={setPreviewCard}
          setEditCard={props.setEditCard}
          setIsOfferlandTemplate={props.setIsOfferlandTemplate}
        />
      );
    } else {
      setPreviewComponent();
    }
  }, [previewCard]);

  return (
    <div
      className={clsx('template-preview-group', {
        'template-preview-display': previewCard,
      })}
    >
      <div
        className={clsx('tlm-cursor-pointer', 'template-list', {
          'template-cards-preview': previewCard,
          'template-cards': !previewCard,
        })}
      >
        {cards.map((card) => {
          return (
            <Card
              key={card.id}
              className={classes.card}
              onClick={() => openPreviewModal(card)}
            >
              <CardHeader
                classes={{ root: classes.root, title: classes.type }}
                action={
                  <div className={classes.button} onClick={() => props.setEditCard(card)}>
                    Use
                  </div>
                }
                title={props.category}
              />
              <CardContent className={classes.cardContent}>
                <Typography className={classes.title}>{card.title}</Typography>
                <Typography className={classes.content}>{card.content}</Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
      <div>{previewComponent}</div>
    </div>
  );
}
