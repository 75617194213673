import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';

export const getJobBoardPosts = async (job_type, page) => {
  return await axios.get(`${BACKEND_PATH}api/posts/`, {
    params: {
      job_type: job_type,
      page: page,
    },
  });
};

export const getJobBoardBookmarkedPosts = async (page) => {
  return await axios.get(`${BACKEND_PATH}api/posts/bookmark/`, {
    params: {
      page: page,
    },
  });
};

export const bookmarkAPostByID = async (postID) => {
  return await axios.post(`${BACKEND_PATH}api/posts/bookmark/`, {
    post_id: postID,
  });
};

export const getUserName = async () => {
  return await axios.get(`${BACKEND_PATH}api/dashboards/`, {});
};

/*New Job Board API Calls */

export const getJobBoardInternData = async () => {
  return await axios.get(`${BACKEND_PATH}api/entryleveljob/intern`);
};

export const getJobBoardNgData = async () => {
  return await axios.get(`${BACKEND_PATH}api/entryleveljob/newgrad`);
};

export const SaveNgData = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/`, {
    request: 'saved',
    job_id,
  });
};

export const UndoData = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/`, {
    request: 'undo',
    job_id,
  });
};

export const getngLocation = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/newgrad`, {
    request: 'location',
  });
};

export const getinternLocation = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/intern`, {
    request: 'location',
  });
};

export const getinternYear = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/intern`, {
    request: 'year',
  });
};
export const getngYear = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/newgrad`, {
    request: 'year',
  });
};

export const npsHelper = async (event) => {
  return await axios.post(`${BACKEND_PATH}api/nps/`, {
    event: event,
  });
};
export const updateDefaultJobType = async (job_id) => {
  return await axios.post(`${BACKEND_PATH}api/entryleveljob/`, {
    default: job_id,
  });
};
export const getNewJobCount = async () => {
  return await axios.get(`${BACKEND_PATH}api/jobcount/`);
};
