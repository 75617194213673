import React from 'react';
import FormItemSelect from './form-item/select';
import { Grid, Stack, Tooltip, Link } from '@mui/material';
import { useFormMode } from '@utilities/form-hooks';
// import InfoIcon from '@mui/icons-material/Info';
import { helpInfoIcon } from '@components/icons';

import {
  genderList,
  veteransList,
  ethnicityList,
  disabilityList,
  identificationSubheading,
  identificationUrls,
} from '../constant';

import '../style/index.scss';

export const HelpInfo = ({
  helpText = 'Learn more',
  detailInfo = 'Click to check guidance.',
  linkUrl,
}) => {
  return (
    <Stack sx={{ display: 'inline' }}>
      <Tooltip
        arrow
        placement="right"
        PopperProps={{
          className: 'light-tooltip-container',
        }}
        title={<Stack className="text-container">{detailInfo}</Stack>}
      >
        <Stack className="header-help-info" direction="row">
          <Link href={linkUrl} target="_blank" variant="button">
            {helpInfoIcon} {helpText}
          </Link>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export const LabelHelpInfo = ({ name, linkUrl }) => {
  return (
    <Stack direction="row">
      <Stack sx={{ mr: 1 }}>{name}</Stack>
      <HelpInfo
        helpText="Learn more"
        detailInfo={`Click to check ${name} guidance.`}
        linkUrl={linkUrl}
      ></HelpInfo>
    </Stack>
  );
};

const IdentificationsForm = ({ info = {}, updateForm, changeFormValid }) => {
  const { formData, updateInfo } = useFormMode(info, updateForm, changeFormValid);
  return (
    <form className="mt-8" noValidate autoComplete="off">
      <HelpInfo
        helpText="Why do we ask about it ?"
        detailInfo={identificationSubheading}
        linkUrl={identificationUrls.privacy}
      ></HelpInfo>
      <Stack sx={{ mt: 2 }}>
        <Grid className="form-container" container spacing={2}>
          <Grid item xs={12}>
            <FormItemSelect
              label="Gender"
              list={genderList}
              handleUpdate={(info, valid) => updateInfo('gender', info, valid)}
              value={formData.gender}
            ></FormItemSelect>
          </Grid>
          <Grid item xs={12}>
            <FormItemSelect
              label={
                <LabelHelpInfo
                  name="Veterans Status"
                  linkUrl={identificationUrls.veterans}
                ></LabelHelpInfo>
              }
              list={veteransList}
              handleUpdate={(info, valid) => updateInfo('veteran_status', info, valid)}
              value={formData.veteran_status}
            ></FormItemSelect>
          </Grid>
          <Grid item xs={12}>
            <FormItemSelect
              label={
                <LabelHelpInfo
                  name="Ethnicity"
                  linkUrl={identificationUrls.ethnicity}
                ></LabelHelpInfo>
              }
              multiple={true}
              placeholder="Select one or multiple"
              list={ethnicityList}
              handleUpdate={(info, valid) => updateInfo('ethnicity', info, valid)}
              value={formData.ethnicity || []}
            ></FormItemSelect>
          </Grid>
          <Grid item xs={12}>
            <FormItemSelect
              label={
                <LabelHelpInfo
                  name="Disability"
                  linkUrl={identificationUrls.disability}
                ></LabelHelpInfo>
              }
              list={disabilityList}
              handleUpdate={(info, valid) => updateInfo('disability', info, valid)}
              value={formData.disability}
            ></FormItemSelect>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

export default IdentificationsForm;
