import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { BUTTON, JOB } from '../../../constants/behaviors';

const CancelConfirmationModal = ({ show, onCancelFunc, setShowPrompt }) => {
  return (
    <Modal
      className="tlm-modal"
      show={show}
      onHide={() => {
        setShowPrompt(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="tlm-modal-title">Unsaved Change</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tlm-modal-message-panel">
          <span>Would you like to save the change?</span>
        </div>
        <div className="tlm-modal-button-panel">
          <button
            className="tlm-modal-button-no"
            variant="outlined"
            onClick={() => {
              onCancelFunc();
            }}
          >
            No
          </button>
          <button
            className="tlm-modal-button-yes"
            onClick={() => {
              setShowPrompt(false);
            }}
          >
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CancelConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancelFunc: PropTypes.func.isRequired,
  onConfirmFunc: PropTypes.func.isRequired,
};

export default CancelConfirmationModal;
