import React, { useState, useEffect } from 'react';
import { TabItemProps } from './Tab-item';
import { TagSmall } from './Tag';
import './Tab.scss';

export interface TabProps {
  className?: string;
  activeKey: string;
  betaItemKey: string;
  children: React.ReactElement<TabItemProps>[];
  width?: number;
  tabWidthPercent?: number;
  // Used in the parent component to keep active key state in sync
  handleTabChangeCallback?: (newKey: string) => void;
}

export const Tab: React.FC<TabProps> = ({
  className,
  activeKey: activeKeyFromProps,
  betaItemKey,
  children,
  width = 492,
  tabWidthPercent,
  handleTabChangeCallback,
}) => {
  let newClassName = `tlm-tab ${className}`;

  const [activeKey, setActiveKey] = useState(activeKeyFromProps);
  useEffect(() => {
    setActiveKey(activeKeyFromProps);
  }, [activeKeyFromProps]);

  const handleTabChange = (newKey: string) => {
    setActiveKey(newKey);
    handleTabChangeCallback && handleTabChangeCallback(newKey);
  };

  const tabWidthPercentValue = tabWidthPercent
    ? tabWidthPercent
    : (1 / children.length) * 100;

  const TabBar = () => {
    return (
      <div className={'tlm-tab-bar'}>
        {Array.isArray(children) &&
          children.map((child: React.ReactElement<TabItemProps>) => (
            <div
              className={`tlm-tab-title${
                activeKey === child.props.dataKey ? ' active' : ''
              }`}
              onClick={() => handleTabChange(child.props.dataKey)}
              style={{ width: `${tabWidthPercentValue}%` }}
              key={child.props.dataKey}
            >
              <span style={{ marginRight: '2px' }}>
                {child.props.icon !== undefined &&
                  child.props.icon(child.props.dataKey === activeKey)}
              </span>
              {child.props.text}
              {child.props.dataKey === betaItemKey ? (
                <TagSmall
                  label={'Beta'}
                  themeColor="c-attention-orange"
                  className="tlm-beta-tag"
                />
              ) : (
                <></>
              )}
            </div>
          ))}
      </div>
    );
  };

  let tabChildren = children.filter(
    (child: React.ReactElement<TabItemProps>) =>
      child.props && child.props.dataKey === activeKey
  );

  return (
    <div className={newClassName} style={{ width: `${width}px` }}>
      <TabBar />
      <div className={'tlm-tab-container'}>{tabChildren}</div>
    </div>
  );
};
