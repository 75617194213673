import { categorysList, tagsList, filtersList, tagColorsList } from '../constant';

export const tagsMatcher = (resource) => {
  const tags = [];
  for (let category of categorysList) {
    const index = resource[`label_${category}`];
    if (index != 0) {
      tags.push(tagsList[category][index - 1]);
    }
  }
  return tags;
};

export const filtersMatcher = (resource) => {
  const tags = [];
  for (let category of categorysList) {
    const index = resource[`label_${category}`];
    if (index != 0) {
      tags.push(filtersList[category][index - 1]);
    }
  }
  return tags;
};

export const colorsMatcher = (resource) => {
  const colors = [];
  for (let category of categorysList) {
    const index = resource[`label_${category}`];
    if (index != 0) {
      colors.push(tagColorsList[category]);
    }
  }
  return colors;
};

export const firstCapitalize = (string) => {
  return string.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
};
