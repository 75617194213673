import React, { useState } from 'react';
import { Stack, InputLabel, TextField } from '@mui/material';
import './index.scss';
import clsx from 'clsx';

const FormItem = ({
  className,
  label = '',
  value = '',
  placeholder = '',
  handleUpdate,
  required = false,
  disabled = false,
  error = false,
  errorMsg = 'This field should not be empty.',
  type = 'input',
  rows = 1,
}) => {
  const [showError, setShowError] = useState(false);
  const [val, setVal] = useState(value || '');
  const updateChange = (event) => {
    const newVal = event.target.value;
    let flag = false;
    if (required && newVal === '') {
      flag = true;
    } else {
      flag = false;
    }
    if (handleUpdate) {
      handleUpdate(newVal, !flag);
    }
    setShowError(flag);
    setVal(newVal);
  };
  return (
    <Stack
      className={clsx('form-item', className, {
        'form-item-error': showError || error,
        'form-item-disabled': disabled,
      })}
      direction="column"
      justifyContent="flex-start"
    >
      <InputLabel className="form-item-label">
        {label} {required && '*'}
      </InputLabel>
      <TextField
        className="form-item-value"
        size="small"
        type={type}
        rows={rows}
        multiline={rows > 1}
        value={val}
        disabled={disabled}
        placeholder={placeholder || label}
        helperText={(showError || error) && errorMsg}
        onChange={updateChange}
      />
    </Stack>
  );
};

export default FormItem;
