import React, { useEffect, useState } from 'react';
import { Tag } from '../../../stories/Tag';
import { Card, CardContent, CardDivider } from '../../../stories/Card';
import { Logo } from './Logo';
import { updateBookmark, updateThumbup } from '@services/LibraryService';
import {
  defaultThumbupIcon,
  thumbupIcon,
  unBookmarkedIcon,
  bookmarkedIcon,
} from './icons';
import { tagsMatcher, colorsMatcher } from './helperFunc';
import { Snackbar } from '@mui/material';
import { THUMBUP, THUMBDOWN, LIBRARY } from '@constants/behaviors';
import '../index.scss';
import '../../../styles/typography.scss';

export function LibraryCards(props) {
  const resources = props.resources;
  const searchResources = props.searchResources;
  const newResources = resources == searchResources ? resources : searchResources;
  const setSearchResources = props.setSearchResources;
  const bookmarkedPage = props.bookmarkedPage;
  const refreshResources = props.refreshResources;
  const [snack, setSnack] = useState({
    open: false,
    status: true,
  });

  //Should be updated when backend can return thumpupStatus
  const thumbupList = new Object();
  for (const resource of resources) {
    const id = resource.id;
    thumbupList[id] = false;
  }

  const thumbupHandler = (isThumbuped, resourceId, e) => {
    e.stopPropagation();
    const status = isThumbuped ? false : true;
    const newSearchResources = [];
    for (let search of searchResources) {
      if (resourceId == search.id) {
        search.is_thumbuped = status;
      }
      newSearchResources.push(search);
    }
    setSearchResources(newSearchResources);
    const updatedStatus = status ? 'True' : 'False';
    updateThumbup(updatedStatus, resourceId).then((res) => {
      refreshResources();
    });
  };

  const bookmarkHandler = (isBookmarked, resourceId, e) => {
    e.stopPropagation();
    const status = isBookmarked ? false : true;
    //Update searchResources
    const newSearchResources = [];
    for (let search of searchResources) {
      if (resourceId == search.id) {
        search.is_bookmarked = status;
      }
      newSearchResources.push(search);
    }
    setSearchResources(newSearchResources);
    //Keep format as same as API configuration
    setSnack({
      open: true,
      status: status,
    });
    const updatedStatus = status ? 'True' : 'False';
    updateBookmark(updatedStatus, resourceId).then((res) => {
      refreshResources();
    });
  };

  const handleSnackClose = (event, reason) => {
    //Prevent user behavior to stop snackBar info
    if (reason === 'clickaway') {
      return;
    }
    setSnack({ open: false });
  };

  return (
    <div className="library-content">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack.open}
        autoHideDuration={3000}
        message={
          snack.status
            ? 'The bookmark has been successfully added to your collection.'
            : 'The bookmark has been successfully removed.'
        }
        onClose={handleSnackClose}
      />
      {newResources.map((resource) => {
        const {
          id,
          title,
          summary,
          author,
          company_logo,
          is_bookmarked,
          is_thumbuped,
          link,
          publish_time,
          reading_time,
        } = resource;

        if (bookmarkedPage && !is_bookmarked) {
          return;
        }
        let logo = resource.company_logo;
        if (resource.company_logo.length == 0) {
          logo = resource.company_name[0];
        }
        const tags = tagsMatcher(resource);
        const colors = colorsMatcher(resource);
        return (
          <div className="library-card">
            <Card key={id} width="361px" height="256px" isVertical>
              <CardContent padding="28px" height="170px">
                <div className="library-card-tags">
                  <div className="library-card-tag">
                    <Tag label={tags[0]} themeColor={colors[0]} />
                  </div>
                  <div className="library-card-tag">
                    {tags.length > 1 ? (
                      <Tag label={tags[1]} themeColor={colors[1]} />
                    ) : (
                      <br />
                    )}
                  </div>
                </div>
                <div className="library-card-title header-20">
                  <a
                    className="library-card-aTag"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title}
                  </a>
                </div>
                <div className="library-card-summary body-text-16">
                  <a
                    className="library-card-aTag"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {summary}
                  </a>
                </div>
              </CardContent>
              <CardDivider margin="0" isHorizontal />
              <CardContent paddingTop="12px" height="88px">
                <div className="library-lower-content">
                  <Logo label={logo} />
                  <div className="library-lower-info">
                    <span className="library-lower-author header-12">{author}</span>
                    <span className="body-text-12">
                      {publish_time}
                      {reading_time == 0 ? '' : ` • ${reading_time} min read`}
                    </span>
                  </div>
                  <div
                    className="library-thumbupIcon"
                    onClick={(e) => thumbupHandler(is_thumbuped, id, e)}
                  >
                    {is_thumbuped ? thumbupIcon : defaultThumbupIcon}
                  </div>
                  <div
                    className="library-bookmarkIcon"
                    onClick={(e) => bookmarkHandler(is_bookmarked, id, e)}
                  >
                    {is_bookmarked ? bookmarkedIcon : unBookmarkedIcon}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
