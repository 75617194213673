// utilities
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useOnClick } from '../../../utilities//hooks';
import {
  updateJobLocation,
  updateJobLink,
  updateJobDescription,
  deleteJobs,
  updateCompanyName,
  updateJobName,
  getReferralInfo,
} from '../../../services/DashBoardServices';
import _ from 'lodash';
import errorMessages from '../errorMessages';
import { popupError } from '../../../utilities/error-handler';
import { Mixpanel } from '../../../utilities/mixpanel';
import { CreateOrToggleFeatureFlag } from '../../../utilities/feature-flag';
import { DASHBOARD_TAB_INDEX } from '../DashboardConstants';

// components
import { linkIcon, WarningIcon } from '../../../components/icons';
import { Card } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { companyIcon, pencilIcon } from '../../../components/icons';
import { buildingIcon } from '../../../components/icons';
import { Tab } from '../../../stories/Tab';
import { TabItem } from '../../../stories/Tab-item';
import JobBoardNotesTab from './JobBoardNotesTab';
import JobBoardReferralTab from './JobBoardReferralTab';
import JobBoardDescriptionTab from './JobBoardDescriptionTab';
import JobBoardAIGuidanceTab from './JobBoardAIGuidanceTab';
import Button from '@mui/material/Button';

// styles
import './JobBoardSecondPanel.scss';

// ----------------------------------------------------------------------

export default function JobBoardSecondPanel({
  isSpecialUserFlag,
  setRows,
  handelChangeLayout,
  curJobInfo,
  setRefreshPageFlag,
  handleChangePinStatus,
  notesOpenFlag,
  selectedStatus,
  refetch,
  defaultProfileId,
  directToProfile,
}) {
  const {
    Company: companyName,
    id,
    company: { logo },
    location,
    description,
    link,
    is_pinned,
    is_refer_requested,
    status,
  } = curJobInfo;

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [jobDescription, setJobDescription] = useState('');

  const [notesList, setNotesList] = useState([]);

  const [jobLocation, setjobLocation] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [editedLinkFlag, setEditedLinkFlag] = useState(false);
  const [threeDotFlag, setThreeDotFlag] = useState(false);
  const [isReferRequested, setIsReferRequested] = useState(false);

  const [jobTitleEmpty, setJobTitleEmpty] = useState(false);
  const [companyNameEmpty, setCompanyNameEmpty] = useState(false);

  const [jobId, setJobId] = useState(id);
  const [jobBasicInfo, setJobBasicInfo] = useState({});
  const [referralInfo, setReferralInfo] = useState([]);
  const [finishedFetchingReferral, setFinishedFetchingReferral] = useState(0);
  const [aIGuidanceFeatureFlag, setAIGuidanceFeatureFlag] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(DASHBOARD_TAB_INDEX.NOTES);

  useEffect(() => {
    const reversedList = curJobInfo.notes.reverse();
    setNotesList(reversedList);
    setJobDescription(description === null ? '' : description);
    setIsBookmarked(is_pinned);
    setjobLocation(location);
    setLinkUrl(link === null || !validURL(link) ? '' : link);
    setJobId(id);
    setIsReferRequested(is_refer_requested);
    setJobBasicInfo({
      companyName: curJobInfo.Company,
      'Job Title': curJobInfo['Job Title'],
    });
    setJobTitleEmpty(false);
    setCompanyNameEmpty(false);
    Mixpanel.track('View Detail Page');
  }, [curJobInfo]);

  async function FeatureFlagFetch() {
    let aIGuidance = await CreateOrToggleFeatureFlag('AIGuidance');
    setAIGuidanceFeatureFlag(aIGuidance);
  }
  useEffect(() => {
    FeatureFlagFetch();
  }, []);

  const ref = useRef();
  useEffect(() => {
    setRows((prev) => {
      const prevAry = [...prev];
      return prevAry.map((ele) => {
        const curEle = { ...ele };
        if (jobId === ele.id) {
          curEle.notes = notesList;
        }
        return curEle;
      });
    });
  }, [notesList]);

  useEffect(() => {
    getReferralInfo(jobId)
      .then((response) => {
        if (response.data) {
          const referralData = response.data;
          setReferralInfo(
            referralData.filter((e) => e.name.length > 0 && e.description.length > 0)
          );
          setFinishedFetchingReferral(1);
        }
      })
      .catch((err) => {
        console.log('fetching referral data error', err);
      });
  }, [jobId]);

  useOnClick(ref, () => setThreeDotFlag(false));
  const onButtonChange = (type) => {
    if (type === 'bookmark') {
      const bookmarkChanged = async () => {
        await handleChangePinStatus(jobId, isBookmarked);
        setIsBookmarked((prev) => !prev);
      };
      bookmarkChanged();
    } else {
      deleteJobs([jobId])
        .then(() => {
          setRows((prev) => {
            const prevAry = [...prev];
            return prevAry.filter((ele) => jobId !== ele.id);
          });
          handelChangeLayout();
        })
        .catch(() => {
          popupError(errorMessages.deleteJobs);
        });
    }
    refetch();
    // setRefreshPageFlag((prev) => !prev);
    setThreeDotFlag(false);
  };

  const updateDescription = (newDescription) => {
    updateJobDescription(jobId, newDescription)
      .then(() => {
        setJobDescription(newDescription);
        setRows((prev) => {
          const prevAry = [...prev];
          return prevAry.map((ele) => {
            const curEle = { ...ele };
            if (ele.id === jobId) {
              curEle.description = newDescription;
            }
            return curEle;
          });
        });
      })
      .catch(() => {
        popupError(errorMessages.updateJobDescription);
      });
  };

  const updateLocation = (location, currentId) => {
    updateJobLocation(currentId, location)
      .then(() => {
        setRows((prev) => {
          const prevAry = [...prev];
          return prevAry.map((ele) => {
            const curEle = { ...ele };
            if (ele.id === currentId) {
              curEle.location = location;
            }
            return curEle;
          });
        });
      })
      .catch(() => {
        popupError(errorMessages.updateJobLocation);
      });
  };

  const updateLinkChange = (link, currentId, isUrlValid) => {
    if (!isUrlValid) return;
    updateJobLink(currentId, link)
      .then(() => {
        setRows((prev) => {
          const prevAry = [...prev];
          return prevAry.map((ele) => {
            const curEle = { ...ele };
            if (ele.id === currentId) {
              curEle.link = link;
            }
            return curEle;
          });
        });
      })
      .catch(() => {
        popupError(errorMessages.updateJobLink);
      });
  };

  const updateJobBasicInfo = (updateInfo, field, currentId) => {
    if (field === 'companyName') {
      updateCompanyName(currentId, updateInfo)
        .then(() => {
          setRows((prev) => {
            const prevAry = [...prev];
            return prevAry.map((ele) => {
              const curEle = { ...ele };
              if (ele.id === currentId) {
                curEle.Company = updateInfo;
              }
              return curEle;
            });
          });
        })
        .catch(() => {
          popupError(errorMessages.updateCompanyName);
        });
    } else {
      updateJobName(currentId, updateInfo)
        .then(() => {
          setRows((prev) => {
            const prevAry = [...prev];
            return prevAry.map((ele) => {
              const curEle = { ...ele };
              if (ele.id === currentId) {
                curEle['Job Title'] = updateInfo;
              }
              return curEle;
            });
          });
        })
        .catch(() => {
          popupError(errorMessages.updateJobName);
        });
    }
  };

  const debounceLocationFn = useCallback(_.debounce(updateLocation, 1000), []);
  const onLocationChange = (jobLocation) => {
    setjobLocation(jobLocation);
    debounceLocationFn(jobLocation, jobId);
  };
  const debounceLinkFn = useCallback(_.debounce(updateLinkChange, 1000), []);
  const onLinkChange = (link) => {
    setLinkUrl(link);
    const isUrlValid = validURL(link);
    debounceLinkFn(link, jobId, isUrlValid);
  };

  const debounceJobInfoFn = useCallback(_.debounce(updateJobBasicInfo, 1000), []);
  const onJobInfoChange = (updateInfo, field) => {
    const newInfo = { ...jobBasicInfo };
    if (field === 'companyName') {
      newInfo.companyName = updateInfo;
    } else {
      newInfo['Job Title'] = updateInfo;
    }
    setJobBasicInfo(newInfo);
    debounceJobInfoFn(updateInfo, field, jobId);
  };
  function validURL(str) {
    if (str === '') return true;
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return pattern.test(str);
  }

  let tabData = [
    {
      text: `Notes (${notesList.length})`,
      key: DASHBOARD_TAB_INDEX.NOTES,
      component: (
        <JobBoardNotesTab
          curJobId={jobId}
          notesList={notesList}
          setNotesList={setNotesList}
          // setRefreshPageFlag={setRefreshPageFlag}
          notesOpenFlag={notesOpenFlag}
          jobTitle={jobBasicInfo['Job Title']}
          curStatus={status}
          selectedStatus={selectedStatus}
          refetch={refetch}
        />
      ),
    },
    {
      text: 'AI Guidance',
      key: DASHBOARD_TAB_INDEX.AI_GUIDANCE,
      component: (
        <JobBoardAIGuidanceTab
          hasJobDescription={jobDescription.length > 0}
          defaultProfileId={defaultProfileId}
          directToProfile={directToProfile}
          switchToDescription={() => setActiveTabKey(DASHBOARD_TAB_INDEX.DESCRIPTION)}
        />
      ),
    },
    {
      text: `Referral (${referralInfo.length})`,
      key: DASHBOARD_TAB_INDEX.REFERRAL,
      component: (
        <JobBoardReferralTab
          referralInfo={referralInfo}
          finishedFetching={finishedFetchingReferral}
          jobId={jobId}
          isReferRequested={isReferRequested}
        />
      ),
    },
    {
      text: 'Description',
      key: DASHBOARD_TAB_INDEX.DESCRIPTION,
      component: (
        <JobBoardDescriptionTab
          description={jobDescription}
          onJobDescriptionChange={updateDescription}
          jobTitle={jobBasicInfo['Job Title']}
        />
      ),
    },
  ];
  // do not show AI Guidance tab if feature flag is off
  tabData = tabData.filter((tab) => aIGuidanceFeatureFlag || tab.key !== '2');

  const onLinkClick = () => {
    const joblink = linkUrl === '' ? '' : linkUrl;
    Mixpanel.track('Click Job Link', {
      jobTitle: jobBasicInfo['Job Title'],
      joblink: joblink,
    });
  };

  return (
    <Card className="tlm--secondPanle__container">
      <CloseIcon className="tlm--secondPanle__closeIcon" onClick={handelChangeLayout} />

      <Button
        onClick={() => setThreeDotFlag(!threeDotFlag)}
        className="tlm--secondPanle__threeDot"
      >
        <MoreVertIcon />
      </Button>
      {threeDotFlag && (
        <Card ref={ref} className="tlm--secondPanle__threeDotContainer">
          <div className="tlm--secondPanle__threeDotContainer--option">
            <button onClick={() => onButtonChange('bookmark')}>
              {' '}
              {isBookmarked ? 'UnBookmark' : 'Bookmark'}
            </button>
          </div>
          <div className="tlm--secondPanle__threeDotContainer--option">
            <button onClick={() => onButtonChange('remove')}> Remove</button>
          </div>
        </Card>
      )}
      <div className="tlm--secondPanle__top">
        <div className="tlm--secondPanle__title">
          {logo ? (
            <img className="tlm--secondPanle__icon" src={logo} alt="icon" />
          ) : (
            <span className="tlm--secondPanle__icon">{companyIcon}</span>
          )}
          <input
            className={
              companyNameEmpty
                ? 'tlm--secondPanle__companyName-input_empty'
                : 'tlm--secondPanle__companyName-input'
            }
            type="text"
            name="companyName"
            placeholder="*Please fill in company name"
            value={jobBasicInfo.companyName}
            onChange={(e) => {
              onJobInfoChange(e.target.value, 'companyName');
              if (e.target.value.length === 0) {
                setCompanyNameEmpty(true);
              } else {
                setCompanyNameEmpty(false);
              }
            }}
            onClick={(e) => {
              if (e.target.value.length === 0) {
                setCompanyNameEmpty(true);
              } else {
                setCompanyNameEmpty(false);
              }
            }}
          />
        </div>
        <input
          className={
            jobTitleEmpty
              ? 'tlm--secondPanle__jobName-input_empty'
              : 'tlm--secondPanle__jobName-input'
          }
          type="text"
          name="jobTitle"
          placeholder="*Please fill in job title"
          value={jobBasicInfo['Job Title']}
          onChange={(e) => {
            onJobInfoChange(e.target.value, 'Job Title');
            if (e.target.value.length === 0) {
              setJobTitleEmpty(true);
            } else {
              setJobTitleEmpty(false);
            }
          }}
          onClick={(e) => {
            if (e.target.value.length === 0) {
              setJobTitleEmpty(true);
            } else {
              setJobTitleEmpty(false);
            }
          }}
        />

        {/*<div className="tlm--secondPanle__tags">
          <Tag label="New Grad" themeColor="c-tag-sapphire" />
          <Tag label="Summer 2022" themeColor="c-tag-sapphire" />
          <Tag label="New Grad" themeColor="c-tag-sapphire" />
          <Tag label="Summer 2022" themeColor="c-tag-sapphire" />
        </div>*/}
        <div className="tlm--secondPanle__inputContainer">
          <div className="tlm--secondPanle__link">
            <span className="tlm--secondPanle__linkIcon">{linkIcon}</span>
            {editedLinkFlag ? (
              <input
                type="text"
                name="links"
                value={linkUrl}
                onChange={(e) => onLinkChange(e.target.value)}
                onBlur={() => setEditedLinkFlag(false)}
              />
            ) : (
              <Button style={{ backgroundColor: 'transparent' }} onClick={onLinkClick}>
                <a
                  className={
                    linkUrl === '' || !validURL(linkUrl)
                      ? 'tlm--secondPanle__linkurl tlm-link-disabled'
                      : 'tlm--secondPanle__linkurl'
                  }
                  href={linkUrl === '' ? '' : linkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {linkUrl === '' && validURL(linkUrl) ? '+ Add job link' : 'Job Link'}
                </a>
              </Button>
            )}

            <div
              className="tlm--secondPanle__link-popover"
              onClick={() => setEditedLinkFlag(true)}
            >
              {!editedLinkFlag && <span>{pencilIcon}</span>}
            </div>
            {!validURL(linkUrl) && (
              <div className="tlm--link-errorMsg">
                <span className="tlm--link-errorMsg-icon">{WarningIcon}</span>Invalide URL
              </div>
            )}
          </div>
          <div className="tlm--secondPanle__location">
            <span className="tlm--secondPanle__buildingIcon">{buildingIcon}</span>
            <input
              type="text"
              name="location"
              value={jobLocation}
              placeholder="+ Add location"
              onChange={(e) => onLocationChange(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="tlm--secondPanle__bottom">
        <Tab
          activeKey={activeTabKey}
          betaItemKey={
            aIGuidanceFeatureFlag
              ? DASHBOARD_TAB_INDEX.AI_GUIDANCE
              : DASHBOARD_TAB_INDEX.REFERRAL
          }
          handleTabChangeCallback={setActiveTabKey}
        >
          {tabData.map((dataItem, idx) => (
            <TabItem
              text={dataItem.text}
              dataKey={dataItem.key}
              key={`${dataItem.text}-${idx}`}
            >
              {dataItem.component}
            </TabItem>
          ))}
        </Tab>
      </div>
    </Card>
  );
}
