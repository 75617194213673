import React from 'react';
import { Stack, Pagination, Select, MenuItem } from '@mui/material';
import './CommonPagination.scss';

const pageSizeList = [10, 25, 50, 100];

export default function CommonPagination({
  totalPages = 0,
  pageNo = 0,
  pageSize = 25,
  pageSizes = pageSizeList,
  changePagination,
}) {
  const changePageSize = (event) => {
    changePagination({
      pageSize: event.target.value,
      pageNo: 0,
    });
  };

  const changePage = (event, pageNum) => {
    changePagination({
      pageSize: pageSize,
      pageNo: pageNum - 1,
    });
  };

  return (
    <div className="tlm-ng-jobBoard-pagniation">
      {totalPages > 0 ? (
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Pagination
            variant="outlined"
            shape="rounded"
            count={totalPages}
            page={pageNo + 1}
            onChange={changePage}
          />
          <Stack direction={'row'} spacing={1}>
            <Select
              sx={{
                displayEmpty: true,
                backgroundColor: '#fff',
                fontSize: '14px',
                height: '32px',
                width: '76px',
              }}
              onChange={changePageSize}
              value={pageSize}
            >
              {pageSizes.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <Stack direction={'row'} justifyContent="center" alignItems="center">
              Records per page
            </Stack>
          </Stack>
        </Stack>
      ) : null}
    </div>
  );
}
