import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import OnboardLandingImg1 from '../../../images/onboardLanding-1.svg';
import OnboardLandingImg2 from '../../../images/onboardLanding-2.svg';
import { makeStyles } from '@mui/styles';
import { Button } from '../../../stories/Button';
import { ModalcloseIcon } from '../../../components/icons';
import { Mixpanel } from '../../../utilities/mixpanel';
import '../../../index.scss';
import '../../../styles/typography';
import '../../../styles/color';
import { OB_STEP } from '../../../constants/onboarding';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 1100,
    height: 619,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
  },
  pic1: {
    marginLeft: 100,
    marginRight: 100,
  },
  pic2: {
    marginLeft: 100,
    marginRight: 100,
    marginTop: 10,
  },
  picTitle: {
    color: '#727272',
    marginLeft: 70,
    marginTop: 27,
  },

  picText: {
    marginTop: 5,
    marginLeft: 70,
    marginBottom: 50,
  },
  closeButton: {
    marginLeft: '990px',
    marginTop: '-15px',
    cursor: 'pointer',
  },
}));

const OnboardingModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div className="tlm">
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.closeButton} onClick={props.handleClose}>
          {ModalcloseIcon}
        </div>
        <div className={`body-text-24 mb-5`}>
          Welcome! Try our core features to help you apply jobs 10x faster...
        </div>
        <div className="v-center">
          <div>
            <img
              className={classes.pic1}
              src={OnboardLandingImg1}
              alt="Save and track jobs"
            />
            <div className={`header-18 ${classes.picTitle}`}>Feature 1</div>
            <div className={`page-title-24 ${classes.picText}`}>Save and Track Jobs</div>
          </div>
          <div>
            <img
              className={classes.pic2}
              src={OnboardLandingImg2}
              alt="Auto-fill job applications"
            />
            <div className={`header-18 ${classes.picTitle}`}>Feature 2</div>
            <div className={`page-title-24 ${classes.picText}`}>
              Auto-fill Job Applications
            </div>
          </div>
        </div>
        <div className="v-center m-3">
          <Button
            width="230px"
            height="40px"
            onClick={() => {
              props.setStep(OB_STEP.DOWNLOAD_EXTENSION);
              props.handleClose();
              Mixpanel.track('onboarding: Get Started');
            }}
          >
            Get Started in 3 Minutes
          </Button>
        </div>
      </div>
    </div>
  );
  return <Modal open={props.open}>{body}</Modal>;
};

OnboardingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OnboardingModal;
