import React, { useEffect, useState, useCallback } from 'react';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import './LocationDropDown.scss';
import { ArrwoIcon, xRemoveIcon } from '../../../components/icons';
import { SearchBar } from '../../../stories/SearchBar.tsx';
import { Checkbox } from '../../../stories/Checkbox';
import { Mixpanel } from '../../../utilities/mixpanel';

const SearchTag = ({ label, onCancel }) => {
  return (
    <div className={`tlm-tag-container`}>
      <span className={`tlm-tag-text`}>{label}</span>
      <div
        className={`tlm-tag-icon`}
        onClick={() => {
          onCancel(label);
        }}
      >
        {xRemoveIcon}
      </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  paper: {
    transform: 'translateY(10px)',
  },
}));

/**
 * value: array of selected value
 * onChange: fire up event when we select or unselect anything
 * options: all the locations we could have
 */
export default function LocationDropDown({
  locationSelected,
  setLocationSelected,
  locationList,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');
  const handleClick = (event) => {
    Mixpanel.track('Filter by Location');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'location-popper' : undefined;

  const recommendLocation = locationList.slice(0, 3);
  const allLocations = locationList;

  const createLocationList = (list) => {
    return list.map((ele, key) => {
      return (
        <div className="tlm-location-search_result">
          <Checkbox
            key={key}
            label={ele}
            onChange={onLocationClick}
            checked={locationSelected.indexOf(ele) > -1}
          />
        </div>
      );
    });
  };
  const afterFilter = allLocations.filter((ele) => {
    return ele.toLowerCase().includes(searchText.toLowerCase());
  });
  const getFilterLocations = useCallback(() => {
    return createLocationList(afterFilter);
  }, [searchText, locationSelected]);

  // search logic
  const onTagRemove = (e) => {
    const currentSelected = [...locationSelected];
    const index = currentSelected.indexOf(e);

    currentSelected.splice(index, 1);

    setLocationSelected(currentSelected);
  };

  const onLocationClick = (e) => {
    const currentSelected = [...locationSelected];

    const index = currentSelected.indexOf(e);

    if (index > -1) {
      currentSelected.splice(index, 1);
    } else {
      currentSelected.push(e);
    }

    setLocationSelected(currentSelected);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleSearchCancel = () => {
    setSearchText('');
  };

  return (
    <div className="tlm-location-search-container">
      <button
        className={
          open
            ? 'tlm-location-search-container_button tlm-location-search-container_button-open'
            : 'tlm-location-search-container_button'
        }
        aria-describedby={id}
        type="button"
        onClick={handleClick}
      >
        <span className="tlm-location-search-container_button_text">Location</span>
        <span>{ArrwoIcon}</span>
      </button>

      <Popover
        id={id}
        className={classes.paper}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            border: '1px solid #05756C',
            borderRadius: '8px',
            padding: '20px 16px',
          },
        }}
      >
        <div className="tlm-location-search_tags">
          {locationSelected.map((ele, key) => {
            return (
              <div className="tlm-location-search_tag">
                <SearchTag label={ele} key={key} onCancel={onTagRemove} />
              </div>
            );
          })}
        </div>
        <SearchBar
          placeholder="Enter Search Keywords"
          searchText={searchText}
          handleChange={(e) => handleSearchChange(e)}
          handleCancel={handleSearchCancel}
        />
        <div className="tlm-location-search_content">
          {searchText === '' && <h3>Popular Location</h3>}
          <div className="tlm-location-search_results">
            {searchText === '' && createLocationList(recommendLocation)}
            {searchText !== '' && getFilterLocations()}
            {afterFilter.length === 0 && (
              <span className="location-no-found">No result found</span>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}
