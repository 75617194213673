import React, { useState, useEffect } from 'react';
import './JobBoardNewGradFilter.scss';
import { Box, Typography } from '@mui/material';
import { Tag } from '../../../stories/Tag';
import LocationDropDown from './LocationDropDown';
import MultiDropDown from './MultiDropDown';

import JobboardConstants from '../JobboardConstants';
import { updateDefaultJobType } from '../../../services/JobBoardServices';
import { Mixpanel } from '../../../utilities/mixpanel';
const FILTERLIST = {
  role: [
    'Software Engineer',
    'Data Scientist',
    'Product Designer',
    'Graphic Designer',
    'Analyst',
    'Product Manager',
    'Data Engineer',
    'UX Researcher',
    'Project Manager',
  ],
  year: [],
  sponsorship: ['Unknown', 'Yes', 'No', 'Potentially Yes'],
  status: ['Open', 'Recently Closed', 'Closed Open Soon', 'Permanently Closed'],
  rank: ['rank 1', 'rank 2', 'rank 3'],
};

export default function JobBoardNewGradFilter({
  locationSelected,
  setLocationSelected,
  sponsorshipSelected,
  setSponsorshipSelected,
  roleSelected,
  setRoleSelected,
  locationList,
  setFilterFields,
  yearList,
  jobIndex,
  resetClick,
}) {
  const [filterList, setFilterList] = useState(FILTERLIST);

  useEffect(() => {
    let list = [];
    const createSeasonList = () => {
      const seasonId = Object.keys(JobboardConstants.seasonType);
      for (let year of yearList) {
        for (let season of seasonId) {
          const res = `${year} ${JobboardConstants.seasonType[season]}`;
          list.push(res);
        }
      }
    };
    createSeasonList();
    setFilterList({ ...FILTERLIST, year: list });
  }, [yearList]);

  // keep track which field is selected
  const [selectedOptions, setOptions] = useState(['Software Engineer']);

  const handleFilterChange = (option, type) => {
    const curOptions = selectedOptions;
    curOptions.push(option);
    const set = new Set(curOptions);
    setOptions(Array.from(set));
    // add to main page filter
    if (type === 'role') {
      setFilterFields((prev) => {
        return { ...prev, role: JobboardConstants['jobType'][option] };
      });
      Mixpanel.track('Filter by Role');
      updateDefaultJobType(JobboardConstants['jobType'][option]);
    }
    if (type === 'sponsorship') {
      setFilterFields((prev) => {
        return { ...prev, sponsorship: JobboardConstants['sponsorshipType'][option] };
      });
      Mixpanel.track('Filter by Sponsorship');
    }
    if (type === 'status') {
      setFilterFields((prev) => {
        return { ...prev, status: JobboardConstants['openStatusType'][option] };
      });
      Mixpanel.track('Filter by Status');
    }
    if (type === 'year') {
      setFilterFields((prev) => {
        return { ...prev, year: option };
      });
      Mixpanel.track('Filter by Year/Season');
    }
  };

  const handleExploreFilter = (type) => {
    if (type === 'exploreFilter1') {
      setFilterFields((prev) => {
        return { ...prev, year: '', exploreFilter1: '2022', exploreFilter2: '' };
      });
    }
    if (type === 'exploreFilter2') {
      setFilterFields((prev) => {
        // return { ...prev, exploreFilter2: 'true', exploreFilter1: '' };
        return { ...prev, exploreFilter2: 2, exploreFilter1: '' };
      });
    }
  };

  const isChoosedValue = (type) => {
    for (let ele of filterList[type]) {
      if (selectedOptions.includes(ele)) {
        return ele;
      }
    }
    return '';
  };
  useEffect(() => {
    setOptions([FILTERLIST.role[jobIndex - 1]]);
  }, [jobIndex]);

  const [selectedLabel, setSelectedLabel] = useState('');
  return (
    <div className="tlm-ng-jobBoard-filter">
      <Box display="flex" alignItems="center" className="tlm-ng-jobBoard-filter_tags">
        <Typography variant="h5" className="tlm-ng-jobBoard-filter_tags_title">
          Explore opportunities:
        </Typography>
        {/* <div
          onClick={() => {
            handleExploreFilter('exploreFilter1');
            setSelectedLabel(0);
            Mixpanel.track('View Recommendations');
          }}
        >
          <Tag
            label="2023 Jobs"
            themeColor="c-theme-200"
            className={`${selectedLabel === 0 ? 'tlm-label-selected' : ''}`}
          />
        </div> */}
        <div
          onClick={() => {
            handleExploreFilter('exploreFilter2');
            setSelectedLabel(1);
            Mixpanel.track('View Recommendations');
          }}
        >
          <Tag
            label="Recently Opened within Last Two Weeks"
            themeColor="c-theme-200"
            className={`${selectedLabel === 1 ? 'tlm-label-selected' : ''}`}
          />
        </div>
        {/* <div onClick={() => setSelectedLabel(2)}>
          <Tag
            label="Hot Startups"
            themeColor="c-theme-200"
            className={`${selectedLabel === 2 ? 'tlm-label-selected' : ''}`}
          />
        </div>
        <div onClick={() => setSelectedLabel(3)}>
          <Tag
            label="Near You"
            themeColor="c-theme-200"
            className={`${selectedLabel === 3 ? 'tlm-label-selected' : ''}`}
          />
        </div> */}
      </Box>
      <Box display="flex" alignItems="center" className="tlm-ng-jobBoard-filter_options">
        {/* <Dropdown
          classNames="tlm-ng-jobBoard-filter-dropdown"
          value={isChoosedValue('role')}
          label="Role"
          onChange={(e) => {
            handleFilterChange(e, 'role');
          }}
          itemsAry={filterList['role']}
        /> */}
        <MultiDropDown
          defaultDisplay="Role"
          locationList={filterList['role']}
          locationSelected={roleSelected}
          setLocationSelected={setRoleSelected}
        />
        <div className="tlm-ng-jobBoard-divider"></div>
        {/* <Dropdown
          value={isChoosedValue('year')}
          label="Year/Season"
          onChange={(e) => {
            handleFilterChange(e, 'year');
          }}
          itemsAry={filterList['year']}
        /> */}
        {/* <Dropdown
          value={isChoosedValue('sponsorship')}
          label="Sponsorship"
          onChange={(e) => {
            handleFilterChange(e, 'sponsorship');
          }}
          itemsAry={filterList['sponsorship']}
        /> */}
        <MultiDropDown
          defaultDisplay="Sponsorship"
          locationList={filterList['sponsorship']}
          locationSelected={sponsorshipSelected}
          setLocationSelected={setSponsorshipSelected}
        />
        {/* <Dropdown
          value={isChoosedValue('status')}
          label="Status"
          onChange={(e) => {
            handleFilterChange(e, 'status');
          }}
          itemsAry={filterList['status']}
        /> */}

        <LocationDropDown
          locationList={locationList}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
        />
        {/* <Dropdown
          value={isChoosedValue('rank')}
          label="Rank"
          onChange={(e) => {
            handleFilterChange(e, 'rank');
          }}
          itemsAry={filterList['rank']}
        /> */}
        <Typography
          variant="h5"
          className="tlm-ng-jobBoard-filter_reset"
          onClick={() => {
            setSelectedLabel('');
            setOptions([]);
            setLocationSelected([]);
            setSponsorshipSelected(['']);
            setRoleSelected(['1']);
            setFilterFields({
              role: ['1'],
              sponsorship: [''],
              status: '',
              location: [],
              year: '',
              exploreFilter1: '',
              exploreFilter2: '',
            });
            resetClick();
            Mixpanel.track('Reset Filters');
          }}
        >
          Reset
        </Typography>
      </Box>
    </div>
  );
}
