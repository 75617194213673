import React, { useState } from 'react';
import FormItem from './form-item';
import FormItemRadio from './form-item/radio';
import { Grid } from '@mui/material';
import { useFormMode } from '@utilities/form-hooks';

import '../style/index.scss';

const BaseForm = ({
  info = {},
  address = {},
  rules,
  formValidator = {},
  updateForm,
  changeFormValid,
}) => {
  const { formData, updateInfo } = useFormMode(
    { ...info, ...address },
    updateForm,
    formValidator,
    changeFormValid
  );
  return (
    <form className="" noValidate autoComplete="off">
      <Grid className="form-container" container spacing={2}>
        <Grid item xs={6}>
          <FormItem
            label="First Name"
            key="first_name"
            handleUpdate={(info, valid) => updateInfo('first_name', info, valid)}
            value={formData.first_name}
            error={!!formValidator.first_name}
            errorMsg={formValidator.first_name}
            required={rules.first_name.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Last Name"
            key="last_name"
            handleUpdate={(info, valid) => updateInfo('last_name', info, valid)}
            value={formData.last_name}
            error={!!formValidator.last_name}
            errorMsg={formValidator.last_name}
            required={rules.last_name.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Email"
            handleUpdate={(info, valid) => updateInfo('email', info, valid)}
            value={formData.email}
            error={!!formValidator.email}
            errorMsg={formValidator.email}
            required={rules.email.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Phone Number"
            handleUpdate={(info, valid) => updateInfo('phone', info, valid)}
            value={formData.phone}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Street Address"
            handleUpdate={(info, valid) => updateInfo('street_address', info, valid)}
            value={formData.street_address}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="City"
            handleUpdate={(info, valid) => updateInfo('city', info, valid)}
            value={formData.city}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="State / Province"
            handleUpdate={(info, valid) => updateInfo('state', info, valid)}
            value={formData.state}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Postcode"
            handleUpdate={(info, valid) => updateInfo('postal_code', info, valid)}
            value={formData.postal_code}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Country"
            handleUpdate={(info, valid) => updateInfo('country', info, valid)}
            value={formData.country}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItemRadio
            label="Are you authorized to work in the United States?"
            handleUpdate={(info, valid) =>
              updateInfo('has_work_authorization', info, valid)
            }
            value={formData.has_work_authorization}
          ></FormItemRadio>
        </Grid>
        <Grid item xs={12}>
          <FormItemRadio
            label="Will you now or in the future require visa sponsorship?"
            handleUpdate={(info, valid) => updateInfo('require_sponsorship', info, valid)}
            value={formData.require_sponsorship}
          ></FormItemRadio>
        </Grid>
      </Grid>
    </form>
  );
};

export default BaseForm;
