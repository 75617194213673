import React from 'react';
import { noBookmarkIcon } from './icons';
import '../index.scss';
import '../../../styles/typography.scss';

export function NoBookmarkComponent() {
  return (
    <div className="library-noResult">
      <div className="library-noResult-header">
        {noBookmarkIcon}
        <span className="library-noResult-header-text page-title-28">
          No bookmark yet
        </span>
      </div>
      <div className="header-16">
        Click the bookmark icon on any resources to add it to your library
      </div>
    </div>
  );
}
