import React from 'react';
import { refreshLogo } from '../../../../components/icons';
import Congrats from '../../../../images/congrats.svg';
import { Button } from '../../../../stories/Button';
import { OB_STEP, OB_STATUS } from '../../../../constants/onboarding';
import PropTypes from 'prop-types';
import { Mixpanel } from '../../../../utilities/mixpanel';
import '../../../../styles/typography.scss';

const FinishedOnboarding = (props) => {
  return (
    <div className="tlm-onboard-card p-3">
      <p className="page-title-24 text-center pt-3">
        Congratulations! <br />
        You finished onboarding
      </p>
      <div className="v-center p-3">
        <img src={Congrats} alt="congrats" />
      </div>
      <div className="v-center mb-2">
        <Button
          height="39px"
          width="259px"
          onClick={() => {
            localStorage.setItem(OB_STATUS, OB_STEP.FINISHED);
            localStorage.setItem('enablePanel', JSON.stringify(false));
            props.setStep(OB_STEP.FINISHED);
            props.setPanelOpen(false);
            props.setPanelNotification(true);
            Mixpanel.track('Finish Onboarding');
          }}
          disableRipple
        >
          Done
        </Button>
      </div>
      <div
        className="redo-link v-center"
        onClick={() => {
          localStorage.setItem(OB_STATUS, OB_STEP.DOWNLOAD_EXTENSION);
          props.setStep(OB_STEP.DOWNLOAD_EXTENSION);
          Mixpanel.track('Redo Onboarding');
        }}
      >
        {refreshLogo} Redo Onboarding
      </div>
    </div>
  );
};

export default FinishedOnboarding;
