import React, { useState } from 'react';
import './MyTemplatePreviewCard.css';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MyTemplateDropdown from './MyTemplateDropdown.jsx';

import {
  createUserTemplate,
  deleteUserTemplate,
} from '../../../services/EmailTemplateServices';

const MyTemplatePreviewCard = (props) => {
  const [dropdown, setDropdown] = useState(false);
  // format time
  var last_modified_time_array = props.template.last_modified_time.split('T');
  last_modified_time_array[1] = last_modified_time_array[1].split('.')[0];
  var formatted_last_modified_time = last_modified_time_array.join(' ');

  const handleHeadClick = (e) => {
    e.stopPropagation();
    if (!dropdown) {
      setDropdown(true);
      setTimeout(() => {
        document.addEventListener('click', handleGlobalClick);
      }, 0);
    }
  };

  const handleGlobalClick = () => {
    setDropdown(false);
    document.removeEventListener('click', handleGlobalClick);
  };

  const handleSaveAsNew = (e) => {
    e.stopPropagation();
    let title = props.template.title;
    title = handleDuplicateName2(title);
    createUserTemplate(
      title,
      props.template.content,
      props.template.template_category
    ).then(() => {
      props.refreshUserTemplates();
    });
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteUserTemplate(props.template.id).then(() => {
      props.refreshUserTemplates();
      props.getFirstTime();
    });
  };

  // [deprecated] duplicate name handler rule 1
  const handleDuplicateName = (inputTitle) => {
    let serielNumber = 2;
    if (props.nameSet.has(inputTitle)) {
      inputTitle = inputTitle + ' - ' + serielNumber;
      while (props.nameSet.has(inputTitle)) {
        serielNumber++;
        let tmpArray = inputTitle.split('-');
        tmpArray[tmpArray.length - 1] = ' ' + serielNumber;
        inputTitle = tmpArray.join('-');
      }
    }
    return inputTitle;
  };

  // duplicate name handler rule 2
  const handleDuplicateName2 = (inputTitle) => {
    while (props.nameSet.has(inputTitle)) {
      inputTitle = inputTitle + ' Copy';
    }
    return inputTitle;
  };

  return (
    <div
      className="my-temp-prev-card"
      onClick={() => {
        props.setEditCard(props.template);
        props.setIsOfferlandTemplate(false);
      }}
    >
      <div className="my-temp-prev-card-title">{props.template.title}</div>
      <div className="my-temp-prev-card-content">{props.template.content}</div>
      <div className="my-temp-prev-card-date">{formatted_last_modified_time}</div>
      <div className="my-temp-prev-card-btn-edit">Edit</div>
      <div className="my-temp-prev-card-dropdown">
        <FontAwesomeIcon
          className="my-temp-prev-card-dropdown-icon"
          icon={faEllipsisH}
          onClick={handleHeadClick}
        />
        <div className="my-temp-prev-card-dropdown-menu-wrapper">
          {dropdown && (
            <MyTemplateDropdown
              handleSaveAsNew={handleSaveAsNew}
              handleDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyTemplatePreviewCard;
