export default {
  sponsorshipType: {
    0: 'Unknown',
    1: 'Yes',
    2: 'No',
    3: 'Potentially Yes',
    Unknown: 0,
    Yes: 1,
    No: 2,
    'Potentially Yes': 3,
  },
  seasonType: {
    0: 'Unknown',
    1: 'Spring',
    2: 'Summer',
    3: 'Fall',
    4: 'Winter',
  },
  jobType: {
    Null: 0,
    'Software Engineer': 1,
    'Data Scientist': 2,
    'Product Designer': 3,
    'Graphic Designer': 4,
    Analyst: 5,
    'Product Manager': 6,
    'Data Engineer': 7,
    'UX Researcher': 8,
    'Project Manager': 9,
  },
  openStatusType: {
    // 0: 'Currently Closed',
    1: 'Open',
    2: 'Recently Closed',
    3: 'Closed Open Soon',
    4: 'Permanently Closed',
    // 'Currently Closed': 0,
    Open: 1,
    'Recently Closed': 2,
    'Closed Open Soon': 3,
    'Permanently Closed': 4,
  },
};
