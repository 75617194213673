import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import '../LoadingProgress.scss';
import CircularProgress from '@mui/material/CircularProgress';

const PreloaderModal = (props) => {

  const body = (
    <div className="tlm-loadingProgress-container">
      <CircularProgress style={{ color: '#05756C' }} size={80} />
    </div>
  );
  return <Modal open={props.open}>{body}</Modal>;
};

PreloaderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PreloaderModal;

