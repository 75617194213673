import React, { useState, useEffect } from 'react';
import { Stack, Button } from '@mui/material';
import SoftwareEnginnerSrc from '@images/profile/software-enginner.svg';
import SoftwareEnginnerDoneSrc from '@images/profile/software-enginner-done.svg';
import clsx from 'clsx';
import { Mixpanel } from '../../../utilities/mixpanel';
import { useUploadResume } from '@services/Profile';

import UploadFile from './upload-file';
import AlertDialog from '@components/CommonDialog';

const ProfileUpload = ({ profileId, resumeName }) => {
  const existResume = resumeName && resumeName !== '';
  const [openReUpload, setOpenReUpload] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const { mutateAsync: onUploadResume, isLoading, error } = useUploadResume();

  useEffect(() => {
    if (error) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  }, [error]);
  const [file, setFile] = useState(null);
  const uploadFile = async (file) => {
    if (!file) {
      setErrorMsg('Please upload file');
      return;
    }
    setErrorMsg(false);
    const res = await onUploadResume({
      profileId: profileId,
      resume: file,
    });
    if (res) {
      setOpenReUpload(false);
      const trackInfo = `${!existResume ? 'Upload' : 'Re-Upload'} Resume`;
      Mixpanel.track(trackInfo);
    }
    if (error) {
      setErrorMsg(true);
      Mixpanel.track('onboarding: Upload Resume Failed');
    }
  };
  return (
    <Stack className="profile-upload">
      <Stack
        className={clsx('', { 'profile-preupload': !existResume })}
        direction={'row'}
      >
        <Stack className="profile-upload-info" direction={'row'}>
          <Stack
            className="profile-upload-info-icon"
            direction={'column'}
            justifyContent="center"
            alignItems="center"
          >
            <img src={existResume ? SoftwareEnginnerDoneSrc : SoftwareEnginnerSrc} />
          </Stack>
          <Stack direction={'column'} justifyContent="center" alignItems="center">
            {existResume
              ? 'Your profile is auto-populated below based on your resume!'
              : 'Populate your profile with your resume information'}
          </Stack>
        </Stack>
        <Stack
          sx={{ flex: 1 }}
          direction={'column'}
          justifyContent="center"
          alignItems="center"
        >
          {!existResume ? (
            <UploadFile
              uploadFile={(fileData) => uploadFile(fileData)}
              isLoading={isLoading}
              error={errorMsg}
            ></UploadFile>
          ) : (
            <Stack direction={'row'} spacing={2}>
              <Button
                variant="outlined"
                component="span"
                onClick={() => {
                  setOpenReUpload(false);
                  setOpenReUpload(true);
                }}
                sx={{ width: '124px' }}
              >
                <strong>Re-Upload</strong>
              </Button>
            </Stack>
          )}

          <AlertDialog
            open={openReUpload}
            title={'Re-Upload resume'}
            onClose={() => setOpenReUpload(false)}
            onSure={() => uploadFile(file)}
          >
            <Stack className="font-14" sx={{ pb: 2 }}>
              Re-uploading a resume file will overwrite the current profile information
              and attachment
            </Stack>
            <UploadFile
              uploadFile={(fileData) => {
                setFile(fileData);
                setErrorMsg(false);
              }}
              isLoading={isLoading}
              error={errorMsg}
            ></UploadFile>
          </AlertDialog>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileUpload;
