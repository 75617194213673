import React, { useEffect, useState } from 'react';
import { SearchBar } from '../../stories/SearchBar';
import { Dropdown } from '../../stories/Dropdown';
import { Checkbox } from '../../stories/Checkbox';
import { LibraryCards } from './components/LibraryCards';
import { NotFoundComponent } from './components/NotFoundComponent';
import { NoBookmarkComponent } from './components/NoBookmarkComponent';
import { tagsMatcher, filtersMatcher, firstCapitalize } from './components/helperFunc';
import { getDataOnLibrary } from '@services/LibraryService';
import { filtersList } from './constant';
import './index.scss';
import '../../styles/typography.scss';
import '../../styles/color.scss';

export const Library = (props) => {
  const tab =
    props.libraryTab == 'LibraryResources' ? 'Library Resources' : 'My Collection';
  const bookmarkedPage = tab == 'Library Resources' ? false : true;
  const [searchText, setSearch] = useState('');
  const [selectedOption, setOption] = useState([]);
  const [selectedFilter, setFilter] = useState({});
  const [searchResources, setSearchResources] = useState([]);
  const [resources, setResources] = useState([]);
  const [notFound, setNotFound] = useState(false);

  let nobookmarks = true;
  for (const resource of resources) {
    if (resource.is_bookmarked) {
      nobookmarks = false;
      break;
    }
    nobookmarks = nobookmarks && !resource.is_bookmarked;
  }

  const refreshResources = () => {
    getDataOnLibrary().then((res) => {
      setResources(res.data);
    });
  };

  useEffect(() => {
    getDataOnLibrary().then((res) => {
      setResources(res.data);
      setSearchResources(res.data);
    });
  }, []);

  useEffect(() => {
    filterHandler(selectedOption);
  }, [selectedOption]);

  const filterHandler = (selectedOption) => {
    setNotFound(false);
    if (selectedOption.length == 0) {
      setSearchResources(resources);
    } else if (selectedOption.length > 2) {
      setNotFound(true);
    } else {
      let newSearchResources = [];
      for (let resource of resources) {
        const fullTags = filtersMatcher(resource);
        const fullTagsStr = fullTags.join(' ');
        if (selectedOption.length == 2) {
          if (
            fullTagsStr.toLowerCase().includes(selectedOption[0].toLowerCase()) &&
            fullTagsStr.toLowerCase().includes(selectedOption[1].toLowerCase())
          ) {
            newSearchResources.push(resource);
          }
        } else {
          if (fullTagsStr.toLowerCase().includes(selectedOption[0].toLowerCase())) {
            newSearchResources.push(resource);
          }
        }
      }
      setSearchResources(newSearchResources);
      if (newSearchResources.length == 0) {
        setNotFound(true);
      }
    }
  };

  const enterHandler = (searchText, e) => {
    setNotFound(false);
    if (e.key === 'Enter') {
      e.preventDefault();
      let newSearchResources = [];
      for (let resource of resources) {
        const fullTags = filtersMatcher(resource);
        const tags = tagsMatcher(resource);
        const fullTagsStr = fullTags.join(' ');
        const tagsStr = tags.join(' ');
        if (
          resource.title.toLowerCase().includes(searchText.toLowerCase()) ||
          resource.summary.toLowerCase().includes(searchText.toLowerCase()) ||
          resource.author.toLowerCase().includes(searchText.toLowerCase()) ||
          fullTagsStr.toLowerCase().includes(searchText.toLowerCase()) ||
          tagsStr.toLowerCase().includes(searchText.toLowerCase())
        ) {
          newSearchResources.push(resource);
        }
      }
      setSearchResources(newSearchResources);
      if (newSearchResources.length == 0) {
        setNotFound(true);
      }
    }
  };

  const handleFilter = (option, filter) => {
    if (selectedOption.includes(option)) {
      setOption((selectedOption) => selectedOption.filter((item) => item !== option));
    } else {
      setOption((selectedOption) => [...selectedOption, option]);
    }
    if (selectedFilter[filter]) {
      if (selectedFilter[filter].includes(option)) {
        if (selectedFilter[filter].length == 1) {
          setFilter(
            Object.assign(selectedFilter, {
              [filter]: undefined,
            })
          );
        } else {
          setFilter(
            Object.assign(selectedFilter, {
              [filter]: selectedFilter[filter].filter((item) => item !== option),
            })
          );
        }
      } else {
        setFilter(
          Object.assign(selectedFilter, { [filter]: [...selectedFilter[filter], option] })
        );
      }
    } else {
      setFilter(Object.assign(selectedFilter, { [filter]: [option] }));
    }
  };
  const handleFilterChange = (option, filterType) => {
    handleFilter(option, filterType);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value == '') {
      setNotFound(false);
      setSearchResources(resources);
    }
  };

  const handleCancel = () => {
    setSearch('');
    setNotFound(false);
    setSearchResources(resources);
  };

  const handleReset = () => {
    setOption([]);
    setFilter({});
  };

  return (
    <div className="library">
      <div className="library-header">
        <div>
          <span className="library-first-title page-title-24">
            Offerland Resources Library
          </span>
          <span className="library-second-title body-text-18">{tab}</span>
        </div>
        <SearchBar
          placeholder="Find the article"
          searchText={searchText}
          handleChange={(e) => handleChange(e)}
          handleCancel={() => handleCancel()}
          onEnter={(e) => enterHandler(searchText, e)}
        ></SearchBar>
      </div>
      <div className="library-filters">
        {Object.keys(filtersList).map((filter) => {
          const isChoosedValue = () => {
            for (let ele of filtersList[filter]) {
              if (selectedOption.includes(ele)) {
                return ele;
              }
            }
            return '';
          };

          return (
            <div className="library-filter">
              <Dropdown
                id={filter}
                value={isChoosedValue()}
                label={`${firstCapitalize(filter).split('_').join(' ')} ${
                  selectedFilter[filter] ? ` (${selectedFilter[filter].length})` : ''
                }`}
                onChange={(e) => {
                  handleFilterChange(e, filter);
                }}
                itemsAry={filtersList[filter]}
              />
            </div>
          );
        })}
        <div className="library-splitLine" />
        <div className="library-reset header-16" onClick={() => handleReset()}>
          Reset
        </div>
      </div>
      {notFound ? (
        <NotFoundComponent />
      ) : nobookmarks && bookmarkedPage ? (
        <NoBookmarkComponent />
      ) : (
        <LibraryCards
          resources={resources}
          searchResources={searchResources}
          setSearchResources={setSearchResources}
          bookmarkedPage={bookmarkedPage}
          refreshResources={refreshResources}
        />
      )}
    </div>
  );
};

export default Library;
