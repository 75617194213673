import React, { useState } from 'react';
import FormItem from './form-item';
import { Checkbox } from '../../../stories/Checkbox.tsx';
import { Grid } from '@mui/material';
import { useFormMode, useValidTwoDate } from '@utilities/form-hooks';

import '../style/index.scss';

const ExperienceForm = ({
  info = {},
  rules,
  formValidator,
  updateForm,
  changeFormValid,
}) => {
  const { formData, updateInfo } = useFormMode(
    info,
    updateForm,
    formValidator,
    changeFormValid
  );
  const { validDate, validTwoDate, invalidStartMsg, invalidEndMsg } = useValidTwoDate();
  const [checked, setChecked] = useState(formData.current_work_status);

  return (
    <form className="" noValidate autoComplete="off">
      <Grid className="form-container" container spacing={2}>
        <Grid item xs={6}>
          <FormItem
            label="Job Title"
            handleUpdate={(info, valid) => updateInfo('title', info, valid)}
            value={formData.title}
            error={!!formValidator.title}
            errorMsg={formValidator.title}
            required={rules.title.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Company"
            handleUpdate={(info, valid) => updateInfo('company_name', info, valid)}
            value={formData.company_name}
            error={!!formValidator.company_name}
            errorMsg={formValidator.company_name}
            required={rules.company_name.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Start Date"
            type="date"
            error={!!formValidator.start_date}
            errorMsg={formValidator.start_date}
            handleUpdate={(info, valid) => {
              const flag = validTwoDate(info, formData.end_date);
              updateInfo('start_date', info, flag, invalidStartMsg);
              if (flag && formValidator.end_date) {
                //如果校验通过，且expiration_date 存在错误，需要清除 expiration_date 的错误
                updateInfo('end_date', formData.end_date, flag);
              }
            }}
            value={formData.start_date}
            required={rules.start_date.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          {checked ? (
            <FormItem
              key="1"
              label="End Date"
              disabled={true}
              value="Present"
              required={true}
            ></FormItem>
          ) : (
            <FormItem
              key="2"
              label="End Date"
              type="date"
              error={!!formValidator.end_date}
              errorMsg={formValidator.end_date}
              handleUpdate={(info, valid) => {
                const flag = validTwoDate(formData.start_date, info);
                updateInfo('end_date', info, flag, invalidEndMsg);
                if (flag && formValidator.start_date) {
                  //如果校验通过，且expiration_date 存在错误，需要清除 expiration_date 的错误
                  updateInfo('issue_date', formData.start_date, flag);
                }
              }}
              value={formData.end_date}
              required={rules.end_date.required}
            ></FormItem>
          )}
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={checked}
            label="I currently work here"
            onChange={() => {
              setChecked(!checked);
              updateInfo('current_work_status', !checked, true);
              // 如果选择了 Present，则清空end_date的错误
              if (checked) {
                updateInfo('end_date', formData.end_date, true);
              }
            }}
          ></Checkbox>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Location"
            handleUpdate={(info, valid) => updateInfo('location', info, valid)}
            value={formData.location}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Description"
            rows={4}
            handleUpdate={(info, valid) => updateInfo('description', info, valid)}
            value={formData.description}
          ></FormItem>
        </Grid>
      </Grid>
    </form>
  );
};

export default ExperienceForm;
