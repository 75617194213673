import React from 'react';
// import ReactMarkdown from 'react-markdown';
import { Stack, Button, LinearProgress, InputBase } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { lightbulbIcon } from '@components/icons';
import '../style/index.scss';
import { STARTER_PLAN_COUNT, PREMIUM_PLAN_COUNT } from '../../../constants/planCount';

export const AiSuggestion = ({
  isLoading,
  isApplied = false,
  description,
  onApplyAi,
  genrateCount,
  isPremiumStatus
}) => {

  const currentMonth = ()=>{
    var monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var d = new Date();
    return monthNames[d.getMonth()+1];
  }

  const checkCountGenrated = ()=>{
    let diffCount;
    if(isPremiumStatus){
      diffCount = (PREMIUM_PLAN_COUNT.AI_RESUME_BUILDER_FOR_PREMIUM_USER - genrateCount);
    }else{
      diffCount = (STARTER_PLAN_COUNT.AI_RESUME_BUILDER_FOR_FREE_USER - genrateCount);
    }
    return diffCount;
  }

  return (
    <Stack className="ai-suggestion">
      <Stack className="ai-suggestion-title" style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <Stack  direction={'row'}>
          {lightbulbIcon()} &nbsp;&nbsp;&nbsp;AI Suggestion
        </Stack>
        <p style={{margin:"0px",fontSize:"14px", fontWeight:"700",  color:"#E98E40"}}>{checkCountGenrated()} times remaining {isPremiumStatus && 'Update 01 '+currentMonth()}</p>
      </Stack>

      <Stack>
        {isLoading && <LinearProgress />}
        <Stack className="content-text large">
          {/* <ReactMarkdown>{description}</ReactMarkdown> */}
          <InputBase readOnly={true} multiline={true} value={description} />
        </Stack>
      </Stack>

      <Stack direction={'row'} className="ai-suggestion-handler">
        {!isApplied ? (
          <Button variant="text" className="bold" onClick={onApplyAi}>
            Apply to description
          </Button>
        ) : (
          <Button variant="text" className="bold" startIcon={<DoneIcon />}>
            Applied
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
