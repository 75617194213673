import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { deleteJob } from '../../../services/JobServices';
import { emptyJob } from '../../../services/JobServices';
import { setPinStatus } from '../../../services/DashBoardServices';
import { pinIcon, unpinIcon, deleteIcon, largeCompany } from '../../../components/icons';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import '../index.scss';
import { popupError } from '@utilities/error-handler';
import errorMessages from '../../dashboard-view/errorMessages';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from '../Style';
import { BUTTON, JOB } from '../../../constants/behaviors';

import { starBookedIcon, starIcon } from '../../../components/icons';

function ModalHead({ job, closeJobModal, handleSave, setSnack }) {
  const [ispin, setPin] = useState(job.is_pinned);
  const [jobToDelete, setjobToDelete] = useState({
    id: '',
    title: '',
    company_name: '',
  });
  const [loading, setLoading] = useState(false);
  const [curIconFlag, setCurIconFlag] = useState(false);
  useEffect(() => {
    setPin(job.is_pinned);
  }, [job.is_pinned]);

  const classes = useStyles();

  const handleDelete = () => {
    if (job.id) {
      setjobToDelete({
        title: job.title,
        id: job.id,
        company_name: job.company_name ? job.company_name : job.company.name,
      });
    } else {
      closeJobModal();
    }
  };

  const handlePinup = (status) => {
    if (job.id) {
      setPinStatus(job.id, status)
        .then((response) => {
          if (response.status === 200) {
            setPin(!ispin);
          }
        })
        .catch(() => {
          popupError(errorMessages.setPinStatus);
        });
    } else {
      popupError(errorMessages.setpinWithoutJob);
    }
  };

  const PinupBtn = () => {
    if (ispin) {
      return (
        <button
          className="tlm-jobModal-leftdiv-pinup"
          onClick={() => {
            handlePinup(true);
          }}
        >
          <div className="tlm-jobModal-leftdiv-pinup-icon">{unpinIcon}</div>
          Pin Off
        </button>
      );
    } else {
      return (
        <button
          className="tlm-jobModal-leftdiv-pinup"
          onClick={() => {
            handlePinup(false);
          }}
        >
          <div className="tlm-jobModal-leftdiv-pinup-icon">{pinIcon}</div>
          Pin It Up
        </button>
      );
    }
  };

  const onSave = () => {
    handleSave();
  };
  const onBookmarkClick = () => {
    setCurIconFlag(true);
    handlePinup(true);
  };

  return (
    <Container fluid="md">
      <Row className="tlm-jobModal-leftdiv-head">
        {/*<span className="tlm-jobModal-leftdiv-head-bookmark" onClick={onBookmarkClick}>
          {' '}
          {curIconFlag ? starBookedIcon : starIcon} Save
        </span>*/}
        <Col className="tlm-jobModal-leftdiv-Image" xs={1} style={{ paddingLeft: 0 }}>
          <div className="tlm-jobModal-leftdiv-comImg">
            {job.company ? (
              job.company.logo ? (
                <img width="100%" src={job.company.logo} alt="icon" />
              ) : (
                largeCompany
              )
            ) : (
              largeCompany
            )}
          </div>
        </Col>
        <Col xs={12} className="tlm-jobModal-leftdiv-job">
          <div className="tlm-jobModal-leftdiv-jobTitle">{job.title}</div>
          <div className="tlm-jobModal-leftdiv-company">
            {job.company_name ? job.company_name : ''}
          </div>
        </Col>
      </Row>
      <DeleteConfirmationModal
        show={jobToDelete.id.length !== 0}
        deleteField="Job"
        fieldName={jobToDelete.company_name + '-' + jobToDelete.title}
        onCancelFunc={() => {
          setjobToDelete({ id: '', title: '', company_name: '' });
        }}
        onConfirmFunc={() => {
          setjobToDelete({ id: '', title: '', company_name: '' });
          deleteJob(job.id)
            .then((res) => {
              closeJobModal();
              setjobToDelete(emptyJob);
            })
            .catch(() => {
              popupError(errorMessages.deleteJob);
            });
        }}
        onHideFunc={() => {
          setjobToDelete({ id: '', title: '', company_name: '' });
        }}
      />
    </Container>
  );
}

ModalHead.propTypes = {
  job: PropTypes.string,
  closeJobModal: PropTypes.func,
  handleSave: PropTypes.func,
  setSnack: PropTypes.func,
};
export default ModalHead;
