import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';

import '../../../index.scss';

const CopyProfileModal = (props) => {
  const isDuplicate = (name) => {
    return !!props.profiles.titles.find((profile) => profile.title === name);
  };

  const defaultName = `Copy of ${props.profileName}`;
  const [newProfileName, setNewProfileName] = useState(defaultName);
  const [profileNameInvalid, setProfileNameInvalid] = useState(isDuplicate(defaultName));
  const [profileNameEmpty, setProfileNameEmpty] = useState(true);
  const [startEdit, setStartEdit] = useState(false);

  useEffect(() => {
    setNewProfileName(defaultName);
    setProfileNameInvalid(isDuplicate(defaultName));
  }, [defaultName]);

  const isValidProfileName = (name) => {
    setStartEdit(true);
    if (name !== '') {
      setProfileNameEmpty(false);
    }
    if (isDuplicate(name)) {
      setProfileNameInvalid(true);
    } else {
      setProfileNameInvalid(false);
    }
  };

  async function addNewProfile() {
    isValidProfileName(newProfileName);
    if (newProfileName === '') {
      setStartEdit(true);
      setProfileNameEmpty(true);
      return;
    }
    if (!profileNameInvalid) {
      await props.onDuplicateProfile(newProfileName);
      props.setShowDuplicateProfile(false);
    }
  }

  const getHelperText = () => {
    if (startEdit && profileNameEmpty) {
      return "Name can't be empty";
    } else if (profileNameInvalid) {
      return 'Name has been taken';
    } else {
      return '';
    }
  };

  return (
    <Modal
      className="tlm tlm-main-modal"
      show={props.showDuplicateProfile}
      onHide={() => {
        props.setShowDuplicateProfile(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="tlm-main-modal-title">Duplicate profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tlm-main-modal-input-panel">
          <form>
            <TextField
              className="tlm-text-field"
              placeholder={`Copy of ${props.profileName}`}
              variant="outlined"
              value={newProfileName}
              onChange={(e) => {
                setNewProfileName(e.target.value);
                isValidProfileName(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  addNewProfile();
                }
              }}
              error={(startEdit && profileNameEmpty) || profileNameInvalid}
              helperText={getHelperText()}
            />
          </form>
        </div>
        <div className="tlm-main-modal-button-panel">
          <button
            className="tlm-main-modal-button-cancel"
            onClick={() => {
              props.setShowDuplicateProfile(false);
            }}
          >
            Cancel
          </button>
          <button className="tlm-main-modal-button-ok" onClick={addNewProfile}>
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CopyProfileModal.propTypes = {
  setShowDuplicateProfile: PropTypes.func.isRequired,
  profiles: PropTypes.object.isRequired,
  showDuplicateProfile: PropTypes.func.isRequired,
  onDuplicateProfile: PropTypes.func.isRequired,
  profileName: PropTypes.string.isRequired,
};

export default CopyProfileModal;
