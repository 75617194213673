import React, { useState, useEffect, useRef } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { formatDate } from '@utilities/tool';
// components
import { DataGrid } from '@mui/x-data-grid';
import {
  starBookedIcon,
  starIcon,
  pencilIcon,
  companyIcon,
  AlarmIcon,
  DefaultSortIcon,
  pinFilterNormal,
  pinFilterActive,
} from '../../../components/icons';
import LoadingProgress from '../../../components/LoadingProgress';
import StatusFilter from './StatusFilter';

import { Dropdown } from '../../../stories/Dropdown.tsx';
import {
  changeStatus,
  setPinStatus,
  isSpecialUser,
} from '../../../services/DashBoardServices';
import { popupError } from '../../../utilities/error-handler';
import { Mixpanel } from '../../../utilities/mixpanel';

import JobBoardSecondPanel from './JobBoardSecondPanel';
import CheckboxWrapper from '../../../components/Checkbox';

// utillities
import { BUTTON, DASHBOARD } from '../../../constants/behaviors';
import clsx from 'clsx';
import errorMessages from '../errorMessages';
import { DASHBOARDCONSTANTS } from '../DashboardConstants';
import './DashBoardMainContent.scss';

// ----------------------------------------------------------------------
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  NextStepToast,
  RejectedToast,
  CongratsToast,
} from './DashboardDelightfulMomentToasts';

import CommonPagination from '../../../components/CommonPagination/CommonPagination';
import DashboardEmpty from '@images/dashboard/empty.png';
/**
 *
 * @param
 * STATUSTYPE: array of status type
 * setRowSelected: change total row we selected
 * dashboardId: dashboard id
 * refreshPageFlag: refresh the whole page to get updated state
 * setRefreshPageFlag
 * searchText: text state
 * changeLayOutFlag:  true when we click notes row
 * setChangeLayOutFlag: event listener for changeLayOutFlag state
 * loading
 */
const sortMap = {
  Company: 'company',
  'Job Title': 'title',
  'Date Added': 'created_time',
};
export default function DashBoardMainContent({
  STATUSTYPE,
  rowSelected,
  setRowSelected,
  dashboardId,
  setRefreshPageFlag,
  searchText,
  changeLayOutFlag,
  setChangeLayOutFlag,
  originalRows,
  setRows,
  loading,
  data,
  paginationInfo,
  setChangePagination,
  refetch,
  changeQuery,
  defaultProfileId,
  directToProfile,
}) {
  const [filterPinStatus, setFilterPinStatus] = useState(false);
  const [fitlerStatusAry, setFitlerStatusAry] = useState([]);
  const [curJobInfo, setCurJobInfo] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [changedStatus, setChangedStatus] = useState(0);
  const [ctaClicked, setCtaClicked] = useState(false);
  const [notesOpenFlag, setNotesOpenFlag] = useState(false);
  const stylesToastContainer = {
    marginRight: '12px',
    marginBottom: '232px',
    width: '412px',
    height: '220px',
  };

  const handleChangeStatus = (jobId, status) => {
    changeStatus(jobId, status)
      .then((res) => {
        Mixpanel.track('Job Status Changed', {
          title: res.data.title,
          company: res.data.company.name,
          location: res.data.location,
          status: res.data.status,
        });

        setRows((prev) => {
          const prevAry = [...prev];
          return prevAry.map((ele) => {
            const curEle = { ...ele };
            if (jobId === ele.id) {
              curEle.Status = status;
              curEle.status = status;
            }
            return curEle;
          });
        });
      })
      .catch(() => {
        popupError(errorMessages.changeStatus);
      });
  };
  const handleStatusFilter = (e) => {
    setFitlerStatusAry(e);
  };

  const handleChangePinStatus = (jobId, is_pinned) => {
    Mixpanel.track('Mark Job');
    setPinStatus(jobId, is_pinned)
      .then(() => {
        // 点击start 重启请求数据
        refetch();
      })
      .catch(() => {
        popupError(errorMessages.setPinStatus);
      });
  };

  const handelChangeLayout = () => {
    setChangeLayOutFlag(false);
    setColumns(DEFAULTCOLUMNS);
  };

  const DEFAULTCOLUMNS = [
    {
      field: 'Job Pin',
      headerName: 'Job Pin',
      sortable: false,
      width: 42,
      renderHeader: () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [filterPin, setFilterPin] = useState(false);
        const openPinFilter = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const changeFilterPin = (bool) => {
          setFilterPin(bool);
          handleClose();
          // todo 传参数
          setFilterPinStatus(bool);
        };
        return (
          <div className="tlm-pin-header">
            <div onClick={handleClick}>
              {filterPin ? pinFilterActive : pinFilterNormal}
            </div>
            <Menu
              className="pin-menu"
              anchorEl={anchorEl}
              open={openPinFilter}
              onClose={handleClose}
            >
              <MenuItem
                className="pin-menu-item"
                selected={filterPin}
                onClick={() => {
                  changeFilterPin(true);
                }}
              >
                Bookmark Only
              </MenuItem>
              <MenuItem
                className="pin-menu-item"
                selected={!filterPin}
                onClick={() => {
                  changeFilterPin(false);
                }}
              >
                All
              </MenuItem>
            </Menu>
          </div>
        );
      },
      renderCell: (cellValues) => {
        const onIconClick = () => {
          handleChangePinStatus(cellValues.id, cellValues.row.is_pinned);
        };
        return (
          <div className="tlm-company-cell-container">
            <span className="tlm-company-bookmark" onClick={onIconClick}>
              {cellValues.row.is_pinned ? starBookedIcon : starIcon}
            </span>
          </div>
        );
      },
    },
    {
      field: 'Company',
      headerName: 'Company',
      minWidth: 222,
      sort: 'asc',
      renderCell: (cellValues) => {
        const onCellClick = () => {
          // setChangeLayOutFlag(true);
          // // let newColumns = DEFAULTCOLUMNS.slice(1, -1);
          // let newColumns = [...DEFAULTCOLUMNS];
          // newColumns[0]['hide'] = true;
          // // todo 点company 没有隐藏notice
          // newColumns[4]['hide'] = true;
          // newColumns[5]['hide'] = true;
          // setColumns(newColumns);
          // setRowSelected([]);
          // setCurJobInfo(cellValues.row);
          Mixpanel.track('Job Details Viewed', {
            jobTitle: cellValues.row['Job Title'],
            company: cellValues.row.Company,
          });
        };
        return (
          <div className="tlm-company-cell-container">
            <div
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
              }}
              onClick={onCellClick}
            >
              {cellValues.row.company.logo ? (
                <img
                  className="tlm-company-cell-logo"
                  src={cellValues.row.company.logo}
                  alt=" company logo"
                />
              ) : (
                <span className="tlm-company-cell-logo">{companyIcon}</span>
              )}

              {cellValues.row.Company}
            </div>
          </div>
        );
      },
    },
    {
      field: 'Job Title',
      headerName: 'Job Title',
      width: 183,
      sort: 'asc',
    },
    {
      field: 'Status',
      sortable: false,
      headerName: 'Status',
      width: 183,
      renderCell: (cellValues) => {
        const [curStatus, setCurStatus] = useState(
          DASHBOARDCONSTANTS.status[cellValues.row.Status].statusName
        );

        useEffect(() => {
          setCurStatus(DASHBOARDCONSTANTS.status[cellValues.row.Status].statusName);
        }, [cellValues.row.Status]);

        const handleChange = (event) => {
          handleChangeStatus(cellValues.row.id, DASHBOARDCONSTANTS.statusIdxMap[event]);
          if (event === 'Reject') {
            toast(<RejectedToast />);
          } else if (event === 'Offer') {
            toast(<CongratsToast />, { closeOnClick: false });
          } else {
            toast(<NextStepToast />, {
              data: event,
              onToastExpandSecondPanel: onToastExpandSecondPanel,
              cell_row: cellValues.row,
            });
          }
          setCurStatus(event);
          setChangedStatus(DASHBOARDCONSTANTS.statusIdxMap[event]);
          Mixpanel.track('Change Status');
        };

        return (
          <div onClick={() => onCellClick(cellValues)}>
            <Dropdown
              classNames="tlm-dashboard-change-status-dropdown"
              itemsAry={STATUSTYPE}
              value={curStatus}
              // noCheckbox
              onChange={handleChange}
            />
          </div>
        );
      },
      renderHeader: () => (
        <div className="tlm-status-header">
          <span> Status</span>
          <div role="button" aria-label="filter Icon" className="tlm-filter-icon">
            <StatusFilter
              STATUSTYPE={STATUSTYPE}
              handleStatusFilter={handleStatusFilter}
              fitlerStatusAry={fitlerStatusAry}
            />
          </div>
        </div>
      ),
    },
    {
      field: 'Date Added',
      headerName: 'Date Added',
      width: 130,
      sortable: true,
      renderCell: (cellValues) => {
        return <div>{formatDate(cellValues.row.created_time)}</div>;
      },
    },
    {
      field: 'Notes',
      headerName: 'Notes',
      sortable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="tlm-notes-cell-container">
            <span className="tlm-notes-edit-icon"> {pencilIcon}</span>
            <div className="tlm-note-container tlm-ellipsis">
              <span className="tlm-default-note-color tlm-note-ellipsis">
                {cellValues.row.notes.length === 0
                  ? '+ Add notes'
                  : cellValues.row.notes[0].text}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      field: 'Reminder',
      headerName: ' ',
      sortable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="tlm-notes-cell-container">
            <div className="tlm-note-container tlm-ellipsis">
              {cellValues.row.reminder_count > 0 && (
                <div className="tlm-note-reminder">
                  {AlarmIcon}
                  <span className="tlm-reminder-ellipsis">
                    {cellValues.row.reminder_count}{' '}
                    {cellValues.row.reminder_count > 1 ? 'reminders' : 'reminder'}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const [isSpecialUserFlag, setIsSpecialUsersFlag] = useState(false);
  const [filterRows, setFilterRows] = useState([]);
  const [columns, setColumns] = useState(DEFAULTCOLUMNS);
  const [sortModel, setSortModel] = React.useState([]);
  const prevIdRef = useRef('');

  const onCellClick = (cell) => {
    if (cell.field === 'Notes' || cell.field === 'Job Title') {
      Mixpanel.track('Job Details Viewed', {
        jobTitle: cell.row['Job Title'],
        company: cell.row.Company,
      });
    }

    if (cell.field !== '__check__') {
      setChangeLayOutFlag(true);
      let newColumns = [...DEFAULTCOLUMNS];
      newColumns[0]['hide'] = true;
      newColumns[4]['hide'] = true;
      newColumns[5]['hide'] = true;
      setColumns(newColumns);
      setRowSelected([]);
      setCurJobInfo(cell.row);
      setNotesOpenFlag(false); // default false status for notes writing flag

      //remove previous selected row
      if (prevIdRef.current !== '') {
        const prevSelected = document.querySelector(`[data-id='${prevIdRef.current}']`);
        if (prevSelected !== null) {
          prevSelected.classList.remove('tlm-small-layout-hightlight');
        }
      }
      // highlight current row
      const rowSelected = document.querySelector(`[data-id='${cell.row.id}']`);
      rowSelected.classList.add('tlm-small-layout-hightlight');
      prevIdRef.current = cell.row.id;
    }
  };

  const onToastExpandSecondPanel = (cell_row) => {
    setCtaClicked(true);
    setCurJobInfo(cell_row);
    setChangeLayOutFlag(true);
    let newColumns = [...DEFAULTCOLUMNS];
    newColumns[0]['hide'] = true;
    newColumns[4]['hide'] = true;
    newColumns[5]['hide'] = true;
    setColumns(newColumns);
    setRowSelected([]);
    setNotesOpenFlag(true);
    //remove previous selected row
    if (prevIdRef.current !== '') {
      const prevSelected = document.querySelector(`[data-id='${prevIdRef.current}']`);
      if (prevSelected !== null) {
        prevSelected.classList.toggle('tlm-small-layout-hightlight');
      }
    }
    // highlight current row
    const rowSelected = document.querySelector(`[data-id='${cell_row.id}']`);
    rowSelected.classList.toggle('tlm-small-layout-hightlight');
    prevIdRef.current = cell_row.id;
  };

  useEffect(() => {
    // getDashboardData();

    // console.log('query -- params', dashboardId, searchText);
    const params = {
      pagination: {
        obj_per_page: paginationInfo.pageSize, // 每一页的obj的个数
        after: paginationInfo.pageNo, // 从哪一页后开始返回数据
      },
      // filters: {},
    };
    const sort = {};
    if (sortModel.length > 0) {
      sortModel.forEach((item) => {
        sort[sortMap[item.field]] = item.sort;
      });
    }
    params.sort = sort;

    // 增加标星过滤
    if (filterPinStatus) {
      params.filters = { is_pinned: filterPinStatus };
    } else {
      params.filters = {};
    }
    if (fitlerStatusAry.length > 0) {
      params.filters.status = fitlerStatusAry;
    } else {
      delete params.filters.status;
    }
    changeQuery({
      ...params,
    });
  }, [dashboardId, fitlerStatusAry, sortModel, filterPinStatus]);

  useEffect(() => {
    if (changeLayOutFlag) {
      setRowSelected([curJobInfo]);
    } else {
      setRowSelected([]);
    }
  }, [changeLayOutFlag]);

  useEffect(() => {
    if (changeLayOutFlag === false) {
      setSelectedStatus(changedStatus);
    }
  }, [changedStatus]);

  useEffect(() => {
    if (changeLayOutFlag === true && ctaClicked === true) {
      setSelectedStatus(changedStatus);
      setCtaClicked(false);
    }
  }, [ctaClicked]);

  useEffect(() => {
    isSpecialUser().then((res) => {
      setIsSpecialUsersFlag(res.data !== 0);
    });
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-table">
        <div
          className={clsx('tlm-job-board-main-container', {
            'tlm-small-layout': changeLayOutFlag,
          })}
        >
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'Date Added', sort: 'desc' }],
              },
            }}
            rows={data}
            columns={columns}
            checkboxSelection={!changeLayOutFlag}
            disableColumnMenu={true}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[5]}
            pagination
            hideFooterPagination={true}
            autoHeight={false}
            sortingMode="server"
            onSortModelChange={(e) => {
              setSortModel(e);
            }}
            selectionModel={rowSelected}
            components={{
              Checkbox: CheckboxWrapper,
              ColumnUnsortedIcon: DefaultSortIcon,
              LoadingOverlay: LoadingProgress,
            }}
            onSelectionModelChange={(newSelection) => {
              // get row selected.
              setRowSelected(newSelection);
              Mixpanel.track('Select One Job');
              if (rowSelected.length == 0) {
                Mixpanel.track('Select All Job');
              }
            }}
            onCellClick={(e) => onCellClick(e)}
            loading={loading}
          />
        </div>
        <CommonPagination
          totalPages={paginationInfo.totalPages}
          pageNo={paginationInfo.pageNo}
          pageSize={paginationInfo.pageSize}
          changePagination={setChangePagination}
        ></CommonPagination>
        {!loading && data.length === 0 ? (
          <div class="empty-data-container">
            <img src={DashboardEmpty} />
            <div class="empty-text">The jobs you added will be displayed here</div>
          </div>
        ) : null}
      </div>
      {changeLayOutFlag && (
        <JobBoardSecondPanel
          isSpecialUserFlag={isSpecialUserFlag}
          setRows={setRows}
          handelChangeLayout={handelChangeLayout}
          curJobInfo={curJobInfo}
          setRefreshPageFlag={setRefreshPageFlag}
          refetch={refetch}
          handleChangePinStatus={handleChangePinStatus}
          notesOpenFlag={notesOpenFlag}
          selectedStatus={selectedStatus}
          changeLayOutFlag={changeLayOutFlag}
          defaultProfileId={defaultProfileId}
          directToProfile={directToProfile}
        />
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={6000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={true}
        style={stylesToastContainer}
      />
    </div>
  );
}
