import React, { useState, useEffect } from 'react';
import './JobBoardDescriptionTab.scss';
import { Mixpanel } from '../../../utilities/mixpanel';
export default function JobBoardDescriptionTab({
  description,
  onJobDescriptionChange,
  jobTitle,
}) {
  const handleChange = async (e) => {
    if (!e.target.textContent.trim().length) {
      e.target.innerHTML = '';
    }

    onJobDescriptionChange(e.target.innerHTML);
  };

  useEffect(() => {
    Mixpanel.track('Click Description Tab', {
      jobTitle: jobTitle,
    });
  });

  return (
    <div className="tlm-job-description-container">
      <div
        className="tlm-job-description-text"
        placeholder="+ Add job description"
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={handleChange}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
}
