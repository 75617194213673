import React from 'react';
import './JobBoardNewGradHeader.scss';
import { Box, Typography } from '@mui/material';
import { Tag } from '../../../stories/Tag';
import { SearchBar } from '../../../stories/SearchBar.tsx';
import moment from 'moment';
import { Mixpanel } from '../../../utilities/mixpanel';
export default function JobBoardNewGradHeader({
  numberOfNew,
  handleSearch,
  handleCancel,
  searchText,
  headerText,
}) {
  const handleChange = (e) => {
    handleSearch(e.target.value);
    Mixpanel.track('Search in Job Board');
  };

  return (
    <div className="tlm-ng-jobBoard-header">
      <div className="tlm-ng-jobBoard-header_top">
        <Box display="flex" alignItems="center">
          <Typography variant="h1">{headerText}</Typography>{' '}
          <Typography variant="h1" style={{ marginRight: '8px' }}>
            · Job Board{' '}
          </Typography>
          <Tag
            label={`${numberOfNew} New`}
            themeColor="c-attention-orange"
            className="tlm-number-of-new"
          />
          <Typography
            variant="body1"
            component="span"
            className="tlm-ng-jobBoard-header_date"
          >
            Updated {moment().format('MMM Do YY')}
          </Typography>
        </Box>
        <SearchBar
          placeholder="Enter Keywords Here"
          searchText={searchText}
          handleCancel={handleCancel}
          handleChange={handleChange}
        />
      </div>
      <Typography
        variant="body1"
        component="span"
        className="tlm-ng-jobBoard-header_subtext"
      >
        The easiest way to find {headerText} opportunities in one place.
      </Typography>
    </div>
  );
}
