import React, { useState, useRef, useEffect } from 'react';
import { Stack, Button, Tooltip } from '@mui/material';
import { LinkedInIconWhite, IndeedIconWhite } from '@components/icons';
import './style/index.scss';
import LoadingProgress from '@components/LoadingProgress';
import StatisticsCard from './components/statistics-card';
import TaskCard from './components/task-card';
import VideoModal from './components/video-modal';
import {
  TaskList,
  AutoFillLinkUrl,
  AutoFillTrackInfo,
  AutoTrackTask,
  ReferenceVideo,
} from './constant';
import { useHomeData } from '@services/Home';
import { Mixpanel } from '@utilities/mixpanel';

export const Home = ({
  defaultDashboardId = '',
  defaultProfileId = '',
  goDashboard,
  goProfile,
  goJobBoard,
}) => {
  const {
    userName,
    statisticsData,
    tasks,
    tasksStatus,
    taskList,
    isLoading,
  } = useHomeData(TaskList);
  const taskView = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoInfo, setVideoInfo] = useState({});

  // 判断任务是否开启，如果有前置任务，需所有前置任务都完成，才开启
  const judgeReady = (preTaskIds = []) => {
    if (preTaskIds.length === 0) return true;
    return preTaskIds.every((taskId) => tasks[taskId]);
  };

  const handleCompleted = (redirectUrl, trackInfo, taskId) => {
    // console.log('track', 'Onboarding: ' + trackInfo);
    Mixpanel.track('Onboarding: ' + trackInfo);
    if (taskId === 'create_profile') {
      if (goProfile) {
        goProfile(defaultProfileId);
      }
    } else if (taskId === 'view_jobboard') {
      if (goJobBoard) {
        goJobBoard(defaultDashboardId);
      }
    } else if (taskId === 'move_to_applied') {
      if (goDashboard) {
        goDashboard(defaultDashboardId);
      }
    } else {
      window.open(redirectUrl, taskId === 'auto_track' ? '_blank' : '_self');
    }
  };

  const handleShowVideo = (taskId, enable = false) => {
    if (ReferenceVideo[taskId]) {
      // console.log('track', 'Onboarding: ' + ReferenceVideo[taskId].trackInfo);
      Mixpanel.track('Onboarding: ' + ReferenceVideo[taskId].trackInfo);
      setVideoInfo({
        ...ReferenceVideo[taskId],
        disabled: enable,
        type: taskId,
      });
      setShowVideo(true);
    }
  };

  const buildChildren = (item, enable = false) => {
    const { taskId } = item;

    // todo 播放视频
    // if ((taskId === 'auto_fill' || taskId === 'auto_track') && tasks[taskId]) {
    if (taskId === 'auto_fill' && tasks[taskId]) {
      return (
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            variant="outlined"
            size="small"
            className="bold"
            sx={{ width: 105 }}
            onClick={() => handleCompleted(item.linkUrl, item.trackInfo, taskId)}
          >
            Try again
          </Button>
          <Stack className="watch-video" onClick={() => handleShowVideo(taskId)}>
            Watch video to review
          </Stack>
        </Stack>
      );
    }
    if (taskId === 'auto_track' && !enable) {
      return (
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip
            arrow
            placement="top-start"
            PopperProps={{
              className: 'light-tooltip-container',
            }}
            title={
              <Stack
                sx={{ padding: '16px 12px', width: 200 }}
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={1}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    handleCompleted(
                      AutoTrackTask.linkedIn.linkUrl,
                      AutoTrackTask.linkedIn.trackInfo,
                      taskId
                    )
                  }
                >
                  <strong>{AutoTrackTask.linkedIn.name}</strong>
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    handleCompleted(
                      AutoTrackTask.indeed.linkUrl,
                      AutoTrackTask.indeed.trackInfo,
                      taskId
                    )
                  }
                >
                  <strong>{AutoTrackTask.indeed.name}</strong>
                </Button>
              </Stack>
            }
          >
            {tasks[taskId] ? (
              <Button
                variant="outlined"
                size="small"
                className="bold"
                sx={{ width: 105 }}
              >
                Try again
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{ width: '100%' }}
                className="bold button-disabled-grey"
              >
                Go to Complete
              </Button>
            )}
          </Tooltip>
          {tasks[taskId] && (
            <Stack className="watch-video" onClick={() => handleShowVideo(taskId)}>
              Watch video to review
            </Stack>
          )}
        </Stack>
      );
    }
    if (taskId === 'download_extension' && tasks[taskId]) {
      return (
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Stack className="bold finished-text 22">{item.finishText}</Stack>
          <Stack
            className="watch-video"
            onClick={() => handleCompleted(item.linkUrl, item.trackInfo, item.taskId)}
          >
            Download again
          </Stack>
        </Stack>
      );
    }
    return null;
  };

  useEffect(() => {
    // 获取当前第一个未完成任务的索引
    if (tasksStatus && tasksStatus.length > 0) {
      const nextTaskIndex = tasksStatus.findIndex((v) => !v);
      // 如果索引存在，则滚动到任务位置；如果索引不存在，则默认滚动到auto fill 任务
      taskView.current.scrollTop = 150 * (nextTaskIndex > -1 ? nextTaskIndex : 2);
    }
  }, [tasksStatus]);

  return (
    <>
      {isLoading ? (
        <LoadingProgress />
      ) : (
        <div className="home-layout">
          <Stack className="home-title">Welcome, {userName}.</Stack>
          <Stack className="home-statistics-container" direction="row" spacing={6}>
            <StatisticsCard
              name="Wishlist"
              count={statisticsData.wishlist}
            ></StatisticsCard>
            <StatisticsCard
              name="Total Applied"
              count={statisticsData.total_applied}
            ></StatisticsCard>
            <StatisticsCard
              name="Interview in Progress"
              count={statisticsData.interview_in_progress}
            ></StatisticsCard>
          </Stack>
          <Stack className="home-category-title">Getting Started</Stack>
          <Stack className="home-category-description">
            Completed Task {tasksStatus.filter((v) => v).length}/{tasksStatus.length}
          </Stack>
          <Stack className="home-content">
            <Stack sx={{ height: 'auto', overflow: 'auto' }} ref={taskView}>
              <Stack className="task-list">
                {taskList.map((item, index) => {
                  const enable = !judgeReady(item.preTaskId);
                  return (
                    <TaskCard
                      taskIndex={index + 1}
                      taskId={item.taskId}
                      taskName={item.taskName}
                      taskDetail={item.taskDetail}
                      taskCover={item.taskCover}
                      finishText={item.finishText}
                      status={tasks[item.taskId]}
                      disabled={enable}
                      preTaskText={item.preTaskText}
                      handleShowVideo={() => handleShowVideo(item.taskId, enable)}
                      handleCompleted={() =>
                        handleCompleted(item.linkUrl, item.trackInfo, item.taskId)
                      }
                    >
                      {buildChildren(item, enable)}
                    </TaskCard>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
          <VideoModal
            open={showVideo}
            title={videoInfo.title}
            playerUrl={videoInfo.playerUrl}
            handleClose={() => setShowVideo(false)}
          >
            {videoInfo.type === 'auto_fill' ? (
              <Button
                sx={{ width: 150 }}
                variant="contained"
                className="bold button-disabled-grey"
                disabled={videoInfo.disabled}
                onClick={() =>
                  handleCompleted(AutoFillLinkUrl, AutoFillTrackInfo, videoInfo.type)
                }
              >
                Try Auto-fill
              </Button>
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Button
                  variant="contained"
                  className="bold button-disabled-grey"
                  startIcon={<LinkedInIconWhite />}
                  disabled={videoInfo.disabled}
                  onClick={() =>
                    handleCompleted(
                      AutoTrackTask.linkedIn.linkUrl,
                      AutoTrackTask.linkedIn.trackInfo,
                      videoInfo.type
                    )
                  }
                >
                  {AutoTrackTask.linkedIn.videoButton}
                </Button>
                <Button
                  variant="contained"
                  className="bold button-disabled-grey"
                  startIcon={<IndeedIconWhite />}
                  disabled={videoInfo.disabled}
                  onClick={() =>
                    handleCompleted(
                      AutoTrackTask.indeed.linkUrl,
                      AutoTrackTask.indeed.trackInfo,
                      videoInfo.type
                    )
                  }
                >
                  {AutoTrackTask.indeed.videoButton}
                </Button>
              </Stack>
            )}
          </VideoModal>
        </div>
      )}
    </>
  );
};

export default Home;
