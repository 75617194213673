import React from 'react';
import { Stack } from '@mui/material';
import './index.scss';
import clsx from 'clsx';

const InfoItem = ({ className, icon, label = '', value = '' }) => {
  return (
    <Stack
      className={clsx('info-item', className)}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Stack className="info-item-icon" direction={'column'}>
        {icon}
      </Stack>
      <Stack className="info-item-text" direction={'column'}>
        <Stack className="info-item-label">{label}</Stack>
        <Stack className="info-item-value">
          {value !== null && value !== '' ? `${value}` : '--'}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InfoItem;
