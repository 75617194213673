import React from 'react';
import { Stack } from '@mui/material';
import { editIcon, primaryDeleteIcon } from '@components/icons';
import clsx from 'clsx';
import './index.scss';

const CardBox = ({ className, title, hasTitle = true, children, onEdit, onDelete }) => {
  const handleButton = (className) => {
    return (
      <Stack className={className} direction={'row'} spacing={2}>
        <Stack
          className="svg-icon-button"
          onClick={(event) => {
            event.stopPropagation();
            onEdit();
          }}
        >
          {editIcon}
        </Stack>
        <Stack
          className="svg-icon-button"
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
        >
          {primaryDeleteIcon}
        </Stack>
      </Stack>
    );
  };
  return (
    <Stack
      className={clsx('card-box', className)}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      {hasTitle ? (
        <Stack
          className="card-box-header"
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack className="card-box-title">{title}</Stack>
          {handleButton()}
        </Stack>
      ) : (
        handleButton('card-box-handler')
      )}
      <Stack
        className="card-box-content"
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default CardBox;
