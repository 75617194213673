export const converPageInfo = (data = {}) => {
  if (!data) {
    return {
      pageNo: 0,
      totalPages: 0,
    };
  }
  return {
    pageNo: data.after,
    pageSize: data.obj_per_page,
    totalPages: data.total_page,
    ...data,
  };
};
