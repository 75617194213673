import React from 'react';
import './index.scss';
import mobileViewPic from '../../images/mobileViewPic.svg';

const MobileView = () => {
  return (
    <div className="mobile-container">
      <img className="mobile-image" src={mobileViewPic} />
      <div className="mobile-textArea">
        For best experiences, please open the link www.offerland.ai on a{' '}
        <span className="mobile-textArea-strength">laptop</span> or{' '}
        <span className="mobile-textArea-strength">desktop computer</span>
      </div>
    </div>
  );
};

export default MobileView;
