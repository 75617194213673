import React from 'react';
import { Button } from '../../stories/Button';
import '../../styles/typography.scss';
import './index.scss';

export const SuccessPage = (props) => {
  return (
    <div className="success-page-container">
      <div className="page-title-24 p-4">Congratulations! You are a super user now!</div>
      <Button
        onClick={() => {
          props.history.push('/');
        }}
      >
        Back to Homepage
      </Button>
    </div>
  );
};
