import React from 'react';
import { Stack } from '@mui/material';
import './index.scss';

const StatisticsCard = ({ name = '', count = '' }) => {
  return (
    <Stack
      className="statistics-card"
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Stack className="statistics-num">{count || '- -'}</Stack>
      <Stack className="statistics-title">{name}</Stack>
    </Stack>
  );
};

export default StatisticsCard;
