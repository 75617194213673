import React from 'react';
import { notFoundIcon } from './icons';
import '../index.scss';
import '../../../styles/typography.scss';

export function NotFoundComponent() {
  return (
    <div className="library-noResult">
      <div className="library-noResult-header">
        {notFoundIcon}
        <span className="library-noResult-header-text page-title-28">Oops!</span>
      </div>
      <div className="library-noResult-text body-text-16">
        We are sorry, but there is no results for your search.{' '}
      </div>
      <div className="header-16">Try a new search</div>
    </div>
  );
}
