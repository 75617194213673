import React, { useState } from 'react';
import { rightArrow } from '../../../components/icons';
import './SecurityItem.scss';
import Button from '@mui/material/Button';
import { toChangeAuthoCredentials } from '../../../services/ProfileServices';
import { getDefaultDashboardData } from '../../../services/DashBoardServices';

const SecurityItem = (props) => {
  const [isContentEnable, setContentEnable] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isActive, setActiveStatus] = useState(false);
  const [togetInput, setGetInput] = useState('');

  const handleChangeContent = () => {
    setContentEnable(true);
  };

  const handleCancelButton = () => {
    setContentEnable(false);
    setActiveStatus(false);
  };

  const handleGetInputs = (e) => {
    setGetInput(e.target.value);
  };

  const checkValidate = (val, field_type) => {
    let validate_text;
    if (field_type === 'email') {
      if (val === '') {
        validate_text = 'Email is required';
      } else {
        var filter =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!filter.test(val)) {
          validate_text = 'Email is invalid';
        }
      }
    } else {
      if (val === '') {
        validate_text = 'Password is required';
      } else {
        var filterpassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!filterpassword.test(val)) {
          validate_text =
            'Password must be at least at 8 characters, one number, upper case and lower case letter, and special character';
        }
      }
    }
    return validate_text;
  };

  const handleSubmitButton = () => {
    let validate = checkValidate(togetInput, props.type.toLowerCase());
    if (validate === undefined) {
      setActiveStatus(false);
      const updateUserProfileObj = {
        change_value: togetInput.trim(),
        change_type: props.type,
        user_id: props.isUserID,
      };
      toChangeAuthoCredentials(updateUserProfileObj)
        .then((res) => {
          getDefaultDashboardData().then((result) => {
            props.userUpdateEmail(result?.data[0]?.user?.email);
          });
          setContentEnable(false);
          props.enableToast(res);
        })
        .catch((error) => {
          props.enableToast(error.response);
        });
    } else {
      setActiveStatus(true);
      setErrorMessage(validate);
    }
  };

  return (
    <div className="tlm-security-item">
      <div
        className="tlm-security-item-left"
        style={{ display: isContentEnable ? 'none' : 'block' }}
      >
        <div className="tlm-security-item-left-type">{props.type}</div>
        {props.content && (
          <div className="tlm-security-item-left-content">{props.content}</div>
        )}
      </div>
      {/* Change user email function
      Todo: enable user to change user email after switch to Auth0 */}
      <div
        className="tlm-security-item-left"
        style={{ display: isContentEnable ? 'block' : 'none' }}
      >
        <div className="tlm-security-item-left-type">{props.type}</div>
        <div className="tlm-security-item-left-content">
          <div style={{ display: 'flex' }}>
            <input
              type={props.type.toLowerCase()}
              className="form-control"
              id="get_input"
              onChange={handleGetInputs}
              required
            />
            <Button
              style={{ background: '#05756C', marginLeft: '2px', color: '#fff' }}
              onClick={handleSubmitButton}
            >
              Submit
            </Button>
            <Button
              style={{ background: '#6e7171', marginLeft: '2px', color: '#fff' }}
              onClick={handleCancelButton}
            >
              Cancel
            </Button>
          </div>
          {isActive && <span style={{ color: 'red' }}>{errorMessage}</span>}
        </div>
      </div>
      {props.checkLoginMethod === 'auth0' && (
        <div
          style={{ display: isContentEnable ? 'none' : 'block' }}
          className="tlm-security-item-right"
          onClick={handleChangeContent}
        >
          Change {rightArrow}
        </div>
      )}
    </div>
  );
};

export default SecurityItem;
