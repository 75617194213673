import React from 'react';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import GuidanceTaskCard from './GuidanceTaskCard';
import arrow from '../../../images/guide-card-arrow.svg';
import { Link } from '../../../stories/Link';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  card: {
    padding: '12px 0',
    borderBottom: '1px solid #EEEEEE',
  },
  cardContent: {
    padding: '20px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: '0 15px 0 0',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '28px',
  },
  counter: {
    fontSize: 18,
    lineHeight: '24px',
    marginLeft: 'auto',
    color: '#05756C',
  },
}));

export default function GuidanceStepCard(props) {
  const [showSubTasks, setShowSubTasks] = useState(false);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setTotalTasks(props.step.subtasks.length);
    let complete = 0;
    props.step.subtasks.forEach((task) => {
      complete += task.is_checked ? 1 : 0;
    });
    setCompletedTasks(complete);
  }, []);

  const handleClick = () => {
    setShowSubTasks(!showSubTasks);
  };

  return (
    <div className={classes.card}>
      <div className={classes.cardContent} onClick={handleClick}>
        <img className={classes.button} src={arrow} />
        <div className={classes.title}>{props.step.title}</div>
        <div className={classes.counter}>
          {completedTasks}/{totalTasks} tasks completed
        </div>
      </div>
      {showSubTasks &&
        props.step.subtasks.map((subtask) => {
          return (
            <GuidanceTaskCard
              key={subtask.id}
              task={subtask}
              onCheckChange={(checked) => {
                setCompletedTasks(checked ? completedTasks + 1 : completedTasks - 1);
              }}
            />
          );
        })}
    </div>
  );
}
