import React from 'react';
import { Stack, Modal, Button } from '@mui/material';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
// import { Mixpanel } from '@utilities/mixpanel';
import './index.scss';

const VideoModal = ({
  open = false,
  title = '',
  playerUrl = 'https://youtu.be/aUdgR32uvfE',
  // buttonText = 'Try Auto-track',
  // href = '',
  handleClose,
  children,
}) => {
  // const handleSure = () => {
  //   window.open(href);
  //   Mixpanel.track('onboarding: Click Indeed');
  // };
  return (
    <Modal open={open}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
        className="modal-video"
      >
        <Stack className="player-header">
          <Stack className="modal-title">{title}</Stack>
          <CloseIcon className="modal-close" onClick={handleClose}></CloseIcon>
        </Stack>
        <Stack className="modal-player">
          <ReactPlayer playing={true} url={playerUrl} />
        </Stack>
        <Stack className="modal-button">
          {children && children}
          {/* <Button sx={{ width: 150 }} variant="contained" onClick={handleSure}>
            <strong>{buttonText}</strong>
          </Button> */}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default VideoModal;
