import { useState } from 'react';

/**
 * initFormData    表单初始化的数据项
 * updateForm      更新表单
 * formValidator   表单的校验结果，{key:msg } key为字段的键，msg为当前的错误信息
 * changeFormValid 更新表单的校验结果
 * */
export function useFormMode(initFormData, updateForm, formValidator, changeFormValid) {
  const [formData, setFormData] = useState({ ...initFormData });
  const updateInfo = (key, val, validFlag, invalidMsg) => {
    // 如果是true，则证明检验通过 在表单校验结果中删除
    if (validFlag) {
      delete formValidator[key];
    } else {
      formValidator[key] = invalidMsg || 'This field should not be empty.';
    }

    // 如果新值没有改变，则不更新
    if (val !== formData[key]) {
      formData[key] = val;
      setFormData(formData);
      if (updateForm) {
        updateForm(formData);
      }
    }
    if (changeFormValid) {
      changeFormValid({ ...formValidator });
    }
  };

  return {
    formData,
    setFormData,
    formValidator,
    updateInfo,
  };
}

export function useValidTwoDate() {
  const [validDate, setValidDate] = useState(true);

  const validTwoDate = (startDate, endDate) => {
    if (startDate && endDate) {
      const flag = new Date(startDate).getTime() <= new Date(endDate).getTime();
      setValidDate(flag);
      return flag;
    } else {
      setValidDate(true);
      return true;
    }
  };
  return {
    validDate,
    validTwoDate,
    invalidStartMsg: 'Must be earlier than the end date',
    invalidEndMsg: 'Must be later than the start date',
  };
}
