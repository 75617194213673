import React from 'react';
import Congrats from '../../../../images/congrats.svg';
import OnboardingDownloadExtension from '../../../../images/onboardingDownloadExtension.svg';
import { Button } from '../../../../stories/Button';
import { OB_STEP } from '../../../../constants/onboarding';
import { Mixpanel } from '../../../../utilities/mixpanel';
import '../../../../styles/typography.scss';
import PropTypes from 'prop-types';

export const DownloadExtension = (props) => {
  const DownloadPage = (
    <div className="tlm-onboard-card p-3">
      <p className="m-2 body-text-16">
        Our Chrome extension helps you Auto-track and Auto-fill
      </p>
      <div className="v-center p-3">
        <img src={OnboardingDownloadExtension} alt="OnboardingDownloadExtension" />
      </div>
      {!props.complete && (
        <div className="v-center m-2">
          <Button
            height="39px"
            width="267px"
            onClick={() => {
              Mixpanel.track('onboarding: Download Plugin');
              window.open(
                'https://chrome.google.com/webstore/detail/offerland/cgneenbonfipejfincfoeljokamigfae',
                '_self'
              );
              // props.setStep(OB_STEP.DOWNLOAD_EXTENSION_COMPLETE);
            }}
          >
            Download to Start
          </Button>
        </div>
      )}
      {!props.complete && (
        <div className="v-center m-2">
          <Button
            height="39px"
            width="267px"
            isSecondary="true"
            onClick={() => {
              Mixpanel.track('onboarding: Already Download Plugin');
              props.setStep(OB_STEP.DOWNLOAD_EXTENSION_COMPLETE);
            }}
            disableRipple
          >
            I've Already Downloaded
          </Button>
        </div>
      )}
    </div>
  );

  return DownloadPage;
};

export default DownloadExtension;
