import React from 'react';
import CardBox from './card-box/index.jsx';
import { Stack, InputBase } from '@mui/material';
import { formatDate } from '@utilities/tool';

import '../style/index.scss';

const ExperienceBox = ({ info = {}, onEdit, onDelete }) => {
  const title = [info.title, info.company_name].join(' | ');
  return (
    <CardBox className="experience-box" title={title} onEdit={onEdit} onDelete={onDelete}>
      <Stack className="experience-sub-title" direction={'row'} spacing={2}>
        <Stack className="experience-sub-title-main">
          {formatDate(info.start_date)}
          {info.current_work_status ? ' - Present' : ` - ${formatDate(info.end_date)}`}
        </Stack>
        <Stack className="experience-sub-title-help">
          {info.company_name}
          {info.location ? ' , ' + info.location : ''}
        </Stack>
      </Stack>
      <Stack>
        <InputBase
          className="box-description"
          readOnly={true}
          multiline={true}
          value={info.description}
        />
      </Stack>
    </CardBox>
  );
};

export default ExperienceBox;
