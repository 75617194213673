import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import BoxHeader from './box-header';
import BoxEmpty from './box-empty';
import './index.scss';

const BlockBox = ({
  title = '',
  onlyTitle = false,
  isEditIcon = false,
  isEmpty = false,
  disabled = false,
  emptyInfoText = '',
  emptyHandleText = '',
  emptyImgSrc = '',
  handleClick,
  children,
}) => {
  return (
    <Stack className="block-box" direction="column">
      <BoxHeader
        title={title}
        disabled={disabled}
        isEditIcon={isEditIcon}
        onlyTitle={onlyTitle}
        handleClick={handleClick}
      ></BoxHeader>
      <Stack className="block-content">
        {children}
        {isEmpty && (
          <BoxEmpty
            title={title}
            emptyInfoText={emptyInfoText}
            emptyHandleText={emptyHandleText}
            emptyImgSrc={emptyImgSrc}
            handleClick={handleClick}
          ></BoxEmpty>
        )}
      </Stack>
    </Stack>
  );
};

export default BlockBox;
