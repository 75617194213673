import React, { useState } from 'react';
import './checkbox.scss';
import '../styles/typography.scss';

export interface CheckboxProps {
  label?: string;
  checked: boolean;
  isDisabled?: boolean;
  onChange: (e: any) => any;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label = '',
  checked,
  isDisabled = false,
  onChange,
  ...props
}) => {
  const onInputClick = (e: any) => {
    onChange(label);
  };
  return (
    <label
      className={
        isDisabled
          ? 'tlm-checkbox-story-container tlm-checkbox-story-container-disabled'
          : 'tlm-checkbox-story-container'
      }
    >
      <span className={label && 'checkbox-text body-text-14'}>{label}</span>
      <input
        type="checkbox"
        disabled={isDisabled}
        checked={checked}
        onChange={onInputClick}
      />
      <span className={isDisabled ? 'checkbox-disabled' : 'checkbox'}></span>
    </label>
  );
};
