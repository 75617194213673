// utillities
import React, { useState, useCallback, useEffect } from 'react';
import { Stack, Button } from '@mui/material';
import { DASHBOARDCONSTANTS } from '../DashboardConstants';
import { changeMultipleStatus } from '../../../services/DashBoardServices';
import { Mixpanel } from '../../../utilities/mixpanel';
import _ from 'lodash';

// components
import { SearchBar } from '../../../stories/SearchBar';
import LoadingProgress from '../../../components/LoadingProgress';
import Modal from 'react-modal';
import JobModal from '../../jobinfo-view';
// import { deleteIcon as DeleteIcon } from '../../../components/icons';
import { Button as ButtonStories } from '../../../stories/Button.tsx';
// import { Dropdown } from '../../../stories/Dropdown.tsx';
// import { DropdownArray } from '../../../stories/Dropdown-new.tsx';
// import { BUTTON, DASHBOARD } from '../../../constants/behaviors';
import { popupError } from '../../../utilities/error-handler';
import { deleteJobs, updateDashboard } from '../../../services/DashBoardServices';
import errorMessages from '../errorMessages';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { BatchUpdateToast } from './DashboardDelightfulMomentToasts';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import CommonDropdown from '@components/CommonDropdown';
import CommonSnackbar from '@components/CommonSnackbar';
import UpgradePlanModal from '../../../components/CommonDialog/UpgradePlanModal';
import DeleteIcon from '@mui/icons-material/Delete';

import { useJobsBulkMove } from '@services/Dashboard';
// style
import './JobBoardHeader.scss';
/**
 *
 * @param
 * status: Status Dropdown displayed value
 * setStatus: Change Status Dropdown displayed value
 * STATUSTYPE: Pass to status dropdown
 * rowSelected: used to check if button is disabled or not
 * dashboardId: current dashboard id
 * handleSearch search bar on change function
 * changeLayOutFlag: keep track whether we are in small layout or not
 * @returns  None
 */

export default function JobBoardMainHeader({
  setMainPageData,
  jobBoardInfo,
  STATUSTYPE,
  rowSelected,
  dashboardId,
  dashboardList, // 排除当前dashboard的list
  setRowSelected,
  handleSearch,
  changeLayOutFlag,
  setRows,
  handleCancel,
  searchText,
  refetch, // 基于当前条件刷新数据
  reloadDataByDefaultPageNo, // 基于当前条件，回到第一页，刷新数据
  goDashboard,
}) {
  const [isJobModalOpen, setJobModal] = useState(false);
  const [jobId, setJobId] = useState(0);
  const [dropdownStatus, setDropdownStatus] = useState('');
  const [jobBoardName, setJobBoardName] = useState('');
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // job move 消息提示
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarInfo, setSnackBarInfo] = useState({});
  // PlanUpgradeModal
  const [isPlanUpgradeModal, setPlanUpgradeModal] = useState(false);

  const { mutateAsync: onJobsBulkMove } = useJobsBulkMove();

  const handleBatchStatusChange = (event) => {
    Mixpanel.track('Change Jobs Status', { status: event });
    changeMultipleStatus(rowSelected, DASHBOARDCONSTANTS.statusIdxMap[event])
      .then(() => {
        // change job status
        // setRows((prev) => {
        //   const prevAry = [...prev];
        //   return prevAry.map((ele) => {
        //     const curEle = { ...ele };
        //     if (rowSelected.includes(ele.id)) {
        //       curEle.Status = DASHBOARDCONSTANTS.statusIdxMap[event];
        //     }
        //     return curEle;
        //   });
        // });
        // to test setRows 数据刷新
        refetch();
        setRowSelected([]);
        toast(<BatchUpdateToast />);
      })
      .catch(() => {
        popupError(errorMessages.changeStatus);
      });
  };
  const handleBatchDeleteJobs = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleBatchMoveJobs = async (dashboard_id) => {
    // console.log('rowSelected', dashboard_id, rowSelected);
    const res = await onJobsBulkMove({
      dashboard_id: dashboard_id,
      jobs_id: rowSelected,
    });
    if (res.status === 204) {
      setRowSelected([]);
      setSnackBarOpen(true);
      setSnackBarInfo({
        type: 'success',
        count: rowSelected.length,
        targetDashboard: dashboardList.find((item) => item.id === dashboard_id),
      });
      goDashboard(dashboard_id);
    } else {
      setSnackBarOpen(true);
      setSnackBarInfo({
        type: 'error',
        message: res.data,
      });
    }
  };

  const handleDeleteConfirm = () => {
    deleteJobs(rowSelected)
      .then(() => {
        setRowSelected([]);
        // to test setRows 数据刷新
        refetch();
        Mixpanel.track('Remove Job', {
          jobId: rowSelected,
        });
        if (rowSelected.length == 1) {
          Mixpanel.track('Remove Job', {
            deleteType: 'Single',
          });
        }
        if (rowSelected.length > 1) {
          Mixpanel.track('Remove Job', {
            deleteType: 'Multiple',
          });
        }
      })
      .catch(() => {
        popupError(errorMessages.deleteJobs);
      });
    setShowDeleteModal(false);
  };

  const handleCreateJob = () => {
    // Mixpanel.track('New Job Clicked');
    Mixpanel.track('Add New Job');
    openJobModal('new job');
  };

  const openJobModal = (jobId) => {
    setJobId(jobId);
    setJobModal(true);
  };

  const closeJobModal = (isReload = false) => {
    if (isReload) {
      reloadDataByDefaultPageNo();
    }
    setJobModal(false);
  };

  const updateJobBoardName = (name, currentId) => {
    updateDashboard(currentId, name)
      .then(() => {
        setMainPageData((prev) => {
          const newDashboards = [...prev.dashboards];

          for (let ele of newDashboards) {
            if (ele.id === currentId) {
              ele.name = name;
            }
          }

          return { ...prev, dashboards: newDashboards };
        });
      })
      .catch(() => {
        popupError(errorMessages.updateDashboardName);
      });
  };
  const debounceNameFn = useCallback(_.debounce(updateJobBoardName, 1000), []);
  const onJobBoardNameChange = (name) => {
    setJobBoardName(name);
    debounceNameFn(name, dashboardId);
  };

  useEffect(() => {
    setJobBoardName(jobBoardInfo.name);
  }, [dashboardId]);
  useEffect(() => {
    if (Object.keys(jobBoardInfo).length !== 0) {
      setLoading(false);
      setJobBoardName(jobBoardInfo.name);
    }
  }, [jobBoardInfo]);

  return (
    <div className="tlm-header-container">
      <CommonSnackbar
        snackBarOpen={snackBarOpen}
        setSnackBarOpen={setSnackBarOpen}
        type={snackBarInfo.type}
      >
        <Stack direction="row">
          {snackBarInfo.type === 'success' ? (
            <>
              <strong style={{ paddingRight: '4px' }}>{snackBarInfo.count} jobs</strong>
              have been successfully moved to
              <strong style={{ paddingLeft: '4px' }}>
                {snackBarInfo.targetDashboard.name}
              </strong>
            </>
          ) : (
            <>{snackBarInfo.message}</>
          )}
        </Stack>
      </CommonSnackbar>
      <div className="tlm-main-header-container">
        {loading ? (
          <LoadingProgress />
        ) : (
          <input
            className="tlm-main-header-name"
            type="text"
            name="name"
            value={jobBoardName}
            placeholder="+ Add Job Name"
            onChange={(e) => onJobBoardNameChange(e.target.value)}
          />
        )}
        <SearchBar
          searchText={searchText}
          placeholder="Search"
          handleCancel={handleCancel}
          handleChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="tlm-second-header-container">
        <Stack direction="row" spacing={2}>
          {/* <Dropdown
            classNames="tlm-change-status-dropdown"
            itemsAry={STATUSTYPE}
            noCheckbox
            value={dropdownStatus}
            isDisabled={rowSelected.length === 0 || changeLayOutFlag}
            label="Change Status"
            onChange={handleBatchStatusChange}
          /> */}
          <CommonDropdown
            items={STATUSTYPE}
            noCheckbox
            value={dropdownStatus}
            isDisabled={rowSelected.length === 0 || changeLayOutFlag}
            label="Change Status"
            onChange={handleBatchStatusChange}
          ></CommonDropdown>

          <CommonDropdown
            items={dashboardList}
            emptyText="There is only one Dashboard created in your account."
            label="Move to"
            hasButton={true}
            isDisabled={rowSelected.length === 0 || changeLayOutFlag}
            onChange={handleBatchMoveJobs}
          ></CommonDropdown>

          <Button
            disabled={rowSelected.length === 0 || changeLayOutFlag}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={handleBatchDeleteJobs}
          >
            Remove
          </Button>
          {/* <ButtonStories
            icon={DeleteIcon}
            isDisabled={}
            className="tlm-btn-remove"
            onClick={handleBatchDeleteJobs}
          >
            Remove
          </ButtonStories> */}
        </Stack>

        <ButtonStories className="tlm-btn-add-new" onClick={handleCreateJob}>
          + Add New
        </ButtonStories>
      </div>
      <Modal
        isOpen={isJobModalOpen}
        ariaHideApp={false}
        className="tlm-jobModal-container"
      >
        <JobModal
          jobId={`${jobId}`}
          closeJobModal={closeJobModal}
          tabId={0}
          dashboardId={dashboardId}
          setRows={setRows}
          setPlanUpgradeModal={setPlanUpgradeModal}
        />
      </Modal>
      <UpgradePlanModal
        open={isPlanUpgradeModal}
        handleClose={() => setPlanUpgradeModal(false)}
        trigger="dashboard"
      />
      <DeleteConfirmationModal
        show={showDeleteModal}
        deleteField="Jobs"
        fieldName=""
        onHideFunc={handleDeleteCancel}
        onCancelFunc={handleDeleteCancel}
        onConfirmFunc={handleDeleteConfirm}
      />
    </div>
  );
}
