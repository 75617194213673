const mockData = {
  user_name: 'shunhuai',
  has_signup_5days: true,
  insight_data: {
    wishlist: 4,
    total_applied: 88,
    interview_in_progress: 4,
  },
  tasks: {
    download_extension: false,
    create_profile: false,
    auto_fill: false,
    auto_track: false,
    view_jobboard: true,
    move_to_applied: true,
  },
};

export default mockData;
