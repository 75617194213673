export const roleList = [
  'Software Engineer',
  'Product Manager',
  'Data Scientist',
  'Full-stack Developer',
  'Frontend Developer',
  'Backend Developer',
  'DevOps Engineer',
  'Product Designer',
  'UX Designer',
  'Technical Program Manager',
  'Technical Support Engineer',
  'Network Engineer',
  'Cloud Engineer',
  'Security Engineer',
  'Mobile Developer',
  'Marketing Analyst',
  'Financial Analyst',
  'User Researcher',
  'Business Analyst',
  'Data Analyst',
  'iOS Developer',
  'Android Developer',
  'Solutions Architect',
  'UI Designer',
  'Database Administrator',
  'Project Manager',
  'Machine Learning Engineer',
  'Quality Assurance Engineer',
  'Data Engineer',
  'Cybersecurity Analyst',
  'Content Manager',
  'Sales Representative',
  'IT Support Specialist',
  'Site Reliability Engineer (SRE)',
  'Systems Engineer',
  'Test Automation Engineer',
  'Blockchain Developer',
  'AR/VR Developer',
  'Game Developer',
  'Embedded Systems Engineer',
  'Firmware Engineer',
  'Hardware Engineer',
  'Robotics Engineer',
  'Computer Vision Engineer',
  'NLP (Natural Language Processing) Engineer',
  'Business Intelligence (BI) Developer',
  'Big Data Engineer',
  'Cloud Solutions Architect',
  'IT Project Manager',
  'Systems Architect',
  'Application Developer',
  'UX/UI Designer',
  'Release Manager',
  'Network Administrator',
  'IT Infrastructure Engineer',
  'Platform Engineer',
  'Penetration Tester',
  'Cloud Security Engineer',
  'AI Research Scientist',
  'IT Operations Manager',
  'IT Service Manager',
  'IT Asset Manager',
  'Marketing Manager',
  'HR Manager',
  'Sales Manager',
  'Customer Success Manager',
  'Account Manager',
  'Business Development Manager',
  'Finance Manager',
  'Operations Manager',
  'Talent Acquisition Specialist',
  'Recruiter',
  'Content Strategist',
  'Copywriter',
  'Public Relations Specialist',
  'Social Media Manager',
  'Community Manager',
  'Brand Manager',
  'Event Manager',
  'Customer Support Specialist',
  'Technical Writer',
  'Instructional Designer',
  'Training and Development Specialist',
  'Learning and Development Manager',
  'Compensation and Benefits Specialist',
  'Employee Relations Specialist',
  'Diversity and Inclusion Manager',
  'Communications Manager',
  'Legal Counsel',
  'Compliance Officer',
  'Procurement Specialist',
  'Vendor Manager',
  'Office Manager',
  'Executive Assistant',
  'Administrative Assistant',
  'Supply Chain Manager',
  'Logistics Coordinator',
  'Quality Assurance Manager',
  'Product Marketing Manager',
  'Digital Marketing Specialist',
  'SEO Specialist',
  'PPC Specialist',
  'E-commerce Manager',
  'Market Research Analyst',
  'Data Privacy Officer',
  'Risk Manager',
  'Environmental Health and Safety (EHS) Manager',
  'Facilities Manager',
  'Real Estate Manager',
  'Investor Relations Manager',
];

export const levelList = ['Internship', 'Entry Level', 'Mid-Senior Level'];
