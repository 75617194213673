import React from 'react';
import MuiCard from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import './Card.scss';
import { StylesProvider } from '@mui/styles';
import clsx from 'clsx';
/* Card */
export interface CardProps {
  /**
   * pass className to the root element of this component to override styles
   */
  className?: string;
  /**
   * onClick handler
   */
  onClick?: () => any;
  width?: string;
  height?: string;
  /**
   * change card layout
   */
  isVertical?: boolean;
  isSelected?: boolean;
}
export const Card: React.FC<CardProps> = ({
  className,
  onClick,
  width,
  height,
  isVertical = false,
  isSelected = false,
  ...props
}) => {
  // set newClassName
  let newClassName = 'tlm-card';
  if (className) {
    newClassName = clsx(newClassName, className);
  }
  if (!isVertical) {
    newClassName = clsx(newClassName, 'tlm-card-horizontal');
  }
  if (isSelected) {
    newClassName = clsx(newClassName, 'tlm-card-selected');
  }
  // set inline style
  const cardStyle: any = {};
  if (width) {
    cardStyle.width = width;
  }
  if (height) {
    cardStyle.height = height;
  }
  // render
  return (
    // <StylesProvider injectFirst>
    //   <MuiCard className={newClassName} style={cardStyle} onClick={onClick}>
    //     {props.children}
    //   </MuiCard>
    // </StylesProvider>
    <div className={newClassName} style={cardStyle} onClick={onClick}>
      {props.children}
    </div>
  );
};

/* CardContent */
export interface CardContentProps {
  /**
   * pass className to the root element of this component to override styles
   */
  className?: string;
  width?: string;
  height?: string;
  /**
   * default value is 24px
   */
  padding?: string;
  /**
   * default value is 24px
   */
  paddingLeft?: string;
  /**
   * default value is 24px
   */
  paddingRight?: string;
  /**
   * default value is 24px
   */
  paddingTop?: string;
  /**
   * default value is 24px
   */
  paddingBottom?: string;
}
export const CardContent: React.FC<CardContentProps> = ({
  className,
  width,
  height,
  padding,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  ...props
}) => {
  // set newClassName
  let newClassName = 'tlm-card-content';
  if (className) {
    newClassName = clsx(newClassName, className);
  }
  // set inline style
  const cardContentStyle: any = {};
  if (width) {
    cardContentStyle.width = width;
  }
  if (height) {
    cardContentStyle.height = height;
  }
  if (padding) {
    cardContentStyle.paddingLeft = padding;
    cardContentStyle.paddingRight = padding;
    cardContentStyle.paddingTop = padding;
    cardContentStyle.paddingBottom = padding;
  }
  if (paddingLeft) {
    cardContentStyle.paddingLeft = paddingLeft;
  }
  if (paddingRight) {
    cardContentStyle.paddingRight = paddingRight;
  }
  if (paddingTop) {
    cardContentStyle.paddingTop = paddingTop;
  }
  if (paddingBottom) {
    cardContentStyle.paddingBottom = paddingBottom;
  }
  // render
  return (
    <div className={newClassName} style={cardContentStyle}>
      {props.children}
    </div>
  );
};

/* CardDivider */
export interface CardDividerProps {
  /**
   * when the <Card> isVertical, <CardDivider> should be isHorizontal
   */
  isHorizontal?: boolean;
  dividerWidth?: string;
  color?: string;
  /**
   * change it to "0" if you need a full-bleed divider
   */
  margin?: string;
}
export const CardDivider: React.FC<CardDividerProps> = ({
  isHorizontal = false,
  dividerWidth = '1px',
  color = '#E6E6E6',
  margin = '24px',
  ...props
}) => {
  const useStyles = makeStyles({
    horizontal: {
      height: dividerWidth,
      backgroundColor: color,
      marginLeft: margin,
      marginRight: margin,
    },
    vertical: {
      width: dividerWidth,
      backgroundColor: color,
      marginTop: margin,
      marginBottom: margin,
    },
  });
  const classes = useStyles();
  let newClassName = '';
  if (isHorizontal) {
    newClassName = classes.horizontal;
  } else {
    newClassName = classes.vertical;
  }

  return <div className={newClassName} />;
};
