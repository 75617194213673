import React from 'react';
import CardBox from './card-box/index.jsx';
import { Stack } from '@mui/material';
import { formatDate } from '@utilities/tool';

import '../style/index.scss';

const CertificateBox = ({ info = {}, onEdit, onDelete }) => {
  return (
    <CardBox
      className="experience-box education-box"
      title={info.name}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <Stack className="experience-sub-title">
        <Stack className="experience-sub-title-main">{info.issue_organization}</Stack>
      </Stack>
      <Stack
        className="box-description"
        direction={'row'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack> {formatDate(info.issue_date)}</Stack>
        <Stack>
          {info.expiration_date && 'Valid until ' + formatDate(info.expiration_date)}
        </Stack>
      </Stack>
    </CardBox>
  );
};

export default CertificateBox;
