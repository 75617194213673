import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Rating from '@mui/material/Rating';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';
import { delightfulMomentSurvey } from '../../../services/DashBoardServices';

const handleRequestDelightfulMomentSurvey = (rating, comment, closeToast) => {
  delightfulMomentSurvey(rating, comment).then((res) => {
    closeToast();
  });
};

const arrow = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '6px', transform: 'scale(1.2)' }}
  >
    {'>'}
  </Box>
);

const congrats_icon = <span role="img">🎉🎉✨</span>;

function NextStepToast({ closeToast, toastProps }) {
  let stylesMsg = {
    marginRight: '20px',
    // marginTop: '6px',
    border: 'none',
    boxShadow: 'none',
  };

  const sparkle_icon = <span role="img">✨</span>;
  return (
    <Card style={stylesMsg} sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5">Next steps{congrats_icon}</Typography>
        <Typography style={{ marginTop: '3px' }} variant="body1">
          You did it! Congrats!
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="180"
        image={require('../../../images/file_open_illu.png').default}
        alt="Next Steps!"
      />
      <CardActions style={{ paddingBottom: '0px', color: '#05756C' }}>
        <Button
          size="small"
          onClick={() => toastProps.onToastExpandSecondPanel(toastProps.cell_row)}
          style={{
            color: '#05756C',
            font: '8px',
            textAlign: 'left',
            textTransform: 'none',
          }}
        >
          Set a reminder {arrow}
        </Button>
      </CardActions>
      <CardActions style={{ paddingTop: '0px', color: '#05756C' }}>
        <Button
          size="small"
          style={{ color: '#05756C', textTransform: 'none' }}
          onClick={() => toastProps.onToastExpandSecondPanel(toastProps.cell_row)}
        >
          Add a note {arrow}
        </Button>
      </CardActions>
    </Card>
  );
}

function RejectedToast({ closeToast, toastProps }) {
  let stylesMsg = {
    marginRight: '20px',
    // marginTop: '6px',
    border: 'none',
    boxShadow: 'none',
  };
  // const { history } = props;
  let history = useHistory();

  const handleClickExplore = (e) => {
    history.push('/job-board/ng-opportunity');
    document.location.reload();
  };
  return (
    <Card style={stylesMsg} sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5">Don't give up!</Typography>
        <Typography style={{ marginTop: '3px' }} variant="body1">
          Rejection is success in progress.
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="200"
        image={require('../../../images/dog_illu.jpg').default}
        alt="Don't give up!"
      />
      <CardActions style={{ paddingTop: '0px', color: '#05756C' }}>
        <Button
          size="small"
          style={{ color: '#05756C', textTransform: 'none' }}
          onClick={(e) => handleClickExplore(e)}
        >
          Explore other jobs {arrow}
        </Button>
      </CardActions>
    </Card>
  );
}

function BatchUpdateToast({ closeToast, toastProps }) {
  let stylesMsg = {
    marginRight: '20px',
    // marginTop: '6px',
    border: 'none',
    boxShadow: 'none',
  };
  // const { history } = props;
  let history = useHistory();

  return (
    <Card style={stylesMsg} sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5">Job Statuses Updated</Typography>
        <Typography style={{ marginTop: '6px' }} variant="body1">
          Keep track of your job by setting a reminder.
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        height="200"
        image={require('../../../images/file_open_illu.png').default}
        alt="Batch updated!"
      />
    </Card>
  );
}

function CongratsToast({ closeToast, toastProps }) {
  let stylesMsg = {
    marginRight: '20px',
    // marginTop: '6px',
    border: 'none',
    boxShadow: 'none',
  };
  const [value, setValue] = useState(0);
  const [flagSet, setFlagSet] = useState(false);
  const [feedbackText, setFeedbackText] = useState(' ');

  function randomBinary(min, max) {
    const res = Math.floor(min + Math.random() * (max + 1 - min)).toString(2);
    return res;
  }
  return (
    <Card style={stylesMsg} sx={{ minWidth: 275 }}>
      <CardContent>
        {flagSet ? (
          <Typography variant="h5">Thank you! {congrats_icon}</Typography>
        ) : (
          <Typography variant="h5">Congrats on offer! {congrats_icon}</Typography>
        )}
        {/* {flagSet == true ? (
          <></>
        ) : ( */}
        <Typography style={{ marginTop: '3px', textTransform: 'none' }} variant="body1">
          How do you feel about Offerland.ai?
        </Typography>
        {/* )} */}
      </CardContent>

      <CardActions
        style={{ paddingTop: '0px', color: '#05756C', justifyContent: 'center' }}
      >
        <div style={{ justifyContent: 'center' }}>
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setFlagSet(true);
              toast.update(toastProps.toastId, {
                autoClose: 10000,
              });
            }}
          />
        </div>
      </CardActions>
      {flagSet == true ? (
        <></>
      ) : randomBinary(0, 1) === '0' ? (
        <CardMedia
          component="img"
          height="180px"
          image={require('../../../images/hands_illu_centered.png').default}
          alt="Congrats!"
        />
      ) : (
        <CardMedia
          component="img"
          height="180px"
          image={require('../../../images/paper_plane_illu_centered.png').default}
          alt="Congrats!"
        />
      )}
      <CardActions style={{ paddingLeft: '30px', paddingTop: '10px', color: '#05756C' }}>
        {flagSet && (
          <TextField
            type="text"
            multiline
            fullWidth
            placeholder={'Tell us more about your experience'}
            // variant="outlined"
            rows={4}
            value={feedbackText}
            onChange={(event) => {
              setFeedbackText(event.target.value);
            }}
          />
        )}
      </CardActions>
      <CardActions style={{ paddingLeft: '30px', paddingTop: '0px', color: '#05756C' }}>
        {flagSet && (
          <Button
            fullWidth
            onClick={(event) => {
              event.preventDefault();
              handleRequestDelightfulMomentSurvey(value, feedbackText, closeToast);
            }}
            variant="outlined"
          >
            Submit
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export { NextStepToast, RejectedToast, BatchUpdateToast, CongratsToast };
