import axios from 'axios';
import { isLocalhost } from '../serviceWorker';

const production = process.env.NODE_ENV === 'production';
const useCloud = false;
export const baseEndpoint = production
  ? `${process.env.PUBLIC_URL}/api`
  : useCloud
  ? 'https://www.offerland.com/api'
  : 'https://offerland.devtrust.biz/api';

export const homePage = 'http://home.offerland.ai/';

/* Info panel related endpoints */
export const finishTooltipEndpoint = 'finish-tooltip/';

/* Profile related endpoints */
export const profileTitles = 'profile-titles/';
export const profiles = 'profiles';
export const profilesEndpoint = (profileId) => `profiles/${profileId}/`;
export const cloneProfileEndpoint = (profileId) => `profiles/${profileId}/clone-profile/`;
export const createBlankProfileEndpoint = 'profiles/create-blank-profile/';
export const uploadResumeEndpoint = (profileId) => `profiles/${profileId}/upload_resume/`;

// Get or create work experiences in a profile
export const profileWorkExperiencesEndpoint = (profileId) =>
  `profiles/${profileId}/work-experiences/`;
// Get or update or delete work experience
export const workExperiencesEndpoint = (workExperienceId) =>
  `work-experiences/${workExperienceId}/`;

// Get or create education in a profile
export const profileEducationsEndpoint = (profileId) =>
  `profiles/${profileId}/educations/`;
// Get or update or delete education
export const educationsEndpoint = (educationId) => `educations/${educationId}/`;

// Get or create education in a profile
export const profileLicensesEndpoint = (profileId) => `profiles/${profileId}/licenses/`;
// Get or update or delete education
export const licensesEndpoint = (licensesId) => `licenses/${licensesId}/`;

//Replace cookies-based authentication into auth0 header-based authentication
export const setupRequestInterceptor = (accessToken) => {
  let headerAuth = 'Bearer ' + accessToken;
  axios.defaults.headers.common['Authorization'] = headerAuth;
  return true;
};

export const setupInterceptors = (history) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        // if no cookie
        if (history.location.pathname !== '/login') {
          // we have to split the behavior between prod and dev,
          // since home page will redirect to offerland.ai instead of localhost
          history.push('/login');
          window.location.reload();
        }
      }
      if (error.response.status === 301) {
        // retry
        return axios.request(error.config);
      }

      return Promise.reject(error);
    }
  );
};
