import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './LoadingProgress.scss';
function LoadingProgress() {
  return (
    <div className="tlm-loadingProgress-container">
      <CircularProgress style={{ color: '#05756C' }} size={16} />
      <span>Loading ...</span>
    </div>
  );
}

export default LoadingProgress;
