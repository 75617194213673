import React, { useState, useEffect } from 'react';
import './JobBoardNewGradMain.scss';
import JobBoardNewGradHeader from './JobBoardNewGradHeader';
import JobBoardNewGradFilter from './JobBoardNewGradFilter';
import JobBoardNewGradTable from './JobBoardNewGradTable';
import CommonPagination from '../../../components/CommonPagination/CommonPagination';
import _ from 'lodash';
import {
  getngLocation,
  getNewJobCount,
  getngYear,
} from '../../../services/JobBoardServices';
import { useJobBoardInternData } from '../../../services/JobBoard';
import { Link } from 'react-router-dom';
import { UndoData } from '../../../services/JobBoardServices';
import { SnackbarLocal } from './JobBoardNewGradMain';

const tempMap = {
  role: 'job_type',
  exploreFilter2: 'recently_open',
  // exploreFilter1: 'year/season',
  exploreFilter1: 'year',
  location: 'locations',
  sponsorship: 'sponsorship',
};

const sortMap = {
  role: 'company',
  year: 'year_season',
  location: 'locations',
  sponsorship: 'sponsorship',
  date: 'open_date',
};

const defaultPage = {
  obj_per_page: 25, // 每一页的obj的个数
  after: 0, // 从哪一页后开始返回数据
};
const defaultFilterFieids = {
  role: ['1'],
  sponsorship: [''],
  status: '',
  location: [],
  year: '',
  exploreFilter1: '',
  exploreFilter2: '',
};
const defaultQuery = {
  request: 'location',
  getlist: true,
  // level_type: 2,
  pagination: defaultPage,
  sort: {
    open_date: 'desc',
  },
  filters: {
    job_type: '1',
  },
};

export default function JobBoardInternMain({
  defaultDashboard,
  directToDashboard,
  arrowHandler,
}) {
  const [locationSelected, setLocationSelected] = useState([]);
  // [TODO] need change to array for multiple select
  const [sponsorshipSelected, setSponsorshipSelected] = useState(['']);
  const [roleSelected, setRoleSelected] = useState(['1']);

  const [sortInfo, setSortInfo] = useState({ field: 'date', direction: 'up' });
  // this is used to track current array order and use for us to do pagination
  const [searchText, setSearchText] = useState('');
  // const firstRender = useFirstRender();
  const [didMount, setDidMount] = useState(false);
  //origina data list
  const [locationList, setLocationList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [numberOfNew, setNumberOfNew] = useState(0);
  //include search type and field

  const [filterFields, setFilterFields] = useState(defaultFilterFieids);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarRow, setSnackBarRow] = useState({});

  const [paginationInfo, setChangePagination] = useState({
    pageSize: defaultPage.obj_per_page, // 每一页的obj的个数
    pageNo: defaultPage.after, // 从哪一页后开始返回数据
  });

  const [queryParams, setQueryParams] = useState(defaultQuery);

  // 获取列表数据
  const {
    data = [],
    page,
    isLoading,
    refetch: refetchJobBoardInternData,
  } = useJobBoardInternData(queryParams);

  useEffect(() => {
    // get location list
    getngLocation().then((internData) => {
      let list = [...internData.data].map((ele) => ele[0]);
      setLocationList(list);
    });
    getngYear().then((internData) => {
      setYearList(internData.data);
    });
  }, []);
  useEffect(() => {
    setDidMount(true);
  }, []);

  // ****SEARCH LOGIC*****
  const handleSearch = (searchText) => {
    setSearchText(searchText);
  };
  const handleCancel = () => {
    setSearchText('');
  };

  // 过滤 排序 关键字改变，重新获取列表数据
  useEffect(() => {
    if (didMount) {
      const searchKeyWords = searchText.toLowerCase().trim();
      const params = {
        pagination: {
          obj_per_page: paginationInfo.pageSize, // 每一页的obj的个数
          after: paginationInfo.pageNo, // 从哪一页后开始返回数据
        },
        filters: {},
      };
      Object.keys({ ...filterFields }).forEach((item) => {
        const val = filterFields[item];
        if (item !== 'location' && val) {
          params.filters[tempMap[item]] = val;
        } else {
          if (val.length > 0) {
            params.filters[tempMap[item]] = val;
          }
        }
      });
      const sort = {};
      if (sortInfo.field) {
        // 字段有改变，需做映射
        sort[sortMap[sortInfo.field]] = sortInfo.direction === 'down' ? 'asc' : 'desc';
      }
      params.sort = sort;
      // 接口没有兼容为空
      if (searchKeyWords) {
        params.keyword = searchKeyWords;
      }
      setQueryParams({ ...defaultQuery, ...params });
      // setQueryParams(params);
    }
  }, [paginationInfo]);
  // 过滤 排序 关键字改变 重置页码
  useEffect(() => {
    setChangePagination({
      ...paginationInfo,
      pageNo: defaultPage.after, // 从哪一页后开始返回数据
    });
  }, [sortInfo, searchText, filterFields]);

  // update filter from given location we selected
  useEffect(() => {
    setFilterFields((prev) => {
      return { ...prev, location: locationSelected };
    });
  }, [locationSelected]);
  // update filter from given sponsorship we selected
  useEffect(() => {
    setFilterFields((prev) => {
      return {
        ...prev,
        sponsorship:
          JSON.stringify(sponsorshipSelected) !== JSON.stringify([''])
            ? sponsorshipSelected
            : ['0', '1', '2', '3'],
      };
    });
  }, [sponsorshipSelected]);

  // update filter from given role we selected
  useEffect(() => {
    setFilterFields((prev) => {
      return {
        ...prev,
        role:
          JSON.stringify(roleSelected) !== JSON.stringify([''])
            ? roleSelected
            : ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      };
    });
  }, [roleSelected]);

  const UndoSnackBarSaveAction = () => {
    UndoData(snackBarRow.id).then(() => {
      // const updatedData = totalData.map((ele) => {
      const updatedData = data.map((ele) => {
        if (ele.id !== snackBarRow.id) {
          return ele;
        }
        if (ele.open_status === 1) {
          return { ...ele, is_saved: false };
        }
        if (ele.open_status === 2) {
          return { ...ele, is_subscripted: false };
        }
      });
      // todo 这个是干啥
      // setCurrentTotalData(updatedData);
      // setOriginalData((prev) => {
      //   return prev.map((ele) => {
      //     if (ele.id !== snackBarRow.id) {
      //       return ele;
      //     }
      //     if (ele.open_status === 1) {
      //       return { ...ele, is_saved: false };
      //     }
      //     if (ele.open_status === 2) {
      //       return { ...ele, is_subscripted: false };
      //     }
      //   });
      // });
      setSnackBarOpen(false);
    });
  };

  const DashboardLink = () => {
    return (
      <React.Fragment>
        <Link
          to={`/dashboard/${defaultDashboard.id}`}
          onClick={() => {
            directToDashboard(defaultDashboard.id);
            arrowHandler('dashboard');
            Mixpanel.track('View Saved Job in Dashboard from List');
          }}
        >
          {defaultDashboard.name}
        </Link>
      </React.Fragment>
    );
  };

  useEffect(() => {
    getNewJobCount().then((res) => {
      setNumberOfNew(res.data);
    });
  }, []);

  const resetClick = () => {
    setChangePagination({
      pageSize: defaultPage.obj_per_page, // 每一页的obj的个数
      pageNo: defaultPage.after, // 从哪一页后开始返回数据
    });
  };
  return (
    <div className="tlm-ng-jobBoard-container">
      <SnackbarLocal
        DashboardLink={DashboardLink}
        setSnackBarOpen={setSnackBarOpen}
        snackBarOpen={snackBarOpen}
        UndoSnackBarSaveAction={UndoSnackBarSaveAction}
      />
      <div className="tlm-ng-jobBoard-top">
        <JobBoardNewGradHeader
          handleSearch={handleSearch}
          handleCancel={handleCancel}
          searchText={searchText}
          headerText="Intern"
          numberOfNew={numberOfNew}
        />
        <JobBoardNewGradFilter
          locationList={locationList}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
          sponsorshipSelected={sponsorshipSelected}
          setSponsorshipSelected={setSponsorshipSelected}
          roleSelected={roleSelected}
          setRoleSelected={setRoleSelected}
          setFilterFields={setFilterFields}
          yearList={yearList}
          resetClick={resetClick}
          jobIndex={parseInt(filterFields.role)}
        />
      </div>
      <JobBoardNewGradTable
        loading={isLoading}
        setSnackBarRow={setSnackBarRow}
        setSnackBarOpen={setSnackBarOpen}
        setSortInfo={setSortInfo}
        refetch={refetchJobBoardInternData}
        sortInfo={sortInfo}
        totalData={data}
        defaultDashboard={defaultDashboard}
        directToDashboard={directToDashboard}
        arrowHandler={arrowHandler}
      />
      <CommonPagination
        totalPages={page.totalPages}
        pageNo={page.pageNo}
        pageSize={page.pageSize}
        changePagination={setChangePagination}
      ></CommonPagination>
    </div>
  );
}
