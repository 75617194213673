import React from 'react';
import GuidanceStepCard from './GuidanceStepCard';
import GuidanceFooter from './GuidanceFooter';

export default function GuidanceStepCards(props) {
  return (
    <div>
      {props.guidance.tasks &&
        props.guidance.tasks.map((step) => (
          <GuidanceStepCard key={step.id} step={step} />
        ))}
      <GuidanceFooter />
    </div>
  );
}
