import React from 'react';

// components
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

// styles
import './Toggle.scss';

// ---------------------------------------------------------------------

export interface ToggleProps {
  label: string;
  isDisabled: boolean;
  defaultChecked?: boolean;
  onChange: (e: boolean) => any;
}

export const Toggle: React.FC<ToggleProps> = ({
  label,
  isDisabled = false,
  defaultChecked = false,
  onChange,
  ...restProps
}) => {
  return (
    <FormControlLabel
      {...restProps}
      className="tlm-controlLabel--container"
      control={
        <Switch
          defaultChecked={defaultChecked}
          disabled={isDisabled}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label={label}
    />
  );
};
