import DownloadExtension from '@images/home/task-cover/download-extension.png';
import CreateProfile from '@images/home/task-cover/create-profile.png';
import AutoFill from '@images/home/task-cover/auto-fill.png';
import AutoTrack from '@images/home/task-cover/auto-track.png';
import JobBoard from '@images/home/task-cover/job-board.png';
import JobApplied from '@images/home/task-cover/job-applied.png';

const downloaded = 'Downloaded';
const completed = 'Completed';
const AutoFillLinkUrl = '/example-application?tlm-ref=ApplyForANewJob';
const AutoFillTrackInfo = 'Try Auto-fill Function';

const TaskList = [
  {
    taskId: 'download_extension',
    taskName: 'Download Offerland Extension',
    taskDetail:
      'Download the Offerland Extension to enable Auto-Fill and Auto-Track features.',
    taskCover: DownloadExtension,
    finishText: downloaded,
    linkUrl:
      'https://chrome.google.com/webstore/detail/offerland-autofill-and-tr/cgneenbonfipejfincfoeljokamigfae',
    trackInfo: 'Download Extension',
  },
  {
    taskId: 'create_profile',
    taskName: 'Create Profile',
    taskDetail: 'Create a profile with us to access powerful features.',
    taskCover: CreateProfile,
    finishText: completed,
    linkUrl: '/profile/',
    trackInfo: 'Create Profile',
  },
  {
    taskId: 'auto_fill',
    taskName: 'Auto-fill Function',
    taskDetail:
      'Auto-fill helps you fill out job applications efficiently and accurately.',
    taskCover: AutoFill,
    finishText: '',
    preTaskId: ['download_extension', 'create_profile'],
    preTaskText: 'Complete Task 1, and Task2 to unlock this task.',
    linkUrl: AutoFillLinkUrl,
    trackInfo: AutoFillTrackInfo,
  },
  {
    taskId: 'auto_track',
    taskName: 'Auto-track Function',
    taskDetail:
      "Use the Extension's Auto-Track feature to save job listings that interest you from any website with just one click.",
    taskCover: AutoTrack,
    finishText: '',
    preTaskId: ['download_extension'],
    preTaskText: 'Complete Task 1 to unlock this task.',
    trackInfo: 'Try Auto-track Function',
  },
  {
    taskId: 'view_jobboard',
    taskName: 'View Job Board',
    taskDetail:
      "Use the Extension's Auto-Track feature to save job listings that interest you from any website with just one click.",
    taskCover: JobBoard,
    finishText: completed,
    linkUrl: '/job-board/ng-opportunity',
    trackInfo: 'View Job Board',
  },
  {
    taskId: 'move_to_applied',
    taskName: 'Edit 1 job to Applied Status',
    taskDetail:
      'Organize all your job applications and notes in a personalized dashboard.',
    taskCover: JobApplied,
    finishText: completed,
    linkUrl: '/dashboard/',
    trackInfo: 'Edit 1 Job to Applied Status',
  },
];

const AutoTrackTask = {
  linkedIn: {
    name: 'Complete with LinkedIn',
    videoButton: 'Auto-track with LinkedIn',
    linkUrl: 'https://www.linkedin.com/jobs/?tlm-ref=SaveAndTrackJobs',
    trackInfo: 'Try Auto-track with LinkedIn',
  },
  indeed: {
    name: 'Complete with Indeed',
    videoButton: 'Auto-track with Indeed',
    linkUrl: 'https://www.indeed.com/?from=gnav-homepage/?tlm-ref=SaveAndTrackJobs',
    trackInfo: 'Try Auto-track with Indeed',
  },
};

const ReferenceVideo = {
  auto_fill: {
    title: 'Auto-fill your first job👇',
    playerUrl: 'https://youtu.be/aUdgR32uvfE',
    trackInfo: 'Watch Auto-fill Video',
  },
  auto_track: {
    title: 'Auto-track your first job👇',
    playerUrl: 'https://www.youtube.com/watch?v=xwU2wJGn_wU',
    trackInfo: 'Watch Auto-Track Video',
  },
};

const TlmTaskStatus = {
  DownloadExtensionComplete: 'download_extension',
  ApplyForANewJob: 'auto_fill',
  SaveAndTrackJobs: 'auto_track',
};

export {
  TaskList,
  AutoFillLinkUrl,
  AutoFillTrackInfo,
  AutoTrackTask,
  ReferenceVideo,
  TlmTaskStatus,
};
