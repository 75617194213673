import React, { useState, useEffect } from 'react';
import LoadingProgress from '../../../components/LoadingProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { Button as ButtonStories } from '../../../stories/Button.tsx';
import {
  requestReferral,
  getDefaultDashboardData,
} from '../../../services/DashBoardServices';
import UpgradeLandingImg1 from '../../../images/successful.png';
import { Mixpanel } from '../../../utilities/mixpanel';
import { makeStyles } from '@mui/styles';
// styles
import './JobBoardReferralTab.scss';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  background_img: {
    width: '100%',
    textAlign: 'center',
    margin: '0',
  },
  pic1: {
    width: '408px',
    height: '147px',
    margin: '15px 0px',
  },
  picText: {
    marginTop: 5,
    marginLeft: 70,
    marginBottom: 50,
  },
  text_body: {
    maxWidth: '409px',
    padding: '24px, 32px, 24px, 32px',
    margin: 'auto',
    gap: '24px',
  },
  h3: {
    color: 'var(--OF-dark-gray, #4F4F4F)',
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px',
    padding: '10px',
  },

  p_text: {
    color: '#4f4f4f',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
  },

  btn_block: {
    display: 'flex',
    placeContent: 'center',
    alignItems: 'center',
    margin: '32px 0px',
  },
  got_it: {
    color: '#05756C',
    fontSize: '19.12px',
    fontWeight: '400',
    lineHeight: '23.9px',
  },
  upgrade_btn: {
    fontSize: '19.12px !important',
    fontWeight: '600 !important',
  },
}));
// ----------------------------------------------------------------------
export default function JobBoardReferralTab({
  referralInfo,
  finishedFetching,
  jobId,
  isReferRequested,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [requested, setRequested] = useState(0);
  const [isPremiumUser, setPremiumUserStatus] = useState(false);
  const [isPopStatus, setPopStatus] = useState('none');

  const handleDashboardData = () => {
    getDefaultDashboardData()
      .then((res) => {
        let currentUserDetails = res?.data[0]?.user;
        if (currentUserDetails?.is_subscripted) {
          setPremiumUserStatus(currentUserDetails?.is_subscripted);
          setPopStatus('none');
        } else {
          setPopStatus('block');
          setPremiumUserStatus(false);
        }
      })
      .catch((error) => {
        setPopStatus('block');
        setPremiumUserStatus(false);
      });
  };

  useEffect(() => {
    handleDashboardData();
    if (isReferRequested) {
      setRequested(1);
    } else {
      setRequested(0);
    }
  }, [jobId]);

  const handleRequestReferral = () => {
    setRequested(1);
    requestReferral(jobId);
  };

  const handlePlanSection = () => {
    history.push('/plan-selection');
    Mixpanel.track('Click UpgradeToPremium_Referral');
  };

  const PremiumPopPlan = () => {
    useEffect(() => {
      Mixpanel.track('UpgradeToPremiumPopup_Referral');
    }, []);

    return (
      <div style={{ display: isPopStatus }}>
        <div className={classes.background_img}>
          <div>
            <img
              className={classes.pic1}
              src={UpgradeLandingImg1}
              alt="Save and track jobs"
            />
          </div>
        </div>
        <div className={classes.text_body}>
          <h3 className={classes.h3}> Upgrade to Premium for more benefit</h3>
          <p className={classes.p_text}>
            Referral is a feature only available with the Premium plan or above. Please
            upgrade to Premium to access the feature.
          </p>
          <div className={classes.btn_block}>
            <ButtonStories
              className={classes.upgrade_btn}
              width="230px"
              height="40px"
              onClick={handlePlanSection}
            >
              Upgrade to Premium
            </ButtonStories>
          </div>
        </div>
      </div>
    );
  };

  const referralDataExist = (
    <div className="tlm-referral-lists-container">
      <div className="tlm-referral-header">
        <div className="tlm-referral-container--info-success">
          <span>
            {' '}
            &#127881; Great news! We found {referralInfo.length} people on LinkedIn who
            indicate they are willing to refer you.{' '}
          </span>
        </div>
        <Tooltip title="We refresh this list regularly to ensure you get the best chances to be referred">
          <ErrorOutlineIcon />
        </Tooltip>
      </div>
      <h6> </h6>
      {referralInfo.map((referral) => (
        <div className="tlm-referral-list-container">
          <img
            src={
              referral.is_profile_img_403
                ? 'https://shuoutreach.com/wp-content/uploads/2022/01/person-icon.png'
                : referral.profile_img
            }
            // src={'https://shuoutreach.com/wp-content/uploads/2022/01/person-icon.png'}
            className="tlm-people-img"
            alt="referral people"
          />
          <div>
            <span className="tlm-referral-list-name">{referral.name}</span>
            <Tooltip title={referral.description}>
              <span className="tlm-referral-list-title">{referral.description}</span>
            </Tooltip>
          </div>
          <a href={referral.linkedin_link} target={'_blank'} rel="noreferrer">
            <img
              className="tlm-linkedin-logo"
              src="https://logo.clearbit.com/linkedin.com"
              alt="linkedin logo"
            />
          </a>
        </div>
      ))}
      <div className="tlm-referral-header-disclaimer">
        <div className="tlm-referral-container--info-disclaimer">
          <span>
            <b>Disclaimer:</b> People listed here who are open to referring are not
            employees of <u>Offerland.ai</u>.
          </span>
        </div>
      </div>
    </div>
  );

  const handleReferralDataContent = () => {
    let referralContent;

    if (finishedFetching) {
      if (referralInfo.length > 0) {
        referralContent = (
          <div className="tlm-referral-lists-container">{referralDataExist}</div>
        );
      } else {
        referralContent = (
          <div className="tlm-referral-header-no-referral">
            <span>Sorry we didn't find any referrer for this position.</span>
            <h6></h6>
            <ButtonStories
              className="tlm-btn-request"
              onClick={handleRequestReferral}
              isDisabled={requested}
            >
              Request Referrer
            </ButtonStories>
            <h6></h6>
            {requested ? <span>Thank you for letting us know!</span> : <></>}
          </div>
        );
      }
    } else {
      referralContent = <LoadingProgress />;
    }

    return referralContent;
  };

  return (
    <div className="tlm-referral-container">
      {!isPremiumUser ? <PremiumPopPlan /> : handleReferralDataContent()}
    </div>
  );
}
