import React, { useEffect, useState } from 'react';
import './style/index.scss';
import { ProfileForm } from './components/ProfileForm';
import { ProfileInfo } from './components/ProfileInfo';
import { useGetDraftProfileById } from '../../services/Profile';
import LoadingProgress from '@components/LoadingProgress';

export const ProfileAi = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const [targetInfo, setTargetInfo] = useState({
    profileId: '',
    role: '',
    level: '',
  });
  const [profileData, setProfileData] = useState({});
  const { mutateAsync: getProfileInfo, isLoading } = useGetDraftProfileById();
  // info  {profileId: string; role: string; level: string;}
  const preAiCvProfile = async (info) => {
    setTargetInfo(info);
    // 交互逻辑，目前在后端处理
    // step1 判断 targetInfo 与draftInfo是否相等，相等，则直接获取profile信息
    // step2 如果profileid改变，则直接获取profile信息
    // step3 如果level和type改变，清空ai suggestion
    const res = await getProfileInfo(info);
    if (res) {
      // todo 经历，需要把暂存的描述替换成正式的描述
      setProfileData(res);
      setShowInfo(true);
    }
  };
  const initFormData = (isReset = false) => {
    // start new，则重置，清空targetInfo，表单不携带数据
    if (isReset) {
      setTargetInfo({
        profileId: '',
        role: '',
        level: '',
      });
    }
    setShowInfo(false);
  };

  // profileId  role level 三者其一发生改变，则重新请求 profile-titles
  useEffect(() => {
    // refreshProfiles();
    const { draft_profile_id, job_type, level_type } = props.profiles;
    const { profileId, role, level } = targetInfo;
    if (draft_profile_id !== profileId || job_type !== role || level_type !== level) {
      // todo 目前直接刷新Mian，应该只需要重新请求 api/profile-titles/ 接口即可
      props.refreshMain();
    }
  }, [targetInfo]);

  // 初次进入页面后，需要请求profile详情
  useEffect(() => {
    const { draft_profile_id, job_type, level_type } = props.profiles;
    const { profileId, role, level } = targetInfo;
    if (
      (draft_profile_id !== profileId || job_type !== role || level_type !== level) &&
      draft_profile_id
    ) {
      preAiCvProfile({
        profileId: draft_profile_id,
        role: job_type,
        level: level_type,
      });
    }
  }, [
    props.profiles.draft_profile_id,
    props.profiles.job_type,
    props.profiles.level_type,
  ]);

  return (
    <div className="ai-profile">
      {/* {isLoading && <LinearProgress />} */}
      {isLoading ? (
        <LoadingProgress />
      ) : !showInfo ? (
        <ProfileForm
          getAllRecords = {props.getAllRecords}
          targetInfo={targetInfo}
          profiles={props.profiles}
          createNewProfile={props.createNewProfile}
          preAiCvProfile={preAiCvProfile}
        ></ProfileForm>
      ) : (
        <ProfileInfo
          getAllRecords = {props.getAllRecords}
          isLoading={isLoading}
          goBack={initFormData}
          profiles={props.profiles}
          targetInfo={targetInfo}
          profileData={profileData}
          directToProfile={props.directToProfile}
          refresh={props.refreshMain}
        ></ProfileInfo>
      )}
    </div>
  );
};

export default ProfileAi;
