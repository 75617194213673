import React, { useState } from 'react';
import FormItem from './form-item';
import { Grid } from '@mui/material';
import { useFormMode, useValidTwoDate } from '@utilities/form-hooks';

import '../style/index.scss';

const EducationForm = ({
  info = {},
  rules,
  formValidator,
  updateForm,
  changeFormValid,
}) => {
  const { formData, updateInfo } = useFormMode(
    info,
    updateForm,
    formValidator,
    changeFormValid
  );
  const { validDate, validTwoDate, invalidStartMsg, invalidEndMsg } = useValidTwoDate();
  return (
    <form className="" noValidate autoComplete="off">
      <Grid className="form-container" container spacing={2}>
        <Grid item xs={12}>
          <FormItem
            label="School Name"
            handleUpdate={(info, valid) => updateInfo('school', info, valid)}
            value={formData.school}
            error={!!formValidator.school}
            errorMsg={formValidator.school}
            required={rules.school.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Degree"
            handleUpdate={(info, valid) => updateInfo('degree', info, valid)}
            value={formData.degree}
            error={!!formValidator.degree}
            errorMsg={formValidator.degree}
            required={rules.degree.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="GPA"
            handleUpdate={(info, valid) => updateInfo('gpa', info, valid)}
            value={formData.gpa}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Area of Study 1"
            handleUpdate={(info, valid) => updateInfo('major', info, valid)}
            value={formData.major}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Area of Study 2"
            handleUpdate={(info, valid) => updateInfo('second_major', info, valid)}
            value={formData.second_major}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="Start Date"
            type="date"
            error={!!formValidator.start_date}
            errorMsg={formValidator.start_date}
            handleUpdate={(info, valid) => {
              const flag = validTwoDate(info, formData.end_date);
              updateInfo('start_date', info, flag, invalidStartMsg);
              if (flag && formValidator.end_date) {
                //如果校验通过，且expiration_date 存在错误，需要清除 expiration_date 的错误
                updateInfo('end_date', formData.end_date, flag);
              }
            }}
            value={formData.start_date}
            required={rules.start_date.required}
          ></FormItem>
        </Grid>
        <Grid item xs={6}>
          <FormItem
            label="End Date"
            type="date"
            error={!!formValidator.end_date}
            errorMsg={formValidator.end_date}
            handleUpdate={(info, valid) => {
              const flag = validTwoDate(formData.start_date, info);
              updateInfo('end_date', info, flag, invalidEndMsg);
              if (flag && formValidator.start_date) {
                //如果校验通过，且expiration_date 存在错误，需要清除 expiration_date 的错误
                updateInfo('issue_date', formData.start_date, flag);
              }
            }}
            value={formData.end_date}
            required={rules.end_date.required}
          ></FormItem>
        </Grid>
        <Grid item xs={12}>
          <FormItem
            label="Description"
            rows={4}
            handleUpdate={(info, valid) => updateInfo('description', info, valid)}
            value={formData.description}
          ></FormItem>
        </Grid>
      </Grid>
    </form>
  );
};

export default EducationForm;
