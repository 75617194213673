export const premiumFeature = [
  {
    title: 'Profile',
    description: 'Unlimited',
  },
  {
    title: 'Dashboard',
    description: 'Unlimited',
  },
  {
    title: 'Referral',
    description: 'Identify people willing to provide referrals',
  },
  {
    title: 'AI Resume Builder',
    description: 'Craft impressive resumes with AI(Up to 500 times per month)',
  },
  { title: 'Early Bird', description: 'Early access to all new features' },
];