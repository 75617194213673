import React from 'react';
import CardBox from './card-box/index.jsx';
import { Stack, InputBase } from '@mui/material';
import { formatDate } from '@utilities/tool';

import '../style/index.scss';

const EducationBox = ({ info = {}, onEdit, onDelete }) => {
  const description = [info.major, info.second_major].join('\n');
  return (
    <CardBox
      className="experience-box education-box"
      title={info.school}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <Stack className="experience-sub-title">
        <Stack className="experience-sub-title-main">
          {info.degree} {info.gpa ? ' | GPA : ' + info.gpa : ''}
        </Stack>
      </Stack>
      <Stack className="experience-sub-title">
        <Stack className="experience-sub-title-main">
          {info.start_date && formatDate(info.start_date)}
          {info.end_date && `  -   ${formatDate(info.end_date)}`}
        </Stack>
      </Stack>
      <Stack>
        <InputBase
          className="box-description"
          readOnly={true}
          multiline={true}
          value={description}
        />
      </Stack>
    </CardBox>
  );
};

export default EducationBox;
