import React, { useState } from 'react';
import './MyTemplatePreviewCards.css';
import MyTemplatePreviewCard from './MyTemplatePreviewCard.jsx';

const arrowIcon = (
  <svg viewBox="-512 -512 2048 2048" fill="#007484">
    <path d="M134.261355 580.892043l25.712615-24.940019c5.962805-5.762237 13.833053-8.940626 22.179139-8.940626 8.384971 0 16.256242 3.178389 22.177092 8.940626l261.397429 253.429966 0-726.44507c0-10.968819 8.903787-19.911492 19.832697-19.911492L537.18817 63.025429c10.968819 0 19.871583 8.942673 19.871583 19.911492L557.059753 809.581536 818.617841 555.952025c5.921873-5.762237 13.831007-8.940626 22.177092-8.940626 8.385994 0 16.256242 3.178389 22.177092 8.940626l25.714661 24.940019c6.080485 5.882987 9.419534 13.731746 9.419534 22.098297 0 8.385994-3.339048 16.256242-9.419534 22.157649L554.15868 949.521728c-0.398066 0.3776-0.794086 0.715291-1.153266 1.01205-3.696182 4.869914-9.458419 7.849782-15.818267 7.849782l-51.626821 0c-6.477528 0-12.360516-3.098571-16.016789-8.18645-0.277316-0.23843-0.556679-0.476861-0.836041-0.7552L134.261355 625.128547c-6.081509-5.881964-9.420557-13.751189-9.420557-22.138206C124.840798 594.623789 128.220778 586.775031 134.261355 580.892043z"></path>
  </svg>
);

const MyTemplatePreviewCards = (props) => {
  const [order, setOrder] = useState(true);

  let nameSet = new Set();
  props.userTemplates.forEach((template) => nameSet.add(template.title));

  return (
    <div className="my-temp-prev-cards">
      <div className="my-temp-btn-sort" onClick={() => setOrder(!order)}>
        <div className="my-temp-btn-sort-text">Last Updated</div>
        <div
          className={order ? 'my-temp-btn-sort-icon' : 'my-temp-btn-sort-icon-reversed'}
        >
          {arrowIcon}
        </div>
      </div>
      <div className={order ? 'my-temp-cards-reversed' : 'my-temp-cards'}>
        {props.userTemplates.map((template) => (
          <MyTemplatePreviewCard
            template={template}
            setEditCard={props.setEditCard}
            getFirstTime={props.getFirstTime}
            refreshUserTemplates={props.refreshUserTemplates}
            setIsOfferlandTemplate={props.setIsOfferlandTemplate}
            nameSet={nameSet}
          />
        ))}
      </div>
    </div>
  );
};

export default MyTemplatePreviewCards;
