import axios from 'axios';
import { BACKEND_PATH } from '../services/ServiceConstants';
import {
  profileTitles,
  profilesEndpoint,
  createBlankProfileEndpoint,
  uploadResumeEndpoint,
  cloneProfileEndpoint,
  profileWorkExperiencesEndpoint,
  profileEducationsEndpoint,
  profileLicensesEndpoint,
  workExperiencesEndpoint,
  educationsEndpoint,
  licensesEndpoint,
} from './Endpoints';

const baseEndpoint = 'api';

// Profile related Ajax calls
export const getProfiles = async (userId) => {
  // TODO remove user once backend integrate with login token
  return await axios.get(`${BACKEND_PATH}${baseEndpoint}/${profileTitles}`);
};

export const getProfile = async (profileId) => {
  return await axios.get(`${BACKEND_PATH}${baseEndpoint}/${profilesEndpoint(profileId)}`);
};

export const deleteProfile = async (profileId) => {
  return await axios.delete(
    `${BACKEND_PATH}${baseEndpoint}/${profilesEndpoint(profileId)}`
  );
};

export const updateProfile = async (profileId, payload) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${profilesEndpoint(profileId)}`,
    {
      ...payload,
    }
  );
};

export const cloneProfile = async (profileId, title) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${cloneProfileEndpoint(profileId)}`,
    {
      title,
    }
  );
};

export const createBlankProfile = async (title) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${createBlankProfileEndpoint}`,
    {
      title,
    }
  );
};

export const uploadResume = async (profileId, resume) => {
  let instance = axios.create();
  instance.defaults.timeout = 10000;
  let formData = new FormData();
  formData.append('resume', resume);
  return await instance.post(
    `${BACKEND_PATH}${baseEndpoint}/${uploadResumeEndpoint(profileId)}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

// Experience related Ajax calls
export const createEmptyExperience = async (pid) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${profileWorkExperiencesEndpoint(pid)}`
  );
};

export const updateWorkExperience = async (eid, payload) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${workExperiencesEndpoint(eid)}`,
    {
      ...payload,
      // description_draft: payload.description, // profile 1.0 ，更新经历时，需要把description_draft 设置成和 description一致，避免出现修改过简历的工作经历，ai ci 还显示历史draft
    }
  );
};

export const deleteWorkExperience = async (eid) => {
  return await axios.delete(
    `${BACKEND_PATH}${baseEndpoint}/${workExperiencesEndpoint(eid)}`
  );
};

// Education related Ajax calls
export const createEmptyEducation = async (pid) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${profileEducationsEndpoint(pid)}`
  );
};

export const updateEducation = async (eid, payload) => {
  return await axios.post(`${BACKEND_PATH}${baseEndpoint}/${educationsEndpoint(eid)}`, {
    ...payload,
  });
};

export const deleteEducation = async (eid) => {
  return await axios.delete(`${BACKEND_PATH}${baseEndpoint}/${educationsEndpoint(eid)}`);
};

// License related Ajax calls
export const createEmptyLicense = async (pid) => {
  return await axios.post(
    `${BACKEND_PATH}${baseEndpoint}/${profileLicensesEndpoint(pid)}`
  );
};

export const updateLicense = async (lid, payload) => {
  return await axios.post(`${BACKEND_PATH}${baseEndpoint}/${licensesEndpoint(lid)}`, {
    ...payload,
  });
};

export const deleteLicense = async (lid) => {
  return await axios.delete(`${BACKEND_PATH}${baseEndpoint}/${licensesEndpoint(lid)}`);
};

export const getAllCompanyName = async () => {
  return await axios.get(`${BACKEND_PATH}${baseEndpoint}/autocomplete-company/`);
};

export const getAllCollegeName = async () => {
  return await axios.get(`${BACKEND_PATH}${baseEndpoint}/autocomplete-college/`);
};

export const toGetResumeDraftProfile = async (payloadObj) => {
  return  axios.post(`${BACKEND_PATH}${baseEndpoint}/aibuilder_validation/`, payloadObj );
};

export const toGenerateCountResumeBuilder = async (payloadObj) => {
  return  axios.post(`${BACKEND_PATH}${baseEndpoint}/create_generated_count/`, payloadObj );
};

export const toChangeAuthoCredentials = async (payloadObj) => {
  return  axios.post(`${BACKEND_PATH}${baseEndpoint}/update_auth0_user/`, payloadObj );
};
