import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import './Checkbox.scss';

// a wrapper class for material ui checkbox
function CheckboxWrapper(props) {
  return (
    <Checkbox
      className="tlm-checkbox-wrapper"
      icon={<CircleUnchecked />}
      checkedIcon={props.isRadio?<RadioButtonChecked/>:<CircleCheckedFilled />}
      {...props}
      style={{
        width: 20,
        height: 20,
        padding: 0,
        marginRight: '8px',
      }}
    ></Checkbox>
  );
}

export default CheckboxWrapper;
