import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography } from '@mui/material';
import ProductDesign from '../../../images/ProductDesign.svg';
import DataScientist from '../../../images/DataScientist.svg';
import ProductManagement from '../../../images/ProductManagement.svg';
import SoftwareEngineer from '../../../images/SoftwareEngineer.svg';
import { Snackbar } from '@mui/material';
import { getAllGuides, createGuide } from '../../../services/GuidanceServices';

const useStyles = makeStyles(() => ({
  root: {
    width: 341,
    height: 315,
    border: '1px solid #eeeeee',
    boxSizing: 'border-box',
    boxShadow:
      '0px 2px 10.9px rgba(0, 142, 131, 0.08), 0px 0px 5px rgba(3, 79, 73, 0.13)',
    borderRadius: 8,
    margin: '0px 28px 28px 0px',
    cursor: 'pointer',
  },
  content: {
    position: 'relative',
    width: 341,
    height: 315,
  },
  img: {
    maxWidth: '100%',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 30,
    margin: 'auto auto',
  },
  title: {
    position: 'relative',
    textAlign: 'center',
    top: 226,
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '36px',
  },
}));

export default function NUX(props) {
  const classes = useStyles();
  const NUXInfo = [
    {
      job: 'Product Design',
      img: ProductDesign,
    },
    {
      job: 'Data Scientist',
      img: DataScientist,
    },
    {
      job: 'Product Management',
      img: ProductManagement,
    },
    {
      job: 'Software Engineer',
      img: SoftwareEngineer,
    },
  ];
  const [snack, setSnack] = useState({
    open: false,
    status: 'FAIL',
    message: ``,
  });
  const [guides, setGuides] = useState([]);

  useEffect(() => {
    getAllGuides().then((res) => setGuides(res.data));
  }, []);

  const handleClick = (guidance) => {
    if (guides.filter((guide) => guide.title === guidance).length > 0) {
      const guidanceId = guides.filter((guide) => guide.title === guidance)[0].id;
      if (
        props.userGuides.length === 0 ||
        props.userGuides.filter((guide) => guide.id === guidanceId).length === 0
      ) {
        createGuide(guidanceId).then((res) => props.directToGuidance(res.data));
        setSnack({
          open: true,
          status: 'SUCCESS',
          message: `Guidance is created successfully`,
        });
        props.refreshMain();
      } else {
        setSnack({
          open: true,
          status: 'EXIST',
          message: `Guidance already exists`,
        });
        props.directToGuidance(
          props.userGuides.filter((guide) => guide.id === guidanceId)[0]
        );
      }
    } else {
      setSnack({
        open: true,
        status: 'FAIL',
        message: `Guidance is not available yet`,
      });
    }
  };

  return (
    <div className="nux">
      {NUXInfo.map((card) => {
        return (
          <Card className={classes.root} onClick={() => handleClick(card.job)}>
            <CardContent className={classes.content}>
              <img className={classes.img} src={card.img} />
              <div className={classes.title}>{card.job}</div>
            </CardContent>
          </Card>
        );
      })}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snack.open}
        autoHideDuration={6000}
        message={snack.message}
        onClose={() => {
          setSnack({
            open: false,
            status: 'SUCCESS',
          });
        }}
      ></Snackbar>
    </div>
  );
}
