import axios from 'axios';
import { Mixpanel } from '../utilities/mixpanel';
import queryString from 'query-string';
import { BACKEND_PATH } from './ServiceConstants';

const param = queryString.parse(window.location.search);
const jwt = require('jwt-simple');

const googleLogin = async (accesstoken) => {
  let res = await axios
    .post(BACKEND_PATH + 'api/dj-rest-auth/google/', {
      access_token: accesstoken,
    })
    .then((response) => {
      //The return response seems contains more information than it should be, should be checked later
      Mixpanel.track('Login Successful Google', { userEmail: response.data.user.email });
      return setupUserOnServer(response, 'google');
    });
  return res.status;
};

const auth0Login = async (accesstoken) => {
  let res = await axios
    .post(BACKEND_PATH + 'api/auth0/', {
      access_token: accesstoken,
    })
    .then((response) => setupUserOnServer(response, 'auth0'));
  return res.status;
};

const auth0SetupUser = async (accesstoken) => {
  return axios.post(
    BACKEND_PATH + 'api/setup_user/',
    {
      social_account_provider: 'auth0',
      utm_source: param['utm_source'] ? param['utm_source'] : 'Common',
    },
    {
      headers: {
        // need Authorization header to trigger token validation
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accesstoken,
      },
      // access_token: accesstoken,
    }
  );
};

const auth0SetupUserLogin = async (objData) => {
  return axios.post(
    BACKEND_PATH + 'api/setup_user_auth0/',
    {
      social_account_provider: 'auth0',
      utm_source: param['utm_source'] ? param['utm_source'] : 'Common',
      user: objData.user,
      access_token: objData.access_token,
    },
    {
      headers: {
        // need Authorization header to trigger token validation
        'Content-Type': 'application/json',
      },
      // access_token: accesstoken,
    }
  );
};

export const getAuth0Config = async () => {
  let response = await axios.get(BACKEND_PATH + 'api/react-config/');
  return response;
};

const setupUserOnServer = async (response, social_account_provider) => {
  var encodedData = jwt.encode(
    response.data,
    'abcdef'
    // process.env.REACT_APP_LOGIN_DATA_JWT_SECRET_KEY
  );
  let { email, username, first_name, last_name } = response.data.user;

  Mixpanel.alias(email);
  Mixpanel.identify(email);
  Mixpanel.people.set({
    $first_name: first_name,
    $last_name: last_name,
    $name: username,
    $email: email,
    $channel: param['channel'] ? param['channel'] : 'Common',
  });

  return axios.post(
    BACKEND_PATH + 'api/setup_user/',
    {
      social_account_provider: social_account_provider,
      utm_source: param['utm_source'] ? param['utm_source'] : 'Common',
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + encodedData,
      },
    }
  );
};

const logout = async () => {
  await axios.post(BACKEND_PATH + 'api/logout/');
  window.location.reload();
};

export default {
  googleLogin: googleLogin,
  auth0Login: auth0Login,
  logout: logout,
  auth0SetupUser: auth0SetupUser,
  auth0SetupUserLogin: auth0SetupUserLogin,
};
