import React, { useState, useRef } from 'react';
import Tag from './tag';
import { Stack, TextField, Button } from '@mui/material';
import { hasDuplicateCaseInsensitive } from '@utilities/utility';
import { useOnClick, useDebounce } from '@utilities/hooks';
import { useGetRecommendSkillsByKeywords } from '@services/Profile';
import '../style/index.scss';

const SkillsInfo = ({ data = '', handleUpdateProfile }) => {
  const skillsPlaceholder =
    'Search, type, or paste a series of skills separated by comma(,)';
  const dupSkillMessage = 'Looks like you already have this skill on your profile.';

  const skillList = (data && data.split(',')) || [];
  const [newSkill, setNewSkill] = useState('');
  const [inputError, setInputError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const addNewSkills = (newSkillText) => {
    const duplicate = hasDuplicateCaseInsensitive(newSkillText, skillList, [
      () => setInputError(true),
      () => setHelperText(dupSkillMessage),
    ]);
    if (!duplicate) {
      const newSkillList = [newSkillText, data];
      handleUpdateProfile({ skills: newSkillList.join(',') }, 'Add Skill', () => {
        setInputError(false);
        setHelperText('');
        setNewSkill('');
      });
    }
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.keyCode === 188) {
      addNewSkills(newSkill);
    }
  };

  const handleDelete = (skill) => {
    const newSkillList = skillList.filter((s) => s !== skill);
    handleUpdateProfile({ skills: newSkillList.join(',') }, 'Delete Skill');
  };

  const handleClearAll = () => {
    handleUpdateProfile({ skills: '' }, 'Delete Skill');
  };

  // 输入框改变事件
  const handleChange = (e) => {
    const value = e.target.value;
    setNewSkill(value);
    setInputError(false);
    setHelperText('');
    if (value) {
      getRecommendSkills(value);
    } else {
      resetRecommendSkills();
    }
  };

  // skills 下拉的相关操作
  const [showDropdown, setShowDropdown] = useState(false);
  const [recommendSkills, setRecommendSkills] = useState([]);
  const handleClose = () => {
    setShowDropdown(false);
  };
  // 重置下拉列表
  const resetRecommendSkills = () => {
    setRecommendSkills([]);
    setShowDropdown(false);
  };

  const ref = useRef();
  useOnClick(ref, () => setShowDropdown(false));

  // 通过关键字获取skill下拉列表
  const { mutateAsync: getSkills } = useGetRecommendSkillsByKeywords();
  const getRecommendSkills = useDebounce(async (keywords) => {
    const res = await getSkills(keywords);
    if (typeof res === 'object' && res.length > 0) {
      setRecommendSkills(res);
      // 当有值时，才显示下拉列表
      setShowDropdown(true);
    } else {
      resetRecommendSkills();
    }
  }, 500);
  return (
    <Stack className="skill-information" direction={'column'}>
      <Stack direction={'row'} spacing={2}>
        <Stack
          ref={ref}
          className="skill-information-group"
          sx={{ flex: 1, maxWidth: '680px' }}
        >
          <TextField
            className="skill-information-input"
            size="small"
            fullWidth
            placeholder={skillsPlaceholder}
            variant="outlined"
            value={newSkill}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            error={inputError}
            helperText={helperText}
          />
          {recommendSkills.length > 0 && showDropdown && (
            <div className="skill-dropdown-list">
              {recommendSkills.map((item) => (
                <div
                  className="skill-dropdown-item"
                  disablePadding
                  key={item}
                  onClick={() => {
                    handleClose();
                    addNewSkills(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
        </Stack>
        <Stack direction={'row'} sx={{ width: '200px' }}>
          <Button className="skill-clear-button" variant="text" onClick={handleClearAll}>
            Clear All
          </Button>
        </Stack>
      </Stack>

      <Stack className="skill-information-list" direction={'row'}>
        {skillList.map((item) => {
          if (item) {
            return (
              <Tag
                className="skill-tag-item"
                key={item}
                text={item}
                onDelete={() => handleDelete(item)}
              ></Tag>
            );
          } else {
            return null;
          }
        })}
      </Stack>
    </Stack>
  );
};

export default SkillsInfo;
