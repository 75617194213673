import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles, withStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FFA558',
  },
}))(LinearProgress);

export default function Progress(props) {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={props.progress} />
    </div>
  );
}
