export const DASHBOARDCONSTANTS = {
  status: {
    1: { statusName: 'Wishlist', tagColor: '#ADF7F7' },
    2: { statusName: 'Applied', tagColor: '#CCFFEF' },
    3: { statusName: '1st round', tagColor: '#CCE4FF' },
    4: { statusName: '2nd round', tagColor: '#CCF5FF' },
    5: { statusName: '3rd round', tagColor: '#CCD1FF' },
    6: { statusName: 'Onsite', tagColor: '#E8CCFF' },
    7: { statusName: 'Final round', tagColor: '#FFBEEC' },
    8: { statusName: 'Offer', tagColor: '#D0FFCC' },
    9: { statusName: 'Reject', tagColor: '#E0E0E0' },
  },
  statusIdxMap: {
    Wishlist: 1,
    Applied: 2,
    '1st round': 3,
    '2nd round': 4,
    '3rd round': 5,
    Onsite: 6,
    'Final round': 7,
    Offer: 8,
    Reject: 9,
  },
  todo: {
    'Follow up': '#FFFAF0',
    'Send Availability': '#FFEFEB',
    Apply: '#FFEBFA',
    Refer: '#FFF8D4',
    'Finish OA': '#EFFFC3',
    'Prepare Interview': '#FDFF9E',
  },
  tagFilter: [
    'All jobs',
    'Follow up',
    'Send Availability',
    'Apply',
    'Refer',
    'Finish OA',
    'Prepare interview',
  ],
  statusFilter: ['all', 'wishlist', 'applied', 'interview', 'offer', 'rejected'],
  statusFilterMap: {
    all: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    wishlist: [1],
    applied: [2],
    interview: [3, 4, 5, 6, 7],
    offer: [8],
    rejected: [9],
  },
  snackStatus: {
    SUCCESS: 'success',
    ERROR: 'error',
  },
  snackType: {
    MISSINGFIELD: 'missingField',
    SAVEJOB: 'saveJob',
    CREATEJOB: 'createJob',
  },
};

export const DASHBOARD_STATUS = Object.values(DASHBOARDCONSTANTS.status).map(
  (item) => item.statusName
);

export const DASHBOARD_TAB_INDEX = {
  NOTES: '1',
  AI_GUIDANCE: '2',
  REFERRAL: '3',
  DESCRIPTION: '4',
};
