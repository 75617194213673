import moment from 'moment-timezone';

//Allow formatDate and handle timezone
export function formatDate(
  val,
  format = 'MMM DD , YYYY',
  //Get timezone based on current location
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  if (val) {
    const now = moment.tz(new Date(), timezone);
    const date = moment.tz(val, timezone);

    if (date.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
      return 'Today';
    } else {
      return date.format(format);
    }
  }
  return '';
}

export function checkNextDatePayment(
  paymentDate,
  payment_type,
  format = 'MMM DD , YYYY',
  //Get timezone based on current location
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  if (paymentDate) {
    const inputDate = new Date(paymentDate);
    const date = moment.tz(paymentDate, timezone);

    if (payment_type === 'month') {
      inputDate.setMonth(inputDate.getMonth() + 1);
      date.add(1, 'months');
    } else if (payment_type === 'quarter') {
      inputDate.setMonth(inputDate.getMonth() + 3);
      date.add(3, 'months');
    } else {
      console.error('Unsupported payment type');
    }
    return date.format(format);
  }
  return '';
}

export function formatAiSuggestion(val) {
  if (val) {
    return val.replace(/^-/g, '·  ').replace(/\n-/g, '\n·  ');
  }
  return '';
}

export function arrToMap(arr, key = 'id', val = 'name') {
  const maps = {};
  arr.forEach((item) => {
    maps[item[key]] = item[val];
  });
  return maps;
}
