import React from 'react';
import { Stack, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import './index.scss';

const tag = ({ className, maxWords = 20, ellipsis = false, text, onDelete }) => {
  const tagText = () => {
    return (
      <Stack className="tag-item-text" direction={'column'}>
        {text}
      </Stack>
    );
  };
  return (
    <Stack className={clsx('tag-item', className)} direction="row" alignItems="center">
      {text.length > maxWords && ellipsis ? (
        <Tooltip
          placement="top-start"
          PopperProps={{
            className: 'light-tooltip',
          }}
          arrow
          title={text}
        >
          {tagText()}
        </Tooltip>
      ) : (
        tagText()
      )}

      {onDelete && (
        <Stack
          className="tag-item-icon svg-icon-button"
          direction={'column'}
          onClick={onDelete}
        >
          <CloseIcon></CloseIcon>
        </Stack>
      )}
    </Stack>
  );
};

export default tag;
