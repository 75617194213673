import React from 'react';
import '../index.scss';
import '../../../styles/typography.scss';

export function Logo(props) {
  const label = props.label.toUpperCase();
  return (
    <div className='library-logo-container c-tag-mint'>
      {label.length == 1?
      (<span className='library-logo-text header-18'>{label}</span>)
      :
      (<img className='library-logo-image header-18' src={label} alt="Company Logo" width="50" height="50"></img>
      )    
    }
      </div>
  );
}
