import React, { useState } from 'react';
import { Stack, Button } from '@mui/material';
// import LightbulbIcon from '@mui/icons-material/TipsAndUpdates';
import { lightbulbIcon } from '@components/icons';
import './AiCvNoticeInfo.scss';

export const AiCvNoticeInfo = ({ style, goAiCv }) => {
  const goAiCvPage = () => {
    if (goAiCv) {
      goAiCv();
    } else {
      window.location.href = '/ai-cv-builder';
    }
  };
  return (
    <Stack style={style} className="ai-notice-info">
      <Stack direction={'row'} justifyContent="space-between" alignItems="center">
        <Stack direction={'row'} alignItems="center">
          <Stack sx={{ width: '18px', height: '18px' }}>{lightbulbIcon('#000000')}</Stack>

          <Stack className="ai-notice-info-text">
            80% of Offerland users have used our AI Resume Builder to improve their
            resume. Wanna try？
          </Stack>
        </Stack>
        <Button
          className="black-button"
          variant="contained"
          size="small"
          onClick={goAiCvPage}
        >
          Try it now！
        </Button>
      </Stack>
    </Stack>
  );
};
