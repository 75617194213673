import React from 'react';
import './tag.scss';
import '../styles/color.scss';

export interface TagProps {
  label: string;
  className?: string;
  themeColor?: string;
}

//Input themeColor, which defines tag background color and also text color
export const Tag: React.FC<TagProps> = ({
  label,
  className = '',
  themeColor = 'c-tag-default',
  ...props
}) => {
  return (
    <div className={`tag-container ${themeColor} ${className}`}>
      <span className={`tag-text`}>{label}</span>
    </div>
  );
};

export const TagSmall: React.FC<TagProps> = ({
  label,
  className = '',
  themeColor = 'c-tag-default',
  ...props
}) => {
  return (
    <div className={`tag-small-container ${themeColor} ${className}`}>
      <span className={`tag-text-small`}>{label}</span>
    </div>
  );
};
