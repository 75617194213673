import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';

export const getAllGuides = async () => {
  return await axios.get(`${BACKEND_PATH}api/all-guides/`);
};

export const getUserGuides = async () => {
  return await axios.get(`${BACKEND_PATH}api/guides/`);
};

export const createGuide = async (guideId) => {
  return await axios.post(`${BACKEND_PATH}api/guides/${guideId}/`);
};

export const getUserGuide = async (guideId) => {
  return await axios.get(`${BACKEND_PATH}api/guides/${guideId}/`);
};

export const deleteGuide = async (guideId) => {
  return await axios.delete(`${BACKEND_PATH}api/guides/${guideId}/`);
};

export const changeSubtaskStatus = async (subtaskId, checked) => {
  return await axios.post(`${BACKEND_PATH}api/subtasks/${subtaskId}/`, {
    is_checked: checked,
  });
};
