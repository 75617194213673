import React from 'react';
import { Stack, Button, Avatar } from '@mui/material';
import clsx from 'clsx';
import './index.scss';
import FlagIcon from '@images/home/flag-icon.png';

const TaskCard = ({
  taskIndex = 1,
  taskId = '',
  taskName = '',
  taskDetail = '',
  taskCover = '',
  finishText = '',
  status = 0,
  disabled = false,
  preTaskText = '',
  handleCompleted,
  handleShowVideo,
  children,
}) => {
  return (
    <Stack
      id={taskId}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="task-card"
    >
      {status ? (
        <img src={FlagIcon} className="task-index" />
      ) : (
        <Stack className="task-index text">{taskIndex}</Stack>
      )}
      <Stack
        className={clsx('task-card-main', { 'task-finish': status })}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Stack onClick={handleShowVideo}>
          <Avatar
            src={taskCover}
            variant="rounded"
            sx={{ width: 150, height: 105 }}
          ></Avatar>
        </Stack>
        <Stack
          sx={{ height: 105 }}
          direction="column"
          alignItems="flex-start"
          className="task-recommend"
        >
          <Stack className="task-title">{taskName}</Stack>
          <Stack className="task-bref">{taskDetail}</Stack>
        </Stack>
        <Stack sx={{ width: 147 }}>
          {children ? (
            children
          ) : status ? (
            <Stack className="bold finished-text">{finishText}</Stack>
          ) : (
            <Button
              variant="contained"
              size="small"
              className="bold button-disabled-grey"
              disabled={disabled}
              onClick={handleCompleted}
            >
              Go to Complete
            </Button>
          )}
        </Stack>
        {disabled && <Stack className="task-help-text">{preTaskText}</Stack>}
      </Stack>
    </Stack>
  );
};

export default TaskCard;
