import React, { useState } from 'react';
import { searchIcon, searchOnDisabled, closeIcon, nullIcon } from './assets/icons';
import './searchBar.scss';
import '../styles/color.scss';
import '../styles/typography.scss';

export interface SearchBarProps {
  placeholder: string;
  searchText: string;
  isDisabled?: boolean;
  /**
   * onChange handler
   */
  handleChange?: () => any;
  /**
   * onCancel handler
   */
  handleCancel?: (e: any) => any;
  /**
   * the handler when click 'Enter'
   */
  onEnter?: (e: any) => any;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  searchText = '',
  isDisabled = false,
  handleChange,
  handleCancel,
  onEnter,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  if (isDisabled) {
    return (
      <div className={'searchBar-container-disable'}>
        <div className="searchBar-searchIcon">{searchOnDisabled}</div>
        <input
          className="searchBar-input body-text-14"
          placeholder={placeholder}
          disabled
        />
      </div>
    );
  } else {
    return (
      <div
        className={focused ? 'searchBar-container-focused' : 'searchBar-container-rest'}
      >
        <div className="searchBar-searchIcon">{searchIcon}</div>
        <input
          className="searchBar-input body-text-14"
          placeholder={placeholder}
          value={searchText}
          onChange={handleChange}
          onKeyPress={onEnter}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
        />
        {focused ? (
          <div className={'searchBar-cancel'} onMouseDown={handleCancel}>
            {closeIcon}
          </div>
        ) : (
          <div className={'searchBar-cancel'}>{nullIcon}</div>
        )}
      </div>
    );
  }
};
