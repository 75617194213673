import React, { useState } from 'react';
import premiumBadge from '../../../images/premium-badge.svg';
import { Button } from '../../../stories/Button';
import checkMark from '../../../images/checkMark.svg';
import newMark from '../../../images/newMark.svg';
import './PlanCard.scss';
import {toGetCheckoutPortal} from '../../../services/PaymentServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PlanCard = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const handelCheckOut = (resProps)=>{
    if(resProps.premium){
      let user_ID = localStorage.getItem('userID');
      let text = resProps.subtitle.split(' ');
      const checkoutPayloadObj = {
        userId: user_ID,
        planId: text[1].toLowerCase()
      }
      handleChangeStatus(checkoutPayloadObj)
    }
  }

  const handleChangeStatus = (checkoutPayloadObj) => {
    setIsLoading(true);
    toGetCheckoutPortal(checkoutPayloadObj)
      .then((res) => {
        setIsLoading(false);
        window.location.href = res.data.checkout_url
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsLoading(false);
      });
  };
  
  return (
    <div className="tlm-plan">
      <ToastContainer />
      <div
        className="tlm-plan-top-bar"
        style={{ backgroundColor: props.premium ? '#5CB6AE' : '#EEEEEE' }}
      />
      <div className="tlm-plan-main">
        <div className="tlm-plan-main-title">
          <span className="tlm-plan-main-title-content">{props.title}</span>
          {props.premium && (
            <img className="tlm-plan-main-title-premium-logo" src={premiumBadge} />
          )}
        </div>
        <div className="tlm-plan-main-price">
          <div className="tlm-plan-main-price-content">{props.price}</div>
          {props.lengthType && (
            <div className="tlm-plan-main-price-discount">
              <div className="tlm-plan-main-price-discount-off">20% off</div>
              <div className="tlm-plan-main-price-discount-cost">$14.99/Month</div>
            </div>
          )}
        </div>

        <div className="tlm-plan-main-subtitle">{props.subtitle}</div>
        {isLoading ? (<Button
          className="tlm-plan-main-button"
          isSecondary={true}
          isDisabled={true}
        >
          {props.buttonContent}
        </Button>) : (
          <Button
          className="tlm-plan-main-button"
          onClick={()=>handelCheckOut(props)}
          isSecondary={!props.premium}
          isDisabled={!props.premium}
        >
          {props.buttonContent}
        </Button>
        )}
        
        <div className="tlm-plan-main-feature">
          {props.features.map((feature) => {
            return (
              <div className="tlm-plan-main-feature-item" key={feature.name}>
                {feature.icon === 'Check' ? (
                  <img className="tlm-plan-main-feature-item-icon" src={checkMark} />
                ) : (
                  <img className="tlm-plan-main-feature-item-icon" src={newMark} />
                )}
                <span className="tlm-plan-main-feature-item-content">
                  <span className="tlm-plan-main-feature-item-content-name">
                    {`${feature.name}: `}
                  </span>
                  <span className="tlm-plan-main-feature-item-content-description">
                    {feature.description}
                  </span>
                  <span className="tlm-plan-main-feature-item-content-suffix">
                    {feature.suffix}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
        {props.premium && (
          <div className="tlm-plan-main-footer">Terms & Conditions Apply</div>
        )}
      </div>
    </div>
  );
};

export default PlanCard;
