import React, { useState, useMemo, useEffect } from 'react';
import {
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import { Button } from '../../../../stories/Button';
import StartNewImage from '@images/dashboard/ai-guidance-start-new.svg';
import ErrorImage from '@images/dashboard/ai-guidance-error.svg';
import MissingPartialHelpText from './MissingPartialHelpText';
import { useGetProfileDataById } from '@services/Profile';

const JobBoardAIGuidanceTab = ({
  hasJobDescription,
  defaultProfileId,
  directToProfile,
  switchToDescription,
}) => {
  // TODO: In the future, user could select a profile of their choice
  const { data: profileData } = useGetProfileDataById(defaultProfileId);
  const hasProfile = profileData.experiences && profileData.experiences.length > 0;

  const [guidanceList, setGuidanceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const StartNowComponent = useMemo(
    () => (
      <Stack mt={3} spacing={2} alignItems="center">
        <img src={StartNewImage} alt="Start New" />
        <Typography style={{ textAlign: 'center', maxWidth: '350px' }} variant="body1">
          Ready to unlock personalized interview guidance tailored just for you?
        </Typography>
        <Button style={{ fontWeight: 'bold' }} onClick={() => {}}>
          Start Now
        </Button>
      </Stack>
    ),
    []
  );

  useEffect(() => {
    const fetchGuidance = async () => {
      setIsLoading(true);
      try {
        // Simulated fetch guidance data
        const data = await new Promise((resolve) => setTimeout(() => resolve([]), 100));
        setGuidanceList(data);
        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGuidance();
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Stack m={3} spacing={2} alignItems="flex-start">
        <img src={ErrorImage} alt="error" />
        <Typography variant="body1">
          {'Something went wrong. Please try again'}
        </Typography>
      </Stack>
    );
  }

  const switchToProfile = () => {
    directToProfile(defaultProfileId);
  };

  if (!isLoading && guidanceList.length === 0) {
    if (hasJobDescription && hasProfile) {
      return StartNowComponent;
    } else {
      return (
        <MissingPartialHelpText
          hasJobDescription={hasJobDescription}
          hasProfile={hasProfile}
          startNowHandler={() => {}}
          switchToProfileHandler={!hasProfile && switchToProfile}
          switchToDescriptionHandler={switchToDescription}
        />
      );
    }
  }

  return (
    <List>
      {guidanceList.map((guidance, index) => (
        <ListItem key={index}>
          <ListItemText primary={guidance} />
        </ListItem>
      ))}
    </List>
  );
};

export default JobBoardAIGuidanceTab;
