import configColor from './styles/theme.module.scss';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      100: configColor.theme100,
      200: configColor.theme200,
      300: configColor.theme300,
      400: configColor.theme400,
      500: configColor.theme500,
      600: configColor.theme600,
      700: configColor.theme700,
    },
  },
  typography: {
    // all the text in material ui will use this as default font style
    fontFamily: '"Lato", sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiButtonBase: {
      button: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    MuiButton: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
  },
});

export default theme;
