import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';

export const getDataOnLibrary = async () => {
  return await axios.get(`${BACKEND_PATH}api/resources/`);
};

export const updateBookmark = async (bookmark, resource_id) => {
  return await axios.post(`${BACKEND_PATH}api/resources/bookmark/`, {
    bookmark: bookmark,
    resource_id: resource_id,
  });
};

export const updateThumbup = async (thumbup, resource_id) => {
  return await axios.post(`${BACKEND_PATH}api/resources/thumbup/`, {
    thumbup: thumbup,
    resource_id: resource_id,
  });
};
