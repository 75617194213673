import React from 'react';
import MuiButton from '@mui/material/Button';
import MuiCircularProgress from '@mui/material/CircularProgress';
import './Button.scss';
import { StylesProvider } from '@mui/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export interface ButtonProps {
  /**
   * pass className to the root element of this component to override styles
   */
  className?: string;
  /**
   * onClick handler
   */
  onClick?: () => any;
  /**
   * width of button, eg. "100px", "50%"
   */
  width?: string;
  /**
   * height of button, eg. "100px", "50%"
   */
  height?: string;
  isSecondary?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isFullWidth?: boolean;
  /**
   * If it is not empty, we would treat it as a Link component
   */
  link?: string;
  /**
   * If it is not empty, we would treat it as a href  a component
   */
  href?: string;
  icon?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  className,
  onClick,
  width,
  height,
  isSecondary = false,
  isDisabled = false,
  isLoading = false,
  isFullWidth = false,
  link,
  href,
  icon,
  ...props
}) => {
  // set newClassName
  let newClassName = 'tlm-button';
  if (className) {
    newClassName = clsx(newClassName, className);
  }
  if (isSecondary) {
    newClassName = clsx(newClassName, 'tlm-secondary-btn');
  }
  if (isFullWidth) {
    newClassName = clsx(newClassName, 'tlm-button-full-width');
  }
  if (isDisabled) {
    newClassName = clsx(newClassName, 'tlm-button-disabled');
  }
  if (icon) {
    newClassName = clsx(newClassName, 'tlm-button-icon');
  }
  if (isLoading) {
    newClassName = clsx(newClassName, 'tlm-button-loading');
  }
  // set inline style
  const buttonStyle: any = {};
  if (width) {
    buttonStyle.width = width;
  }
  if (height) {
    buttonStyle.height = height;
  }
  /* Use Material Ui button so we can take link, a  props*/
  if (link) {
    return (
      <StylesProvider injectFirst>
        <MuiButton
          className={newClassName}
          style={buttonStyle}
          disabled={isDisabled}
          onClick={onClick}
          component={Link}
          to={link}
          {...props}
        >
          {!isLoading && (
            <>
              {icon}
              {props.children}
            </>
          )}
          {isLoading && (
            <>
              <MuiCircularProgress className="tlm-button-circular-progress" size={13} />
              Loading
            </>
          )}
        </MuiButton>
      </StylesProvider>
    );
  }

  if (href) {
    return (
      <StylesProvider injectFirst>
        <MuiButton
          className={newClassName}
          style={buttonStyle}
          disabled={isDisabled}
          onClick={onClick}
          component="a"
          href={href}
          {...props}
        >
          {!isLoading && (
            <>
              {icon}
              {props.children}
            </>
          )}
          {isLoading && (
            <>
              <MuiCircularProgress className="tlm-button-circular-progress" size={13} />
              Loading
            </>
          )}
        </MuiButton>
      </StylesProvider>
    );
  }

  return (
    <StylesProvider injectFirst>
      <MuiButton
        className={newClassName}
        style={buttonStyle}
        disabled={isDisabled || isLoading}
        onClick={onClick}
        {...props}
      >
        {!isLoading && (
          <>
            {icon}
            {props.children}
          </>
        )}
        {isLoading && (
          <>
            <MuiCircularProgress className="tlm-button-circular-progress" size={13} />
            Loading
          </>
        )}
      </MuiButton>
    </StylesProvider>
  );
};
