import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';
import { useMutation } from '@tanstack/react-query';

export const useAiSuggestions = () => {
  // const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const { level, role, ...others } = params;

      const res = await axios.post(`${BACKEND_PATH}api/openaiQA/${level}/${role}`, {
        ...others,
        // 本地开发，skip openapi
        // test_mode: true,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        // queryClient.invalidateQueries('');
      },
    }
  );
};
