import React, { useEffect, useState } from 'react';
import { Stack, Button, LinearProgress } from '@mui/material';
import { SortIconDown } from '../../../components/icons';
import { ProfileExperience } from './ProfileExperience';
import UpgradeLandingImg1 from '../../../images/successful.png';
import { Link, useHistory } from 'react-router-dom';
import { Mixpanel } from '../../../utilities/mixpanel';

import {
  useUpdateProfileExperience,
  useCloneNewProfile,
  useDeleteAiSuggestions,
  useUpdateAiExperience,
} from '../../../services/Profile';
import { toGetResumeDraftProfile } from '../../../services/ProfileServices';
import AlertDialog from '@components/CommonDialog';

import CopyProfileModal from '@views/main-view/components/CopyProfileModal';
import '../style/index.scss';
import { makeStyles } from '@mui/styles';
import { STARTER_PLAN_COUNT, PREMIUM_PLAN_COUNT } from '@constants/planCount';

const useStyles = makeStyles((theme) => ({
  body_wraper: {
    borderRadius: '4px',
    border: '0px solid #219FA7',
    background: '#FFF',
    boxShadow:
      '0px 6.4px 20px 0px rgba(3, 79, 73, 0.20), 0px -0.8px 6px 0px rgba(3, 79, 73, 0.10)',
    paddingBottom: '35px',
  },

  inner_wraper: {
    background: '#EAFDFA',
    display: 'inline-table',
  },
  banner_pic: {
    width: '100%',
    objectPosition: 'bottom',
  },
  main_heading: {
    color: '#4F4F4F',
    textAlign: 'center',
    fontSize: '28.68px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '38.241px',
    margin: '0px',
  },
  inner_text: {
    color: '#4F4F4F',
    fontSize: '18.125px',
    fontWeight: '400',
    lineHeight: '160%',
  },
  button_sec: {
    display: 'flex',
    justifyContent: 'end',
    gap: '26px',
    flexDirection: 'row',
    padding: '5px 50px',
  },
  button_got_it: {
    margin: '0px',
    color: '#05756C',
    textAlign: 'center',
    fontSize: '19.12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '35.9px',
  },
  button_upgrade: {
    margin: '0px',
    color: '#fff',
    borderRadius: '4.78px',
    background: '#05756C',
    '&:hover': {
      background: '#05756C',
    },
    textAlign: 'center',
    fontSize: '19.12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '23.9px',
  },
  popup_content: {
    width: '40%',
    padding: '30px',
    display: 'block',
    position: 'absolute',
    top: '-10px',
    right: '10px',
  },
}));

export const ProfileInfo = ({
  isLoading,
  goBack,
  profiles,
  refresh,
  directToProfile,
  targetInfo,
  profileData,
  getAllRecords,
}) => {
  // const history = useHistory();
  // 临时profile
  const classes = useStyles();
  const [experiencesDataCach, setExperiencesDataCach] = useState(
    profileData.experiences || []
  );
  const [isGenerateNow, setIsGenerateNow] = useState(false);
  const [isSubscripted, setIsSubscripted] = useState(
    getAllRecords?.dashboards[0]?.user?.is_subscripted
  );
  const [isGenratedCount, setIsGenratedCount] = useState(false);
  let goItBtnStatus = localStorage.getItem('is_gotIt');
  const initPopup = goItBtnStatus === 'true' ? 'none' : 'block';
  const [isEnabledPopup, setIsEnabledPopup] = useState(initPopup);
  const [toCheckMonthCount, setCheckMonthCount] = useState(false);
  const history = useHistory();

  // 当前是否在执行ai suggestion
  const onGenerate = (bool) => {
    setIsGenerateNow(bool);
  };
  // 更新工作经历，保存到数据库
  const { mutateAsync: updateExperience, isLoading: updateLoading } =
    useUpdateProfileExperience();

  const updateExperiencesDataCach = (info) => {
    setExperiencesDataCach(
      experiencesDataCach.map((item) =>
        item.id === info.id
          ? {
              ...item,
              ...info,
              isEdit: true,
            }
          : item
      )
    );
  };

  const { mutateAsync: updateAiExperience } = useUpdateAiExperience();
  // 更新工作经历，暂存到页面
  const onUpdateExperience = (info, description, isDraft = false) => {
    info.description_draft = description;
    // 清空ai-suggestion
    info.ai_suggestion = {
      has_ai_suggestion: false,
      ai_suggestion: null,
    };

    updateExperiencesDataCach(info);
    // 暂存数据 description_draft
    if (isDraft) {
      updateAiExperience({
        profile_id: targetInfo.profileId,
        level: targetInfo.level,
        role: targetInfo.role,
        experience_id: info.id,
        description_draft: description,
      });
    } else {
      updateExperience({ ...info, description_draft: description });
    }
  };

  // button click
  const doUpdateExperience = async () => {
    const updateArr = [];
    experiencesDataCach.forEach((item) => {
      if (item.description !== item.description_draft) {
        // 点update的时候，需要将description_draft 赋值给 description
        item.description = item.description_draft
          ? item.description_draft
          : item.description;
        updateArr.push(updateExperience(item));
      }
    });
    Promise.all(updateArr).then((res) => {
      // toast 无效
      // toast.info('updated');
      Mixpanel.track('Click Update Current Profile');
      directToProfile(profileData.profile_id);
    });
  };

  // title experience
  const { mutateAsync: cloneNewProfileApi, isLoading: cloneLoading } =
    useCloneNewProfile();
  const [showDuplicateProfile, setShowDuplicateProfile] = useState(false);
  const cloneNewProfile = () => {
    setShowDuplicateProfile(true);
  };
  const handleCloneNewProfile = async (newProfileTitle) => {
    const experiencesObj = {};
    experiencesDataCach.forEach((item) => {
      // clone 的时候，需要将description_draft 赋值给 description
      experiencesObj[item.id] = item.description_draft
        ? item.description_draft
        : item.description;
    });
    const res = await cloneNewProfileApi({
      profileId: profileData.profile_id,
      title: newProfileTitle,
      experiences: experiencesObj,
    });
    if (res) {
      Mixpanel.track('Click Save as New Profile');
      refresh();
      goBack();
      directToProfile(res.profile_id);
    }
  };

  const [showStartNew, setShowStartNew] = useState(false);

  const { mutateAsync: deleteAiSuggestions } = useDeleteAiSuggestions();
  // todo
  const handleStartNew = async () => {
    // step1 是否有二次弹窗
    // step2 清除当前profile的缓存，description_draft + ai suggestion
    const { profileId, level, role } = targetInfo;
    const res = await deleteAiSuggestions({
      profile_id: profileId,
      level,
      role,
    });
    // step3 将当前profile的draft 状态进行移除 （可在清空 description_draft 一并处理）
    goBack(true);
  };

  const handleClosePopup = () => {
    localStorage.setItem('is_gotIt', 'true');
    setIsEnabledPopup('none');
  };

  const handleUpgradePlan = () => {
    history.push('/plan-selection');
    Mixpanel.track('Click UpgradeToPremium_AIResumeBuilder');
  };

  useEffect(() => {
    if (!isGenerateNow) {
      getData();
    }
    // let goItBtnStatus = localStorage.getItem('is_gotIt');
    // if(goItBtnStatus === 'true'){
    //   setIsEnabledPopup('none');
    // }else{
    //   setIsEnabledPopup('block');
    // }
    // console.log("aiBuild>>>localStorage.getItem('is_gotIt')", goItBtnStatus);
    //
  }, [isGenerateNow]);

  function getData() {
    // setIsSubscripted(getAllRecords?.dashboards[0]?.user?.is_subscripted);
    const profileObj = {
      profile_id: profileData.profile_id,
    };
    toGetResumeDraftProfile(profileObj)
      .then((res) => {
        setIsGenratedCount(res.data[0].generated_count);
        setCheckMonthCount(res.data[0].current_month);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  const modaltextContent = () => {
    let result;
    if (isSubscripted) {
      result = {
        titleHeading: 'Enjoy your Premium Benefits',
        textContent: `Users on the Premium plan can only use AI Resume Builder for ${PREMIUM_PLAN_COUNT.AI_RESUME_BUILDER_FOR_PREMIUM_USER} times each month. The available usage of AI Resume Builder will reset when the next billing cycle starts.`,
      };
    } else {
      result = {
        titleHeading: 'Upgrade to Premium for \n more benefit',
        textContent: `Users on the Starter plan can only try AI Resume Builder for ${STARTER_PLAN_COUNT.AI_RESUME_BUILDER_FOR_FREE_USER} times. Please upgrade to Premium to access the feature.`,
      };
    }
    return result;
  };

  return (
    <Stack className="profile-info" sx={{ width: '100%' }}>
      {isLoading && <LinearProgress />}
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '50%' }}>
        <Stack
          className="back help-text large pointer"
          direction={'row'}
          onClick={() => goBack()}
        >
          <span className="arrow-left">{SortIconDown}</span>&nbsp;&nbsp;Back
        </Stack>
        <Button
          sx={{ fontSize: '16px' }}
          size="small"
          variant="text"
          onClick={() => setShowStartNew(true)}
        >
          <strong>Start new</strong>
        </Button>
        <AlertDialog
          open={showStartNew}
          title="Start New Builder"
          sureText="Start New"
          width="480px"
          onClose={() => setShowStartNew(false)}
          onSure={() => handleStartNew()}
        >
          <Stack
            sx={{
              display: 'inline-block',
              fontSize: '18px',
              lineHeight: '24px',
            }}
          >
            Unsaved changes will be lost if you start a new builder. Continue to start new
            builder?
          </Stack>
        </AlertDialog>
      </Stack>
      <Stack direction={'row'} sx={{ marginBottom: '20px' }}>
        <Stack className="profile-title" sx={{ mr: 2 }}>
          {profileData?.profile_title}
        </Stack>
        <Stack direction={'column'}>
          <Stack className="help-text small">Looking for</Stack>
          <Stack className="content-text">
            {targetInfo.role} · {targetInfo.level}
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ position: 'relative' }}>
          <Stack sx={{ width: '100%' }}>
            {experiencesDataCach &&
              experiencesDataCach.map((item, index) => {
                return (
                  <ProfileExperience
                    isEnabledPopup={setIsEnabledPopup}
                    toCheckCurrentMonth={toCheckMonthCount}
                    isPremiumStatus={isSubscripted}
                    genrateCount={isGenratedCount}
                    key={item.id}
                    isLast={index === experiencesDataCach.length - 1}
                    info={item}
                    isGenerateNow={isGenerateNow}
                    targetInfo={targetInfo}
                    onGenerate={onGenerate}
                    onUpdateExperience={(info, description) =>
                      onUpdateExperience(info, description, true)
                    }
                    updateAiSuggestion={updateExperiencesDataCach}
                  ></ProfileExperience>
                );
              })}

            {experiencesDataCach.length > 0 ? (
              <Stack
                className="border-top border_sec"
                direction={'row'}
                spacing={3}
                sx={{
                  marginTop: '20px',
                  paddingTop: '20px',
                  marginBottom: '80px',
                }}
              >
                <Button
                  className="ai-cv-button"
                  variant="contained"
                  sx={{ width: 205, fontWeight: 600 }}
                  disabled={isGenerateNow || updateLoading}
                  onClick={doUpdateExperience}
                >
                  Update current profile
                </Button>
                {isSubscripted ? (
                  <Button
                    className="ai-cv-button"
                    sx={{ width: 205, fontWeight: 600 }}
                    variant="outlined"
                    disabled={isGenerateNow || cloneLoading}
                    onClick={cloneNewProfile}
                  >
                    Save as a new profile
                  </Button>
                ) : (
                  <Button
                    className="ai-cv-button"
                    sx={{ width: 205, fontWeight: 600 }}
                    variant="outlined"
                    disabled={true}
                  >
                    Save as a new profile
                  </Button>
                )}
              </Stack>
            ) : (
              <Stack
                className="ai-experience-empty"
                direction={'row'}
                justifyContent="flex-start"
                alignItems="center"
              >
                Please
                <Button
                  variant="text"
                  onClick={() => directToProfile(profileData.profile_id)}
                >
                  complete your work experience
                </Button>
                to get the most out of our AI resume service.
              </Stack>
            )}
            <CopyProfileModal
              setShowDuplicateProfile={setShowDuplicateProfile}
              profiles={profiles}
              showDuplicateProfile={showDuplicateProfile}
              onDuplicateProfile={handleCloneNewProfile}
              profileName={profileData.profile_title}
            />
          </Stack>
          <Stack className={classes.popup_content} style={{ display: isEnabledPopup }}>
            <Stack className={classes.body_wraper}>
              <Stack className={classes.inner_wraper}>
                <img src={UpgradeLandingImg1} className={classes.banner_pic} />
              </Stack>
              <Stack style={{ padding: '30px 0px' }}>
                <h1 className={classes.main_heading}>
                  {modaltextContent().titleHeading}
                </h1>
              </Stack>

              <Stack style={{ padding: '5px 50px' }}>
                <p className={classes.inner_text}>{modaltextContent().textContent}</p>
              </Stack>

              <Stack className={classes.button_sec}>
                <Link className={classes.button_got_it} onClick={handleClosePopup}>
                  Got it
                </Link>
                {!isSubscripted && (
                  <Button className={classes.button_upgrade} onClick={handleUpgradePlan}>
                    Upgrade to Premium
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
