import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CardBox from './card-box/index.jsx';
import UploadFile from './upload-file';
import AlertDialog from '@components/CommonDialog';
import DescriptionIcon from '@mui/icons-material/Description';

import clsx from 'clsx';
import { Mixpanel } from '../../../utilities/mixpanel';
import { useUploadAttachment } from '@services/Profile';

const ProfileAttachment = ({
  profileId,
  attachmentData,
  onDelete,
  showEditDialog,
  setShowEditDialog,
  refetch,
}) => {
  const existAttachment = !!attachmentData.file_key;

  const [errorMsg, setErrorMsg] = useState('');

  const { mutateAsync: onUploadFile, isLoading, error } = useUploadAttachment();

  useEffect(() => {
    if (error) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  }, [error]);

  const [file, setFile] = useState(null);
  const uploadFile = async (file) => {
    if (!file) {
      setErrorMsg('Please upload file');
      return;
    }
    setErrorMsg(false);
    const res = await onUploadFile({
      profileId: profileId,
      file: file,
    });
    if (res) {
      setShowEditDialog(false);
      const trackInfo = existAttachment ? 'Replace Attachment' : 'Upload Attachment';
      Mixpanel.track(trackInfo);
    }
    if (error) {
      setErrorMsg(true);
      Mixpanel.track('Upload attachment Failed');
    }
  };

  const createHrefElement = () => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = attachmentData.preview_url;
    a.download = attachmentData.file_name;
    a.click();
  };

  const preView = () => {
    // 如果链接已过有效期，则重新请求一次
    if (new Date(attachmentData.expire_time).getTime() > new Date().getTime()) {
      createHrefElement();
    } else {
      if (refetch) {
        refetch();
        setTimeout(() => {
          createHrefElement();
        }, 2000);
      }
    }
  };
  return (
    <Stack className="profile-attachment">
      {existAttachment && (
        <>
          <Stack className="font-14">
            Upload your supporting documents here. We can add them to your job application
            by category automatically.
          </Stack>
          {/* 暂时不加文件类型 */}
          {/* <Stack className="attachment-sub-title">Resume</Stack> */}
          <Stack className={clsx('')} direction={'row'}>
            <CardBox
              className="attachment-box"
              hasTitle={false}
              title={''}
              onEdit={() => {
                setShowEditDialog(true);
              }}
              onDelete={onDelete}
            >
              <Stack
                sx={{ cursor: 'pointer' }}
                direction={'row'}
                justifyContent="flex-start"
                alignItems="center"
                onClick={preView}
              >
                <Stack
                  className="attachment-icon"
                  direction={'column'}
                  justifyContent="center"
                  alignItems="center"
                >
                  <DescriptionIcon></DescriptionIcon>
                </Stack>
                <Stack
                  direction={'column'}
                  justifyContent="center"
                  alignItems="center"
                  className="attachment-name"
                >
                  {attachmentData.file_name}
                </Stack>
              </Stack>
            </CardBox>
          </Stack>
        </>
      )}
      <AlertDialog
        open={showEditDialog}
        title={existAttachment ? 'Replace Attachment' : 'Upload Attachment'}
        onClose={() => setShowEditDialog(false)}
        onSure={() => uploadFile(file)}
      >
        <Stack sx={{ pb: 1, fontSize: '14px' }}>
          Re-uploading a resume file will overwrite the current profile attachment
        </Stack>
        {/* 暂时不加文件类型 */}
        {/* {!existAttachment && (
          <Stack
            sx={{ pb: 2, fontSize: '14px' }}
            direction={'row'}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Stack>Attachment Type</Stack>
            <CommonSelect
              className="attachment-select"
              placeholder="please Select "
              options={['Resume']}
              value={'Resume'}
            ></CommonSelect>
          </Stack>
        )} */}
        <UploadFile
          uploadFile={(fileData) => {
            setFile(fileData);
            setErrorMsg(false);
          }}
          isLoading={isLoading}
          error={errorMsg}
        ></UploadFile>
      </AlertDialog>
    </Stack>
  );
};

export default ProfileAttachment;
