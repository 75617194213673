import React, { useState, useEffect, useRef } from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../index.scss';
import '../../dashboard-view/components/DashBoardMainContent.scss';
import '../../../stories/Dropdown.scss';
import StatusDropdownBtn from '../../dashboard-view/components/StatusDropdownBtn';
import { DROPDOWN, JOB } from '../../../constants/behaviors';
import { Button } from '../../../stories/Button';
import { Dropdown } from '../../../stories/Dropdown.tsx';
import {
  DASHBOARDCONSTANTS,
  DASHBOARD_STATUS,
} from '../../dashboard-view/DashboardConstants';

const JobInfoTab = ({
  onSave,
  job,
  setJob,
  setHasChanged,
  handleSave,
  handleCloseBtnClicked,
}) => {
  const [isJobInvalid, setJobInvalid] = useState(false);
  const [isCompanyInvalid, setCompanyInvalid] = useState(false);
  const [isSalaryInvalid, setSalaryInvalid] = useState(false);
  const updateRender = useRef();

  useEffect(() => {
    if (updateRender.current) checkFields();
    updateRender.current = true;
  }, [onSave]);

  const checkFields = (field, value) => {
    const regexForSalary = /\D/;
    if (field === 'title') {
      setJobInvalid(value ? false : true);
    } else if (field === 'company_name') {
      setCompanyInvalid(value ? false : true);
    } else if (field === 'salary') {
      setSalaryInvalid(value && value.match(regexForSalary) ? true : false);
    } else {
      setJobInvalid(job.title ? false : true);
      setCompanyInvalid(job.company_name ? false : true);
      setSalaryInvalid(job.salary && job.salary.match(regexForSalary) ? true : false);
    }
  };

  const handleChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    if (name === 'status') {
      const newTimestamps = job.timestamps;
      if (job.updatingStatus)
        newTimestamps[newTimestamps.length - 1]['status_code'] = value;
      else newTimestamps.push({ status_code: value });
      setJob({
        ...job,
        timestamps: newTimestamps,
        status: value,
        updatingStatus: true,
      });
    } else {
      setJob({ ...job, [name]: value });
      if (name === 'title' || name === 'company_name' || name === 'salary') {
        checkFields(name, value);
      }
    }
    setHasChanged(true);
  };

  return (
    <div className="tlm-jobModal-leftdiv-infoForm">
      <Form>
        <Form.Row>
          <Col style={{ maxWidth: '186px' }}>
            <Form.Group controlId="title">
              <Form.Label>Job Title*</Form.Label>
              <Form.Control
                required
                type="text"
                onChange={handleChange}
                isInvalid={isJobInvalid}
                placeholder="Title"
                value={job.title}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please fill in job title.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: '186px' }}>
            <Form.Group controlId="company_name">
              <Form.Label>Company*</Form.Label>
              <Form.Control
                required
                isInvalid={isCompanyInvalid}
                type="text"
                onChange={handleChange}
                placeholder="Company"
                value={job.company_name}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please fill in company name.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: '186px' }}>
            <Form.Group controlId="Status">
              <Form.Label>Status</Form.Label>
              <div classNames="form-control">
                <Dropdown
                  classNames="dropdown"
                  itemsAry={DASHBOARD_STATUS}
                  value={DASHBOARDCONSTANTS.status[job.status]?.statusName || 'Wishlist'}
                  onChange={(e) => {
                    handleChange({ target: { id: 'status', value: e } });
                  }}
                />
              </div>
              {/* <StatusDropdownBtn
                onClick={(e) => {
                  handleChange({ target: { id: 'status', value: e } });
                }}
                currStatus={job.status ? job.status : 1}
              ></StatusDropdownBtn> */}
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col style={{ maxWidth: '186px' }}>
            <Form.Group controlId="link">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={job.id && job.link}
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col style={{ maxWidth: '186px' }}>
            <Form.Group controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={job.location}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={13}
            onChange={handleChange}
            value={job.description}
          ></Form.Control>
        </Form.Group>
      </Form>
      <div className="tlm-jobModal-leftdiv-btngroups">
        <Button isSecondary={true} onClick={handleCloseBtnClicked}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </div>
    </div>
  );
};

JobInfoTab.propTypes = {
  onSave: PropTypes.bool,
  job: PropTypes.object,
  setJob: PropTypes.func,
  setHasChanged: PropTypes.func,
};
export default JobInfoTab;
