import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';

export const getFeatureList = async () => {
  return await axios.get(`${BACKEND_PATH}api/feature-flag/`);
};

export const postFeatureFlag = async (featureName) => {
  return await axios
    .post(`${BACKEND_PATH}api/feature-flag/${featureName}/`)
    .catch((err) => {
      console.log('Post Feature Flag Failed' + err);
    });
};

export const getFeatureFlag = async (featureName) => {
  return await axios.get(`${BACKEND_PATH}api/feature-flag/${featureName}/`);
};
