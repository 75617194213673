import axios from 'axios';
import { BACKEND_PATH } from '@services/ServiceConstants';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export const useGetProfileById = () => {
  return useMutation(async (profileId) => {
    const res = await axios.get(`${BACKEND_PATH}api/profiles/${profileId}/`);
    return res.data;
  });
};

export const useGetProfileDataById = (profileId) => {
  const { data, ...rest } = useQuery({
    queryKey: ['profile', profileId],
    queryFn: async () => {
      const res = await axios.get(`${BACKEND_PATH}api/profiles/${profileId}/`);
      return res.data;
    },
  });
  return {
    data: data || {},
    ...rest,
  };
};

export const useUpdateProfileById = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.post(`${BACKEND_PATH}api/profiles/${params.profileId}/`, {
        ...params,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useCloneNewProfile = () => {
  // const queryClient = useQueryClient();
  return useMutation(async (params) => {
    const { profileId, ...rest } = params;
    const res = await axios.post(
      `${BACKEND_PATH}api/profiles/${profileId}/clone-profile/`,
      {
        ...rest,
      }
    );
    return res.data;
  });
};

export const useUploadResume = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      let formData = new FormData();
      formData.append('resume', params.resume);
      const res = await axios.post(
        `${BACKEND_PATH}api/profiles/${params.profileId}/upload_resume/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useAddExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.post(
        `${BACKEND_PATH}api/profiles/${params.profileId}/work-experiences/`,
        { ...params }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useUpdateProfileExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (experience) => {
      const res = await axios.post(
        `${BACKEND_PATH}api/work-experiences/${experience.id}/`,
        {
          ...experience,
        }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useDeleteProfileExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (experience) => {
      const res = await axios.delete(
        `${BACKEND_PATH}api/work-experiences/${experience.id}/`
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useAddEducational = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.post(
        `${BACKEND_PATH}api/profiles/${params.profileId}/educations/`,
        { ...params }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useUpdateEducational = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (educational) => {
      const res = await axios.post(`${BACKEND_PATH}api/educations/${educational.id}/`, {
        ...educational,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useDeleteEducational = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (educational) => {
      const res = await axios.delete(`${BACKEND_PATH}api/educations/${educational.id}/`);
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useAddCertificate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.post(
        `${BACKEND_PATH}api/profiles/${params.profileId}/licenses/`,
        { ...params }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useUpdateCertificate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (certificate) => {
      const res = await axios.post(`${BACKEND_PATH}api/licenses/${certificate.id}/`, {
        ...certificate,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useDeleteCertificate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (certificate) => {
      const res = await axios.delete(`${BACKEND_PATH}api/licenses/${certificate.id}/`);
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useGetProfileAttachments = (profileId) => {
  const { data, ...rest } = useQuery({
    queryKey: ['attachment', profileId],
    queryFn: async () => {
      const res = await axios.get(`${BACKEND_PATH}api/profiles/${profileId}/attachment/`);
      return res.data;
    },
  });
  return {
    attachment: data || {},
    ...rest,
  };
};
export const useUploadAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      let formData = new FormData();
      formData.append('file', params.file);
      const res = await axios.post(
        `${BACKEND_PATH}api/profiles/${params.profileId}/attachment/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('attachment');
      },
    }
  );
};

export const useDeleteAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const res = await axios.delete(
        `${BACKEND_PATH}api/profiles/${params.id}/attachment/`
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('attachment');
      },
    }
  );
};

export const useGetRecommendSkillsByKeywords = () => {
  return useMutation(async (keyword) => {
    const res = await axios.get(`${BACKEND_PATH}api/skills/?keyword=${keyword}`);
    return res.data;
  });
};

export const useUpdateAiExperience = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (experience) => {
      const res = await axios.post(
        `${BACKEND_PATH}api/resume-builder/${experience.level}/${experience.role}/${experience.profile_id}/`,
        {
          description_draft: experience.description_draft,
          experience_id: experience.experience_id,
        }
      );
      return res.data;
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('profile');
      },
    }
  );
};

export const useDeleteAiSuggestions = () => {
  // const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      const { level, role, ...others } = params;

      const res = await axios.delete(`${BACKEND_PATH}api/openaiQA/${level}/${role}`, {
        data: { ...others },
        // 本地开发，skip openapi
        // test_mode: true,
      });
      return res.data;
    },
    {
      onSuccess: async () => {
        // queryClient.invalidateQueries('');
      },
    }
  );
};

export const useGetDraftProfileById = () => {
  // const queryClient = useQueryClient();
  return useMutation(async (info) => {
    const res = await axios.get(
      `${BACKEND_PATH}api/resume-builder/${info.level}/${info.role}/${info.profileId}/`
    );
    return res.data;
  });
};

// 获取draft_profile_id， level_type, job_type
export const useGetProfileTitles = () => {
  const { data, ...rest } = useQuery({
    queryKey: ['profiles'],
    queryFn: async () => {
      const res = await axios.get(`${BACKEND_PATH}api/profile-titles/`);
      return res.data;
    },
  });
  return {
    data: data || {},
    ...rest,
  };
};
