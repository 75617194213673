import React, { useState } from 'react';
import './MyTemplateEditor.css';

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar } from '@mui/material';
import MyTemplateDropdown from './MyTemplateDropdown.jsx';
import { Tooltip } from '@mui/material';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import copy from 'copy-to-clipboard'; // note: npm install --save copy-to-clipboard
import {
  createUserTemplate,
  deleteUserTemplate,
  saveUserTemplate,
} from '../../../services/EmailTemplateServices';
import { Mixpanel } from '@utilities/mixpanel';

const MyTemplateEditor = (props) => {
  const [editingTitle, setEditingTitle] = useState(false);
  const [title, setTitle] = useState(props.card.title);
  const [content, setContent] = useState(props.card.content);
  const [dropdown, setDropdown] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  // get name set
  let nameSet = new Set();
  props.userTemplates.forEach((template) => nameSet.add(template.title));
  let nameSetForSave = new Set(nameSet);
  nameSetForSave.delete(props.card.title);

  // close editor
  const closeEdit = () => {
    props.setEditCard();
  };

  // change title
  const changeTitle = (event) => {
    setTitle(event.target.value);
  };

  // change content
  const changeContent = (event) => {
    setContent(event.target.value);
  };

  // dropdown menu click handler - head
  const handleHeadClick = (e) => {
    e.stopPropagation();
    if (!dropdown) {
      setDropdown(true);
      setTimeout(() => {
        document.addEventListener('click', handleGlobalClick);
      }, 0);
    }
  };

  // dropdown menu click handler - global
  const handleGlobalClick = () => {
    setDropdown(false);
    document.removeEventListener('click', handleGlobalClick);
  };

  // copy email template
  const copyEmailTemplate = () => {
    var text = content;
    text = text.replaceAll('[', '');
    text = text.replaceAll(']', '');
    text = text.replaceAll('\n\n\n', '\n');
    copy(text);
    setSnackOpen(true);
    Mixpanel.track('Click Copy Template Text');
  };

  // duplicate name handler rule 3
  const handleDuplicateName3 = (inputTitle, nameSet) => {
    while (nameSet.has(inputTitle)) {
      inputTitle = inputTitle + ' Copy';
    }
    return inputTitle;
  };

  const handleSaveAsNew = () => {
    let newTitle = handleDuplicateName3(title, nameSet);
    createUserTemplate(newTitle, content, props.card.template_category).then(() => {
      props.refreshUserTemplates();
      if (props.firstTime) {
        props.getFirstTime();
      }
    });
    closeEdit();
  };

  const handleDelete = () => {
    if ('user' in props.card) {
      deleteUserTemplate(props.card.id).then(() => {
        props.refreshUserTemplates();
        props.getFirstTime();
      });
    }
    closeEdit();
  };

  const handleSave = () => {
    // save in my template
    if ('user' in props.card) {
      let newTitle = handleDuplicateName3(title, nameSetForSave);
      saveUserTemplate(
        props.card.id,
        newTitle,
        content,
        props.card.template_category
      ).then(() => props.refreshUserTemplates());
    } else {
      // save in offerland template
      let newTitle = handleDuplicateName3(title, nameSet);
      createUserTemplate(newTitle, content, props.card.template_category).then(() => {
        props.refreshUserTemplates();
        if (props.firstTime) {
          props.getFirstTime();
        }
      });
    }

    closeEdit();
    props.setIsSavedFlag(true);
  };

  return (
    <div className="temp-editor">
      {!props.hideBack ? (
        <div className="temp-editor-btn-back" onClick={closeEdit}>
          &lt;Back
        </div>
      ) : null}

      <div className="temp-editor-title-bar">
        {!editingTitle ? (
          <Tooltip title="Double Click to Rename">
            <span
              className="temp-editor-title-bar-title"
              onDoubleClick={() => setEditingTitle(true)}
            >
              {title}
            </span>
          </Tooltip>
        ) : (
          <input
            className="temp-editor-title-bar-title"
            value={title}
            onChange={changeTitle}
            onBlur={() => setEditingTitle(false)}
          />
        )}
        <div className="temp-editor-title-bar-dropdown">
          <div className="temp-editor-title-bar-dropdown-btn">
            <FontAwesomeIcon
              className="temp-editor-title-bar-dropdown-btn-icon"
              icon={faEllipsisH}
              onClick={handleHeadClick}
            />
          </div>
          <div className="temp-editor-title-bar-dropdown-menu">
            {dropdown && (
              <MyTemplateDropdown
                handleSaveAsNew={handleSaveAsNew}
                handleDelete={() => setDeleteFlag(true)}
                isOfferlandTemplate={props.isOfferlandTemplate}
              />
            )}
          </div>
        </div>
      </div>

      <textarea
        className="temp-editor-content-editor"
        value={content}
        onChange={changeContent}
      />

      <button className="temp-editor-btn-copy" onClick={copyEmailTemplate}>
        Copy Text
      </button>

      <button className="temp-editor-btn-save" onClick={handleSave}>
        Save
      </button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackOpen}
        autoHideDuration={1000}
        onClose={() => setSnackOpen(false)}
        message="Text Copied Successfully!"
        key={'top center'}
      />

      <DeleteConfirmationModal
        show={deleteFlag}
        deleteField="Template"
        fieldName={'Template'}
        onCancelFunc={() => setDeleteFlag(false)}
        onConfirmFunc={() => handleDelete()}
        onHideFunc={() => setDeleteFlag(false)}
      />
    </div>
  );
};

export default MyTemplateEditor;
