import React, { useState } from 'react';
import SaveYourJob from '../../../../images/saveYourJob.svg';
import {
  LinkedInIconWhite,
  IndeedIconWhite,
  LinkedInIconDisabled,
  IndeedIconDisabled,
  ModalcloseIcon,
} from '../../../../components/icons';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player';
import { Button } from '../../../../stories/Button';
import { Mixpanel } from '../../../../utilities/mixpanel';
import '../../../../styles/typography.scss';

const SaveAndTrackJobs = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpenAutoFill = () => {
    setOpen(true);
  };

  const handleOpenAutoTrack = () => {
    Mixpanel.track('Onboarding: Watch Auto-track Video');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const linkedInButton = (
    <Button
      height="39px"
      width="278px"
      onClick={() => {
        window.open('https://www.linkedin.com/jobs/?tlm-ref=SaveAndTrackJobs');
        Mixpanel.track('onboarding: Click Linkedin');
      }}
    >
      <LinkedInIconWhite /> <span className="ml-2"> Auto-track with LinkedIn</span>
    </Button>
  );

  const indeedButton = (
    <Button
      height="39px"
      width="278px"
      onClick={() => {
        window.open(
          'https://www.indeed.com/?from=gnav-homepage/?tlm-ref=SaveAndTrackJobs'
        );
        Mixpanel.track('onboarding: Click Indeed');
      }}
    >
      <IndeedIconWhite /> <span className="ml-1"> Auto-track with Indeed </span>
    </Button>
  );

  const linkedInButtonDisabled = (
    <Button height="39px" width="278px" isDisabled="true">
      <LinkedInIconDisabled /> <span className="ml-2"> Auto-track with LinkedIn</span>
    </Button>
  );

  const indeedButtonDisabled = (
    <Button height="39px" width="278px" isDisabled="true">
      <IndeedIconDisabled /> <span className="ml-1"> Auto-track with Indeed </span>
    </Button>
  );

  const downloadExtension = !props.downloadExtensionComplete && (
    <div className="download-extension-reminder body-text-18">
      Need to{' '}
      <span
        className="download-extension-reminder-link header-18"
        onClick={() => {
          Mixpanel.track('onboarding: Download Plugin');
          window.open(
            'https://chrome.google.com/webstore/detail/offerland/cgneenbonfipejfincfoeljokamigfae',
            '_self'
          );
        }}
      >
        Download Extension
      </span>
    </div>
  );

  const videoSection = (
    <div className="p-3 video-section">
      <img
        className="video-pic"
        src={SaveYourJob}
        alt="applyYourJobVideo"
        onClick={handleOpenAutoFill}
      />
      <div>
        <div className="video-header body-text-12 ml-2">Walk through tutorials</div>{' '}
        <div className="body-text-14 ml-2">
          Learn how to use auto-fill job applications
        </div>
      </div>
    </div>
  );

  const inProgressView = (
    <div className="tlm-onboard-card pl-3 pr-3 pb-3">
      <div className="p-3 video-section">
        <img
          className="video-pic"
          src={SaveYourJob}
          alt="saveYourJobVideo"
          onClick={handleOpenAutoTrack}
        />
        <div>
          <div className="video-header body-text-12 ml-2">Walk through tutorials</div>{' '}
          <div className="body-text-14 ml-2">
            Watch the 30-sec video to learn how to Auto-track jobs
          </div>
        </div>
      </div>
      <div className="grey-reminder body-text-16 mt-3 mb-3">
        Try saving your first job!
      </div>

      {downloadExtension}

      <div className="v-center m-2">
        {!props.downloadExtensionComplete ? linkedInButtonDisabled : linkedInButton}
      </div>
      <div className="v-center m-2">
        {!props.downloadExtensionComplete ? indeedButtonDisabled : indeedButton}
      </div>
    </div>
  );

  const completeView = (
    <div className="tlm-onboard-card pl-3 pr-3 pb-3">{videoSection}</div>
  );

  const helperModal = (
    <Modal open={open}>
      <div className="onboarding-modal">
        <div className="onboarding-modal-closeButton" onClick={handleClose}>
          {ModalcloseIcon}
        </div>
        <p className="page-title-24 text-center p-3">Auto-track your first job👇</p>
        <div className="v-center m-3">
          <ReactPlayer playing={true} url="https://www.youtube.com/watch?v=xwU2wJGn_wU" />
        </div>
        {!props.complete && (
          <div className="v-center">
            <div className="m-3">
              {!props.downloadExtensionComplete ? linkedInButtonDisabled : linkedInButton}
            </div>
            <div className="m-3">
              {!props.downloadExtensionComplete ? indeedButtonDisabled : indeedButton}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );

  return (
    <>
      {props.complete ? completeView : inProgressView}
      {helperModal}
    </>
  );
};

export default SaveAndTrackJobs;
