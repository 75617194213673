import axios from 'axios';
import { BACKEND_PATH } from '../ServiceConstants';
import { converPageInfo } from '../../utilities/dataHandler';

import { useQuery } from '@tanstack/react-query';

export const useJobBoardInternData = (params) => {
  const { data, ...rest } = useQuery({
    queryKey: ['internJob', params],
    queryFn: async () => {
      const res = await axios.post(`${BACKEND_PATH}api/entryleveljob/intern`, params);
      return res.data;
    },
  });
  return {
    data: data && data.data.length > 0 ? data.data : [],
    page: converPageInfo(data ? data.pagination : {}),
    ...rest,
  };
};

export const useJobBoardNgData = (params) => {
  const { data, ...rest } = useQuery({
    queryKey: ['ngJob', params],
    queryFn: async () => {
      const res = await axios.post(`${BACKEND_PATH}api/entryleveljob/newgrad`, params);
      return res.data;
    },
  });
  return {
    data: data && data.data.length > 0 ? data.data : [],
    page: converPageInfo(data ? data.pagination : {}),
    ...rest,
  };
};
