import React from 'react';
import Rocket from '../../../images/Rockets.svg';

export default function GuidanceFooter(props) {
  return (
    <div className="footer">
      <img className="footer-img" src={Rocket} />
      <div className="footer-slogan">You reached the end of your list!</div>
    </div>
  );
}
