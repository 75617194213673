import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RoundTag from './RoundTag';
import { DASHBOARDCONSTANTS } from '../DashboardConstants';
import { dropdown } from '../../../components/icons';

const StatusDropdownBtn = ({ backgroundColor, currStatus, onClick }) => {
  const toggleStyle = {
    backgroundColor: backgroundColor || 'white',
    color: '#007383',
    border: 'none',
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span>
      <a
        href="/#"
        ref={ref}
        style={toggleStyle}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {RoundTag({
          text: DASHBOARDCONSTANTS.status[currStatus].statusName,
          color: '#0F0F0F',
          backgroundColor: DASHBOARDCONSTANTS.status[currStatus].tagColor,
        })}{' '}
        {dropdown}
      </a>
    </span>
  ));

  return (
    <Dropdown className="tlm-bootstrap-dropdown">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-title"></Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(DASHBOARDCONSTANTS.status).map((code) => (
          <Dropdown.Item key={code} onClick={() => onClick(code)}>
            {RoundTag({
              text: DASHBOARDCONSTANTS.status[code].statusName,
              backgroundColor: DASHBOARDCONSTANTS.status[code].tagColor,
            })}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

StatusDropdownBtn.propTypes = {
  backgroundColor: PropTypes.string,
  currStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StatusDropdownBtn;
