import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Progress from './component/progress';
import CollapseCard from '../../components/collapse-card';
import { collapseRight, redWarningDot } from '../../components/icons';
import SaveAndTrackJobs from './component/steps/SaveAndTrackJobs';
import FinishedOnboarding from './component/steps/FinishedOnboarding';
import ApplyForANewJob from './component/steps/ApplyForANewJob';
import DownloadExtension from './component/steps/DownloadExtension';
import { OB_STEP, OB_STATUS } from '../../constants/onboarding';
import { uploadResume } from '../../services/ProfileServices';
import PanelNotificationTooltip from './component/PanelNotification';
import './index.scss';
import { Mixpanel } from '../../utilities/mixpanel';

const Onboarding = (props) => {
  //Shortterm solution: Update DOWNLOAD_EXTENSION_COMPLETE as same as SAVE_AND_TRACK_JOBS
  //Should update extension download success url into tlm-ref = SAVE_AND_TRACK_JOBS
  //Shortterm solution: Update SAVE_AND_TRACK_JOBS_COMPLETE as same as APPLY_FOR_NEW_1

  function getInitStates() {
    switch (props.step) {
      case OB_STEP.DOWNLOAD_EXTENSION:
        return [false, false, false, true, '0-0', false, true, false, 0];
      case OB_STEP.DOWNLOAD_EXTENSION_COMPLETE:
        return [true, true, false, false, '0-0', false, true, false, 20];
      case OB_STEP.SAVE_AND_TRACK_JOBS:
        return [true, true, false, false, '1-1', false, true, false, 20];
      case OB_STEP.SAVE_AND_TRACK_JOBS_COMPLETE:
        return [true, true, true, true, '1-1', false, false, false, 60];
      case OB_STEP.APPLY_FOR_NEW_1:
        return [true, true, true, true, '1-1', false, false, false, 60];
      case OB_STEP.APPLY_FOR_NEW_1_2:
        return [true, true, true, true, '1-2', false, false, false, 80];
      case OB_STEP.APPLY_FOR_NEW_2:
        return [true, true, true, true, '2', false, false, false, 80];
      case OB_STEP.COMPLETE:
        return [true, true, true, true, '2', true, true, true, 100];
      case OB_STEP.FINISHED:
        return [true, true, true, true, '2', true, true, true, 100];
      default:
        return [false, false, false, true, '0-0', false, true, false, 0];
    }
  }

  const initStates = getInitStates();

  const [panelOpen, setPanelOpen] = useState(false);

  const [panelNotification, setPanelNotification] = useState(false);

  const [downloadExtensionComplete, setDownloadExtensionComplete] = useState(
    initStates[0]
  );
  const [downloadExtensionCollapse, setDownloadExtensionCollapse] = useState(
    initStates[1]
  );
  const [saveAndTrackJobsComplete, setSaveAndTrackJobsComplete] = useState(initStates[2]);
  const [saveAndTrackJobsCollapse, setSaveAndTrackJobsCollapse] = useState(initStates[3]);
  const [applyNewJobStep, setApplyNewJobStep] = useState(initStates[4]);
  const [applyNewJobComplete, setApplyNewJobComplete] = useState(initStates[5]);
  const [applyNewJobCollapse, setApplyNewJobCollapse] = useState(initStates[6]);
  const [allComplete, setAllComplete] = useState(initStates[7]);
  const [progress, setProgress] = useState(initStates[8]);

  function setStates(arr) {
    setDownloadExtensionComplete(arr[0]);
    setDownloadExtensionCollapse(arr[1]);
    setSaveAndTrackJobsComplete(arr[2]);
    setSaveAndTrackJobsCollapse(arr[3]);
    setApplyNewJobStep(arr[4]);
    setApplyNewJobComplete(arr[5]);
    setApplyNewJobCollapse(arr[6]);
    setAllComplete(arr[7]);
    setProgress(arr[8]);
  }

  useEffect(() => {
    switch (props.step) {
      case OB_STEP.DOWNLOAD_EXTENSION:
        setStates([false, false, false, true, '0-0', false, true, false, 0]);
        localStorage.setItem(OB_STATUS, OB_STEP.DOWNLOAD_EXTENSION);
        break;
      case OB_STEP.DOWNLOAD_EXTENSION_COMPLETE:
        setStates([true, true, false, false, '0-0', false, true, false, 20]);
        localStorage.setItem(OB_STATUS, OB_STEP.DOWNLOAD_EXTENSION_COMPLETE);
        break;
      case OB_STEP.SAVE_AND_TRACK_JOBS:
        setStates([true, true, false, false, '1-1', false, true, false, 20]);
        localStorage.setItem(OB_STATUS, OB_STEP.SAVE_AND_TRACK_JOBS);
        break;
      case OB_STEP.SAVE_AND_TRACK_JOBS_COMPLETE:
        setStates([true, true, true, true, '1-1', false, false, false, 60]);
        // localStorage.setItem(OB_STATUS, OB_STEP.SAVE_AND_TRACK_JOBS_COMPLETE);
        break;
      case OB_STEP.APPLY_FOR_NEW_1:
        setStates([true, true, true, true, '1-1', false, false, false, 60]);
        localStorage.setItem(OB_STATUS, OB_STEP.APPLY_FOR_NEW_1);
        break;
      case OB_STEP.APPLY_FOR_NEW_1_2:
        setStates([true, true, true, true, '1-2', false, false, false, 80]);
        localStorage.setItem(OB_STATUS, OB_STEP.APPLY_FOR_NEW_1_2);
        break;
      case OB_STEP.APPLY_FOR_NEW_2:
        setStates([true, true, true, true, '2', false, false, false, 80]);
        localStorage.setItem(OB_STATUS, OB_STEP.APPLY_FOR_NEW_2);
        break;
      case OB_STEP.COMPLETE:
        setStates([true, true, true, true, '2', true, true, true, 100]);
        localStorage.setItem(OB_STATUS, OB_STEP.COMPLETE);
        break;
      case OB_STEP.FINISHED:
        setStates([true, true, true, true, '2', true, true, true, 100]);
        localStorage.setItem(OB_STATUS, OB_STEP.FINISHED);
        break;
      default:
        setStates([false, false, false, false, '2', false, true, false, 0]);
    }
  });

  useEffect(() => {
    const panelStatus = localStorage.getItem('enablePanel');
    if (panelStatus !== null) {
      setPanelOpen(JSON.parse(panelStatus));
    } else {
      setPanelOpen('true');
    }
  }, []);

  function triggerStonlyEvent() {
    const pathName = window.location.pathname;
    let isDashboardPage = pathName === '/' || pathName.includes('dashboard');
    if (
      JSON.parse(localStorage.getItem('enablePanel')) === false &&
      localStorage.getItem('numOfDashboardNewUserGuideFlow') !== '1' &&
      isDashboardPage
    ) {
      StonlyWidget('openGuidedTour', { guideId: 'dOWd8JdSlW' });
      localStorage.setItem('numOfDashboardNewUserGuideFlow', '1');
    }
  }

  function togglePanel() {
    setPanelOpen(!panelOpen);
    localStorage.setItem('enablePanel', JSON.stringify(!panelOpen));

    triggerStonlyEvent();
    if (panelOpen === true) {
      Mixpanel.track('Onboarding: Collapse');
    } else {
      Mixpanel.track('Onboarding: Expand');
    }
  }

  function handleUpdateResume(resume) {
    return uploadResume(props.profileId, resume)
      .then(({ data }) => {
        props.setStep(OB_STEP.APPLY_FOR_NEW_2);
        props.directToProfile(props.profileId);
        return data;
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          return error;
        } else {
          throw error;
        }
      });
  }
  return (
    <div className="tlm-onboard" id={panelOpen ? 'tlm-onboard' : 'tlm-onboard-s'}>
      {(panelOpen || panelNotification) && <div className="grey-layer"></div>}
      {panelOpen && (
        <div id="rsz" onClick={togglePanel}>
          {collapseRight}
        </div>
      )}
      {!panelOpen && panelNotification && (
        <PanelNotificationTooltip setPanelNotification={setPanelNotification} />
      )}
      {!panelOpen && (
        <div className={'onboarding-text-s'} onClick={togglePanel}>
          <div className="body-text-16">Quick Start</div>
          <div className="rsz-left">{collapseRight}</div>
          <div className="red-warning-dot">{!allComplete && redWarningDot}</div>
        </div>
      )}
      <div className={panelOpen ? '' : 'd-none'}>
        <div className="tlm-onboard-header">
          <h1 className="tlm-onboard-header-title">Offerland Quick Start</h1>
          <div className="tlm-onboard-header-progress">
            <Progress progress={progress} />
          </div>
        </div>
        <div className="tlm-onboard-content">
          <CollapseCard
            header={'Download Extension'}
            collapsed={downloadExtensionCollapse}
            completed={downloadExtensionComplete}
            noHeader={false}
            tag="download"
            progress={progress}
          >
            <DownloadExtension
              complete={downloadExtensionComplete}
              setStep={props.setStep}
            />
          </CollapseCard>
          <CollapseCard
            header={'Save and Track Jobs'}
            collapsed={saveAndTrackJobsCollapse}
            completed={saveAndTrackJobsComplete}
            noHeader={false}
            tag="autotrack"
            progress={progress}
          >
            <SaveAndTrackJobs
              complete={saveAndTrackJobsComplete}
              downloadExtensionComplete={downloadExtensionComplete}
              setStep={props.setStep}
              collapsed={saveAndTrackJobsCollapse}
            />
          </CollapseCard>
          <CollapseCard
            header={'Auto-fill Job Application'}
            collapsed={applyNewJobCollapse}
            completed={applyNewJobComplete}
            noHeader={false}
            tag="autofill"
            progress={progress}
          >
            <ApplyForANewJob
              saveAndTrackJobsComplete={saveAndTrackJobsComplete}
              downloadExtensionComplete={downloadExtensionComplete}
              allComplete={allComplete}
              completed={applyNewJobComplete}
              applyNewJobStep={applyNewJobStep}
              setStep={props.setStep}
              uploadResume={handleUpdateResume}
            />
          </CollapseCard>
          {allComplete && (
            <CollapseCard
              header={'hello header'}
              collapsed={false}
              completed={false}
              noHeader={true}
            >
              <FinishedOnboarding
                setPanelOpen={setPanelOpen}
                setPanelNotification={setPanelNotification}
                setClose={props.handleClose}
                setStep={props.setStep}
              />
            </CollapseCard>
          )}
        </div>
      </div>
    </div>
  );
};

Onboarding.propTypes = {
  step: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
  directToProfile: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Onboarding;
