import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { cloneProfile, deleteProfile } from '../services/ProfileServices';
import { popupError } from '../utilities/error-handler';
// import DeleteConfirmationModal from './DeleteConfirmationModal';
// import CopyProfileModal from '../views/main-view/components/CopyProfileModal';
import { Mixpanel } from '../utilities/mixpanel';
import AlertDialog from '@components/CommonDialog';
import FormItem from '@views/profile-view-2/components/form-item';

const ProfileContextMenu = (props) => {
  const {
    setShowDuplicateProfile,
    setShowDeleteProfile,
    showDuplicateProfile,
    showDeleteProfile,
    showAddProfile,
    setShowAddProfile,
    onAddNewProfile,
    loading,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    deleteProfile(props.profileId)
      .then((res) => {
        setIsLoading(false);
        setShowDeleteProfile(false);
        if (props.currentProfile === props.profileId) {
          const profileToGo = props.profiles.titles.find(
            (profile) => profile.id !== props.profileId
          )?.id;
          props.redirectToProfile(profileToGo);
        }
        Mixpanel.track('Delete Profile', {
          profileType:
            props.profiles['default_profile_id'] === props.profileId ? 'default' : 'new',
          ProfileName: props.profileTitle,
        });
        props.refresh();
      })
      .catch((err) => {
        setIsLoading(false);
        popupError('Something went wrong while deleting. Please re-upload resume.');
      });
  };

  const handleDuplicate = (newProfileTitle) => {
    setIsLoading(true);
    return cloneProfile(props.profileId, newProfileTitle)
      .then((res) => {
        setIsLoading(false);
        setShowDuplicateProfile(false);
        props.refresh();
        props.redirectToProfile(res.data.profile_id);
        Mixpanel.track('Duplicate Profile', {
          profileType:
            props.profiles['default_profile_id'] === props.profileId ? 'default' : 'new',
          ProfileName: newProfileTitle,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };

  const [errorMsg, setErrorMsg] = useState('');
  const [titleName, setTitleName] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  useEffect(() => {
    if (showAddProfile) {
      setTitleName('');
      setDialogTitle('Add Profile');
    }
    if (showDuplicateProfile) {
      setTitleName(props.profileTitle + ' ( Copy )');
      setDialogTitle('Duplicate Profile');
    }
  }, [props.profileTitle, showDuplicateProfile, showAddProfile]);
  // console.log('props.profileTitle', props.profileTitle, titleName);
  const updateInfo = (newVal, flag) => {
    setTitleName(newVal.trim());
    setErrorMsg('');
  };

  const isDuplicate = (name) => {
    return !!props.profiles.titles.find((profile) => profile.title === name);
  };

  const onDuplicateSure = () => {
    if (!titleName) {
      setErrorMsg("Name can't be empty");
      return;
    } else if (isDuplicate(titleName)) {
      setErrorMsg('Name has been taken');
      return;
    } else {
      // clone
      if (showDuplicateProfile) {
        handleDuplicate(titleName);
      }
      // 新增
      if (showAddProfile) {
        onAddNewProfile(titleName);
      }
    }
  };
  return (
    <>
      <AlertDialog
        open={showDuplicateProfile || showAddProfile}
        title={dialogTitle}
        onClose={() => {
          setShowDuplicateProfile(false);
          setShowAddProfile(false);
        }}
        onSure={onDuplicateSure}
        loading={loading || isLoading}
      >
        <FormItem
          label="New Profile Name"
          handleUpdate={(info, valid) => updateInfo(info, valid)}
          value={titleName}
          error={!!errorMsg}
          errorMsg={errorMsg}
        ></FormItem>
      </AlertDialog>

      <AlertDialog
        key="Delete"
        width="480px"
        sureText="Delete"
        open={showDeleteProfile}
        title={'Delete Profile'}
        onClose={() => setShowDeleteProfile(false)}
        onSure={handleDelete}
        loading={isLoading}
      >
        <Stack
          sx={{
            display: 'inline-block',
            fontSize: '18px',
            lineHeight: '24px',
            wordBreak: 'break-all',
          }}
        >
          Are you sure to delete your profile :&nbsp;&nbsp;
          <strong>{props.profileTitle}</strong>&nbsp;&nbsp;？
        </Stack>
      </AlertDialog>
    </>
  );
};

ProfileContextMenu.propTypes = {
  id: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  profileTitle: PropTypes.string.isRequired,
  currentProfile: PropTypes.string.isRequired,
  profiles: PropTypes.object.isRequired,
  redirectToProfile: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ProfileContextMenu;
