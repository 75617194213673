import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import UpgradeLandingImg1 from '../../images/successful.png';
import { makeStyles } from '@mui/styles';
import { Button } from '../../stories/Button';
import { ModalcloseIcon } from '../../components/icons';
import { Mixpanel } from '../../utilities/mixpanel';
import '../../index.scss';
import '../../styles/typography';
import '../../styles/color';
import { Link, useHistory } from 'react-router-dom';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  background_img: {
    width: '100%',
    background: '#EFFDFB',
    textAlign: 'center',
    margin: '0',
  },
  paper: {
    position: 'absolute',
    width: 708,
    minHeight: 240,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  pic1: {
    width: '100%',
    height: '240px',
    objectFit: 'none',
    objectPosition: 'bottom',
  },
  pic2: {
    marginLeft: 100,
    marginRight: 100,
    marginTop: 10,
  },
  picTitle: {
    color: '#727272',
    marginLeft: 70,
    marginTop: 27,
  },

  picText: {
    marginTop: 5,
    marginLeft: 70,
    marginBottom: 50,
  },
  closeButton: {
    cursor: 'pointer',
    marginTop: '0',
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  text_body: {
    maxWidth: '708px',
    padding: '32px 96px 40px 96px',
    margin: 'auto',
  },
  h3: {
    color: 'var(--OF-dark-gray, #4F4F4F)',
    textAlign: 'center',
    fontSize: '28.68px',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '38.241px',
  },

  p_text: {
    color: '#4f4f4f',
    textAlign: 'center',
    fontSize: '18.125px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '160%',
  },

  btn_block: {
    display: 'flex',
    placeContent: 'flex-end',
    gap: '50px',
    alignItems: 'center',
    margin: '70px 0 0',
  },
  got_it: {
    color: '#05756C',
    fontSize: '19.12px',
    fontWeight: '400',
    lineHeight: '23.9px',
  },
  upgrade_btn: {
    fontSize: '19.12px !important',
    fontWeight: '600 !important',
  },
}));

const ProfileValidateModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const location = useHistory();
  const handeleRedirect = () => {
    location.push('/plan-selection');
    Mixpanel.track('Click UpgradeToPremium_Profile');
  };
  const body = (
    <div className="tlm">
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.closeButton} onClick={props.handleClose}>
          {ModalcloseIcon}
        </div>
        <div className={classes.background_img}>
          <div>
            <img
              className={classes.pic1}
              src={UpgradeLandingImg1}
              alt="Save and track jobs"
            />
          </div>
        </div>
        <div className={classes.text_body}>
          <h3 className={classes.h3}> Upgrade to Premium for more benefit</h3>
          <p className={classes.p_text}>
            User on the Starter plan can only have 1 profile. Please delete one or more of
            your existing profile before creating a new one. User on the Premium Plan or
            above can have unlimited profiles.
          </p>

          <div className={classes.btn_block}>
            <Link onClick={props.handleClose} className={classes.got_it}>
              Got it
            </Link>
            <Button
              className={classes.upgrade_btn}
              width="230px"
              height="40px"
              onClick={() => handeleRedirect()}
            >
              Upgrade to Premium
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  return <Modal open={props.open}>{body}</Modal>;
};

ProfileValidateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProfileValidateModal;
