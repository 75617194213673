import React, { useEffect } from 'react';

// components
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Menu } from '../../../stories/Menu.tsx';
import { Mixpanel } from '../../../utilities/mixpanel';

// styling
import './StatusFilter.scss';

// ----------------------------------------------------------------------

/**
 *
 * @param
 * STATUSTYPE: array of type for filter
 * handleStatusFilter: event listener for status filter
 */

export default function StatusFilter({ STATUSTYPE, handleStatusFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statuses, setStatuses] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    Mixpanel.track('Filter Status');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'status-filter-multiple-select' : undefined;

  const handleStatusFilterCurrent = (e) => {
    setStatuses(e);
    // update status in the main content file
    handleStatusFilter(e);
  };
  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        className="tlm-status-filter-btn"
      >
        <FilterListIcon />
      </IconButton>
      <Popover
        className="tlm-status-filter-container"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Menu
          checkbox={true}
          reset={true}
          options={STATUSTYPE}
          title="Filter"
          onChange={handleStatusFilterCurrent}
          values={statuses}
        />
      </Popover>
    </>
  );
}
