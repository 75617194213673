import axios from 'axios';
import { BACKEND_PATH } from './ServiceConstants';
import { useQuery } from '@tanstack/react-query';

export const useGetUserInfo = () => {
  return useQuery(['getUserStripeStatus'], async () => {
    try {
      const response = await axios.get(`${BACKEND_PATH}api/user-stripe-status/`);
      return response.data;
    } catch (error) {
      // Optionally handle errors or throw them to be caught by error boundaries or react-query error handling
      throw error;
    }
  });
};
