import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';

export const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: grey[100],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
