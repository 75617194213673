import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import errorModalSvg from '@images/errorModal.svg';
import './ErrorModel.scss';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing.units * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ErrorModal(props) {
  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('error-dialog-root-el'));
  };

  const defaultMessage = 'Something went wrong, please try again.';
  const errorMessage = props.errMsg ? props.errMsg : defaultMessage;

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        fullWidth
        onClose={handleClose}
        aria-labelledby="error-dialog-title"
        open={true}
      >
        <DialogTitle
          id="error-dialog-title"
          className="error-title"
          onClose={handleClose}
        >
          An error occurred
        </DialogTitle>
        <DialogContent dividers>
          <div className="d-flex error-img">
            <img src={errorModalSvg} alt="something wrong with web page" />
          </div>
          <div className="error-msg">{errorMessage}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
