import React from 'react';
import clsx from 'clsx';
import './Menu.scss';
import { Checkbox } from './Checkbox';

export interface MenuProps {
  checkbox?: boolean;
  options: string[];
  values?: string[];
  singleValueSelection?: boolean;
  title?: string;
  reset?: boolean;
  onChange?: (e: any) => void;
}

export const Menu: React.FC<MenuProps> = ({
  checkbox = false,
  options = [],
  values = [],
  singleValueSelection = false,
  title = null,
  reset = false,
  onChange,
  ...props
}) => {
  let menuClassName = 'tlm-menu body-text-14';
  if (checkbox) {
    menuClassName = clsx(menuClassName, 'tlm-menu-checkbox');
  }

  const [checkedOptions, setCheckedOptions] = React.useState<string[]>([...values]);

  // If user has defined onChange method, this function will pass the on-change event to user defined onChange callback.
  const propsOnChange = (updatedValues: any) => {
    if (onChange) {
      onChange(updatedValues);
    }
  };

  // Callback method to handle on change for any checkbox.
  const handleOnChange = (option: any) => {
    let updatedValues = [...checkedOptions];
    if (singleValueSelection) {
      if (!values.includes(option)) {
        updatedValues.pop();
        updatedValues.push(option);
      }
    } else {
      if (updatedValues.includes(option)) {
        let index = updatedValues.indexOf(option);
        updatedValues.splice(index, 1);
      } else {
        updatedValues.push(option);
      }
    }
    setCheckedOptions(updatedValues);
    propsOnChange(updatedValues);
  };

  // Handle the reset button to reset all checkbox values.
  const handleReset = () => {
    setCheckedOptions([]);
    propsOnChange([]);
  };

  return (
    <div className={menuClassName}>
      {title && (
        <div
          className="menu-item body-text-14"
          style={{ marginBottom: '12px', fontWeight: 'bold' }}
        >
          {title}
        </div>
      )}
      {checkbox
        ? options.map((option) => (
            <Checkbox
              label={option}
              checked={checkedOptions.includes(option)}
              onChange={() => handleOnChange(option)}
            />
          ))
        : options.map((option) => (
            <div className="menu-item body-text-14" style={{ marginBottom: '12px' }}>
              {option}
            </div>
          ))}
      {reset && (
        <div
          className="menu-reset-button body-test-14"
          onClick={() => handleReset()}
          style={{
            textDecoration: 'underline',
            color: '#05756c',
            marginBottom: '12px',
            cursor: 'pointer',
          }}
        >
          {'Reset'}
        </div>
      )}
    </div>
  );
};
