import React, { useState } from 'react';
import arrowUp from '../../../images/arrowUp.svg';
import arrowDown from '../../../images/arrowDown.svg';
import './QuestionCard.scss';

const QuestionCard = (props) => {
  const [cardOpen, setCardOpen] = useState(false);
  return (
    <div className="tlm-questionCard">
      <div
        className="tlm-questionCard-question"
        onClick={() => {
          setCardOpen(!cardOpen);
        }}
      >
        <div className="tlm-questionCard-question-content">{props.question}</div>
        {cardOpen ? (
          <img className="tlm-questionCard-question-icon" src={arrowUp} />
        ) : (
          <img className="tlm-questionCard-question-icon" src={arrowDown} />
        )}
      </div>
      {cardOpen && <div className="tlm-questionCard-answer">{props.answer}</div>}
    </div>
  );
};

export default QuestionCard;
