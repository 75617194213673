export const OB_STEP = {
  LANDING: 'OnboardWelcomePage',
  DOWNLOAD_EXTENSION: 'DownloadExtension',
  DOWNLOAD_EXTENSION_COMPLETE: 'DownloadExtensionComplete',
  SAVE_AND_TRACK_JOBS: 'SaveAndTrackJobs',
  SAVE_AND_TRACK_JOBS_COMPLETE: 'SaveAndTrackJobsComplete',
  APPLY_FOR_NEW_1: 'ApplyForANewJobStep1',
  APPLY_FOR_NEW_1_2: 'ApplyForANewJobStep1_2',
  APPLY_FOR_NEW_2: 'ApplyForANewJobStep2',
  COMPLETE: 'COMPLETE',
  FINISHED: 'FINISHED',
};

export const OB_STATUS = 'OnboardingStatus';
