import { arrToMap } from '@utilities/tool';

export const baseInfoRules = {
  first_name: {
    required: true,
    msg: 'Please enter first name before save',
  },
  last_name: {
    required: true,
    msg: 'Please enter last name before save',
  },
  email: {
    required: true,
    msg: 'Please enter email before save',
  },
};

export const linksRules = {};

export const identificationsRules = {};

export const defaultExperienceInfo = {
  title: '',
  company_name: '',
  location: '',
  start_date: '',
  end_date: '',
  description: '',
  current_work_status: false,
};

export const experienceRules = {
  title: {
    required: true,
    msg: 'Please enter job title before save',
  },
  company_name: {
    required: true,
    msg: 'Please enter company before save',
  },
  start_date: {
    required: true,
    msg: 'Please enter start date before save',
  },
  end_date: {
    required: true,
    msg: 'Please enter end date before save',
  },
};

export const defaultEducationalInfo = {
  school: '',
  degree: '',
  major: '',
  second_major: '',
  gpa: '',
  start_date: '',
  end_date: '',
  description: '',
};

export const educationalRules = {
  school: {
    required: true,
    msg: 'Please enter school before save',
  },
  degree: {
    required: true,
    msg: 'Please enter degree before save',
  },
  start_date: {
    required: true,
    msg: 'Please enter start date before save',
  },
  end_date: {
    required: true,
    msg: 'Please enter end date before save',
  },
};

export const defaultCertificationInfo = {
  name: '',
  issue_organization: '',
  issue_date: '',
  expiration_date: '',
};

export const certificationRules = {
  name: {
    required: true,
    msg: 'Please enter name',
  },
  issue_date: {
    msg: 'Must be earlier than the expiration date',
  },
  expiration_date: {
    msg: 'Must be later than the issue date',
  },
};

export const rulesMap = {
  work: experienceRules,
  educational: educationalRules,
  certificate: certificationRules,
};

export const boolMap = {
  true: 'Yes',
  false: 'No',
};

// Self-Identification Questions
// 4个下拉列表
export const genderList = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
  { id: 3, name: 'I choose not to disclose' },
];
export const genderListMap = arrToMap(genderList);

export const veteransList = [
  {
    id: 1,
    name:
      'I identify as one or more of the classifications of protected veterans listed in the linked article',
  },
  { id: 2, name: 'I identify as a veteran, just not a protected veteran' },
  { id: 3, name: 'I’m not a veteran' },
  { id: 4, name: 'I choose not to disclose' },
];
export const veteransListMap = arrToMap(veteransList);

export const ethnicityList = [
  'Black or African American',
  'American-Indian / Alaskan Native',
  'Asian',
  'Hispanic or Latino',
  'Native Hawaiian or Other Pacific Islander',
  'White (Not of Hispanic Origin)',
  'I choose not to disclose',
];
export const disabilityList = [
  { id: 1, name: 'Yes, I have a disability, or have had one in the past' },
  { id: 2, name: 'No, I do not have a disability and have not had one in the past' },
  { id: 3, name: 'I choose not to disclose' },
];
export const disabilityListMap = arrToMap(disabilityList);

// help info
export const identificationSubheading =
  'Answers to the self-identification questions are voluntary and will only be used to streamline auto-filling your job applications. Click here to check our Privacy Policy';
// 链接转跳地址
export const identificationUrls = {
  privacy: 'https://home.offerland.ai/privacy-terms/',
  veterans: 'https://home.offerland.ai/contactus/self-identity/#veterans_status',
  ethnicity: 'https://home.offerland.ai/contactus/self-identity/#ethnicity',
  disability: 'https://home.offerland.ai/contactus/self-identity/#disability',
};
