import TextField from '@mui/material/TextField';
import React, { useEffect, useRef } from 'react';
function AutoFocusTextField(props) {
  const inputRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <TextField inputRef={inputRef} {...props} />;
}
export default AutoFocusTextField;
