import React from 'react';

import './index.scss';
import '../../styles/typography.scss';
import '../../styles/color.scss';

export default function Interview() {
  return (
    <div className="interview">
      <div className="interview-header">
        <div>
          <span className="interview-first-title page-title-24">
            Offerland Interview Questions Library
          </span>
        </div>
      </div>
      <iframe
        className="interview-chart"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTaesJlt8cPs58mcnG2NgP-jM-LIp2FgNc-R9rLR4N9fYHm1mY3o6PhAwwE_3lYSDHA1DPzMGtO6gpP/pubhtml?widget=true&amp;headers=false"
      ></iframe>
    </div>
  );
}
