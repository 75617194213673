import React, { useState, useEffect } from 'react';
import GoogleLogin from 'react-google-login';
import LoginServices from '../services/LoginServices';
import { GoogleIcon, officialLogo } from '../components/icons';
import queryString from 'query-string';
import { OB_STEP } from '../constants/onboarding';
import { Mixpanel } from '../utilities/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';
import { CreateOrToggleFeatureFlag } from '../utilities/feature-flag';

function LoginPage(props) {
  const privacyAndTermsText = 'Privacy & Terms';
  const contactUsText = 'Contact Us';
  const GOOGLE_CLIENT_ID =
    '984821712016-pv72ql9omr8u3u380uoqajbaq2i4i4nk.apps.googleusercontent.com';
  const [auth0LoginFeatureFlag, setAuth0LoginFeatureFlag] = useState(false);
  const param = queryString.parse(window.location.search);
  const extensionPinPopup =
    Object.keys(param).length !== 0
      ? param['tlm-ref'] === OB_STEP.DOWNLOAD_EXTENSION_COMPLETE
      : false;

  async function FeatureFlagFetch() {
    let auth0Login = await CreateOrToggleFeatureFlag('Auth0Login');
    setAuth0LoginFeatureFlag(auth0Login);
  }
  useEffect(() => {
    FeatureFlagFetch();
  }, []);

  const jobboardStatus =
    Object.keys(param).length !== 0
      ? param['jobboard'] === 'ng-opportunity'
        ? 'ng-opportunity'
        : param['jobboard'] === 'intern-opportunity'
        ? 'intern-opportunity'
        : ''
      : '';
  Mixpanel.track('View Login Page');

  const { isAuthenticated, getAccessTokenSilently, audience, user } = useAuth0();

  const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    if (isAuthenticated) {
      handleAuth0(user);
    }
    return (
      !isAuthenticated && (
        <div>
          <button
            className="tlm-google-button tlm-login-button"
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Log in/Sign Up
          </button>
          {isAuthenticated && <p>You have already Login</p>}
        </div>
      )
    );
  };

  async function handleAuth0(userData) {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience,
        scope: 'openid profile email',
      },
    });

    const userDataRecord = {
      first_name: userData.nickname,
      last_name: '',
      email: userData.email,
      username: userData.nickname,
      pk: '',
      auth0_user_id: userData?.sub.trim(),
    };

    const objRecord = {
      access_token: accessToken,
      user: userDataRecord,
    };

    const response = await LoginServices.auth0SetupUserLogin(objRecord);
    if (response.status === 200 || response.status === 201) {
      extensionPinPopup
        ? props.history.push(`/?tlm-ref=DownloadExtensionComplete`)
        : props.history.push(`/`);
    }
  }

  const LoginViewForJobBoard = (
    <div className="tlm-login-container">
      <div className="tlm-login-content">
        <div className="tlm-website-header">
          <icon className="tlm-icon">{officialLogo}</icon>
        </div>
        <h1 className="tlm-login-header">New Grad/Intern Still Hiring List</h1>

        <div className="tlm-login-jobboard-text-container">
          <h6>
            Below is a list of companies that are hiring New Grads and Interns. We check
            regularly based on our Job Board data to keep this live list up-to-date. This
            list is powered by Offerland, an AI tool that empowers you to take control of
            your job searching process. Offerland can also help you to Auto-fill &
            Auto-track applications 10x faster.
          </h6>
        </div>
        <div className="tlm-social-login-button-group">
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Continue with Google"
            onSuccess={(res) =>
              handleGoogleResponse(res, props, extensionPinPopup, jobboardStatus)
            }
            onFailure={(res) =>
              handleGoogleResponse(res, props, extensionPinPopup, jobboardStatus)
            }
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="tlm-google-button tlm-login-button"
              >
                <div className="icon">
                  <GoogleIcon />
                </div>
                Login to See More
              </button>
            )}
          />
        </div>
        <iframe
          title="airtable"
          class="airtable-embed"
          src="https://airtable.com/embed/shraQjzCtg8Ft8io7?backgroundColor=green&viewControls=on"
          frameborder="0"
          onmousewheel=""
          width="960px"
          height="533"
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        ></iframe>
      </div>

      <div className="tlm-login-footer">
        <span>
          <a
            href="https://offerland2020.wpcomstaging.com/privacy-terms/"
            onClick={() => Mixpanel.track('Click Privacy & Terms')}
          >
            {privacyAndTermsText}
          </a>
          <a
            href="https://home.offerland.ai/contactus/"
            onClick={() => {
              Mixpanel.track('Click Contact US');
            }}
          >
            {contactUsText}
          </a>
        </span>
      </div>
    </div>
  );

  const LoginView = (
    <div className="tlm-login-container">
      <div className="tlm-login-content">
        <div className="tlm-website-header">
          <icon className="tlm-icon">{officialLogo}</icon>
        </div>

        <div className="tlm-social-login-button-group">
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Continue with Google"
            onSuccess={(res) =>
              handleGoogleResponse(res, props, extensionPinPopup, jobboardStatus)
            }
            onFailure={(res) =>
              handleGoogleResponse(res, props, extensionPinPopup, jobboardStatus)
            }
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="tlm-google-button tlm-login-button"
              >
                <div className="icon">
                  <GoogleIcon />
                </div>
                Continue with Google
              </button>
            )}
          />
          {auth0LoginFeatureFlag && <LoginButton />}
        </div>
      </div>
      <div className="tlm-login-footer">
        <span>
          <a
            href="https://offerland2020.wpcomstaging.com/privacy-terms/"
            onClick={() => Mixpanel.track('Click Privacy & Terms')}
          >
            {privacyAndTermsText}
          </a>
          <a
            href="https://home.offerland.ai/contactus/"
            onClick={() => {
              Mixpanel.track('Click Contact US');
            }}
          >
            {contactUsText}
          </a>
        </span>
      </div>
    </div>
  );

  return jobboardStatus === '' ? LoginView : LoginViewForJobBoard;
}

async function handleGoogleResponse(res, props, extensionPinPopup, jobboardStatus) {
  Mixpanel.track('Login Google Clicked');
  const response = await LoginServices.googleLogin(res.accessToken);
  if (response === 200 || response === 201) {
    // Success Google Login is installed on login service googleLogin
    extensionPinPopup
      ? props.history.push(`/dashboard?tlm-ref=DownloadExtensionComplete`)
      : jobboardStatus !== ''
      ? props.history.push(`/job-board/${jobboardStatus}`)
      : props.history.push(`/`);
  } else {
    Mixpanel.track('Google Login Failed');
  }
}

export default LoginPage;
